import React, {Component} from 'react';
import TaskPriosContainer from './TaskPriosList/TaskPriosContainer'
import moment from "moment";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";

class TaskPriosContent extends Component {
    constructor(props) {
        super(props)
        this.state = {
            query: this.props.uri,
            startDate: localStorage.getItem('date_start_afn') ? moment(localStorage.getItem('date_start_afn'), 'DD/MM/YYYY') : null,
            endDate: localStorage.getItem('date_end_afn') ? moment(localStorage.getItem('date_end_afn'), 'DD/MM/YYYY') : null,
        }
    }

    handleChangeFilter = (e) => {
        let filter = e.target.name.substring(7)
        localStorage.setItem(filter, e.target.value)

        this.props.getTaskPrios({
            query: this.state.query,
            [filter]: localStorage.getItem(filter)
        })
    }

    handleChangeDates = (start, end) => {
        this.setState({
            startDate: start,
            endDate: end
        })
        if (!end) {
            localStorage.setItem('date_end_afn', moment().format('L'))
        } else {
            localStorage.setItem('date_end_afn', moment(end).format('L'))
        }
        localStorage.setItem('date_start_afn', moment(start).format('L'))
        this.props.getTaskPrios({
            query: this.state.query,
            date_start: localStorage.getItem('date_start_afn') || '',
            date_end: localStorage.getItem('date_end_afn') || ''
        })
    }

    resetFilters = () => {
        localStorage.setItem('date_end_afn', '')
        localStorage.setItem('date_start_afn', '')
        this.setState({
            date: ''
        })
        this.props.getTaskPrios({
            query: this.state.query,
            date_start: '',
            date_end: '',
        })
    }


    render() {
        const {uri_detail, total} = this.props

        return (
            <div className={'tasks-container container'}>
                <div className={'row'}>
                    <div className={'col-12'}>
                        <h1 className={'title-primary title-task'}>AFN prioritaires</h1>
                        {total > 0 && <small className={'bold text-uppercase'}>{total} tâches</small>}
                    </div>
                    <div className={'col-12 filters'}>
                        <div className={'content'}>
                            <select
                                name="filter_score"
                                onChange={this.handleChangeFilter}
                                className={'small-select select-filter-tasks custom-select'}
                                value={localStorage.getItem('score')}
                            >
                                <option value="100">100% de pièces reçues</option>
                                <option>80</option>
                                <option>75</option>
                                <option>67</option>
                                <option>60</option>
                                <option>50</option>
                                <option>40</option>
                                <option>33</option>
                                <option>25</option>
                                <option>20</option>
                                <option>0</option>
                            </select>
                            <select
                                name="filter_portefeuille"
                                onChange={this.handleChangeFilter}
                                className={'small-select select-filter-tasks custom-select'}
                                value={localStorage.getItem('portefeuille')}
                            >
                                <option value="">Portefeuille</option>
                                <option>AMA</option>
                                <option>BQC</option>
                                <option>COM</option>
                                <option>CON</option>
                                <option>COP</option>
                                <option>COU</option>
                                <option>DIR</option>
                                <option>DUC</option>
                                <option>PRE</option>
                            </select>
                            <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="fr">
                                <DatePicker
                                    className={'mr-1 small date-picker'}
                                    label='Date de début'
                                    value={this.state.startDate}
                                    onChange={(newValue) => {
                                        this.handleChangeDates(newValue, this.state.endDate)
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>

                            <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="fr">
                                <DatePicker
                                    className={'small date-picker'}
                                    label='Date de fin'
                                    value={this.state.endDate}
                                    onChange={(newValue) => {
                                        this.handleChangeDates(this.state.startDate, newValue)
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                    minDate={this.state.startDate}
                                />
                            </LocalizationProvider>
                            <div>
                                <small onClick={this.resetFilters}>Réinitialiser les filtres</small>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={'row'}>
                    <TaskPriosContainer className='loader' url={uri_detail}/>
                </div>
            </div>
        );
    }
};

export default TaskPriosContent;
