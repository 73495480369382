import React, {Fragment} from 'react';
import PropTypes from 'prop-types';
import './calltoaction.scss'
import {Link} from "react-router-dom";
import {tokenUser} from "../../../../redux/selectors/localStorage/localStorage";

const CallToAction = (props) => {
    const {action, toolText, url, link_externe, publish, disabled, disabledReason, ...other} = props

    if (!link_externe && !disabled && url) {
        return (
            <Link to={url} className={`${action} call-to-action ${disabled && 'disabled'}`} {...other}>
                <span className={'tooltips'}>
                    {toolText}
                </span>
            </Link>
        )
    } else if (!link_externe && disabled) {
        return (
            <div className={`${action} call-to-action ${disabled && 'disabled'}`}{...other} onClick={(e) => {
                e.preventDefault()
            }}>
                <span className={'tooltips'}>
                    {toolText}<br/>
                    <strong>{disabledReason}</strong>
                </span>
            </div>
        )
    } else if (link_externe && url && publish) {
        return (
            <form action={url.indexOf('?') < 0 ? url.replace('&', '?') : url} className={'inline'} target={'_blank'} method={'POST'}>
                <input type={'hidden'} name={'access_token'} value={tokenUser} />
                <button type={'submit'} className={`${action} call-to-action ${disabled && 'disabled'}`} {...other}
                   rel="noopener noreferrer">
                <span className={'tooltips'}>
                    {toolText}
                    {disabled && <Fragment><br/><strong>{disabledReason}</strong></Fragment>}
                </span>
                </button>
            </form>

        )
    } else if (link_externe && url) {
        return (
            <a href={url} className={`${action} call-to-action ${disabled && 'disabled'}`} {...other} target={'_blank'}
               rel="noopener noreferrer">
                <span className={'tooltips'}>
                    {toolText}
                    {disabled && <Fragment><br/><strong>{disabledReason}</strong></Fragment>}
                </span>
            </a>
        )
    } else {
        return (
            <a className={`${action} call-to-action ${disabled && 'disabled'}`} {...other}>
                <span className={'tooltips'}>
                    {toolText}
                    {disabled && <Fragment><br/><strong>{disabledReason}</strong></Fragment>}
                </span>
            </a>
        )
    }
}

CallToAction.defaultProps = {
    action: 'email',
    toolText: 'Envoyer un email',
    link_externe: false,
    disabled: false,
    url: ''
}

CallToAction.propTypes = {
    action: PropTypes.oneOf([
        'email',
        'courrier',
        'appel',
        'chat',
        'edit-document',
        'edit-contract',
        'accident',
        'validate_rib',
        'avenant',
        'paiement',
        'coordonnees',
        'demenagement',
        'mdp-client',
        'create-account',
        'delete',
        'add-chrono',
        'add-note',
        'paiement-mail',
        'edit-note',
        'delete-note',
        'change_address',
        'reclamation',
        'edit-repairer',
        'edit-accident',
        'edit-tiers-payeur',
        'delete-penalties'
    ]),
    toolText: PropTypes.string,
    url: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.bool,
        PropTypes.object
    ]),
    disabled: PropTypes.bool
}

export default CallToAction;
