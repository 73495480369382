import {createSelector} from 'reselect'

/* UI */
const uiSelector = state => state.ui

/* Maintenance */
export const getMaintenance = createSelector(
    uiSelector,
    ui => ui.maintenance
)

/* Login */
export const getLogin = createSelector(
    uiSelector,
    ui => ui.login
)

/* Filters */
const filtersEntitySelector = (state, entity) => state.ui.filters[entity]

export const getFiltersEntity = createSelector(
    filtersEntitySelector,
    filters => filters || {}
)

/* Loading */
const loadingEntitySelector = (state, entity) => state.ui.loading[entity]

export const getLoading = createSelector(
    uiSelector,
    ui => ui.loading
)

export const getLoadingEntity = createSelector(
    loadingEntitySelector,
    loading => loading
)

/* Error */
const errorEntitySelector = (state, entity) => state.ui.error[entity]

export const getErrorEntity = createSelector(
    errorEntitySelector,
    error => error
)

/* Selected Entity */
const selectedEntitySelector = (state, entity) => state.ui.selected[entity]

export const getIdSelectedEntity = createSelector(
    selectedEntitySelector,
    selected => selected
)

/* Accident ID after POST */
export const getAccidentPost = createSelector(
    uiSelector,
    ui => ui.accidentPost
)

/* Get validate documents en attente*/
export const getValidateDocumentsPending = createSelector(
    uiSelector,
    ui => ui.validateDocumentsPending
)

/* Get Documents Needed For Accident */
export const getDocumentsNeededForAccident = createSelector(
    uiSelector,
    ui => ui.documentsForAccident || []
)

/* Confirme Virement */
export const getConfirmeVirement = createSelector(
    uiSelector,
    ui => ui.confirmeVirement
)

/* Confirme Accident Post */
export const getValidatePostAccident = createSelector(
    uiSelector,
    ui => ui.validatePostAccident
)

/* Statistiques */
export const getStatistiquesUi = createSelector(
    uiSelector,
    ui => ui.statistiques
)

/* Change address */
export const getChangeAddressUi = createSelector(
    uiSelector,
    ui => ui.changeAddress
)

/* Nb Tasks */
export const getNbTasksUi = createSelector(
    uiSelector,
    ui => ui.nbTasks
)

/* confirm validate virement prod */
export const getValidateVirementProd = createSelector(
    uiSelector,
    ui => ui.validateVirementProd
)

/* Liste des emails envoyés */
export const getEmailsEnvoyes = createSelector(
    uiSelector,
    ui => ui.emailsEnvoyes
)

export const getExpertReportPost = createSelector(
    uiSelector,
    ui => ui.expertReportPost
)

export const getHivernagePost = createSelector(
    uiSelector,
    ui => ui.hivernagePost
)

export const getHivernageReduction = createSelector(
    uiSelector,
    ui => ui.hivernageReduction
)