import {connect} from 'react-redux'
import {compose} from 'redux'

import TaskPrios from './TaskPrios'
import ErrorHttpComponent from '../../../../Commun/Error/ErrorHttpComponent'
import {TASKPRIOS, getTaskPrio} from '../../../../../redux/actions/app/taskprios/tasks.actions'
import {getErrorEntity, getLoadingEntity} from '../../../../../redux/selectors/ui/ui.selectors'
import {getTaskPriosData} from "../../../../../redux/selectors/taskprios/tasks.selectors";
import loader from "../../../../Commun/Spinner/Loader/Loader";

const mapDispatchToProps = {
    getTaskPrio,
};

const mapStateToProps = (state, ownProps) => {
    return {
        tasks: getTaskPriosData(state),
        url: ownProps.url,
        loaded: getLoadingEntity(state, TASKPRIOS) === false,
        error: getErrorEntity(state, TASKPRIOS)
    }
}

const TaskPriosContainer = compose(
    connect(mapStateToProps, mapDispatchToProps),
    loader({ErrorIndicator: ErrorHttpComponent})
)(TaskPrios)

export default TaskPriosContainer
