import React, {Component, Fragment} from 'react';
import {getCity, getReparateurByCodePostal, JoursFeries} from "../../../../../../../../utils/function";
import TextFieldRedux from "../../../../../../../Commun/Input/Text/TextFieldRedux";
import {Field} from "redux-form";
import moment from "moment";
import _ from 'lodash'
import SelectFieldRedux from "../../../../../../../Commun/Input/Select/SelectFieldRedux";
import PostalCodeCity from "../../../../../../../Commun/PostalCodeCity/PostalCodeCity";
import RadioFieldRedux from "../../../../../../../Commun/Input/Radio/RadioFieldRedux";

class Reparation extends Component {
    constructor(props) {
        super(props)
        this.state = {
            reparateurs: [],
            adresseReparateur: null,
            missions: [],
            city: []
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.cpreparateur !== this.props.cpreparateur && prevProps.reparateurAutreVille !== this.props.reparateurAutreVille) {
            this.setPostalCode(this.props.cpreparateur)
        }
    }

    setPostalCode(postalCode) {
        this.fetchCityByPostalCode(postalCode)
        this.fetchReparateurs(postalCode)
    }

    async fetchCityByPostalCode(value) {
        let cities = await getCity(value)
        this.setState({
            city: cities
        })
    }

    changeValueReparateurVisites(reparateur) {
        this.props.changeValue('reparateur', reparateur)
        if (!(!_.find(this.props.garanties, ['code_famille', 'DTA']) && _.includes(['104.2', '104.3', '104.5', '104.1'], this.props.categorie))) {
            reparateur !== 'NONE' ? this.props.changeValue('visit', this.state.missions[0]) : this.props.changeValue('visit', '')
        }
    }

    removeJoursFeries(array) {
        const joursFeries = JoursFeries(moment().weekYear())

        _.remove(array, n => {
            return _.includes(joursFeries, n)
        })
    }

    async fetchReparateurs(postalCode) {
        let arrayReparateurs = await getReparateurByCodePostal(postalCode)
        let reparateurs = _.orderBy(arrayReparateurs, ['raisonSociale'], ['asc'])
        reparateurs.push({id: 'NONE', raisonSociale: 'Autre garage', datesVisite: []})

        this.removeJoursFeries(reparateurs[0].datesVisite)

        this.setState({
            reparateurs,
            missions: reparateurs[0].datesVisite,
            adresseReparateur: reparateurs[0].rue1
        })

        this.changeValueReparateurVisites(reparateurs[0].id)
    }

    getAdresseReparateur(id) {
        const item = _.find(this.state.reparateurs, ['id', id])

        this.removeJoursFeries(item.datesVisite)

        this.setState({
            adresseReparateur: item.rue1,
            missions: item.datesVisite
        })

        this.changeValueReparateurVisites(item.id)
    }

    render() {
        const {reparateurs, missions, city, adresseReparateur} = this.state
        const {
            reparateur,
            vehicule: {immatriculation, marqueModele},
            garanties,
            categorie,
            reparateur_autre,
            remb_garage
        } = this.props

        return (
            <div>
                <div className="alert alert-warning" role="alert" style={{marginTop: 20}}>
                    <p>Garage où se trouve le véhicule: <b>{marqueModele}</b> - <b>{immatriculation}</b></p>
                </div>

                <PostalCodeCity
                    nameCodePostal='cpreparateur'
                    nameVille={`reparateurAutreVille`}
                    label={'Code postal du garage/réparateur'}
                    changeValue={this.props.changeValue}
                />

                <Field
                    name='reparateur'
                    component={SelectFieldRedux}
                    label={'Garage / Réparateur'}
                    disabled={city.length === 0}
                    input={{
                        onChange: (e) => this.getAdresseReparateur(e.target.value)
                    }}
                >
                    {
                        reparateurs.map((reparateur, index) =>
                            <option key={index} value={reparateur.id}>{reparateur.raisonSociale}</option>
                        )
                    }
                </Field>

                {
                    reparateur !== 'NONE' && !reparateur_autre && reparateur !== undefined &&
                    <div className="alert alert-warning" role="alert">
                        <p style={{margin: 20}}>Adresse : {adresseReparateur}</p>
                    </div>
                }

                {
                    (reparateur === 'NONE' || reparateur_autre) && <Fragment>
                        <Field
                            name='reparateurAutreNom'
                            component={TextFieldRedux}
                            label='Nom du garage'
                        />

                        <Field
                            name='reparateurAutreAdresse'
                            component={TextFieldRedux}
                            label='Adresse du garage'
                        />

                    </Fragment>
                }

                {
                    reparateur === 'NONE' || (!_.find(garanties, ['code_famille', 'DTA']) && _.includes(['104.2', '104.3', '104.5', '104.1'], categorie)) ? null :
                        <Field
                            name='visit'
                            component={SelectFieldRedux}
                            label={'Date de passage de l\'expert'}
                            disabled={city.length === 0}
                        >
                            {
                                missions.map((mission, index) =>
                                    <option key={index}
                                            value={mission}>{moment(mission).format('dddd DD MMMM YYYY')}</option>
                                )
                            }
                        </Field>
                }

                <Field
                    name='remb_garage'
                    component={RadioFieldRedux}
                    label={'Dans le cas de réparations, souhaitez-vous que l\'on rembourse le réparateur directement ?'}
                    data={[{value: "T", label: "Oui"}, {value: "F", label: "Non"}]}
                />
            </div>
        );
    }
}

export default Reparation;
