import {connect} from 'react-redux'
import Buttons from './Buttons'
import {PUBLISH_URL} from "../../../../../constants/constants";
import {tiersCreateAccountLink} from "../../../../../redux/selectors/links/links.selectors";
import {bindActionCreators} from "redux";
import {POST_CREATE_ACCOUNT, postCreateAccount} from "../../../../../redux/actions/app/account/account.actions";
import {logAction} from "../../../../../redux/actions/core/logs/logs.actions";
import {POST_CHANGE_PASSWORD} from "../../../../../redux/actions/app/password/password.actions";
import {getUserPermissions, getUserRoles} from "../../../../../redux/selectors/user/user.selectors";


const mapStateToProps = (state, ownProps) => {
    return {
        permissions: getUserPermissions(state),
        tiers: ownProps.tiers,
        publishUrl: PUBLISH_URL,
        uri_account: tiersCreateAccountLink(state),
        owner_belair_id: 43397,
        roles: getUserRoles(state),
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    postCreateAccount: ({email, owner_belair_id, nom, date_naissance, belair_id, query}) => postCreateAccount({
        email,
        owner_belair_id,
        nom,
        date_naissance,
        belair_id,
        query
    }),
    logAction: ({entity, type, tiers}) => logAction({entity, type, tiers})
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {postCreateAccount, logAction} = dispatchProps
    const {uri_account} = stateProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        postAccount: ({email, owner_belair_id, nom, date_naissance, belair_id}) => postCreateAccount({
            email, owner_belair_id, nom, date_naissance, belair_id, query: uri_account
        }),
        postLogActionCreateAccount: () => logAction({
            entity: POST_CREATE_ACCOUNT,
            type: 'CLICK_CREATE_ACCOUNT',
            tiers: ownProps.tiers.belair_id
        }),
        postLogActionChangePassword: () => logAction({
            entity: POST_CHANGE_PASSWORD,
            type: 'CLICK_CHANGE_PASSWORD',
            tiers: ownProps.tiers.belair_id
        }),
        postLogActionEditDocument: () => logAction({
            entity: null,
            type: 'CLICK_EDIT_DOCUMENT',
            tiers: ownProps.tiers.belair_id
        }),
        postLogActionFormRib: () => logAction({entity: null, type: 'CLICK_FORM_RIB', tiers: ownProps.tiers.belair_id}),
    }
}

const ButtonsContainer = connect(mapStateToProps, mapDispatchToProps, mergeProps)(Buttons)

export default ButtonsContainer
