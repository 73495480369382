import React, {Fragment, useState} from "react";
import DashboardContainer from "./DashboardList/DashboardContainer";
import DashboardInfoContainer from "./DashboardInfo/DashboardInfoContainer";

const DashboardContent = (props) => {
    const [blocCollab, setBlocCollab] = useState(false)
    const [filterState, setFilterState] = useState({
        'service': localStorage.getItem('service'),
        'service_user': localStorage.getItem('service_user')
    })

    const handleChange = () => {
        setBlocCollab(!blocCollab)
    }

    const handleChangeSort = (e) => {
        localStorage.setItem('sort_dashboard', e.target.value)
        props.getDashboard({
            query: props.uri,
            sort: localStorage.getItem('sort_dashboard'),
        })
        window.scrollTo(0, 0)
    }

    const handleChangeFilter = (e) => {
        let filter = e.target.name.substring(7)
        localStorage.setItem(filter, e.target.value)

        filterState[filter] = e.target.value
        setFilterState(filterState)

        props.getDashboard({
            query: props.uri,
            sort: localStorage.getItem('sort_dashboard'),
            [filter]: localStorage.getItem(filter)
        })
        window.scrollTo(0, 0)
    }

    return (
        <div className={'suivi-container tasks-container container'}>
            <div className={'row'}>
                <div className={'col-12 buttons-dashboard'}>
                    <div className={'row'}>
                        <div className={'col-6'}>
                            <h1 className={`title-primary title-dashboard title-suivi ${!blocCollab ? 'active' : ''}`}
                                onClick={() => handleChange()}>Suivi en temps réel du stock</h1>
                        </div>
                        <div className={'col-6'}>
                            <h1 className={`title-primary title-dashboard title-users ${blocCollab ? 'active' : ''}`}
                                onClick={() => handleChange()}>Suivi des collaborateurs sur la journée</h1>
                        </div>
                    </div>
                </div>
            </div>
            {!blocCollab &&
                <Fragment>
                    <DashboardInfoContainer className='loader'/>
                    <div className={'margin-top row panieres'}>
                        <div className={'col-12 filters'}>
                            <div className={'content'}>
                                <select
                                    name="filter_service"
                                    onChange={handleChangeFilter}
                                    className={'small-select select-sort-courtier custom-select'}
                                    value={filterState['service']}
                                >
                                    <option value="">Filtre par Service</option>
                                    <option value={"COMMERCIAL"}>Commercial</option>
                                    <option value={"COMPTABILITE"}>Comptabilité</option>
                                    <option value={"GESTION DEDIEE"}>Gestion dediée</option>
                                    <option value={"INDEMNISATION"}>Indemnisation</option>
                                    <option value={"MADRID"}>Madrid</option>
                                    <option value={"PROSPECTS"}>Prospects</option>
                                    <option value={"VIE DE CONTRAT"}>Vie de contrat</option>
                                </select>
                                <select
                                    name="sort_dashboard"
                                    onChange={handleChangeSort}
                                    className={'small-select select-sort-dashboard custom-select'}
                                    value={localStorage.getItem('sort_dashboard')}
                                >
                                    <option value="asc_date">Du + ancien au + récent</option>
                                    <option value="desc_date">Du + récent au + ancien</option>
                                    <option value="asc_taches">Par nombre croissant de tâches</option>
                                    <option value="desc_taches">Par nombre décroissant de tâches</option>
                                </select>
                            </div>
                        </div>
                        <div className={'col-12'}>
                            <DashboardContainer className='loader' blocCollab={blocCollab}/>
                        </div>
                    </div>
                </Fragment>
            }
            {blocCollab &&
                <div className={'row users'}>
                    <div className={'col-12 filters'}>
                        <div className={'content'}>
                            <select
                                name="filter_service_user"
                                onChange={handleChangeFilter}
                                className={'small-select select-sort-courtier custom-select'}
                                value={filterState['service_user']}
                            >
                                <option value="">Filtre par Service</option>
                                <option value={"COMMERCIAL"}>Commercial</option>
                                <option value={"COMPTABILITE"}>Comptabilité</option>
                                <option value={"GESTION DEDIEE"}>Gestion dediée</option>
                                <option value={"GESTION_OFFSHORE"}>Gestion Offshore</option>
                                <option value={"INDEMNISATION"}>Indemnisation</option>
                                <option value={"GESTION_OFFSHORE"}>Madrid</option>
                                <option value={"AN"}>Prospects</option>
                                <option value={"VIE CONTRAT"}>Vie du contrat</option>
                            </select>
                            <select
                                name="sort_dashboard"
                                onChange={handleChangeSort}
                                className={'small-select select-sort-dashboard custom-select'}
                                value={localStorage.getItem('sort_dashboard')}
                            >
                                <option value="alpha">Par ordre alphabétique</option>
                                <option value="asc_taches">Par nombre croissant de tâches</option>
                                <option value="desc_taches">Par nombre décroissant de tâches</option>
                            </select>
                        </div>
                    </div>
                    <div className={'col-12'}>
                        <DashboardContainer className='loader' blocCollab={blocCollab}/>
                    </div>
                </div>
            }
        </div>
    )
}

export default DashboardContent
