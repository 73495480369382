import {connect} from 'react-redux'
import {bindActionCreators, compose} from "redux";
import Souscripteur from "./Souscripteur";
import {reduxForm} from "redux-form";
import _ from "lodash";
import {postContractDriverNotMinor} from "../../../../../redux/actions/app/contracts/contracts.actions";
import {deleteThirdPartyPayerGetLink, driverNotMinorLink} from "../../../../../redux/selectors/links/links.selectors";
import {getLastFIDPiece} from "../../../../../redux/selectors/pieces/pieces.selector";
import {postDeleteTiersPayeur} from "../../../../../redux/actions/app/tiers_payeur/tiers_payeur.actions";

const mapStateToProps = (state, ownProps) => {
    return {
        uri: driverNotMinorLink(state),
        uri_delete_tp: deleteThirdPartyPayerGetLink(state),
        contract_id: ownProps.contract,
        last_piece_FID: getLastFIDPiece(state),
        dernierGeste: ownProps.dernierGeste,
        initialValues: {
            "conducteurMineur": true
        }
    }
}

const mapDispatchToProps = (dispatch, ownProps) => bindActionCreators({
    postContractDriverNotMinor,
    postDeleteTiersPayeur
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {uri, uri_delete_tp, contract_id} = stateProps
    const {postContractDriverNotMinor, postDeleteTiersPayeur} = dispatchProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        submitSetDriverNotMinor: () => postContractDriverNotMinor({query: _.replace(uri, '{id}', contract_id)}),
        submitDeleteTiersPayeur: () => postDeleteTiersPayeur({query: _.replace(uri_delete_tp, '{id}', contract_id)})
    }
}

const SouscripteurContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    reduxForm({
        form: 'contrat-conducteur-mineur'
    })
)(Souscripteur)

export default SouscripteurContainer
