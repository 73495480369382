import {connect} from 'react-redux'
import {change, formValueSelector} from 'redux-form'
import DeclarationTer from './DeclarationTer'
import {bindActionCreators} from "redux";

const mapStateToProps = state => {
    const selector = formValueSelector('constat')

    return {
        commentaire1: selector(state, 'commentaire1') || "",
        commentaire2: selector(state, 'commentaire2') || "",
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    changeValue: (field, value) => change('constat', field, value),
}, dispatch)

const DeclarationTerContainer = connect(mapStateToProps, mapDispatchToProps)(DeclarationTer)

export default DeclarationTerContainer
