import React, {Component} from 'react'
import Client from "./Client/Client";
import Garage from "./Garage/Garage";
import _ from "lodash";
import CourtierContainer from "./Courtier/CourtierContainer";
import Compagnie from "./Compagnie/Compagnie";

class Tiers extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
        if (this.props.tiers.belair_id !== this.props.idTiers) {
            this.props.getTiers({
                query: (_.replace(this.props.uri, '{id}', this.props.idTiers)),
                id: this.props.idTiers
            })
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.tiers.belair_id !== this.props.idTiers) {
            window.scrollTo(0, 0)
            this.props.getTiers({
                query: (_.replace(this.props.uri, '{id}', this.props.idTiers)),
                id: this.props.idTiers
            })
        }
    }

    render() {
        const {tiers, roles} = this.props
        let validTiers = ['1', '1P', '3', 'M', 'G', 'MG', '7', '72', '87', 'J', 'GM', 'MY', 'P', 'K', '7', '87', '72', '7U', '27', 'MG7', '78', '7F', '75', '7H', 'U7', 'R', 'D']

        return (
            <div className={'tiers-container container'}>
                <div className={'row return-content'}>
                    <div className={'col'}>
                        <button onClick={() => this.props.history.go(-1)} className={'btn btn-return'}>Retour</button>
                    </div>
                </div>
                {(tiers.type === 'P' || tiers.type === '1' || tiers.type === '1P' || tiers.type === '3') &&
                    <Client tiers={tiers} roles={roles}/>}
                {(tiers.type === 'G' || tiers.type === 'J'|| tiers.type === 'R') && <Garage tiers={tiers} roles={roles}/>}
                {(tiers.type === 'K') && <Compagnie tiers={tiers} roles={roles}/>}
                {(_.includes(tiers.type, '7') || _.includes(tiers.type, 'M') || _.includes(tiers.type, 'D')) &&
                    <CourtierContainer tiers={tiers} roles={roles}/>}
                {!_.includes(validTiers, tiers.type) &&
                    <p>Aucun dossier ne correspond à la recherche du client. Merci de vérifier le numéro de tiers ou le
                        numéro de contrat.</p>}
            </div>
        )
    }
}

export default Tiers
