import React, {Fragment} from 'react'
import DashboardCollapseRowContainer from "./DashboardCollapseRow/DashboardCollapseRowContainer";
import DashboardPaniereRowContainer from "./DashboardPaniereRow/DashboardPaniereRowContainer";

const Dashboard = (props) => {
    const {data: {panieres, users, totalUsers}, blocCollab} = props

    return (
        <Fragment>
            {!blocCollab && <Fragment>
                <table className="table">
                    <thead>
                    <tr>
                        <th scope="col">Panière</th>
                        <th scope="col">Service</th>
                        <th scope="col">Tâche à traiter</th>
                        <th scope="col">Date du plus ancien popup</th>
                    </tr>
                    </thead>
                    <tbody>
                    {panieres.map((paniere, index) =>
                        <DashboardPaniereRowContainer key={index} index={index} emetteur={paniere}/>
                    )}
                    </tbody>
                </table>
            </Fragment>}
            {blocCollab && <Fragment>
                <table className="table">
                    <thead>
                    <tr>
                        <th scope="col">&nbsp;</th>
                        <th scope="col">Tâches traitées</th>
                        <th scope="col">Tâches réassignées</th>
                        <th scope="col">Tâches agenda créées</th>
                        <th scope="col"></th>
                    </tr>
                    </thead>
                    <tbody>
                    {users.map((user, index) =>
                        <DashboardCollapseRowContainer key={index} index={index} user={user}/>
                    )}
                    <tr>
                        <td></td>
                        <td>{totalUsers.nb_taches}</td>
                        <td>{totalUsers.nb_reassign}</td>
                        <td>{totalUsers.nb_agenda}</td>
                        <td></td>
                    </tr>
                    </tbody>
                </table>
            </Fragment>}
        </Fragment>
    )
}

export default Dashboard
