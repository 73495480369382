/*
Usage :

<Field
  name={String}
  component={TextFieldRedux}
  label={String}
  InputProps={{
    placeholder: {String}
  }}
/>
 */

const TextFieldRedux = ({
    input,
    meta: { touched, error },
    classes,
    label,
    subLabel,
    type,
    InputLabelProps,
    ...custom
}) => {

    return (
        <div className={'form-group'}>
            {label && <label {...InputLabelProps}>{label}</label>}
            {subLabel && <small className={'float-right'}>{subLabel}</small>}
            <input
                className={'form-control ' + (touched ? (Boolean(error) ? 'is-invalid' : 'is-valid') : '')}
                type={type}
                {...input}
                {...custom}
            />
            {touched && error && <small className="invalid-feedback">{error}</small>}
        </div>
    );
};

export default TextFieldRedux;
