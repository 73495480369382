import React from 'react';
import moment from "moment";
import {Link} from "react-router-dom";

const DashboardDetailActivites = (props) => {
    const {gestionnaireDetails} = props

    return (
        <div className={'container'}>
            <div className={'col-12'}>
                <h1 className={'title-primary'}>Détail des tâches du collaborateur {gestionnaireDetails.name}</h1>
            </div>
            <div className={'col-12'}>
                <div className={'content row'}>
                    <table className="table">
                        <thead>
                        <tr>
                            <th scope="col">Nom de l’emetteur</th>
                            <th scope="col">Date</th>
                            <th scope="col">Numéro de Tiers</th>
                        </tr>
                        </thead>
                        <tbody>
                        {gestionnaireDetails && gestionnaireDetails.details.map((detail, index) =>
                            <tr key={index}>
                                <td>{detail.emetteur}</td>
                                <td>{moment(detail.date).format('L')}</td>
                                <td><Link to={`/tiers/${detail.numTiers}`}>{detail.numTiers}</Link></td>
                            </tr>
                        )}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};


export default DashboardDetailActivites;