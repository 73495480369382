import React, {Fragment, Component} from 'react';
import {Field} from "redux-form";
import _ from 'lodash'
import RadioFieldRedux from "../../../../../../../Commun/Input/Radio/RadioFieldRedux";

class DeclarationBis extends Component {
    constructor(props) {
        super(props)
        this.state = {
            alert: false
        }
    }

    render() {
        const {codeProduit, setEmailToClient, categorie, type_sinistre, vehiculeIsAttached, antiVolMecanique, garanties, changeValidSecondCheck, blesse, indentify, temoin} = this.props

        const categoryNeedIdentify = ['104.1', '104.4', '104.3']

        const checkIfNotDTA = () => {
            return !_.find(garanties, ['code_famille', 'DTA'])
        }

        const checkIfNotVI = () => {
            return !_.find(garanties, ['code_famille', 'VI'])
        }

        const checkIfNotPCC = () => {
            return !_.find(garanties, g => {
                return _.includes(['PCC1', 'PCC2', 'PCC3', 'PCC0'], g.code_famille)
            })
        }

        const checkIfNotIC = () => {
            return !_.find(garanties, g => {
                return _.includes(['IC1', 'IC2'], g.code_famille)
            })
        }

        const checkIfNotASSorASSP = () => {
            return !_.find(garanties, g => {
                return _.includes(['ASS', 'ASSP', 'ASSVR', 'ASST'], g.code_famille)
            })
        }

        const setRefusContinuePreDec = (cause) => {
            handleAlert(true)
            changeValidSecondCheck(false)
            setEmailToClient(cause)
        }

        const checkStep = () => {
            /* Accident Seul */
            if (categorie === "104.2") {
                if (blesse === "F" && checkIfNotPCC() && checkIfNotIC() && checkIfNotDTA()) {
                    if (checkIfNotASSorASSP()) {
                        setRefusContinuePreDec("accident_seul")
                    } else {
                        setRefusContinuePreDec("accident_seul_ASS")
                    }

                    return false
                }
            }
            /* Délit de fuite */
            if (categorie === "104.1") {
                if (indentify === "F" && temoin === "F" && checkIfNotPCC() && checkIfNotIC() && checkIfNotDTA()) {
                    if (checkIfNotASSorASSP()) {
                        setRefusContinuePreDec("delit_fuite")
                    } else {
                        setRefusContinuePreDec("delit_fuite_ASS")
                    }
                    return false
                }
            }
            /* Stationnement */
            if (categorie === "104.3") {
                if (indentify === "F" && temoin === "F" && checkIfNotDTA()) {
                    setRefusContinuePreDec("stationnement")
                    return false
                }
            }
            /* Animal */
            if (categorie === "104.4") {
                if (indentify === "F" && checkIfNotPCC() && checkIfNotIC() && checkIfNotDTA()) {
                    if (checkIfNotASSorASSP()) {
                        setRefusContinuePreDec("choc_animal")
                    } else {
                        setRefusContinuePreDec("choc_animal_ASS")
                    }
                    return false
                }
            }


            handleAlert(false)
            changeValidSecondCheck(true)

        }

        const handleAlert = (alert) => {
            this.setState({
                alert
            })
        }

        return (
            <div className={'form-content h-100'}>
                <h1 className={'title-primary'}>Dites-nous en plus...</h1>
                {
                    type_sinistre === 'accident' || (codeProduit === '7311_VAE' && (categorie === '103.1' || categorie === '104.2')) ?
                        <Fragment>
                            {
                                _.includes(categoryNeedIdentify, categorie) && <Field
                                    name='indentify'
                                    component={RadioFieldRedux}
                                    label={'La partie adverse est elle identifiée ?'}
                                    data={[{value: "T", label: "Oui"}, {value: "F", label: "Non"}]}
                                />
                            }

                            <Field
                                name='corpoconduct'
                                component={RadioFieldRedux}
                                label={'Le conducteur est-il blessé ?'}
                                data={[{value: "T", label: "Oui"}, {value: "F", label: "Non"}]}
                            />

                            <Field
                                name='blesse'
                                component={RadioFieldRedux}
                                label={'Y-a-t-il d\'autre(s) blessé(s) ?'}
                                data={[{value: "T", label: "Oui"}, {value: "F", label: "Non"}]}
                            />


                            <Field
                                name='temoin'
                                component={RadioFieldRedux}
                                label={'Y-a-t-il des témoins ?'}
                                data={[{value: "T", label: "Oui"}, {value: "F", label: "Non"}]}
                            />
                        </Fragment>
                        : type_sinistre === 'vol' || (codeProduit === '7311_VAE' && (categorie === '10.0' || categorie === '105.1')) ?
                            <Fragment>
                                <Field
                                    name='vehiculeIsInGarage'
                                    component={RadioFieldRedux}
                                    label={'Votre véhicule était-il dans un garage ?'}
                                    data={[{value: "T", label: "Oui"}, {value: "F", label: "Non"}, {
                                        value: "unknown",
                                        label: "Ne sais pas"
                                    }]}
                                />

                                <Field
                                    name='vehiculeIsMark'
                                    component={RadioFieldRedux}
                                    label={'Votre véhicule était-il gravé ?'}
                                    data={[{value: "T", label: "Oui"}, {value: "F", label: "Non"}, {
                                        value: "unknown",
                                        label: "Ne sais pas"
                                    }]}
                                />

                                <Field
                                    name='vehiculeIsAttached'
                                    component={RadioFieldRedux}
                                    label={'Votre véhicule était-il attaché avec un antivol au moment du vol ?'}
                                    data={[{value: "T", label: "Oui"}, {value: "F", label: "Non"}, {
                                        value: "unknown",
                                        label: "Ne sais pas"
                                    }]}
                                />

                                {
                                    vehiculeIsAttached === 'T' && <Fragment>
                                        <Field
                                            name='antiVolMecanique'
                                            component={RadioFieldRedux}
                                            label={'Quel(s) anti-vol(s) possédez vous ?'}
                                            type={'checkbox'}
                                            data={[{label: "Mécanique"}]}
                                        />
                                        {
                                            antiVolMecanique && <Field
                                                name='sraCertified'
                                                component={RadioFieldRedux}
                                                label={'Agréé SRA ?'}
                                                data={[{value: "T", label: "Oui"}, {value: "F", label: "Non"}]}
                                            />
                                        }
                                        <Field
                                            name='antiVolElectronique'
                                            component={RadioFieldRedux}
                                            type={'checkbox'}
                                            data={[{label: "Electronique"}]}
                                        />
                                        <Field
                                            name='antiVolAntiDemarrage'
                                            component={RadioFieldRedux}
                                            type={'checkbox'}
                                            data={[{label: "Anti démarrage"}]}
                                        />
                                    </Fragment>
                                }

                            </Fragment>
                            : <Field
                                name='moreDamages'
                                component={RadioFieldRedux}
                                label={"Votre véhicule a-t-il causé des dommages à d'autres biens ou personnes ?"}
                                data={[{value: "T", label: "Oui"}, {value: "F", label: "Non"}]}
                            />
                }

                {this.state.alert && <div className="alert alert-danger" role="alert">
                    <p>Les garanties du contrat et les éléments de la déclaration ne permettent pas de donner suite à
                        cette déclaration.</p>
                    <p>Une confirmation est adressée au client.</p>
                </div>}

                <span className={'btn btn-primary btn-lg float-right'}
                      onClick={() => checkStep()}
                >
                Poursuivre
            </span>
            </div>
        );
    }
};

export default DeclarationBis;
