export const TASKPRIOS = '[TaskPrios]'
export const TASKPRIO = '[TaskPrio]'

export const GET_TASKPRIOS = `${TASKPRIOS} Get`
export const SET_TASKPRIOS = `${TASKPRIOS} Set`

export const GET_TASKPRIO = `${TASKPRIO} Get`

export const getTaskPrios = ({
                                 query,
                                 portefeuille = localStorage.getItem('portefeuille') || '',
                                 date_start = localStorage.getItem('date_start_afn') || '',
                                 date_end = localStorage.getItem('date_end_afn') || '',
                                 score = localStorage.getItem('score') || ''
                             }) => ({
    type: GET_TASKPRIOS,
    payload: {
        data: query,
        filters: {
            portefeuille,
            date_start,
            date_end,
            score
        }
    }
})

export const setTaskPrios = ({data, total}) => ({
    type: SET_TASKPRIOS,
    payload: {data, total}
})

export const getTaskPrio = ({query}) => ({
    type: GET_TASKPRIO,
    payload: {data: query}
})

