import {connect} from 'react-redux';
import VirementsSinistre from "./VirementsSinistre";
import {formValueSelector, getFormValues} from "redux-form";
import _ from "lodash";
import {
    getAccidentcategorieCode,
    getAccidentFranchiseReglee,
    getAccidentGarantiesACC20,
    getAccidentGarantiesACCEQ,
    getAccidentGarantiesDTASinistree,
    getAccidentGarantiesEQ,
    getAccidentGarantiesRFVI,
    getAccidentHivernage,
    getAccidentIDA,
    getAccidentReglementsEnBanque,
    getAccidentTauxResp
} from "../../../../../../../redux/selectors/accident/accident.selectors";
import {
    getPenalitesRegles,
    getPostesIndemnisationRegles,
    getReglementDetailTypeRegCode
} from "../../../../../../../redux/selectors/reglement_detail/reglement_detail.selectors";
import {getCodeTypeReglement} from "../../../../../../../utils/valueMatching";
import {getBcaData} from "../../../../../../../redux/selectors/bca/bca.selectors";

const mapStateToProps = state => {
    const selector = formValueSelector('virement-sinistre')

    let penalites  = [
        {
            libelle: 'ABSVOLSRA',
            value: selector(state, `penalite[0][montant]`),
            text: 'absence_justificatif_antivol_SRA'
        },
        {
            libelle: 'ABSGRAVAGE',
            value: selector(state, `penalite[1][montant]`),
            text: 'absence_gravage'
        },
        {
            libelle: 'ABSREMISNUIT',
            value: selector(state, `penalite[2][montant]`),
            text: 'absence_de_remisage_de_nuit'
        },
        {
            libelle: 'FRANCHISE',
            value: selector(state, `penalite[3][montant]`),
            text: 'franchise',
            commentaire: true
        },
        {
            libelle: 'FRANCONDEXCLU',
            value: selector(state, `penalite[4][montant]`),
            text: 'franchise_conduite_exclusive',
            commentaire: true
        },
        {
            libelle: 'RACHATFRAN',
            value: selector(state, `penalite[5][valeur]`),
            text: 'rachat_de_franchise',
        },
        {
            libelle: 'AUTREPEN',
            value: selector(state, `penalite[6][montant]`),
            text: 'autre_penalite',
            commentaire: true
        },
    ];

    if(getAccidentHivernage(state)?.dateDebut){
        penalites = [ ...penalites,
            {
                libelle: 'SURFRAN_HIVER',
                value: selector(state, `penalite[7][montant]`),
                text: 'surfranchise_ma_periode_hivernale'
            }
        ];
    }

    return {
        formValues: getFormValues("virement-sinistre")(state),
        rapports_bca: getBcaData(state),
        beneficiaire: selector(state, 'beneficiaire'),
        titulaire: selector(state, 'titulaire'),
        type_reglement: selector(state, 'type_reglement'),
        montant_type_reg: Number(selector(state, 'montant_type_reglement') || 0),
        deduction: selector(state, 'deduction'),
        montant_total: selector(state, 'montant_total'),
        sousTotalOne: _.round(Number(selector(state, 'montant_type_reglement') || 0) - Number(selector(state, 'montant_deduction') || 0), 2),
        montant_plafond: selector(state, 'plafond_acc_eqp'),
        montant_remboursement_franchise: Number(selector(state, `indemnisation[9][montant]`) || 0),
        montant_complement: Number(selector(state, `indemnisation[5][complement]`) || 0),
        totalAccessoires: _.round(Number(selector(state, '[indemnisation][1][montant]') || 0) + Number(selector(state, `indemnisation[5][complement]`) || 0), 2),
        IDA: getAccidentIDA(state),
        RC: getAccidentTauxResp(state),
        hasGarantieEquipement: !_.isEmpty(getAccidentGarantiesEQ(state)),
        hasGarantieAccessoireEquipement: !_.isEmpty(getAccidentGarantiesACCEQ(state)),
        hasGarantieRfvi: !_.isEmpty(getAccidentGarantiesRFVI(state)),
        hasGarantieACC20: !_.isEmpty(getAccidentGarantiesACC20(state)),
        indem_values: selector(state, '[indemnisation]'),
        penalite_values: selector(state, '[penalite]'),
        reglements_en_banque: getAccidentReglementsEnBanque(state),
        reg_effectue_type : getCodeTypeReglement(getReglementDetailTypeRegCode(state)),
        postes_indem_penalites_regles : [ ...getPostesIndemnisationRegles(state), ...getPenalitesRegles(state)],
        opposition_montant: formValueSelector('virement-sinistre')(state, 'opposition_montant'),
        penalite_franchise: !!selector(state, 'penalite[3][montant]'),
        franchise_reglee: getAccidentFranchiseReglee(state) ? parseFloat(_.replace(getAccidentFranchiseReglee(state), ',', '.')) : null,
        reglement_cheque: selector(state, 'reglement_cheque'),
        categorie_code: getAccidentcategorieCode(state),
        hivernage: getAccidentHivernage(state)?.dateDebut,
        postesIndem: [
            {
                libelle: 'REMORQUAGE',
                value: selector(state, `indemnisation[0][montant]`),
                text: 'remorquage_et_gardiennage',
                maxValue: getAccidentGarantiesDTASinistree(state) ? 150
                    : (getAccidentcategorieCode(state) === '9' ? 300 : null)
            },
            {
                libelle: 'ACCESSOIRES',
                vetuste: true,
                value: selector(state, `indemnisation[1][montant]`),
                text: 'accessoires_et_equipements',
                commentaire: true
            },
            {
                libelle: 'ACC10',
                vetuste: true,
                value: selector(state, `indemnisation[2][montant]`),
                text: 'accessoires_10%',
                commentaire: true
            },
            {
                libelle: 'ACC20',
                vetuste: true,
                value: selector(state, `indemnisation[3][montant]`),
                text: 'accessoires_20%',
                commentaire: true
            },
            {
                libelle: 'EQUPMTS',
                vetuste: true,
                value: selector(state, `indemnisation[4][montant]`),
                text: 'equipements',
                commentaire: true
            },
            {
                libelle: 'CASQUEGANTS',
                vetuste: true,
                value: selector(state, `indemnisation[5][montant]`),
                text: 'casque_et_gants',
                commentaire: true,
                complement: true,
            },
            {
                libelle: 'EFFETPERSO',
                vetuste: true,
                value: selector(state, `indemnisation[6][montant]`),
                text: 'effets_personnels',
                commentaire: true
            },
            {
                libelle: 'MOYPROTECTIONS',
                value: selector(state, `indemnisation[7][montant]`),
                text: 'moyens_de_protection'
            },
            {
                libelle: 'MAJVALEXP',
                value: selector(state, `indemnisation[8][montant]`),
                text: 'majoration_valeur_expertise'
            },
            {
                libelle: 'REMBCOMPL',
                value: selector(state, `indemnisation[9][montant]`),
                text: 'remboursement_franchise'
            },
        ],
        penalites: penalites
    }
}

const VirementsSinistreContainer = connect(mapStateToProps)(VirementsSinistre)

export default VirementsSinistreContainer
