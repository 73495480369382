import {connect} from 'react-redux'
import {compose} from 'redux'
import ReclamationClose from "./ReclamationClose";
import {getTiersData} from "../../../../../../redux/selectors/tiers/tiers.selectors";
import _ from "lodash";
import {complainCloseLink, tiersGetLink} from "../../../../../../redux/selectors/links/links.selectors";

import {getErrorEntity, getLoadingEntity} from "../../../../../../redux/selectors/ui/ui.selectors";
import {getTiers, TIERS} from "../../../../../../redux/actions/app/tiers/tiers.actions";
import {
    POST_CLOSE_RECLAMATION,
    postCloseReclamation
} from "../../../../../../redux/actions/app/reclamation/reclamation.actions";
import {reduxForm} from "redux-form";
import validate from "./validate";
import ErrorHttpComponent from "../../../../../Commun/Error/ErrorHttpComponent";
import loader from "../../../../../Commun/Spinner/Loader/Loader";

const mapStateToProps = state => {
    return {
        queryPost: complainCloseLink(state),
        query: tiersGetLink(state),
        tiers: getTiersData(state),
        loaded: getLoadingEntity(state, TIERS) === false,
        error: getErrorEntity(state, TIERS),
        loading: getLoadingEntity(state, POST_CLOSE_RECLAMATION)
    }
}

const mapDispatchToProps = {
    getTiers,
    postCloseReclamation
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {query, queryPost} = stateProps
    const {getTiers, postCloseReclamation} = dispatchProps
    const {id, ids} = ownProps.match.params

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => getTiers({query: _.replace(query, '{id}', id), id}),
        onSubmit: form => postCloseReclamation({
            query: _.replace(_.replace(queryPost, '{id}', id), '{ids}', ids),
            form,
            otherData: {id, ids}
        })
    }
}

const ReclamationCloseContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    reduxForm({
        form: 'fermeture-reclamation',
        validate
    }),
    loader({ErrorIndicator: ErrorHttpComponent})
)(ReclamationClose)

export default ReclamationCloseContainer
