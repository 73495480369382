import React from 'react'
import {Link} from "react-router-dom";

const Comptabilite = props => {
    const {submitExtractionCVComptabilite} = props;

    return (
        <div className={'compta-container suivi-container container'}>
            <div className={'row'}>
                <div className={'col-12'}>
                    <h1 className={'title-primary title-task'}>Requêtes à executer.</h1>
                    <div className={'row poles'}>
                        <div className={'col-3'}>
                            <div className={'item text-center'}>
                                <h3 className={'d-block'} style={{color: '#1e3947'}}>Reversement compagnies</h3>
                                <Link
                                    to={{pathname: '/comptabilite/reversement-compagnies'}}
                                    className={'btn btn-cta btn-success btn-small'}
                                >
                                    Extraction
                                </Link>
                            </div>
                        </div>
                        <div className={'col-3'}>
                            <div className={'item text-center'}>
                                <h3 className={'d-block'} style={{color: '#1e3947'}}>Carte verte</h3>
                                <button
                                    onClick={() => {
                                        if (window.confirm('Voulez-vous vraiment lancer l\'extraction des cartes vertes ?')) submitExtractionCVComptabilite()
                                    }}
                                    className={'btn btn-cta btn-success btn-small'}
                                >
                                    Extraction
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Comptabilite
