import React, {useEffect, useMemo} from 'react';
import { Field } from 'redux-form';
import SelectFieldRedux from '../../../../Commun/Input/Select/SelectFieldRedux';
import TextAreaFieldRedux from '../../../../Commun/Input/Text/TextAreaFieldRedux';
import Spinner from '../../../../Commun/Spinner/Spinner';
import { getFormDerogation } from './derogationMotifs';
import RadioSimpleFieldRedux from "../../../../Commun/Input/RadioSimple/RadioSimpleFieldRedux";
import TextFieldRedux from "../../../../Commun/Input/Text/TextFieldRedux";
import {normalizeNumber} from "../../../../../utils/normalizeInputs";

export default function Derogation(props) {
    const { formValues, roles, handleSubmit, changeValue, loading, valid, contract} = props;
    const isFidAvailable = contract && contract.avenant_link_fid !== null && contract.apporteur1 === '43397';

    const themes = useMemo(() => {
        return getFormDerogation(roles, isFidAvailable) || [];
    }, [roles, isFidAvailable]);

    const motifs = useMemo(() => themes?.find((theme) => theme.label === formValues.theme)?.motifs || [], [themes, formValues.theme]);
    const montants = useMemo(() => motifs?.find((motif) => motif.label === formValues.motif)?.montants || [], [motifs, formValues.motif]);
    const montant_libre = useMemo(() => motifs?.find((motif) => motif.label === formValues.motif)?.montant_libre || false, [motifs, formValues.motif]);
    const montant_max = useMemo(() => motifs?.find((motif) => motif.label === formValues.motif)?.montant_max || undefined, [motifs, formValues.motif]);
    const isFidelisationTheme = formValues.theme === 'Fidélisation' || formValues.theme === 'Fidélisation - Revue de contrat';

    useEffect(() => {
        changeValue('motif', motifs[0]?.label || '');
        changeValue('montant', '');
        changeValue('informations', '');
    }, [formValues.theme]);

    console.log(montants)

    return (
        <div className='contract-container container'>
            <div className='row'>
                <div className='col-12'>
                    <h1 className='title-primary'>DÉROGATION /  FIDÉLISATION</h1>
                </div>
            </div>

            <div className='row header-row'>
                <div className='col-12'>
                    <form className='form-content' onSubmit={handleSubmit}>
                        <div className='row'>
                            <div className='col-6'>
                                <Field name='theme' component={SelectFieldRedux} label='Thème' required>
                                    <option value=''>Sélectionner un thème</option>
                                    {themes.map((theme, index) => (
                                        <option key={index} value={theme.value}>
                                            {theme.label}
                                        </option>
                                    ))}
                                </Field>
                            </div>
                            <div className='col-12'>
                                <Field name='description' component={TextAreaFieldRedux} label='Description de la dérogation/fidélisation' required rows={4} />
                            </div>

                            {isFidelisationTheme &&
                                <div className='col-12'>
                                    <Field
                                        name={`informations`}
                                        component={RadioSimpleFieldRedux}
                                        data={[
                                            {value: "0", label: "Proposition faite, en attente du retour client"},
                                            {value: "1", label: "Client injoignable / répondeur"},
                                            {value: "2", label: "Client non éligible"},
                                            {value: "3", label: "Geste FID refusé (collaborateur)"},
                                            {value: "4", label: "Geste FID refusé (client)"},
                                        ]}
                                        required
                                    />
                                </div>
                            }
                            {formValues.informations === ''  && <>
                                <div className='col-6'>
                                    <Field name='motif' component={SelectFieldRedux} label='Motif de la dérogation/fidélisation' required>
                                        {motifs.length > 1 && <option value=''>Sélectionner un motif</option>}
                                        {motifs.length === 0 && <option value=''>Aucun motif disponible</option>}
                                        {motifs.map((motif, index) => (
                                            <option key={index} value={motif.label}>
                                                {motif.label}
                                            </option>
                                        ))}
                                    </Field>
                                </div>
                                <div className='col-6'>
                                    {
                                        !montant_libre ?
                                        <Field
                                            name='montant'
                                            component={SelectFieldRedux}
                                            label='Montant'
                                            required={montants.length !== 0}>
                                            {montants.length > 1 && <option value=''>Sélectionner un montant</option>}
                                            {montants.length === 0 && <option value=''>Aucun montant disponible</option>}
                                            {montants.map((amount, index) => (
                                                <option key={index} value={amount.value}>
                                                    {amount.label}
                                                </option>
                                            ))}
                                        </Field> :
                                        <Field
                                            name='montant'
                                            component={TextFieldRedux}
                                            type={'number'}
                                            label={'Montant'}
                                            subLabel={montant_max ? `MAX ${montant_max}€` : ''}
                                            normalize={normalizeNumber}
                                            meta={{
                                                touched: true,
                                                error: montant_max ? formValues.montant > montant_max ? `Le montant maximum est de ${montant_max}€` : undefined : undefined
                                            }}
                                            required={true || montant_max}
                                        />
                                    }
                                </div>
                            </>
                            }
                        </div>

                        <button
                            className={'btn btn-primary position-relative'}
                            disabled={!valid || loading || (montant_max ? formValues.montant > montant_max : false)}
                        >
                            {loading && <Spinner absolute/>}
                            Valider
                        </button>
                    </form>
                </div>
            </div>
        </div>
    );
}
