import {createSelector} from 'reselect'

const tasksSelector = state => state.taskprios
export const getTaskPriosData = createSelector(
    tasksSelector,
    tasks => tasks.data
)
export const getTaskPriosTotal = createSelector(
    tasksSelector,
    tasks => tasks.total
)
