import React from 'react';
import {Field} from "redux-form";
import moment from "moment";
import SelectFieldRedux from "../../../../Commun/Input/Select/SelectFieldRedux";
import CheckboxFieldRedux from "../../../../Commun/Input/Checkbox/CheckboxFieldRedux";
import DatePickerFieldRedux from "../../../../Commun/Input/DatePicker/DatePickerFieldRedux";
import TextAreaFieldRedux from "../../../../Commun/Input/Text/TextAreaFieldRedux";
import {normalizeBooleanForNoobBelair} from "../../../../../utils/normalizeInputs";
import Spinner from "../../../../Commun/Spinner/Spinner";
import _ from "lodash";

const Reclamation = (props) => {
    const {tiers, handleSubmit, loading, contract} = props

    return (
        <div className={'contract-container container'}>
            <div className={'row'}>
                <div className={'col-12'}>
                    <h1 className={'title-primary'}>{tiers.titre} {tiers.name} {tiers.belair_id}</h1>
                </div>
            </div>
            <div className="row header-row">
                <div className={'col-12'}>
                    <form className={`form-content`} onSubmit={handleSubmit}>
                        <h2 className="title-primary text-center d-block">Ajout d'une réclamation</h2>

                        <div className="row">
                            <div className="col-6">
                                <Field
                                    component={SelectFieldRedux}
                                    name='arrive'
                                    label={'Canal d\'arrivée'}
                                >
                                    <option value={""}>Choisissez un canal d'arrivée</option>
                                    <option value={"FORME"}>Formulaire de contact</option>
                                    <option value={"EMAIE"}>Email entrant</option>
                                    <option value={"COURE"}>Courrier entrant</option>
                                    <option value={"TELE"}>Télephone entrant</option>
                                    <option value={"FDBCK"}>Feedback</option>
                                    <option value={"RESC"}>Réseaux sociaux</option>
                                </Field>
                            </div>
                            <div className="col-6">
                                <Field
                                    name={"date_reception"}
                                    component={DatePickerFieldRedux}
                                    label={'Date de la réclamation'}
                                    maxDate={moment()}
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col">
                                <Field
                                    name={'description'}
                                    component={TextAreaFieldRedux}
                                    label={'Description du mécontentement'}
                                    subLabel={'255 caractères maximum'}
                                    maxLength={'255'}
                                    rows="4"
                                />
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-6">
                                <Field
                                    component={SelectFieldRedux}
                                    name='nature'
                                    label={'Nature de la réclamation'}
                                >
                                    <option value={""}>Choisissez la nature de la réclamation</option>
                                    <option value="17">Accessibilité aux services APRIL Moto</option>
                                    <option value="38">Accessoires et équipements</option>
                                    <option value="21">APRIL Moto - Refus de résiliation</option>
                                    <option value="42">Chiffrage expert</option>
                                    <option value="22">Client - Refus de la résiliation</option>
                                    <option value="04">Conseils trompeurs</option>
                                    <option value="23">Contestation contentieux</option>
                                    <option value="24">Délais compagnie</option>
                                    <option value="25">Délais de remboursement</option>
                                    <option value="26">Délais expert</option>
                                    <option value="27">Délais intervention dépanneur</option>
                                    <option value="43">Délais instruction</option>
                                    <option value="02">Délais de traitement</option>
                                    <option value="20">Désaccord sur application des règles</option>
                                    <option value="36">Désaccord sur la date de résiliation</option>
                                    <option value="37">Désaccord Bonus</option>
                                    <option value="13">Frais</option>
                                    <option value="40">Frais de gardiennage</option>
                                    <option value="07">Litige sur la prime</option>
                                    <option value="34">Litiges sur Responsabilités</option>
                                    <option value="28">Mauvais ou non traitement de la demande</option>
                                    <option value="29">Montant du remboursement indemnisation</option>
                                    <option value="19">Non réception des documents</option>
                                    <option value="41">Pénalité retenue</option>
                                    <option value="30">Plafond dépanneur</option>
                                    <option value="44">Process compagnie</option>
                                    <option value="31">Qualité dépanneur</option>
                                    <option value="32">Refus intervention assistance</option>
                                    <option value="11">Sinistre non-couvert</option>
                                    <option value="39">Vetusté</option>
                                </Field>
                            </div>

                            <div className="col-6">
                                <Field
                                    component={SelectFieldRedux}
                                    name='moment_vie'
                                    label={'Moment de vie'}
                                >
                                    <option value={""}>Choisissez un moment de vie</option>
                                    <option value={"A"}>Devis / Souscription</option>
                                    <option value={"C"}>Gestion de contrat</option>
                                    <option value={"B"}>Tarification / Prime</option>
                                    <option value={"D"}>Indemnisation</option>
                                    <option value={"F"}>Résiliation</option>
                                    <option value={"E"}>Assistance</option>
                                </Field>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-6">
                                <Field
                                    component={SelectFieldRedux}
                                    name='assureur'
                                    label={'L\'assureur'}
                                >
                                    <option value={""}>Choisissez l'assureur</option>
                                    <option value="7311">Allianz</option>
                                    <option value="35">Mondial</option>
                                    <option value="2955">Solucia</option>
                                    <option value="1715">Caarea</option>
                                    {contract.apporteurs.apporteur1.id !== '43397' && <option value={contract.apporteurs.apporteur1.id}>{contract.apporteurs.apporteur1.raisonSociale}</option>}
                                    {!_.isEmpty(contract.apporteurs.apporteur2) && <option value={contract.apporteurs.apporteur2.id}>{contract.apporteurs.apporteur2.nom}</option>}
                                </Field>
                            </div>
                            <div className="col-6">
                                <Field
                                    component={SelectFieldRedux}
                                    name='niveau_recla'
                                    label={'Niveau de la réclamation'}
                                >
                                    <option value={""}>Choisissez le niveau</option>
                                    <option value={"1"}>Gestionnaire</option>
                                    <option value={"2"}>Responsable des réclamations</option>
                                    <option value={"3"}>Médiateur</option>
                                    <option value={"4"}>Tribunal</option>
                                </Field>
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-6">
                                <Field
                                    component={SelectFieldRedux}
                                    name='qualite_emetteur'
                                    label={'Qualité de l\'émetteur'}
                                >
                                    <option value={""}>Choisissez la qualité de l'emetteur</option>
                                    <option value={"1"}>Courtier</option>
                                    <option value={"2"}>Assuré</option>
                                    <option value={"3"}>Compagnie</option>
                                    <option value={"4"}>Tiers</option>
                                    <option value={"5"}>Avocat</option>
                                    <option value={"6"}>Association de défense</option>
                                    <option value={"7"}>Prospect</option>
                                </Field>
                            </div>
                            <div className="col-6">
                                <Field
                                    name='recla_initiale'
                                    component={CheckboxFieldRedux}
                                    label={'Réclamation initiale'}
                                    type={'checkbox'}
                                    normalize={normalizeBooleanForNoobBelair}
                                    className={'pt-4'}
                                />
                            </div>
                        </div>

                        {
                            loading ? <span className={'d-block text-center'}><Spinner/></span>
                                : <div className={'d-flex justify-content-between'}>
                                    <button className={'btn btn-primary btn-inline-block mt-3'} type={'submit'}>
                                        Valider la réclamation
                                    </button>

                                    <button className={'btn btn-primary btn-inline-block mt-3'} type={'submit'}
                                            onClick={handleSubmit(values =>
                                                props.onSubmit({
                                                    ...values,
                                                    transfert: true
                                                }))}
                                    >
                                        Valider la réclamation et transfert au niveau 2
                                    </button>
                                </div>
                        }
                    </form>
                </div>
            </div>
        </div>
    );
};

Reclamation.defaultProps = {};

Reclamation.propTypes = {};

export default Reclamation;


