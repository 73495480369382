import React from 'react';
import RadioFieldRedux from "../../../../../Commun/Input/Radio/RadioFieldRedux";
import {Field} from "redux-form";
import TextAreaFieldRedux from "../../../../../Commun/Input/Text/TextAreaFieldRedux";
import Spinner from "../../../../../Commun/Spinner/Spinner";

const ReclamationTransfert = (props) => {
    const {handleSubmit, tiers, raison_transfert, loading} = props

    return (
        <div className={'contract-container container'}>
            <div className={'row return-content'}>
                <div className={'col-2'}>
                    <button onClick={() => props.history.go(-1)} className={'btn btn-return'}>Retour</button>
                </div>
            </div>

            <div className={'row'}>
                <div className={'col-12'}>
                    <h1 className={'title-primary'}>{tiers.titre} {tiers.name} {tiers.belair_id}</h1>
                </div>
            </div>
            <div className="row header-row">
                <div className={'col-12'}>
                    <form className={`form-content`} onSubmit={handleSubmit}>
                        <h2 className="title-primary text-center d-block">Transfert d'une réclamation</h2>

                        <div className="row">
                            <div className="col">
                                <Field
                                    name='raison_transfert'
                                    component={RadioFieldRedux}
                                    label={"Pourquoi passez-vous cette réclamation en niveau 2 ?"}
                                    data={[{value: "refus", label: "Refus de la réponse au niveau 1"}, {
                                        value: "autres",
                                        label: "Avocat, association de consommateurs, Médiateur...*"
                                    }]}
                                />
                                <small><em>*Association de consommateurs, Protection Juridique, Avocat, Conciliateur de
                                    Justice, Médiateur, Journaliste / Média, Personnalités Politiques, Hautes autorités,
                                    Juge sensible selon les risques d’image et juridique</em></small>
                            </div>
                        </div>

                        {
                            raison_transfert === 'refus' && <>
                                <div className="row mt-3">
                                    <div className="col">
                                        <Field
                                            name={'description_mecontentement'}
                                            component={TextAreaFieldRedux}
                                            label={'Quel est le mécontentement de départ (détailler) ?'}
                                            subLabel={'255 caractères maximum'}
                                            maxLength={'255'}
                                            rows="5"
                                            placeholder={'Exposer les faits'}
                                        />
                                    </div>
                                </div>

                                <div className="row mt-3">
                                    <div className="col">
                                        <Field
                                            name={'reponse'}
                                            component={TextAreaFieldRedux}
                                            label={'La réponse apportée au client :'}
                                            subLabel={'255 caractères maximum'}
                                            maxLength={'255'}
                                            rows="5"
                                            placeholder={'Réponse'}
                                        />
                                    </div>
                                </div>

                                <div className="row mt-3">
                                    <div className="col">
                                        <Field
                                            name={'cause_mecontentement'}
                                            component={TextAreaFieldRedux}
                                            label={'Pourquoi le client réitère son mécontentement ?'}
                                            subLabel={'255 caractères maximum'}
                                            maxLength={'255'}
                                            rows="5"
                                            placeholder={'Argument du client'}
                                        />
                                    </div>
                                </div>

                                <div className="row mt-3">
                                    <div className="col">
                                        <Field
                                            name={'issue'}
                                            component={TextAreaFieldRedux}
                                            label={'Peut-on trouver une issue favorable à sa demande ?'}
                                            subLabel={'255 caractères maximum'}
                                            maxLength={'255'}
                                            rows="5"
                                            placeholder={'Solution (le client a-t-il eu un sinistre ? est-ce qu’il y a un contentieux ?)'}
                                        />
                                    </div>
                                </div>
                            </>
                        }

                        {
                            loading ? <Spinner class={'float-right'}/> :
                                <button className={'btn btn-primary btn-inline-block mt-3 float-right'} type={'submit'}>
                                    Valider le transfert
                                </button>
                        }

                    </form>
                </div>
            </div>
        </div>
    );
};


export default ReclamationTransfert;
