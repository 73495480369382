import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'

import Resiliation from "./Resiliation";
import {formValueSelector, reduxForm, change, clearFields} from "redux-form";
import {
    InsurersGetLink,
    postChangeSituationContractLink
} from "../../../../../../redux/selectors/links/links.selectors";
import ErrorHttpComponent from "../../../../../Commun/Error/ErrorHttpComponent";
import _ from "lodash";
import {
    CHANGE_SITUATION_CONTRACT,
    postChangeSituationContract
} from "../../../../../../redux/actions/app/change_situation_contract/change_situation_contract.actions";
import {getErrorEntity, getLoadingEntity} from "../../../../../../redux/selectors/ui/ui.selectors";
import validate from './validate'
import {getInsurers} from "../../../../../../redux/actions/app/insurers/insurers.actions";
import {getInsurersData} from "../../../../../../redux/selectors/insurers/insurers.selector";
import {CLOSE_TASK} from "../../../../../../redux/actions/app/tasks/tasks.actions";
import loader from "../../../../../Commun/Spinner/Loader/Loader";

const mapStateToProps = (state, ownProps) => {
    const selector = formValueSelector('resiliation')

    return {
        taskDetail: ownProps.task,
        uri: postChangeSituationContractLink(state),
        uriInsurers: InsurersGetLink(state),
        terminateQuery: ownProps.terminateTask,
        loadingTerminate: getLoadingEntity(state, CLOSE_TASK),
        errorTerminate: getErrorEntity(state, CLOSE_TASK),
        loadingChangeSituation: getLoadingEntity(state, CHANGE_SITUATION_CONTRACT),
        errorResil: getErrorEntity(state, CHANGE_SITUATION_CONTRACT),
        reassign: selector(state, 'reassign'),
        refuse: selector(state, 'refuse'),
        transfer_ownership_missing: selector(state, 'transfer_ownership_missing'),
        initialValues: {
            'commentaire': ownProps.task.contract.situation.statut === 'Résiliée' ? 'Le contrat est déjà resilié' : ''
        },
        insurers: getInsurersData(state)
    }
}
const mapDispatchToProps = dispatch => bindActionCreators({
    postChangeSituationContract: ({type, task_id, form, query, terminateQuery}) => postChangeSituationContract({
        type,
        task_id,
        form,
        query,
        terminateQuery
    }),
    getInsurers: (query) => getInsurers({query}),
    changeValue: (field, value) => change('resiliation', field, value),
    unsetValue: (field) => clearFields('resiliation', false, false, field),
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {postChangeSituationContract, getInsurers} = dispatchProps
    const {uri, uriInsurers, taskDetail, terminateQuery} = stateProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        onSubmit: (form) => postChangeSituationContract({
            query: _.replace(uri, '{id}', taskDetail.contract.id),
            task_id: taskDetail.id,
            form,
            type: 'terminate',
            terminateQuery
        }),
        load: () => getInsurers(uriInsurers)
    }
}

const ResiliationContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    reduxForm({
        form: 'resiliation',
        validate
    }),
    loader({ErrorIndicator: ErrorHttpComponent})
)(Resiliation)

export default ResiliationContainer
