import React from 'react'
import _ from "lodash";
import {Field} from "redux-form";
import CheckboxFieldRedux from "../../../../Commun/Input/Checkbox/CheckboxFieldRedux";

const Circonstances = props => {
    const {onSubmit, onSubmitCession, accident, permissions, contrat} = props

    return (
        <div className={'bloc-info bloc-circonstance'}>
            <h2>Circonstances et dommages</h2>
            <div className={'content row'}>
                <div className={'col full-content'}>
                    <p><span className={'bold'}>Circonstances :</span> {accident.circonstances}</p>
                    <p><span className={'bold'}>Dommages :</span> {accident.decDommages}</p>
                    {
                        _.includes(permissions, 'update accident informations') ?
                            <div className={'bloc-align-left'}>
                                <div>
                                    <Field
                                        component={CheckboxFieldRedux}
                                        name={'attentePV'}
                                        label={`En attente de PV`}
                                        type={'checkbox'}
                                        onChange={e => onSubmit({
                                            attentePV: e.target.checked
                                        })}
                                    />
                                </div>

                                {(contrat.situation.statutCode !== '5' && contrat.situation.statutCode !== '6' && contrat.situation.statutCode !== 'I' && contrat.situation.statutCode !== '9') &&
                                    <button type={'button'} onClick={() => {
                                        if (window.confirm('Voulez-vous lancer le processus de cession compagnie ?')) onSubmitCession()
                                    }} className={'btn btn-cta btn-ternary mt-3'}>
                                        Cession compagnie
                                    </button>}
                            </div>
                            :
                            <>
                                {
                                    (accident.attentePV ? <p><span className={'bold'}>En attente de PV</span></p> : '')
                                }
                            </>
                    }
                </div>
            </div>
        </div>
    )
}

export default Circonstances
