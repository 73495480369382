import React from 'react';

const FeedbackNote = (props) => {
    const {feedback} = props
    return (
        <span className={'title-secondary m-0'}>
            {feedback?.feedback_date && <>Feedback à chaud : {feedback.note}/10</>}
        </span>
    );
};

export default FeedbackNote;
