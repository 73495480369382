import {
    SIGNATURES,
    SEND_SIGNATURE,
    FETCH_SIGNATURES,
    setSignatures,
    POST_SEND_SIGNATURE, setSendSignature
} from '../../../actions/app/signatures/signatures.actions'
import {API_ERROR, API_SUCCESS, apiRequest} from '../../../actions/core/api/api.actions'
import {setError, setLoader} from '../../../actions/app/ui/ui.actions'

import {setNotification} from "../../../actions/core/notifications/notifications.actions";

export const signaturesMiddleware = () => next => action => {
    next(action)

    switch (action.type) {

        case FETCH_SIGNATURES:
            next([
                apiRequest({body: null, method: 'GET', url: action.payload.data, entity: SIGNATURES}),
                setLoader({state: true, entity: SIGNATURES})
            ])
            break

        case `${SIGNATURES} ${API_SUCCESS}`:
            const {data} = action.payload.data

            next([
                setSignatures({data}),
                setError({state: false, entity: SIGNATURES}),
                setLoader({state: false, entity: SIGNATURES})
            ])
            break

        case `${SIGNATURES} ${API_ERROR}`:
            next([
                setError({state: true, entity: SIGNATURES}),
                setLoader({state: false, entity: SIGNATURES})
            ])
            break

        case POST_SEND_SIGNATURE:
            next([
                apiRequest({
                    body: (action.payload.body),
                    method: 'POST',
                    url: action.payload.data,
                    entity: SEND_SIGNATURE
                }),
                setLoader({state: true, entity: SEND_SIGNATURE})
            ])
            break

        case `${SEND_SIGNATURE} ${API_SUCCESS}`:
            next([
                setSendSignature({state: true}),
                setNotification({
                    entity: SEND_SIGNATURE,
                    body: `<p>La procédure de signature a bien été envoyée</p>`,
                    type: 'success',
                    title: 'Procédure envoyée!'
                }),
                setLoader({state: false, entity: SEND_SIGNATURE}),
                setError({state: false, entity: SEND_SIGNATURE}),
            ])
            break

        case `${SEND_SIGNATURE} ${API_ERROR}`:
            next([
                setNotification({
                    entity: SEND_SIGNATURE,
                    body: `<p>Votre demande n'a pas pu être envoyée. ${action.payload.data.response.data.reason}</p>`,
                    type: 'error',
                    title: 'Erreur lors de la création de la procédure !',
                    timeout: '0'
                }),
                setError({state: true, entity: SEND_SIGNATURE}),
                setLoader({state: false, entity: SEND_SIGNATURE}),
            ])
            break

        default:
            break
    }
    return null
}
