import React, {useEffect, useState} from 'react';
import MyButton from "../../../../../Commun/Button/Button";
import _ from "lodash";
import {Link} from "react-router-dom";
import {redirectInNewWindow} from "../../../../../../utils/function";
import {openInWindow} from "../../../../../../constants/constants";


const DashboardCollapseRow = (props) => {
    const {user, uri, getManagerTaskDetail, detail} = props
    const [open, setOpen] = useState(false)

    useEffect(() => {
        if (open && detail !== []) {
            getManagerTaskDetail({query: _.replace(uri, '{id}', user.pro_id), pro_id: user.pro_id})
        }
    }, [open])

    return (
        <>
            <tr>
                <td>{user.name}</td>
                <td>{user.nb_taches}</td>
                <td>{user.nb_reassign}</td>
                <td>{user.nb_agenda}</td>
                <td>
                    {user.nb_taches > 0 &&
                        <MyButton
                            className={'btn btn-rounded'}
                            onClick={() => setOpen(!open)}
                        >
                            {!open ? '+' : '-'}
                        </MyButton>
                    }
                </td>
            </tr>
            {open &&
                <>
                    <tr className={'bg-white'}>
                        <td></td>
                        <td><strong>Panière</strong></td>
                        <td colSpan={3}><strong>Nombre d'activités</strong></td>
                    </tr>
                    {detail !== [] &&
                        <>
                            {_.orderBy(detail, ['paniere'], ['asc']).map((d, index) =>
                                <tr key={index} className={'bg-white'}>
                                    <td className={'border-top-0'}></td>
                                    <td>{d.paniere}</td>
                                    <td colSpan={3}>{d.nb_taches}</td>
                                </tr>
                            )}
                            <tr className={'bg-white'}>
                                <td className={'border-top-0'}></td>
                                <td colSpan={4} className={'text-center'}>
                                    <Link
                                        onClick={(e) => redirectInNewWindow({
                                            e,
                                            url: `/dashboard/detail-suivi/${user.belair_id}`,
                                            name: openInWindow.dashboardDetail
                                        })}
                                    >
                                        Voir plus de détail
                                    </Link>
                                </td>
                            </tr>
                        </>
                    }
                </>
            }
        </>
    );
};


DashboardCollapseRow.defaultProps = {};


DashboardCollapseRow.propTypes = {};


export default DashboardCollapseRow;
