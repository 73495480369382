import React, {useEffect} from 'react';
import {Field} from "redux-form";
import TextAreaFieldRedux from "../../../../../../../Commun/Input/Text/TextAreaFieldRedux";

const DeclarationTer = (props) => {
    const {commentaire1, commentaire2, changeValue} = props

    useEffect(() => {
        changeValue('commentaire', commentaire1 + ' ' + commentaire2)
    }, [commentaire1, commentaire2])

    return (
        <div className={'form-content h-100'}>
            <h1 className={'title-primary'}>Quel était le motif du déplacement au moment du sinistre ?</h1>

            <Field
                name='commentaire1'
                component={TextAreaFieldRedux}
                label={'Motif du déplacement'}
                placeholder={'L\'usage au moment du sinistre'}
                maxLength={'120'}
                subLabel={'120 caractères maximum'}
            />
            <Field
                name='commentaire2'
                component={TextAreaFieldRedux}
                label={'Quelles sont les circonstances du sinistre ?'}
                maxLength={'120'}
                subLabel={'120 caractères maximum'}
            />
        </div>
    );
};

export default DeclarationTer;
