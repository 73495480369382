import React, {Component, Fragment} from 'react';
import {Field} from "redux-form";
import MyButton from "../../../../Commun/Button/Button";
import Spinner from "../../../../Commun/Spinner/Spinner";
import Badge from "../../../../Commun/Badge/Badge";
import SelectFieldRedux from "../../../../Commun/Input/Select/SelectFieldRedux";
import {motifResil, motifResilNVEI, motifResilVAE} from "../../../../../utils/optionsSelect";
import moment from "moment";
import DatePickerFieldRedux from "../../../../Commun/Input/DatePicker/DatePickerFieldRedux";
import TextAreaFieldRedux from "../../../../Commun/Input/Text/TextAreaFieldRedux";
import RadioFieldRedux from "../../../../Commun/Input/Radio/RadioFieldRedux";


class ResilContrat extends Component {
    constructor(props) {
        super(props);
        this.state = {
            motif: this.props.motif,
        };
    }

    handleChange = (e) => {
        this.setState({motif: e.target.value})
        switch (e.target.value) {
            case 'X':
            case '9':
                this.props.changeValue('date', moment(this.props.contract.situation.date))
                break;
            case 'Z':
                this.props.changeValue('date', moment(this.props.contract.situation.date).add(60, 'days'))
                break;
            case 'B':
            case 'H':
                this.props.changeValue('date', moment(this.props.contract.situation.dateEcheance).subtract(1, 'days'))
                break;
            case 'K':
            case 'F':
                this.props.changeValue('date', moment().add(10, 'days'))
                break;
            case 'C':
                this.props.changeValue('date', moment().add(30, 'days'))
                break;
            case 'A':
            case '2':
                if (this.props.contract.situation.statutCode === 'I') {
                    this.props.changeValue('date', moment(this.props.contract.situation.dateFin))
                } else {
                    this.props.changeValue('date', moment())
                }
                break;
            case '4':
            default:
                this.props.changeValue('date', moment())
                break;
        }
    }

    render() {
        const {handleSubmit, errorResil, contract, loading, insurers} = this.props
        const {motif} = this.state
        let listeMotifs = motifResil;

        if (contract.situation.statutCode === 'I' && contract.vehicule.codeProduit && contract.vehicule.codeProduit !== '7311_NVEI') {
            listeMotifs = [{code: 'A', libelle: 'Résiliation suite à vente'}, {code: '2', libelle: 'Résil/Vol'}];
        } else if (contract.vehicule.codeProduit && contract.vehicule.codeProduit === '7311_VAE') {
            listeMotifs = motifResilVAE;
        } else if (contract.vehicule.codeProduit && contract.vehicule.codeProduit === '7311_NVEI') {
            listeMotifs = motifResilNVEI;
        }

        return (
            <div className={'contract-container container'}>
                <Fragment>
                    <div className={'row return-content'}>
                        <div className={'col'}>
                            <button onClick={() => this.props.history.go(-1)} className={'btn btn-return'}>Retour
                            </button>
                        </div>
                    </div>
                    {contract && <div className={'row'}>
                        <div className={'col-12'}>
                            <h1 className={'title-primary'}>{contract.souscripteur.titre} {contract.souscripteur.nom} {contract.souscripteur.id}</h1>
                            <Badge status={contract.situation.statut}/>
                        </div>
                        <div className={'col-12'}>
                            <span
                                className={'bold text-uppercase subtitle'}>Contrat n°{contract.numeroPolice} - {contract.title}</span>
                        </div>
                    </div>}
                </Fragment>
                <div className="row header-row">
                    <div className={'col-12'}>
                        <form className={`form-content`} onSubmit={handleSubmit}>
                            <Field
                                name='motif'
                                component={SelectFieldRedux}
                                label='Sélectionnez un motif de résiliation'
                                onChange={this.handleChange}
                            >
                                <option value={null}>Sélectionner un motif</option>
                                {listeMotifs.map((motif, index) => (
                                    <option key={index} value={motif.code}>{motif.libelle}</option>
                                ))}
                            </Field>
                            {motif === '0' &&
                                <Field
                                    name='insurer'
                                    component={SelectFieldRedux}
                                    label='Sélectionnez la compagnie'
                                >
                                    <option value={null}>Sélectionner la compagnie</option>
                                    {insurers.map((insurer, index) => (
                                        <option key={index} value={insurer.code}>{insurer.libelle}</option>
                                    ))}
                                </Field>
                            }
                            {motif === 'Z' && <p className={'alert alert-danger'}>
                                La LR50 a été générée
                                le {moment(contract.situation.date).add(50, 'days').format('dddd DD MMMM YYYY')}.
                                Merci de bien vérifier la date d'envoi.
                            </p>}
                            {motif === 'B' && <p className={'alert alert-danger'}>
                                L'échéance du contrat est au {moment(contract.situation.dateEcheance).format('L')}.
                                Merci de bien vérifier l'année.
                            </p>}
                            {(motif === '4' || motif === '2') && <p className={'alert alert-danger'}>
                                Merci de vérifier la date de résiliation en regardant la date du sinistre.
                            </p>}
                            {(motif === '9') && <p className={'alert alert-danger'}>
                                Merci de bien vérifier que la date de résiliation correspond à la date d'affaire
                                nouvelle.
                            </p>}

                            {motif === 'W' &&
                                <Field
                                    name={`suite_vol_ou_vente`}
                                    component={RadioFieldRedux}
                                    data={[
                                        {value: "vente", label: "Suite vente ou dépôt de vente"},
                                        {value: "vol", label: "Suite vol"}
                                    ]}
                                />
                            }

                            {motif &&
                                <Field
                                    name='date'
                                    component={DatePickerFieldRedux}
                                    label={'Date de la résiliation'}
                                    minDate={moment(contract.situation.dateAffaireNouvelle)}
                                    maxDate={contract.situation.statutCode === 'I' && contract.situation.dateFin ? moment(contract.situation.dateFin).add(1, 'd').format('YYYY-MM-DD') : null}
                                />
                            }
                            {motif && (motif === "K" || motif === "F" || motif === "C") &&
                                <div>
                                    <p className={'alert alert-warning mb-3'}>Attention à ne pas dépasser
                                        les <b>4&nbsp;retours</b> à la ligne dans la description</p>
                                    <Field
                                        name={'motif_description'}
                                        component={TextAreaFieldRedux}
                                        label={'Description du motif'}
                                        subLabel={'350 caractères maximum'}
                                        maxLength={'350'}
                                    />
                                </div>
                            }
                            {loading
                                ? <Spinner/>
                                : <MyButton
                                    variant='raised'
                                    color='secondary'
                                    size='large'
                                    className={'btn btn-primary'}
                                    disabled={loading}
                                >
                                    {loading && <Spinner absolute/>}
                                    Valider
                                </MyButton>
                            }
                            {errorResil && <h2 className={'title-primary text-uppercase'}>Une erreur est survenue</h2>}
                        </form>
                    </div>
                </div>
            </div>
        );
    }
}

export default ResilContrat;
