import React, {Fragment} from 'react'
import Badge from "../../../../Commun/Badge/Badge";

const NavigationAccidents = (props) => {
    const {accidents, current} = props

    return (
        accidents.data.length > 0 &&
        <div className={'row'}>
            <div className={'col offset-md-3'}>
                {accidents.data.slice(0, 3).map((accident, index) =>
                    <Fragment key={index}>
                        {accident.id !== current &&
                            <a className={`btn btn-return btn-smaller ${index === 2 ? 'btn-next' : 'btn-prev'}`}
                               href={`/contrat/${accident.id_contrat}/sinistre/${accident.id}`}>
                                Sinistre {index === 2 ? 'suivant' : 'précédent'} : n°{accident.numCbt}
                                &nbsp;&nbsp;&nbsp;<Badge status={accident.situation}/>
                            </a>
                        }
                    </Fragment>
                )}
            </div>
        </div>
    )
}

export default NavigationAccidents
