/**
 * Liste des thèmes de dérogation
 */
export const themes = {
    SOUSCRIPTION_ANTECEDENTS_BONUS: 'Champs de souscription - Antécédents/Bonus',
    SOUSCRIPTION_CARTE_GRISE: 'Champs de souscription - Carte grise',
    SOUSCRIPTION_SINISTRE: 'Champs de souscription - Sinistre',
    REV: 'REV',
    RESILIATION: 'Résiliation',
    SINISTRE: 'Sinistre',
    ASSISTANCE: 'Assistance',
    DEROGATION_TARIFAIRE: 'Dérogation tarifaire',
    FRAIS: 'Frais',
    COTISATION: 'Prorata de cotisation',
    GESTE_COMMERCIAL: 'Geste co',
    FIDELISATION: 'Fidélisation',
    FIDELISATION_REVUE_CONTRAT: 'Fidélisation - Revue de contrat',
};

/**
 * Type de d'utilisateur sont les roles paddock
 */
export const rolesPaddock = {
    CONSEILLER: ['gestionnaire an', 'gestionnaire indem', 'gestionnaire vdc'],
    ANIMATEUR: ['animateur'],
    MANAGER: ['manager prod', 'manager', 'managers'],
    DIRECTION: ['direction'],
    DG: ['direction generale'],
};

/**
 * Enumération des roles d'une table des droits pour les dérogations
 */
export const r = {
    CONSEILLER: 'CONSEILLER',
    ANIMATEUR: 'ANIMATEUR',
    MANAGER: 'MANAGER',
    DIRECTION: 'DIRECTION',
    DG: 'DG',
};

/**
 * Liste des montants possibles
 */
export const m = {
    EF_30: { label: '30 euros', value: 30 },
    EF_40: { label: '40 euros', value: 40 },
    EF_50: { label: '50 euros', value: 50 },
    EF_150: { label: '150 euros', value: 150 },
};

/**
 * Formulaire de dérogation (thèmes -> motifs -> montants) avec les droits associés
 */
export const derogationMotifs = [
    {
        label: themes.SOUSCRIPTION_ANTECEDENTS_BONUS,
        motifs: [
            {
                label: "Manque 1 mois d'antécédent",
            },
            {
                label: 'Véhicule non assuré depuis + 3 mois',
                notRoles: [r.CONSEILLER],
            },
            {
                label: 'Reprise du bonus auto en moto',
                notRoles: [r.CONSEILLER, r.ANIMATEUR],
            },
        ],
    },
    {
        label: themes.SOUSCRIPTION_CARTE_GRISE,
        motifs: [
            {
                label: 'Carte grise pas au même nom ni même adresse (selon contexte familial ou commercial)',
                notRoles: [r.CONSEILLER],
            },
            {
                label: "Contrat au nom d'une société mais carte grise au nom d'une personne physique",
                notRoles: [r.CONSEILLER],
            },
            {
                label: 'Valeur véhicule supérieure à 32 500 euros',
                notRoles: [r.CONSEILLER, r.ANIMATEUR, r.MANAGER, r.DIRECTION, r.DG],
            },
        ],
    },
    {
        label: themes.SOUSCRIPTION_SINISTRE,
        motifs: [
            {
                label: '1 sinistre matériel non comptabilisé dans 1 mois',
                notRoles: [r.CONSEILLER],
            },
            {
                label: "Prise avenant lors d'un sinistre en attente de PV avec RC non déterminée",
                notRoles: [r.CONSEILLER],
            },
        ],
    },
    {
        label: themes.REV,
        motifs: [{ label: 'REV suite impayé' }],
    },
    {
        label: themes.RESILIATION,
        motifs: [
            { label: 'Modification du motif de résiliation (uniquement dans période de préavis)' },
            { label: 'Amiable / Assuré' },
        ],
    },
    {
        label: themes.SINISTRE,
        motifs: [
            {
                label: 'Demande suspension garantie pendant la réparation véhicule',
                notRoles: [r.CONSEILLER, r.ANIMATEUR],
            },
            {
                label: 'Avance de la franchise',
                notRoles: [r.CONSEILLER, r.ANIMATEUR, r.MANAGER, r.DIRECTION, r.DG],
            },
            {
                label: 'Avance sur recours',
                notRoles: [r.CONSEILLER, r.ANIMATEUR, r.MANAGER, r.DIRECTION, r.DG],
            },
            {
                label: 'Absence gravage ou SRA',
                notRoles: [r.CONSEILLER, r.ANIMATEUR, r.MANAGER],
            },
            {
                label: "Manque facture d'accessoire ou équipement sous réserve de l'absence de la facture lors d’un sinistre responsable",
                notRoles: [r.CONSEILLER, r.ANIMATEUR, r.MANAGER],
            },
        ],
    },
    {
        label: themes.ASSISTANCE,
        motifs: [
            {
                label: 'Remboursement de la facture au dela du plafond',
                notRoles: [r.CONSEILLER, r.ANIMATEUR, r.MANAGER],
            },
            {
                label: 'Prise en charge remorquage sans appel vers Mondial',
                notRoles: [r.CONSEILLER, r.ANIMATEUR, r.MANAGER],
            },
            {
                label: 'Allonger véhicule de prêt',
                notRoles: [r.CONSEILLER, r.ANIMATEUR, r.MANAGER],
            },
        ],
    },
    {
        label: themes.DEROGATION_TARIFAIRE,
        motifs: [
            {
                label: 'Maintien du tarif en dehors de la période de validité',
                notRoles: [r.CONSEILLER, r.ANIMATEUR],
            },
        ],
    },
    {
        label: themes.FRAIS,
        motifs: [
            {
                label: 'Suppression frais gestion (AV - RES - MED)',
            },
        ],
    },
    {
        label: themes.COTISATION,
        motifs: [
            {
                label: 'Offrir le prorata de cotisation',
            },
            {
                label: 'Suite au décès - Mise en perte quittance',
                montant_libre: true,
                montant_max: {
                    [r.CONSEILLER]: 20,
                    [r.ANIMATEUR]: 50,
                    [r.MANAGER]: 80,
                    [r.DIRECTION]: 150,
                },
            },
        ],
    },
    {
        label: themes.GESTE_COMMERCIAL,
        motifs: [
            {
                label: 'Carte cadeau (hors fid et parrainage)',
                notRoles: [r.CONSEILLER],
                montants: {
                    [r.ANIMATEUR]: [m.EF_30],
                    [r.MANAGER]: [m.EF_30, m.EF_40],
                    [r.DIRECTION]: [m.EF_30, m.EF_40, m.EF_150],
                },
                montant_libre: {
                    [r.DG]: true,
                },
            },
            {
                label: 'Suite à réclamation sinistre',
                notRoles: [r.CONSEILLER],
                montant_libre: true,
                montant_max: {
                    [r.ANIMATEUR]: 100,
                    [r.MANAGER]: 200,
                    [r.DIRECTION]: 500,
                },
            },
        ],
    },
    {
        label: themes.FIDELISATION,
        fid: true,
        motifs: [
            {
                label: 'Carte cadeau',
                montants: {
                    [r.CONSEILLER]: [m.EF_30, m.EF_40],
                    [r.ANIMATEUR]: [m.EF_30, m.EF_40],
                    [r.MANAGER]: [m.EF_30, m.EF_40],
                    [r.DIRECTION]: [m.EF_30, m.EF_40],
                },
                montant_libre: {
                    [r.DG]: true,
                },
                montant_max: {
                    [r.DG]: 150,
                },
            },
            {
                label: 'Avenant régul',
                montant_libre: true,
            },
            {
                label: 'Frais modif (AV/RES)',
                montant_libre: true,
            },
        ],
    },
    {
        label: themes.FIDELISATION_REVUE_CONTRAT,
        fid: true,
        motifs: [
            { label: 'Ajout- Retrait option', montant_libre: true },
            { label: 'Modification des garanties', montant_libre: true },
            { label: "Changements d'usage", montant_libre: true },
            { label: 'Franchise fixe', montant_libre: true },
            { label: 'Changement fractionnement', montant_libre: true },
        ],
    },
];

/**
 *
 * @param {Array} roles Roles du PASS (ex: ['gestionnaire an', 'gestionnaire', 'direction'])
 * @returns {String} Rôle principal de l'utilisateur (ex: 'ANIMATEUR')
 */
export const getMajorRoles = (roles) => {
    // récupérer les rôles de l'utilisateur et les formater pour les comparer avec l'objet rolesPaddock et me retourner une listes de rôles (ex: ['ANIMATEUR', 'MANAGER'])
    const formatedRoles = roles.reduce((acc, role) => {
        const rolePaddock = Object.keys(rolesPaddock).find((key) => rolesPaddock[key].includes(role));

        // vérifier si le rôles n'est pas déjà présent dans le tableau
        if (rolePaddock && !acc.includes(rolePaddock)) {
            acc.push(rolePaddock);
        }

        return acc;
    }, []);

    // récupérer le rôle principal de l'utilisateur (ex: 'ANIMATEUR')
    let majorRole = null;

    if (formatedRoles.includes(r.DG)) {
        majorRole = r.DG;
    } else if (formatedRoles.includes(r.DIRECTION)) {
        majorRole = r.DIRECTION;
    } else if (formatedRoles.includes(r.MANAGER)) {
        majorRole = r.MANAGER;
    } else if (formatedRoles.includes(r.ANIMATEUR)) {
        majorRole = r.ANIMATEUR;
    } else if (formatedRoles.includes(r.CONSEILLER)) {
        majorRole = r.CONSEILLER;
    }

    return majorRole;
};

/**
 *
 * @param {Array} roles Roles du PASS : ['gestionnaire an', 'gestionnaire', 'direction']
 * @param isFidAvailable
 * @returns {Array} Tableau de motifs de dérogation selon les rôles
 */
export const getFormDerogation = (roles, isFidAvailable) => {
    const majorRole = getMajorRoles(roles);
    // const majorRole = 'ANIMATEUR'

    if (majorRole !== r.CONSEILLER) {
        isFidAvailable = true;
    }

    // filtrer les motifs selon le rôle principal de l'utilisateur
    return derogationMotifs.reduce((acc, theme) => {
        // Si le contrat n'est pas éligible à la fid et le thème a fid: true, on retourne directement acc
        if (!isFidAvailable && theme.fid) {
            return acc;
        }

        // Sinon, on traite les motifs comme d'habitude
        const motifs = theme.motifs
            .filter((motif) => !motif.notRoles || !motif.notRoles.includes(majorRole))
            .map((motif) => ({
                label: motif.label,
                montants: motif.montants ? motif.montants[majorRole] : null,
                montant_libre: motif.montant_libre
                    ? typeof motif.montant_libre === 'boolean'
                        ? motif.montant_libre
                        : motif.montant_libre[majorRole]
                    : false,
                montant_max: motif.montant_max ? motif.montant_max[majorRole] : null,
            }));

        if (motifs.length > 0) {
            return [...acc, { label: theme.label, motifs: motifs }];
        }

        return acc;
    }, []);
};
