import React from 'react'
import moment from "moment";

const Edi = (props) => {
    const {task: {date, type, paniere, title, tiers_id}} = props

    return (
        <div className={'container'}>
            <div className={'row'}>
                <div className={'col text-center'}>
                    <h2 className={'title-primary text-uppercase'}>Tâche {paniere} - Tiers n°{tiers_id}</h2>
                    <p className={'subtitle'}>Tâche du {moment(date).format('L')}. Recu par {type}</p>
                </div>
            </div>
            <div className={'header-row row'}>
                <div className={'col-12'}>
                    <p className={'form-content text-center'}>
                        <strong>{title}</strong><br/><br/>

                        Editer le document demandé par le {title.includes('espace pro') ? 'courtier' : 'client'} et lui
                        envoyer par mail via le bouton 'Envoyer un email' sur la fiche du contrat.<br/>
                        Pensez à envoyer également la carte verte par courrier chez le client.
                    </p>
                </div>
            </div>
        </div>
    )
}

export default Edi
