import React from 'react'
import moment from "moment";

const Vehicule = ({vehicule}) => {
    const isVAE = vehicule.codeProduit === '7311_VAE'
    const isNVEI = vehicule.codeProduit === '7311_NVEI'

    return (
        <div className={'bloc-info bloc-vehicule'}>
            <h2>Véhicule assuré</h2>
            <div className={'content row'}>
                <div className={'col-5 left-content'}>
                    <p>Véhicule&nbsp;:</p>
                </div>
                <div className={'col-7 right-content'}>
                    {(!isVAE && !isNVEI) ?
                        <p>{vehicule.marque} {vehicule.modele} {vehicule.cylindree} cm<sup>3</sup></p> :
                        <p>{vehicule.marque} {vehicule.modele}</p>}
                </div>

                {vehicule.version !== "" && <>
                    <div className={'col-5 left-content'}>
                        <p>Version&nbsp;:</p>
                    </div>
                    <div className={'col-7 right-content'}>
                        <p>{vehicule.version}</p>
                    </div>
                </> }

                <div className={'col-5 left-content'}>
                    <p>Type de véhicule&nbsp;:</p>
                </div>
                <div className={'col-7 right-content'}>
                    <p>{vehicule.genre || '-'}</p>
                </div>

                {(!isVAE && !isNVEI) && <>
                    <div className={'col-5 left-content'}>
                        <p>Groupe / Classe&nbsp;:</p>
                    </div>
                    <div className={'col-7 right-content'}>
                        <p>{vehicule.groupe || '-'} / {vehicule.classe || '-'}</p>
                    </div>

                    <div className={'col-5 left-content'}>
                        <p>Date de MEC&nbsp;:</p>
                    </div>
                    <div className={'col-7 right-content'}>
                        <p>{moment(vehicule.dateMEC).format('L')}</p>
                    </div>

                    <div className={'col-5 left-content'}>
                        <p>Immatriculation&nbsp;:</p>
                    </div>
                    <div className={'col-7 right-content'}>
                        <p>{vehicule.immatriculation}</p>
                    </div>
                </> }

                <div className={'col-5 left-content'}>
                    <p>Valeur déclarée:</p>
                </div>
                <div className={'col-7 right-content'}>
                    <p>{vehicule.valeur}€</p>
                </div>

                {(isVAE && vehicule.VIN !== "") && <>
                    <div className={'col-5 left-content'}>
                        <p>Numéro de marquage&nbsp;:</p>
                    </div>
                    <div className={'col-7 right-content'}>
                        <p>{vehicule.VIN}</p>
                    </div>
                </> }

                {!isVAE && <>
                    <div className={'col-5 left-content'}>
                        <p>Usage :</p>
                    </div>
                    <div className={'col-7 right-content'}>
                        <p>{vehicule.usage}</p>
                    </div>
                </> }

                {(!isVAE && !isNVEI) && <>
                    <div className={'col-5 left-content'}>
                        <p>Stationnement :</p>
                    </div>
                    <div className={'col-7 right-content'}>
                        <p>{vehicule.lieuStationnement}</p>
                    </div>
                </> }

                {vehicule.zoneStationnement && <>
                    <div className={'col-5 left-content'}>
                        <p>Zone de garage :</p>
                    </div>
                    <div className={'col-7 right-content'}>
                        <p>{vehicule.zoneStationnement}</p>
                    </div>
                </> }

                {(!isVAE && !isNVEI) && <>
                    <div className={'col-5 left-content'}>
                        <p>Véhicule assuré les 3 derniers mois ?</p>
                    </div>
                    <div className={'col-7 right-content'}>
                        <p></p>
                        <p>{vehicule.assureTroisDerniersMois !== null ? (vehicule.assureTroisDerniersMois ? 'Oui' : 'Non') : '-'}</p>
                    </div>
                </> }
            </div>
        </div>
    )
}

export default Vehicule
