import React from 'react'
import { createRoot } from 'react-dom/client';
import AppProvider from './AppProvider'
import {APP_ENV} from "./constants/constants"
import * as Sentry from "@sentry/react";
import 'bootstrap/dist/css/bootstrap.min.css'
import './components/Commun/Badge/badge.scss'
import './components/Commun/Toggle/toggle.scss'
import './components/App/Page/Accident/Timeline/timeline.scss'
import './assets/scss/style.scss'
import {reportWebVitals, sendToVercelAnalytics} from "./utils/vitals";


window.dataLayer = window.dataLayer || []

window.dataLayer.push({
    'environnement': APP_ENV
})

Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [new Sentry.BrowserTracing(), new Sentry.Replay()],
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 0.1,
    tracesSampleRate: 0.01,
    environment: APP_ENV,
    enabled: true
});

const container = document.getElementById('root');
const root = createRoot(container);

if (APP_ENV) {
    root.render(<AppProvider />);
} else {
    root.render(<h1>.env requis à la racine du projet, un npm start est nécessaire.</h1>);
}

if (APP_ENV === 'production') {
    reportWebVitals(sendToVercelAnalytics);
}
