import React, {useEffect, useState} from 'react';
import moment from "moment/moment";
import {Field} from "redux-form";
import DatePickerFieldRedux from "../../../../../Commun/Input/DatePicker/DatePickerFieldRedux";
import Spinner from "../../../../../Commun/Spinner/Spinner";
import ClausesHivernage from "./ClausesHivernage/ClausesHivernage";

const FormHivernage = (props) => {
    const {can_activate_hivernage, dernier_hivernage, maxDate, loading, loading_fin, handleSubmit, submitFinHivernage, hivernagePost, maxDateEcheance, submitGetHivernageReduc, hivernage_reduction, loading_get_reduc, dateFin, formIsValid} = props
    const [tarifSimule, setTarifSimule] = useState(false)

    useEffect(() => {
        if (hivernage_reduction){
            setTarifSimule(true)
        }
    }, [hivernage_reduction])

    return (
        <>
            {dernier_hivernage?.statutCode === '1' &&
                <>
                    <p className={'font-weight-bold'}><span className={'text-light-blue'}>Ma période hivernale</span> est activée jusqu’au {moment(dernier_hivernage.dateFin, 'YYYY-MM-DD').format('DD/MM/YYYY')}.</p>
                    <div className="d-grid gap-2">
                        <div className={'text-center'}>
                            {
                                hivernagePost ?
                                <div className="alert alert-success text-center" role="alert">
                                    <p>Ma période hivernale est bien désactivée</p>
                                </div>
                                : loading_fin ? <Spinner/> :
                                    <button className={'btn btn-primary max-rounded null mb-5'}
                                            type={'button'}
                                            onClick={() => {
                                                if (window.confirm('En désactivant de manière anticipée Ma période hivernale, il ne sera plus possible de la réactiver')) submitFinHivernage()
                                            }}>
                                        Je désactive Ma période hivernale de manière anticipée
                                    </button>
                            }
                        </div>
                    </div>
                </>
            }
            {
                (dernier_hivernage?.periodeCourante && dernier_hivernage.statutCode !== '1') &&
                <div className={'mb-4'}>
                    <p className={'font-weight-bold'}>Le client a bénéficié de <span className={'text-light-blue'}>Ma période hivernale</span> entre le {moment(dernier_hivernage.dateDebut, 'YYYY-MM-DD').format('DD/MM/YYYY')} et le {moment(dernier_hivernage.dateFin, 'YYYY-MM-DD').format('DD/MM/YYYY')}.</p>
                    {!can_activate_hivernage && <p className={'font-weight-bold mb-0'}>Il n’est plus possible de l’activer.</p> }
                </div>
            }

            <div className={`${(dernier_hivernage?.statutCode === '1' || (dernier_hivernage?.periodeCourante && !can_activate_hivernage)) ? 'overlay p-4' : ''}`}>
                <ClausesHivernage/>
                {
                    can_activate_hivernage &&
                    <div className="col-12 mx-auto">
                        <form onSubmit={handleSubmit} className={'my-5'}>
                            <div className="row my-4">

                                <div className="col col-md-6 mb-4">
                                    <p>La date de début est <span className={'font-weight-bold'}>obligatoirement</span> celle d’aujourd’hui</p>
                                </div>

                                <div className="col col-md-6">
                                    {
                                        maxDateEcheance
                                            ? <p>La date de fin de Ma période hivernale ne peut pas dépasser la date d’échéance <span className={'font-weight-bold'}>{moment(maxDate).format('DD/MM/YYYY')}</span></p>
                                            : <p>La date de fin de Ma période hivernale ne peut dépasser le <span className={'font-weight-bold'}>{moment(maxDate).format('DD/MM/YYYY')}</span></p>
                                    }
                                </div>

                                <div className="col col-md-6 mt-3">
                                    <p>Date de début :<br/><span className={'font-weight-bold'}>{moment().format('DD/MM/YYYY')}</span></p>
                                </div>

                                <div className="col col-md-6">
                                    <div className={'mb-2'}>
                                        <Field
                                            name={'dateFin'}
                                            component={DatePickerFieldRedux}
                                            label={'Date de fin'}
                                            minDate={moment().add(1, 'day')}
                                            maxDate={maxDate}
                                            onChange={() => setTarifSimule(false)}
                                        />
                                    </div>
                                    {
                                        loading_get_reduc ? <Spinner/> :
                                        <button className={'btn btn-primary btn-small max-rounded'}
                                            type={'button'}
                                            disabled={!formIsValid}
                                            onClick={() => submitGetHivernageReduc()}>
                                            Estimer la réduction
                                        </button>
                                    }

                                    {
                                        (tarifSimule && hivernage_reduction) &&
                                        <p className="mb-0 mt-3">
                                            Pour Ma période hivernale du {moment().format('DD/MM/YYYY')} au {moment(dateFin).format('DD/MM/YYYY')}, <span className={'font-weight-bold'}>le montant estimé de votre réduction est de {hivernage_reduction} euros. </span>
                                            Le montant exact de la réduction sera affiché sur la lettre-avenant transmise après activation.
                                        </p>
                                    }
                                </div>

                            </div>
                            <div className={'text-center'}>
                                {
                                    hivernagePost ?
                                    <div className="alert alert-success text-center" role="alert">
                                        <p>Ma période hivernale est bien activée</p>
                                    </div>
                                    : loading
                                        ? <Spinner/>
                                        : <button
                                                className={'btn btn-primary max-rounded null'}
                                                type={'submit'}
                                                disabled={!tarifSimule}
                                            >
                                            {
                                                dernier_hivernage?.periodeCourante
                                                    ? 'Je réactive Ma période hivernale aux dates choisies'
                                                    : 'J\'active Ma période hivernale aux dates choisies'
                                            }
                                        </button>
                                }
                            </div>
                        </form>
                    </div>
                }
            </div>
        </>
    );
};
export default FormHivernage;