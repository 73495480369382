import React from 'react'
import moment from "moment";

const Resume = ({accident, contract}) => {
    return (
        <div className={'bloc-info bloc-decla'}>
            <h2>Déclaration</h2>
            <div className={'content row'}>

                <div className={'col-5 left-content'}>
                    <p>Véhicule concerné :</p>
                </div>
                <div className={'col-7 right-content'}>
                    <p>{accident.marqueVehicule} {accident.modeleVehicule}</p>
                </div>

                <div className={'col-5 left-content'}>
                    <p>Immatriculation :</p>
                </div>
                <div className={'col-7 right-content'}>
                    <p>{accident.immatVehicule}</p>
                </div>

                <div className={'col-5 left-content'}>
                    <p>Date mise en circulation :</p>
                </div>
                <div className={'col-7 right-content'}>
                    <p>{moment(accident.dateMECVehicule).format('L')}</p>
                </div>

                <div className={'col-5 left-content'}>
                    <p>Date du sinistre :</p>
                </div>
                <div className={'col-7 right-content'}>
                    <p>{moment(accident.dateSurv).format('L')}</p>
                </div>

                <div className={'col-5 left-content'}>
                    <p>Sinistre ouvert le :</p>
                </div>
                <div className={'col-7 right-content'}>
                    <p>{moment(accident.dateOuverture).format('L')}</p>
                </div>

                <div className={'col-5 left-content'}>
                    <p>Lieu du sinistre :</p>
                </div>
                <div className={'col-7 right-content'}>
                    <p>{accident.codePostalAccident !== '99000' && accident.codePostalAccident} {accident.villeAccident}</p>
                </div>

                <div className={'col-5 left-content'}>
                    <p>Code produit :</p>
                </div>
                <div className={'col-7 right-content'}>
                    <p>{accident.codeProduit}</p>
                </div>

                {
                    accident.codeProduit === "7311_MOT1" &&
                    <>
                        <div className={'col-5 left-content'}>
                            <p>Crédit franchise fidélité :</p>
                        </div>
                        <div className={'col-7 right-content'}>
                            <p>{accident.credit_franchise}</p>
                        </div>
                    </>
                }

            </div>
        </div>
    )
}

export default Resume
