import {
    TASK,
    GET_TASK,
    setTask,
    CLOSE_TASK,
    setCloseTask,
    POST_CLOSE_TASKS,
    POST_REASSIGN_TASKS,
    setReassignTask,
    REASSIGN_TASK,
    POST_SHIFT_DATE_TASKS, SHIFT_DATE_TASK, POST_UPDATE_INSATISFACTION_REASON_TASK_FEEDBACK
} from '../../../actions/app/tasks/tasks.actions'
import {API_ERROR, API_SUCCESS, apiRequest} from '../../../actions/core/api/api.actions'
import {setError, setLoader} from '../../../actions/app/ui/ui.actions'
import {setNotification} from "../../../actions/core/notifications/notifications.actions";

import moment from "moment";
import _ from "lodash";

export const taskDetailMiddleware = () => next => action => {
    next(action)

    let message = null

    switch (action.type) {
        case GET_TASK:
            next([
                apiRequest({body: null, method: 'GET', url: action.payload.data, entity: TASK}),
                setLoader({state: true, entity: TASK})
            ])
            break
        case `${TASK} ${API_SUCCESS}`:
            next([
                setTask({data: action.payload.data}),
                setError({state: false, entity: TASK}),
                setLoader({state: false, entity: TASK})
            ])
            break

        case `${TASK} ${API_ERROR}`:
            next([
                setError({state: true, entity: TASK}),
                setLoader({state: false, entity: TASK})
            ])
            break

        case `${POST_CLOSE_TASKS}`:
            next([
                apiRequest({body: null, method: 'POST', url: action.payload.data, entity: CLOSE_TASK}),
                setLoader({state: true, entity: CLOSE_TASK})
            ])
            break

        case `${CLOSE_TASK} ${API_SUCCESS}`:
            next([
                setCloseTask({state: true}),
                setError({state: false, entity: CLOSE_TASK}),
                setLoader({state: false, entity: CLOSE_TASK})
            ])
            break

        case `${CLOSE_TASK} ${API_ERROR}`:
            next([
                setError({state: true, entity: CLOSE_TASK}),
                setLoader({state: false, entity: CLOSE_TASK})
            ])
            break

        case `${POST_REASSIGN_TASKS}`:
            let dataForm = new FormData()
            if (action.payload.body.contrat !== undefined && action.payload.body.contrat !== '') {
                dataForm.set('contrat', action.payload.body.contrat)
            } else if (action.payload.body.sinistre !== undefined && action.payload.body.sinistre !== '') {
                dataForm.set('sinistre', action.payload.body.sinistre)
            } else {
                dataForm.set('paniere', action.payload.body.paniere)
                dataForm.set('keep_old', action.payload.body.keep_old)
            }
            next([
                apiRequest({body: dataForm, method: 'POST', url: action.payload.data, entity: REASSIGN_TASK}),
                setLoader({state: true, entity: REASSIGN_TASK})
            ])
            break

        case `${REASSIGN_TASK} ${API_SUCCESS}`:
            message = `<p>La tâche a bien été réassignée.</p>`
            next([
                setReassignTask({state: true}),
                setNotification({entity: REASSIGN_TASK, body: message, type: 'success', title: 'Tâche réassignée !'}),
                setError({state: false, entity: REASSIGN_TASK}),
                setLoader({state: false, entity: REASSIGN_TASK})
            ])
            break

        case `${REASSIGN_TASK} ${API_ERROR}`:
            next([
                setError({state: true, entity: REASSIGN_TASK}),
                setLoader({state: false, entity: REASSIGN_TASK})
            ])
            break

        case `${POST_SHIFT_DATE_TASKS}`:
            let clone = _.cloneDeep(action.payload.body)
            clone.date = clone.date ? moment(clone.date).format('DD/MM/YYYY') : null

            next([
                apiRequest({
                    body: (clone),
                    method: 'POST',
                    url: action.payload.data,
                    entity: SHIFT_DATE_TASK
                }),
                setLoader({state: true, entity: SHIFT_DATE_TASK})
            ])
            break

        case `${SHIFT_DATE_TASK} ${API_SUCCESS}`:
            message = `<p>La tâche a bien été décalée.</p>`
            next([
                setNotification({entity: SHIFT_DATE_TASK, body: message, type: 'success', title: 'Tâche décalée !'}),
                setError({state: false, entity: SHIFT_DATE_TASK}),
                setLoader({state: false, entity: SHIFT_DATE_TASK})
            ])
            break

        case `${SHIFT_DATE_TASK} ${API_ERROR}`:
            next([
                setError({state: true, entity: SHIFT_DATE_TASK}),
                setLoader({state: false, entity: SHIFT_DATE_TASK})
            ])
            break

        case POST_UPDATE_INSATISFACTION_REASON_TASK_FEEDBACK:
            next([
                apiRequest({
                    body: (action.payload.body),
                    method: 'POST',
                    url: action.payload.data,
                    entity: POST_UPDATE_INSATISFACTION_REASON_TASK_FEEDBACK,
                    otherData: action.payload.otherData
                }),
                setLoader({state: true, entity: POST_UPDATE_INSATISFACTION_REASON_TASK_FEEDBACK}),
            ])
            break

        case `${POST_UPDATE_INSATISFACTION_REASON_TASK_FEEDBACK} ${API_SUCCESS}`:
            message = `<p>La tâche a bien été mise à jour et clôturée.</p>`

            next([
                apiRequest({
                    body: null,
                    method: 'POST',
                    url: action.payload.meta.otherData.queryCloseTask,
                    entity: CLOSE_TASK
                }),
                setError({state: false, entity: POST_UPDATE_INSATISFACTION_REASON_TASK_FEEDBACK}),
                setLoader({state: false, entity: POST_UPDATE_INSATISFACTION_REASON_TASK_FEEDBACK}),
                setNotification({
                    entity: POST_UPDATE_INSATISFACTION_REASON_TASK_FEEDBACK,
                    body: message,
                    type: 'success',
                    title: 'Tâche validée'
                }),
            ])

            break

        case `${POST_UPDATE_INSATISFACTION_REASON_TASK_FEEDBACK} ${API_ERROR}`:
            next([
                setError({state: true, entity: POST_UPDATE_INSATISFACTION_REASON_TASK_FEEDBACK}),
                setLoader({state: false, entity: POST_UPDATE_INSATISFACTION_REASON_TASK_FEEDBACK})
            ])
            break

        default:
            break;
    }
    return null
}
