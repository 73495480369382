import React, {Fragment} from 'react'
import moment from "moment";
import _ from "lodash";
import ButtonsContainer from "./Buttons/ButtonsContainer";

const FlotteDetail = (props) => {
    const {vehiculeId, vehicule, contract, roles} = props

    return (
        <div className={'cd-main-content'}>
            <div className={'contract-container container'}>
                <div className={'row return-content'}>
                    <div className={'col-2'}>
                        <button onClick={() => props.history.go(-1)} className={'btn btn-return'}>Retour</button>
                    </div>
                </div>

                {!_.includes(roles, 'gestionnaire televente') &&
                    <ButtonsContainer contract={contract} vehiculeId={vehiculeId}/>}

                <div className={'row margin-top'}>
                    <div className={'col'}>
                        <div className={'bloc-info bloc-souscripteur'}>
                            <h2>Véhicule</h2>
                            <div className={'content row overflow-auto'}>
                                <div className={'col-5 left-content'}>
                                    <p>Adhésion&nbsp;:</p>
                                    <p>Véhicule&nbsp;:</p>
                                    <p>Cylindrée&nbsp;:</p>
                                    <p>Immatriculation&nbsp;:</p>
                                    <p>Date de MEC&nbsp;:</p>
                                    <p>Date d'entrée&nbsp;:</p>
                                    <p>Date de sortie&nbsp;:</p>
                                </div>
                                <div className={'col-7 right-content'}>
                                    <p>{vehicule.adhesion}</p>
                                    <p>{vehicule.marque} {vehicule.modele}</p>
                                    <p>{vehicule.cylindree ? `${vehicule.cylindree}cm<sup>3</sup>` : '-'}</p>
                                    <p>{vehicule.immatriculation}</p>
                                    <p>{vehicule.dateMEC ? moment(vehicule.dateMEC).format('L') : '-'}</p>
                                    <p>{vehicule.dateEntree ? moment(vehicule.dateEntree).format('L') : '-'}</p>
                                    <p>{vehicule.dateSortie ? moment(vehicule.dateSortie).format('L') : '-'}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={'col'}>
                        <div className={'bloc-info bloc-garanties'}>
                            <h2>Garanties</h2>
                            <div className={'content row overflow-auto'}>
                                <div className={'col-12'}>
                                    {
                                        vehicule.garanties.map((g, i) => (
                                            <Fragment key={i}><p>{g.libelle}</p></Fragment>
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default FlotteDetail
