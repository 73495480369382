
import {API_ERROR, API_SUCCESS, apiRequest} from '../../../actions/core/api/api.actions'
import {setError, setLoader} from '../../../actions/app/ui/ui.actions'
import {setNotification} from "../../../actions/core/notifications/notifications.actions";
import {
    POST_CHANGE_SITUATION_CONTRACT,
    CHANGE_SITUATION_CONTRACT
} from "../../../actions/app/change_situation_contract/change_situation_contract.actions";
import moment from "moment";
import {CLOSE_TASK} from "../../../actions/app/tasks/tasks.actions";

export const changeSituationContractMiddleware = () => next => action => {
    next(action)

    const {payload} = action
    let message = null
    let title = null
    let nextAction = []

    switch (action.type) {
        case POST_CHANGE_SITUATION_CONTRACT:
            if (payload.body.type === 'terminate' || payload.body.type === 'rev') {
                payload.body.date = moment(payload.body.date).format('YYYY-MM-DD')
            }

            nextAction = [
                apiRequest({
                    body: (payload.body),
                    method: 'POST',
                    url: payload.data,
                    entity: CHANGE_SITUATION_CONTRACT,
                    otherData: payload.otherData
                }),
                setLoader({state: true, entity: CHANGE_SITUATION_CONTRACT})
            ]

            if (payload.otherData.terminate !== undefined) {
                nextAction.push(setLoader({state: true, entity: CLOSE_TASK}))
            }

            next(nextAction)
            break

        case `${CHANGE_SITUATION_CONTRACT} ${API_SUCCESS}`:
            nextAction = [
                setLoader({state: false, entity: CHANGE_SITUATION_CONTRACT}),
                setError({state: false, entity: CHANGE_SITUATION_CONTRACT}),
            ]
            if (payload.meta.otherData.terminate !== undefined) {
                nextAction.push(apiRequest({
                    body: null,
                    method: 'POST',
                    url: payload.meta.otherData.terminate,
                    entity: CLOSE_TASK
                }))
            } else {
                if (payload.meta.otherData.type === 'terminate') {
                    message = `<p>Le contrat a bien été suspendu ou resilié</p>`
                    title = 'Le contrat est resilié !';
                } else if (payload.meta.otherData.type === 'rev') {
                    message = `<p>Le contrat a bien été remis en vigueur</p>`
                    title = 'Le contrat est remis en vigueur !';
                } else {
                    message = `<p>Le contrat a été validé et la situation du contrat est passée en cours</p>`
                    title = 'Le contrat a été validé !';
                }
                nextAction.push(setNotification({
                    entity: CHANGE_SITUATION_CONTRACT,
                    body: message,
                    type: 'success',
                    title: title
                }))
            }
            if (payload.data && payload.data.data && payload.data.data.message !== "") {
                nextAction.push(setNotification({
                    entity: CHANGE_SITUATION_CONTRACT,
                    body: `<p>${payload.data.data.message}</p>`,
                    type: 'warning',
                    title: 'Attention !',
                    timeout: '0'
                }))
            }

            next(nextAction)

            break

        case `${CHANGE_SITUATION_CONTRACT} ${API_ERROR}`:
            message = `
                <p>Votre demande n'a pas pu être envoyée. ${payload.data.response.data.reason}</p>
            `
            next([
                setNotification({
                    entity: CHANGE_SITUATION_CONTRACT,
                    body: message,
                    type: 'error',
                    title: 'Erreur !',
                    timeout: '0'
                }),
                setError({state: true, entity: CHANGE_SITUATION_CONTRACT}),
                setLoader({state: false, entity: CHANGE_SITUATION_CONTRACT}),
            ])
            break

        default:
            break
    }
    return null
}
