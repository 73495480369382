import React from 'react';
import * as Sentry from "@sentry/react";
import {BrowserRouter, Route, Switch, Redirect} from 'react-router-dom'
import _ from 'lodash'
import LoginContainer from './Login/LoginContainer'
import PrivateRouteContainer from './PrivateRoute/PrivateRouteContainer'
import PageContainer from './Page/PageContainer'
import WithTrackerComponent from '../Commun/GTM/WithTrackerComponent'
import Maintenance from "./Maintenance/Maintenance";

const App = (props) => {
    const {maintenance, favicon} = props

    document.title = `Espace conseiller | APRIL Moto`

    const meta = document.createElement('link');
    meta.setAttribute('rel', 'shortcut icon');
    meta.setAttribute('href', favicon);
    document.getElementsByTagName('head')[0].appendChild(meta);

    return (
        <BrowserRouter>
            <Switch>
                {maintenance && !_.startsWith(window.location.pathname, '/maintenance') &&
                    <Redirect to={'/maintenance'}/>}
                <Route exact path='/login' component={WithTrackerComponent(LoginContainer)}/>
                <Route exact path='/maintenance' component={WithTrackerComponent(Maintenance)}/>
                <PrivateRouteContainer path='/' component={PageContainer} className='loader'/>
            </Switch>
        </BrowserRouter>
    )
}

export default App
