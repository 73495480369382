import {API_ERROR, API_SUCCESS, apiRequest} from '../../../actions/core/api/api.actions'
import {setError, setLoader} from '../../../actions/app/ui/ui.actions'
import {
    GET_RAPPORT_BCA,
    setRapportBca,
    RAPPORT_BCA,
    POST_DELETE_RAPPORT_BCA, DELETE_RAPPORT_BCA
} from "../../../actions/app/bca/bca.actions";
import {setNotification} from "../../../actions/core/notifications/notifications.actions";

export const rapportBcaMiddleware = () => next => action => {
    next(action)

    switch (action.type) {
        case GET_RAPPORT_BCA:
            next([
                apiRequest({body: null, method: 'GET', url: action.payload.data, entity: RAPPORT_BCA}),
                setLoader({state: true, entity: RAPPORT_BCA})
            ])
            break

        case `${RAPPORT_BCA} ${API_SUCCESS}`:
            next([
                setRapportBca({rapport_bca: action.payload.data}),
                setError({state: false, entity: RAPPORT_BCA}),
                setLoader({state: false, entity: RAPPORT_BCA})
            ])
            break

        case `${RAPPORT_BCA} ${API_ERROR}`:
            next([
                setError({state: true, entity: RAPPORT_BCA}),
                setLoader({state: false, entity: RAPPORT_BCA})
            ])
            break

        case POST_DELETE_RAPPORT_BCA:
            next([
                apiRequest({
                    body: JSON.stringify(action.payload.body),
                    method: 'POST',
                    url: action.payload.data,
                    entity: DELETE_RAPPORT_BCA
                }),
                setLoader({state: true, entity: DELETE_RAPPORT_BCA})
            ])
            break

        case `${DELETE_RAPPORT_BCA} ${API_SUCCESS}`:
            next([
                setNotification({
                    entity: DELETE_RAPPORT_BCA,
                    body: `<p>Le rapport a bien été supprimé</p>`,
                    type: 'success',
                    title: 'Rapport supprimé !'
                }),
                setLoader({state: false, entity: DELETE_RAPPORT_BCA}),
                setError({state: false, entity: DELETE_RAPPORT_BCA})
            ])
            break

        case `${DELETE_RAPPORT_BCA} ${API_ERROR}`:
            next([
                setNotification({
                    entity: DELETE_RAPPORT_BCA,
                    body: `<p>Erreur lors de la suppression du rapport: ${action.payload.data.response.data.reason}</p>`,
                    type: 'error',
                    title: 'Attention !',
                    timeout: '0'
                }),
                setError({state: true, entity: DELETE_RAPPORT_BCA}),
                setLoader({state: false, entity: DELETE_RAPPORT_BCA}),
            ])
            break

        default:
            break
    }
    return null
}
