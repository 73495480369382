import React, {Fragment} from 'react'
import {Link} from "react-router-dom";
import _ from "lodash";
import CallToAction from "../../../../Commun/Button/CallToAction/CallToAction";

const TiersAdverse = props => {
    const {adverse, permissions, contract, accident, compagnieAdverse, idCompagnieAdverse, roles} = props

    return (
        <div className={'bloc-info bloc-intervenants'}>
            <h2>Tiers Adverse</h2>
            {_.includes(permissions, 'create tiers') &&
                <Fragment>
                    {adverse.id ?
                        <CallToAction
                            url={{
                                pathname: `/tiers/${adverse.id}/edition`,
                                state: {
                                    tiers: adverse.id,
                                    contract: contract,
                                    accident: accident,
                                    adverse: adverse
                                }
                            }}
                            action={'edit-repairer'}
                            toolText={'Modifier le tiers adverse'}
                        />
                        :
                        <CallToAction
                            url={{
                                pathname: `/tiers/create`,
                                state: {
                                    contract: contract,
                                    accident: accident
                                }
                            }}
                            action={'edit-repairer'}
                            toolText={'Ajouter le tiers adverse'}
                        />
                    }
                </Fragment>
            }
            <div className={'content row'}>
                {(adverse && adverse.id) ?
                    <Fragment>
                        <div className={'col-5 left-content'}>
                            <p>Dossier :</p>
                        </div>
                        <div className={'col-7 right-content'}>
                            <p>
                                {adverse.id ?
                                    <Link to={`/tiers/${adverse.id}`}>
                                        {adverse.id} - {adverse.prenom || ''} {adverse.nom || ''} - {adverse.codePostal} {adverse.ville}
                                    </Link>
                                    : '-'
                                }
                            </p>
                        </div>
                        <div className={'col-5 left-content'}>
                            <p>Compagnie :</p>
                        </div>
                        <div className={'col-7 right-content'}>
                            <p>{compagnieAdverse ?
                                <Link to={`/tiers/${idCompagnieAdverse}`}>{compagnieAdverse}</Link> : "-"}
                                {!_.includes(roles, 'gestionnaire televente') &&
                                    <CallToAction
                                        url={{
                                            pathname: `/contrat/${contract}/sinistre/${accident}/modification-compagnie`,
                                            state: {
                                                compagnie: idCompagnieAdverse,
                                                contract: contract,
                                                accident: accident
                                            }
                                        }}
                                        action={'edit-repairer'}
                                        toolText={`${idCompagnieAdverse ? 'Modifier' : 'Ajouter'} la compagnie adverse`}
                                    />
                                }
                            </p>
                        </div>
                        <div className={'col-5 left-content'}>
                            <p>Nom :</p>
                        </div>
                        <div className={'col-7 right-content'}>
                            <p>{adverse.prenom || '-'} {adverse.nom || '-'}</p>
                        </div>
                        <div className={'col-5 left-content'}>
                            <p>Immat :</p>
                        </div>
                        <div className={'col-7 right-content'}>
                            <p>{adverse.immat || '-'}</p>
                        </div>
                        <div className={'col-5 left-content'}>
                            <p>Police :</p>
                        </div>
                        <div className={'col-7 right-content'}>
                            <p>{adverse.police || '-'}</p>
                        </div>
                    </Fragment>
                    : <div className={'col-12'}>
                        <p>Aucun tiers adverse de renseigné pour ce sinistre</p>
                    </div>
                }
            </div>
        </div>
    )
}

export default TiersAdverse
