import React from 'react'
import moment from "moment";
import {formatThousandSpace} from "../../../../../utils/function";

const DashboardInfo = (props) => {
    const {data: {poles, total}} = props

    const returnClassname = (date) => {
        let diff = moment(moment().format("YYYY-MM-DD")).diff(date, 'days');

        if (diff > 60) {
            return 'alert-danger'
        } else if (diff <= 60 && diff >= 30) {
            return 'alert-warning'
        } else if (diff < 30) {
            return 'alert-prewarning'
        }
    }


    return (
        <div className={'container'}>
            <div className={'row'}>
                <div className={'col-12 total-tasks'}>
                    <h2 className={'total-tasks content'}>{formatThousandSpace(total)} tâches à traiter</h2>
                </div>
            </div>
            <div className={'row poles'}>
                {poles.map((pole, index) =>
                    <div className={'col-3'} key={index}>
                        <div className={'item'}>
                            <h3>{pole.pole}</h3>
                            <p><strong>{formatThousandSpace(pole.nbPopups)}</strong> tâches à traiter</p>
                            <p className={`round-date ${returnClassname(pole.date_last)}`}>Plus ancien
                                : <strong>{moment(pole.date_last).format('L')}</strong></p>
                        </div>
                    </div>
                )}
            </div>
        </div>
    )
}

export default DashboardInfo
