import React, {useRef, useState} from 'react';
import {Field} from "redux-form";
import _ from 'lodash'
import TextFieldRedux from "../../../../Commun/Input/Text/TextFieldRedux";
import {capitalizeInput} from "../../../../../utils/normalizeInputs";
import MyButton from "../../../../Commun/Button/Button";
import ChangeTitulaire from "../../../../Commun/Forms/ChangeTitulaire/ChangeTitulaire";
import Spinner from "../../../../Commun/Spinner/Spinner";
import Badge from "../../../../Commun/Badge/Badge";
import {checkIban} from "../../../../../utils/function";

const ChangeRib = (props) => {
    const {handleSubmit, errorRib, contract, changeValue, loadingValidateRib, tiers, ibanIsCheck} = props
    const [errorIban, setErrorIban] = useState(null)
    const [loadingIban, setLoadingIban] = useState(false)
    const inputIban = useRef("");

    const fetchCheckIban = async (value) => {
        setLoadingIban(true)
        let iban = await checkIban(value)
        setLoadingIban(false)
        changeValue('domiciliation', iban.bank_data.bank)
        changeValue('bic', iban.bank_data.bic)

        /* Vérifie si le format de l'iban est correct */
        const codeValidationsSuccess = ['001', '002', '003', '004', '005', '006', '007'];
        const fieldValidations = ['account', 'country_support', 'iban', 'length', 'structure'];
        let error = null

        _.forEach(fieldValidations, field => {
            if (!_.includes(codeValidationsSuccess, iban.validations[field].code)) {
                error = iban.validations[field].message
                return false;
            }
        });

        /* Vérifie si l'iban accepte les prélèvement */
        if (iban.sepa_data.SDD === "NO" || iban.sepa_data.SCT === "NO") {
            return setErrorIban("L'IBAN renseignée n'autorise pas les prélèvements bancaires")
        }

        return error ? setErrorIban(`Le format de l'IBAN n'est pas valide. <br /> ${error}`) : setErrorIban(null)
    }

    return (
        <div className={'contract-container container'}>
            <div className={'row return-content'}>
                <div className={'col'}>
                    <button onClick={() => props.history.go(-1)} className={'btn btn-return'}>Retour</button>
                </div>
            </div>
            {
                contract.id && <div className={'row'}>
                    <div className={'col-12'}>
                        <h1 className={'title-primary'}>{contract.souscripteur.titre} {contract.souscripteur.nom} {contract.souscripteur.id}</h1>
                        <Badge status={contract.situation.statut}/>
                    </div>
                    <div className={'col-12'}>
                        <span
                            className={'bold text-uppercase subtitle'}>Contrat n°{contract.numeroPolice} - {contract.title}</span>
                    </div>
                </div>
            }
            {
                tiers && tiers.belair_id && <div className={'row'}>
                    <div className={'col'}>
                        <h1 className={'title-primary'}>{tiers.belair_id} - {tiers.titre} {tiers.name}</h1>
                    </div>
                </div>
            }
            <div className="row header-row">
                <div className={'col-12'}>
                    <form className={`form-content`} onSubmit={handleSubmit}>
                        <p><u>Information actuelle :</u></p>
                        {
                            contract && contract.banque && <>
                                <p>Nom titulaire : <i>{contract.banque.titulaire || '-'}</i></p>
                                <p>IBAN : <i>{contract.banque.iban || '-'}</i></p>
                                <p>Domiciliation : <i>{contract.banque.domiciliation || '-'}</i></p>
                                <p>BIC : <i>{contract.banque.bic || '-'}</i></p>
                            </>
                        }
                        {
                            tiers && tiers.banque && <>
                                <p>Nom titulaire : <i>{tiers.banque.titulaire || '-'}</i></p>
                                <p>IBAN : <i>{tiers.banque.iban || '-'}</i></p>
                                <p>Domiciliation : <i>{tiers.banque.domiciliation || '-'}</i></p>
                                <p>BIC : <i>{tiers.banque.bic || '-'}</i></p>
                            </>
                        }
                        <Field
                            name='iban'
                            component={TextFieldRedux}
                            label={'IBAN'}
                            ref={inputIban}
                            normalize={value => value && value.toUpperCase()}
                        />

                        {
                            ibanIsCheck && <>
                                <Field
                                    name='domiciliation'
                                    component={TextFieldRedux}
                                    label={'Domiciliation de la banque'}
                                    disabled
                                    normalize={capitalizeInput}
                                />
                                <Field
                                    name='bic'
                                    component={TextFieldRedux}
                                    label={'Code BIC'}
                                    disabled
                                    normalize={capitalizeInput}
                                />
                            </>
                        }

                        {
                            errorIban &&
                            <div className={'alert-danger alert mb-0'} dangerouslySetInnerHTML={{__html: errorIban}}/>
                        }

                        {
                            ibanIsCheck && !errorIban && <div className={'alert-success alert mb-0'}>
                                l'IBAN est valide
                            </div>
                        }

                        <span
                            color='secondary'
                            className={`${loadingIban && 'disabled'} btn btn-secondary position-relative my-3`}
                            style={{minHeight: 50, minWidth: 230}}
                            onClick={loadingIban ? null : () => fetchCheckIban(inputIban.current.value)}
                        >
                                {loadingIban && <Spinner absolute/>}
                            Vérifier l'IBAN du client
                            </span>

                        <ChangeTitulaire
                            contract_id={contract ? contract.id : null}
                            souscripteur={contract && contract.souscripteur ? `${contract.souscripteur.lastname} ${contract.souscripteur.firstname ?? ''}` : null}
                            changeValue={changeValue}/>

                        {
                            ibanIsCheck && <MyButton
                                variant='raised'
                                color='secondary'
                                size='large'
                                className={'btn btn-primary position-relative'}
                                disabled={loadingValidateRib || errorIban}
                                style={{width: 232}}
                            >
                                {loadingValidateRib ? <Spinner absolute/> : "Valider"}
                            </MyButton>
                        }

                        {errorRib && <h2 className={'title-primary text-uppercase'}>Une erreur est survenue</h2>}
                    </form>
                </div>
            </div>
        </div>
    );
};

export default ChangeRib;
