import React, {Fragment} from 'react'
import Badge from "../../../../Commun/Badge/Badge";
import ButtonsContainer from "../Buttons/ButtonsContainer";
import {redirectInNewWindow} from "../../../../../utils/function";
import TasksContainer from "./Tasks/TasksContainer";
import ContractsContainer from "./Contracts/ContractsContainer";
import AccidentsContainer from "./Accidents/AccidentsContainer";
import ActivityLogsContainer from "./ActivityLogs/ActivityLogsContainer";
import InfoClient from "./InfoClient/InfoClient";
import {openInWindow} from "../../../../../constants/constants";
import ReclamationsContainer from "../../Reclamations/ReclamationsContainer";
import _ from "lodash";
import FeedbackContainer from "./Feedback/FeedbackContainer";
import ParrainagesContainer from "./Parrainages/ParrainagesContainer";
import CallToAction from "../../../../Commun/Button/CallToAction/CallToAction";
import BeneficiairesEffectif from "./BeneficiairesEffectif/BeneficiairesEffectif";

const Client = (props) => {
    const {tiers, roles} = props

    return (
        <Fragment>
            <div className={'row'}>
                <div className={'col'}>
                    <h1 className={'title-primary'}>{tiers.belair_id} - {tiers.titre} {tiers.name}</h1>
                    <Badge status={`has_account_${tiers.has_account}`} classBadge={'align-text-top'}/>
                    {!_.includes(roles, 'gestionnaire televente') &&
                        <span className={'btn btn-cta btn-action float-right'} onClick={(e) => redirectInNewWindow({
                            e,
                            url: `/les-activites/tiers/${tiers.belair_id}`,
                            name: openInWindow.activitiesTiers
                        })}>Les activités du tiers</span>}
                </div>
                <div className={'col-12 mt-3'}>
                    <ButtonsContainer tiers={tiers}/>
                </div>
            </div>
            <div className="row header-row">
                {((tiers.pays_naissance === null && tiers.titre !== 'SOC' ) || (tiers.titre === 'SOC' && tiers.statut_juridique === null)) && <div className={'col'}>
                    <div className={'alert alert-warning'}>
                        <p>
                            Attention, les données de naissance du Tiers ne sont pas complétées à 100%. Merci de faire
                            le nécessaire.
                            <CallToAction
                                url={{
                                    pathname: `/tiers/${tiers.belair_id}/modifier-informations-naissance`,
                                    state: {tiers: tiers.belair_id}
                                }}
                                action={'edit-note'}
                                toolText={'Modifier les informations règlementaires'}
                                style={{float: 'none', marginLeft: 30}}
                            />
                        </p>
                    </div>
                </div>}
            </div>
            <div className={'row header-row'}>
                <div className={'col'}>
                    <TasksContainer className={'loader'} tiers={tiers.belair_id}/>
                </div>
            </div>
            <div className={'row margin-top'}>
                <div className={'col-6'}>
                    <InfoClient tiers={tiers}/>
                </div>
                <div className={'col-6'}>
                    <FeedbackContainer tiers={tiers}/>
                </div>
            </div>
            <div className="row margin-top">
                <div className="col">
                    <ReclamationsContainer className={'loader'} id={tiers.belair_id}/>
                </div>
            </div>
            <div className={'row margin-top'}>
                <div className={'col'}>
                    <ContractsContainer className={'loader'} tiers={tiers.belair_id}/>
                </div>
            </div>
            <div className={'row margin-top'}>
                <div className={'col'}>
                    <AccidentsContainer className={'loader'}/>
                </div>
            </div>
            {
                tiers.titre === 'SOC' &&
                <div className={'row margin-top'}>
                    <div className={'col'}>
                        <BeneficiairesEffectif className={'loader'} tiers={tiers.belair_id} beneficiaires_effectifs={tiers.beneficiaires_effectifs} roles={roles}/>
                    </div>
                </div>
            }
            <div className={'row margin-top'}>
                <div className={'col'}>
                    <ParrainagesContainer className={'loader'} tiers={tiers}/>
                </div>
            </div>
            {tiers.has_account && <div className={'row margin-top'}>
                <div className={'col'}>
                    <ActivityLogsContainer className={'loader'}/>
                </div>
            </div>}
        </Fragment>
    )
}

export default Client
