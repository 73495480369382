import React, {Component} from 'react';
import FieldArrayAdversaires from "./FieldArrayAdversaires";
import {FieldArray} from 'redux-form'
import {getCompagnys} from "../../../../../../../../utils/function";

class Adversaires extends Component {
    constructor(props) {
        super(props)
        this.state = {
            compagnys: []
        }
    }

    componentDidMount() {
        this.fetchCompagnys()
    }

    async fetchCompagnys() {
        let compagnys = await getCompagnys()

        const listcompanys = compagnys.map(c => ({
            value: c.id,
            label: c.id + ' - ' + c.name + ' - ' + c.adresse + ' - ' + c.codePostal + ' ' + c.ville
        }))

        this.setState({compagnys: listcompanys})
    }

    render() {

        return (
            <div className={`form-content`}>
                <h1 className={'title-primary'}>Adversaire(s)</h1>

                <FieldArray
                    name='adversaires'
                    component={FieldArrayAdversaires}
                    compagnys={this.state.compagnys}
                    adversaires={this.props.adversaires}
                    changeValue={this.props.changeValue}
                />
            </div>
        );
    }
};

export default Adversaires;
