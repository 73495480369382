import CallToAction from "../../../../Commun/Button/CallToAction/CallToAction";
import React, {Component, Fragment} from "react";
import ButtonsGarage from "./Garage/ButtonsGarage";
import ButtonsCourtier from "./Courtier/ButtonsCourtier";
import {redirectInNewWindow} from "../../../../../utils/function";
import _ from "lodash";
import {listMailJet, openInWindow} from "../../../../../constants/constants";

class Buttons extends Component {
    constructor(props) {
        super(props)
        this.state = {
            disabledBtnAccount: false
        }
    }

    handleClick = () => {
        this.props.postAccount({
            email: this.props.tiers.address_mail,
            owner_belair_id: this.props.owner_belair_id,
            nom: this.props.tiers.lastname,
            date_naissance: this.props.tiers.date_naissance,
            belair_id: this.props.tiers.belair_id
        })
        this.props.postLogActionCreateAccount()
        this.setState({
            disabledBtnAccount: true
        })
    }

    handleClickChangePassword = () => {
        this.props.postLogActionChangePassword()
    }
    handleClickEditDocument = () => {
        this.props.postLogActionEditDocument()
    }

    render() {
        const {tiers, publishUrl, postLogActionFormRib, permissions, roles} = this.props

        return (
            <Fragment>
                {!_.includes(roles, 'gestionnaire televente') &&
                    <div className={'buttons'}>
                        {tiers.type !== '7' && (tiers.type === 'M' || _.includes(tiers.type, 'G') || tiers.type === 'MY' || _.includes(tiers.type, 'R')) &&
                            <ButtonsGarage tiers={tiers} postLogRib={postLogActionFormRib}/>
                        }
                        {(tiers.type === '7') && <ButtonsCourtier tiers={tiers}/>}

                        {
                            _.includes(permissions, 'wallet extraction') && !_.includes(tiers.type, '1') &&
                            <>
                                <CallToAction
                                    action={'create-extraction'}
                                    toolText={'Extraction portefeuille'}
                                    onClick={(e) => redirectInNewWindow({
                                        e,
                                        url: `/tiers/${tiers.belair_id}/extraction-portefeuille`,
                                        name: openInWindow.extraction
                                    })}
                                />
                            </>
                        }

                        {
                            _.includes(permissions, 'wallet transfert') && !_.includes(tiers.type, '1') &&
                            <>
                                <CallToAction
                                    action={'create-transfert'}
                                    toolText={'Transfert de portefeuille'}
                                    onClick={(e) => redirectInNewWindow({
                                        e,
                                        url: `/tiers/${tiers.belair_id}/transfert-portefeuille`,
                                        name: openInWindow.transfert
                                    })}
                                />
                            </>
                        }


                        {_.includes(permissions, 'order flyers') && (_.includes(tiers.type, 'M') || tiers.type === '7' || tiers.type === 'J') &&
                            <CallToAction
                                url={{pathname: ``, state: {tiers: tiers}}}
                                action={'order-flyers'}
                                toolText={'Commande de produits'}
                                onClick={(e) => redirectInNewWindow({
                                    e,
                                    url: `/tiers/${tiers.belair_id}/commande`,
                                    name: openInWindow.commande
                                })}
                            />
                        }
                        {(tiers.type === '1' || tiers.type === '1P') && <Fragment>
                            {!tiers.has_account && <CallToAction
                                link_externe
                                action={'create-account'}
                                toolText={'Créer l\'espace assuré'}
                                onClick={() => {
                                    this.handleClick()
                                }}
                                disabled={this.state.disabledBtnAccount || tiers.address_mail === ''}
                                disabledReason={tiers.address_mail === '' && 'Le tiers n\'a pas d\'adresse email renseigné'}
                            />}
                            <CallToAction
                                url={{pathname: `/tiers/${tiers.belair_id}/mot-de-passe`, state: {tiers: tiers}}}
                                action={'mdp-client'}
                                toolText={'Modifier son mot de passe'}
                                onClick={() => {
                                    this.handleClickChangePassword()
                                }}
                                disabled={!tiers.has_account}
                                disabledReason={!tiers.has_account && 'Le tiers n\'a pas d\'espace assuré'}
                            />
                            <CallToAction
                                link_externe
                                action={'edit-document'}
                                toolText={'Editer un document'}
                                url={`${publishUrl}&tiers=${tiers.belair_id}`}
                                publish
                                onClick={() => {
                                    this.handleClickEditDocument()
                                }}
                            />
                            <CallToAction
                                url={{
                                    pathname: `/tiers/${tiers.belair_id}/modifier-informations`,
                                    state: {tiers: tiers}
                                }}
                                action={'coordonnees'}
                                toolText={'Modifier les coordonnées'}
                            />
                            <CallToAction
                                url={{
                                    pathname: `/tiers/${tiers.belair_id}/modifier-informations-naissance`,
                                    state: {tiers: tiers.belair_id}
                                }}
                                action={'calendar'}
                                toolText={'Modifier les informations règlementaires'}
                            />
                            <CallToAction
                                action={'add-chrono'}
                                toolText={'Ajouter un chrono / agenda'}
                                onClick={(e) => redirectInNewWindow({
                                    e,
                                    url: `/tiers/${tiers.belair_id}/ajout-suivi`,
                                    name: openInWindow.addActivities
                                })}
                            />
                        </Fragment>}
                        <CallToAction
                            action={'email'}
                            toolText={'Envoyer un email'}
                            onClick={(e) => redirectInNewWindow({
                                e,
                                url: `/envoyer-email/libre/${listMailJet.libre}/${tiers.belair_id}`,
                                name: `${openInWindow.emailTiers}${tiers.belair_id}`
                            })}
                        />
                    </div>
                }
            </Fragment>
        )
    }
}

export default Buttons
