import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import Circonstances from "./Circonstances";
import {change, reduxForm} from "redux-form";
import _ from "lodash";
import {
    postAttentePvAccident,
    postCessionCieAccident
} from "../../../../../redux/actions/app/accidents/accidents.actions";
import {
    AccidentVehicleTranferGetLink,
    updateAccidentAttentePVGetLink
} from "../../../../../redux/selectors/links/links.selectors";

const mapStateToProps = (state, ownProps) => {
    return {
        uri: updateAccidentAttentePVGetLink(state),
        uriCession: AccidentVehicleTranferGetLink(state),
        accidentId: ownProps.accident.id,
        contractId: ownProps.accident.id_contrat,
        initialValues: {
            "attentePV": ownProps.accident.attentePV === true
        }
    }
}

const mapDispatchToProps = (dispatch, ownProps) => bindActionCreators({
    postAttentePvAccident,
    postCessionCieAccident,
    changeValue: (field, value) => change(ownProps.form, field, value),
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {uri, uriCession, accidentId, contractId} = stateProps
    const {postAttentePvAccident, postCessionCieAccident} = dispatchProps


    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        onSubmit: form => postAttentePvAccident({
            query: _.replace(_.replace(uri, '{id}', contractId), '{ids}', accidentId),
            form
        }),
        onSubmitCession: form => postCessionCieAccident({
            query: _.replace(_.replace(uriCession, '{id}', contractId), '{ids}', accidentId),
            form
        })
    }
}


const CirconstancesContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    reduxForm({
        form: 'accident-attente-pv'
    })
)(Circonstances)


export default CirconstancesContainer
