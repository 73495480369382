import {connect} from 'react-redux'
import {compose} from 'redux'

import Dashboard from './Dashboard'
import ErrorHttpComponent from '../../../../Commun/Error/ErrorHttpComponent'
import {getErrorEntity, getLoadingEntity} from '../../../../../redux/selectors/ui/ui.selectors'
import {getDashboardData} from "../../../../../redux/selectors/dashboard/dashboard.selectors";
import {DASHBOARD} from "../../../../../redux/actions/app/dashboard/dashboard.actions";
import loader from "../../../../Commun/Spinner/Loader/Loader";

const mapStateToProps = state => {
    return {
        data: getDashboardData(state),
        loaded: getLoadingEntity(state, DASHBOARD) === false,
        error: getErrorEntity(state, DASHBOARD)
    }
}

const DashboardContainer = compose(
    connect(mapStateToProps),
    loader({ErrorIndicator: ErrorHttpComponent})
)(Dashboard)

export default DashboardContainer
