import {connect} from 'react-redux'
import {compose} from 'redux'
import _ from 'lodash'
import FormAvenantSimple from "./FormAvenantSimple";
import {reduxForm, change, reset, formValueSelector} from "redux-form";
import {getContractCodeProduit, getContractData} from "../../../../../../redux/selectors/contract/contract.selectors";
import {
    civilityUpdateContractLink,
    effectiveDateUpdateContractLink, informationUpdateContractLink,
} from "../../../../../../redux/selectors/links/links.selectors";
import {
    POST_AVENANT_SIMPLE,
    postAvenantSimple
} from "../../../../../../redux/actions/app/avenant_simple/avenant_simple.actions";
import {getLoadingEntity} from "../../../../../../redux/selectors/ui/ui.selectors";
import {getUserRoles} from "../../../../../../redux/selectors/user/user.selectors";
import moment from "moment";

const getUri = (state, typeUpdate, contract_id) => {
    switch (typeUpdate) {
        case 'informations':
            return _.replace(informationUpdateContractLink(state), '{id}', contract_id)
        case 'civilite':
            return _.replace(civilityUpdateContractLink(state), '{id}', contract_id)
        case 'dateEffet':
        default:
            return _.replace(effectiveDateUpdateContractLink(state), '{id}', contract_id)
    }
}

const mapStateToProps = state => {
    const selector = formValueSelector('avenant-simple')
    const contract = getContractData(state);
    const typeUpdate = selector(state, 'typeUpdate');

    return {
        query: getUri(state, typeUpdate, contract.id),
        contract: contract,
        typeUpdate: typeUpdate,
        isManagerQualite: _.includes(getUserRoles(state), 'manager qualite'),
        isManagerEC: _.includes(getUserRoles(state), 'managers'),
        isNVEI: getContractCodeProduit(state) === '7311_NVEI',
        loading: getLoadingEntity(state, POST_AVENANT_SIMPLE),
        defaultValueCodepostalCity: `${contract.souscripteur.adresse.ville} ${contract.souscripteur.adresse.codePostal}`,
        initialValues: {
            'typeUpdate': '',
            'souscripteur_nom': contract.souscripteur.lastname,
            'souscripteur_prenom': contract.souscripteur.firstname,
            'conducteur_nom': contract.conducteur.nom,
            'conducteur_prenom': contract.conducteur.prenom,
            'adresse1': contract.souscripteur.adresse.rue1,
            'adresse2': contract.souscripteur.adresse.rue2,
            'adresse3': contract.souscripteur.adresse.rue3,
            'code_postal': contract.souscripteur.adresse.codePostal,
            'ville': contract.souscripteur.adresse.ville,
            'immatriculation': contract.vehicule.immatriculation,
            'apporteur1': contract.apporteurs.apporteur1.id,
            'marque_vehicule': contract.vehicule.marque,
            'modele_vehicule': contract.vehicule.modele,
            'version_vehicule': contract.vehicule.version,
            'vin_vehicule': contract.vehicule.VIN,
            'loiHamon': false,
            'dateEffet': moment(),
            'heureEffet': moment().format('HH:mm'),
            'souscripteur_civilite': contract.souscripteur.titre,
            'conducteur_civilite': contract.conducteur.titre,
        }
    }
}

const mapDispatchToProps = {
    postAvenantSimple,
    changeValue: (field, value) => change('avenant-simple', field, value),
    resetForm: () => reset('avenant-simple')
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {query} = stateProps
    const {postAvenantSimple} = dispatchProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        onSubmit: form => postAvenantSimple({form, query})
    }
}

const FormAvenantSimpleContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    reduxForm({
        form: 'avenant-simple'
    })
)(FormAvenantSimple);

export default FormAvenantSimpleContainer
