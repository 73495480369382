import React, {useEffect} from 'react';
import LargeSpinner from "../../../../Commun/Spinner/Spinner";
import Souscripteur from "../../Devis/DevisDetail/Souscripteur/Souscripteur";
import Vehicule from "./Vehicule/Vehicule";
import Antecedents from "./Antecedents/Antecedents";
import Info from "./Info/Info";
import {statutDevis} from "../../../../../utils/valueMatching";
import _ from "lodash";
import Spinner from "../../../../Commun/Spinner/Spinner";
import AutresDevisContainer from "./AutresDevis/AutresDevisContainer";

const DevisDetail = (props) => {
    const {quote, reprise, is_gestionnaire_commercial, roles, cancelQuote, loadingCancel} = props

    if (reprise) {
        window.location.href = quote.urlReprise
        return <LargeSpinner/>
    }

    return (
        <div className={'cd-main-content'}>
            <div className={'contract-container container'}>
                <div className={'row return-content'}>
                    <div className={'col-2'}>
                        <button onClick={() =>  props.history.go(-1)} className={'btn btn-return'}>Retour</button>
                    </div>
                </div>
                <div className={'row'}>
                    <div className={'col-12'}>
                        <h1 className={'title-primary'}>
                            Devis n°{quote.id} - {quote.conducteur.nom} {quote.conducteur.prenom}
                            <span className={'link-icon-primary ml-2'}/>
                        </h1>
                        <span className={`badge badge-${statutDevis(quote.statut)?.badge}`}>{statutDevis(quote.statut)?.label}</span>
                    </div>
                </div>
                <div className="row">
                    <div className="col">
                        {
                            (
                                (
                                    quote.statut === 'in_progress' ||
                                    (is_gestionnaire_commercial && quote.statut === 'cancelled')
                                ) &&
                                (
                                    !_.includes(roles, 'gestionnaire an') &&
                                    !_.includes(roles, 'gestionnaire vdc')
                                ) ||
                                (
                                    quote.acteGestion === 'AV' &&
                                    (
                                        _.includes(roles, 'gestionnaire an') ||
                                        _.includes(roles, 'gestionnaire vdc')
                                    )
                                )
                            ) &&
                            <a href={quote.urlSouscription} target={'_blank'} rel="noopener noreferrer" className={'btn btn-cta btn-success h-auto mt-3 ml-0 mr-2 py-2'}>
                                Modifier le devis
                            </a>
                        }
                        <a href={quote.urlPdf} download className={'btn btn-cta btn-success h-auto mt-3 my-0 py-2'}>
                            Télécharger le devis
                        </a>
                        {
                            (is_gestionnaire_commercial && quote.statut !== 'cancelled') &&
                            <button
                                type={'button'}
                                className={'btn position-relative btn-cta btn-success h-auto mt-3 ml-2 py-2'}
                                disabled={loadingCancel}
                                onClick={() => {
                                    if (window.confirm('Voulez-vous vraiment désactiver le devis ?')) cancelQuote()
                                }}>
                                {loadingCancel && <Spinner absolute/>}
                                Désactiver le devis
                            </button>
                        }

                    </div>
                </div>
                <div className={'row margin-top'}>
                    <div className={'col'}>
                        <Info quote={quote}/>
                    </div>
                    <div className={'col'}>
                        <Souscripteur souscripteur={quote.souscripteur} conducteur={quote.conducteur}/>
                    </div>
                </div>
                <div className={'row margin-top'}>
                    <div className={'col'}>
                        <Vehicule vehicule={quote.vehicule}/>
                    </div>
                    <div className={'col'}>
                        <Antecedents conducteur={quote.conducteur}/>
                    </div>
                </div>
                <div className={'row margin-top'}>
                    <div className={'col'}>
                        <AutresDevisContainer account_id={quote.account_id} className={'loader'} currentId={quote.id}/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DevisDetail;
