import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'

import DevisListe from './DevisListe'
import {getQuotesLink} from "../../../../../redux/selectors/links/links.selectors";
import {fetchQuotes} from "../../../../../redux/actions/app/quotes/quotes.actions";
import loader from "../../../../Commun/Spinner/Loader/Loader";
import {getUserRoles} from "../../../../../redux/selectors/user/user.selectors";

const mapStateToProps = state => {
    return {
        query: getQuotesLink(state),
        roles: getUserRoles(state)
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    fetchQuotes: ({query, filters}) => fetchQuotes({query, filters})
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {query} = stateProps
    const {fetchQuotes} = dispatchProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        fetchQuotes: ({filters}) => fetchQuotes({query, filters})
    }
}

const DevisListeContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader()
)(DevisListe)

export default DevisListeContainer
