import {connect} from 'react-redux'
import {compose} from "redux";
import {addAccidentEDAGetLink} from "../../../../../redux/selectors/links/links.selectors";
import {POST_ADD_EDA, postAddEDA} from "../../../../../redux/actions/app/accidents/accidents.actions";
import _ from "lodash";
import Intervenants from "./Intervenants";
import {getLoadingEntity} from "../../../../../redux/selectors/ui/ui.selectors";

const mapStateToProps = (state, ownProps) => {
    return {
        loading_EDA: getLoadingEntity(state, POST_ADD_EDA),
        uri_add: addAccidentEDAGetLink(state),
        accidentId: ownProps.accident,
        contractId: ownProps.contract,
    }
}

const mapDispatchToProps = {
    postAddEDA
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { uri_add, contractId, accidentId } = stateProps
    const { postAddEDA } = dispatchProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        postAddEDA: () => postAddEDA({query: _.replace(_.replace(uri_add, '{id}', contractId), '{ids}', accidentId)}),
    }
}

const IntervenantsContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps)
)(Intervenants)

export default IntervenantsContainer