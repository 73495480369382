import {API_ERROR, API_SUCCESS, apiRequest} from '../../../actions/core/api/api.actions'
import {setError, setLoader} from '../../../actions/app/ui/ui.actions'
import {setNotification} from "../../../actions/core/notifications/notifications.actions";
import {POST_EXTRACTION, POST_EXTRACTION_TLV, POST_TRANSFERT, TRANSFERT} from "../../../actions/app/wallet/wallet.actions";
import _ from "lodash";

import Swal from "sweetalert2";
import {tokenUser} from "../../../selectors/localStorage/localStorage";
import {truncateMiddle} from "../../../../components/Commun/APILink/APILink";
import {toast} from "../../core/notifications/notifications.middleware";
import moment from "moment/moment";

export const walletMiddleware = () => next => action => {
    next(action)
    let clone = null;

    switch (action.type) {
        case POST_EXTRACTION:
            clone = _.cloneDeep(action.payload.body)

            if (action.payload.body.type && action.payload.body.type === "PorteFeuilleApp1All") {
                clone.dateStart = clone.dateStart.format('YYYY-MM-DD')
                clone.dateEnd = clone.dateEnd.format('YYYY-MM-DD')
            } else {
                delete clone.dateStart
                delete clone.dateEnd
            }

            next([
                apiRequest({
                    body: (clone),
                    method: 'POST',
                    url: action.payload.data,
                    entity: POST_EXTRACTION
                }),
                setLoader({state: true, entity: POST_EXTRACTION})
            ])
            break

        case `${POST_EXTRACTION} ${API_SUCCESS}`:
            next([
                setError({state: false, entity: POST_EXTRACTION}),
                setLoader({state: false, entity: POST_EXTRACTION})
            ])

            // window.open(action.payload.data.data, '_blank', 'noopener,noreferrer')

            const url = action.payload.data.data
            const filename = url.split('?')[1].split('=')[1]

            const loadingToast = Swal.fire({
                toast: true,
                position: 'top',
                showConfirmButton: false,
                didOpen: () => {
                    Swal.showLoading();
                },
                titleText: `Téléchargement du fichier ${truncateMiddle(filename, 20)}...`
            });

            fetch(url, {
                method: 'GET',
                headers: {
                    'Authorization': `Bearer ${tokenUser.trim()}`
                }
            })
                .then(response => response.blob())
                .then(blob => {
                    const url = window.URL.createObjectURL(new Blob([blob]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', filename);
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                })
                .catch(error => {
                    toast({
                        type: 'error',
                        title: 'Téléchargement échoué'
                    })
                })
                .finally(() => {
                    loadingToast.close()
                })

            break

        case `${POST_EXTRACTION} ${API_ERROR}`:
            next([
                setNotification({
                    entity: POST_EXTRACTION,
                    body: 'Une erreur est survenue',
                    type: 'error',
                    timeout: '0'
                }),
                setError({state: true, entity: POST_EXTRACTION}),
                setLoader({state: false, entity: POST_EXTRACTION})
            ])
            break

        case POST_EXTRACTION_TLV:
            clone = _.cloneDeep(action.payload.body)

            clone.dateStart = clone.dateStart.format('YYYY-MM-DD')
            clone.dateEnd = clone.dateEnd.format('YYYY-MM-DD')

            next([
                apiRequest({
                    body: (clone),
                    method: 'POST',
                    url: action.payload.data,
                    entity: POST_EXTRACTION_TLV
                }),
                setLoader({state: true, entity: POST_EXTRACTION_TLV})
            ])
            break

        case `${POST_EXTRACTION_TLV} ${API_SUCCESS}`:
            next([
                setError({state: false, entity: POST_EXTRACTION_TLV}),
                setLoader({state: false, entity: POST_EXTRACTION_TLV})
            ])

            window.open(action.payload.data.data, '_blank', 'noopener,noreferrer')
            break

        case `${POST_EXTRACTION_TLV} ${API_ERROR}`:
            let message = 'Une erreur est survenue';
            if (action?.payload?.data?.response?.data?.reason) {
                message = action.payload.data.response.data.reason;
            }
            next([
                setNotification({entity: POST_EXTRACTION_TLV, body: `<p>${message}</p>`, type: 'error', timeout: '0'}),
                setError({state: true, entity: POST_EXTRACTION_TLV}),
                setLoader({state: false, entity: POST_EXTRACTION_TLV})
            ])
            break

        case POST_TRANSFERT:
            clone = _.cloneDeep(action.payload.body)

            delete clone.contracts
            if(clone.type_transfert === "complet") {
                delete clone.selected_contracts
                clone.date_transfert =  clone.date_transfert.format('YYYY-MM-DD')
            } else if(clone.type_transfert === "partiel" && clone.date_transfert !== undefined){
                delete clone.date_transfert
            }

            next([
                apiRequest({
                    body: clone,
                    method: 'POST',
                    url: action.payload.data,
                    entity: TRANSFERT
                }),
                setLoader({state: true, entity: TRANSFERT})
            ])
            break

        case `${TRANSFERT} ${API_SUCCESS}`:
            next([
                setNotification({
                    entity: TRANSFERT,
                    body: `<p>Le transfert de portefeuille a été effectué</p>`,
                    type: 'success',
                    title: 'Transfert effectué !'
                }),
                setLoader({state: false, entity: TRANSFERT}),
                setError({state: false, entity: TRANSFERT}),
            ])
            break

        case `${TRANSFERT} ${API_ERROR}`:
            next([
                setNotification({
                    entity: TRANSFERT,
                    body: `<p>Votre demande n'a pas pu être envoyée. ${action.payload.data.response.data.reason}</p>`,
                    type: 'error',
                    title: 'Erreur lors du transfert !',
                    timeout: '0'
                }),
                setError({state: true, entity: TRANSFERT}),
                setLoader({state: false, entity: TRANSFERT}),
            ])
            break

        default:
            break
    }
    return null
}
