import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'

import Email from './Email'
import ErrorHttpComponent from "../../../../Commun/Error/ErrorHttpComponent";
import {change, reduxForm, formValueSelector} from "redux-form";
import {
    accidentGetLink,
    contractDefaultGetLink,
    emailLibrePostLinks,
    emailPMQPostLinks, tiersGetLink
} from "../../../../../redux/selectors/links/links.selectors";
import _ from "lodash";
import {CONTRACT, getContract} from "../../../../../redux/actions/app/contracts/contracts.actions";
import {getEmailsEnvoyes, getErrorEntity, getLoadingEntity} from "../../../../../redux/selectors/ui/ui.selectors";
import {getContractData} from "../../../../../redux/selectors/contract/contract.selectors";
import {clearEmailsEnvoyes, EMAIL, postEmail} from "../../../../../redux/actions/app/email/email.actions";
import validate from './validate'
import mailPMQAccident from "../../../../../assets/img/images/mail_pmq_accident.jpg"
import mailPMQ from "../../../../../assets/img/images/mail_pmq.jpg"
import mailLibre from "../../../../../assets/img/images/mail_libre.jpg"
import {ACCIDENT, getAccident} from "../../../../../redux/actions/app/accidents/accidents.actions";
import {getAccidentData} from "../../../../../redux/selectors/accident/accident.selectors";
import {getTiersData} from "../../../../../redux/selectors/tiers/tiers.selectors";
import {getTiers, TIERS} from "../../../../../redux/actions/app/tiers/tiers.actions";
import loader from "../../../../Commun/Spinner/Loader/Loader";

const getLoaded = (ownProps, state) => {
    if (ownProps.idSinistre) {
        return getLoadingEntity(state, CONTRACT) === false && getLoadingEntity(state, ACCIDENT) === false
    } else if (ownProps.idContract) {
        return getLoadingEntity(state, CONTRACT) === false
    } else {
        return getLoadingEntity(state, TIERS) === false
    }
}

const mapStateToProps = (state, ownProps) => {
    const {type, idEmail, idContract, idSinistre, idTiers} = ownProps.match.params
    const selector = formValueSelector('send-email')
    let uriPostEmail

    switch (type) {
        case 'pmq-accident':
        case 'pmq':
            uriPostEmail = emailPMQPostLinks(state)
            break
        case 'libre':
            uriPostEmail = emailLibrePostLinks(state)
            break
        default:
            uriPostEmail = emailLibrePostLinks(state)
    }

    return {
        idEmail,
        idTiers,
        idContract,
        type,
        idSinistre,
        uriPostEmail,
        uriContract: contractDefaultGetLink(state),
        uriAccident: accidentGetLink(state),
        uriTiers: tiersGetLink(state),
        tiers: getTiersData(state),
        contract: getContractData(state),
        accident: getAccidentData(state),
        loaded: getLoaded(ownProps.match.params, state),
        error: getErrorEntity(state, CONTRACT),
        loading: getLoadingEntity(state, EMAIL),
        is_send: getLoadingEntity(state, EMAIL) === false && getErrorEntity(state, EMAIL) === false,
        destinataire_id: selector(state, 'destinataire_id'),
        recipients: selector(state, 'recipients'),
        copieCourtier: selector(state, 'copieCourtier'),
        copieClient: selector(state, 'copieClient'),
        copieLibre: selector(state, 'copieLibre'),
        copieLibreEmail: selector(state, 'copieLibreEmail'),
        files: selector(state, 'files'),
        template: selector(state, 'template'),
        champsPersonnalises: selector(state, 'champsPersonnalises'),
        profileDestinataire: selector(state, 'profile'),
        text: selector(state, 'text'),
        emailsEnvoyes: getEmailsEnvoyes(state),
        mails: {
            'pmq-accident': mailPMQAccident,
            '1309563': mailPMQ,
            '1311585': mailLibre
        },
        initialValues: {
            'contract_id': idContract || "",
            'accident_id': idSinistre || "",
            'pmqAccident': type === 'pmq-accident',
            'complain_id': (ownProps.location.state && ownProps.location.state.reclamation) || "",
            'clotureReclamation': (ownProps.location.state && ownProps.location.state.clotureReclamation) || false,
            'recipients': [],
            'text': 'Bonjour,',
            'template': '',
            'template_title': '',
            'champsPersonnalises': []
        }
    }
}

const mapDispatchToProps = (dispatch, ownProps) => bindActionCreators({
    getContract: ({query, id}) => getContract({query, id}),
    getAccident: ({query, id}) => getAccident({query, id}),
    getTiers: ({query, id}) => getTiers({query, id}),
    postEmail: ({form, query, tiers_id}) => postEmail({
        form,
        query,
        tiers_id,
        otherData: {id: tiers_id, ids: ownProps.location.state ? ownProps.location.state.reclamation : null}
    }),
    changeValue: (field, value) => change('send-email', field, value),
    clearEmailsEnvoyes,
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {
        idEmail,
        uriContract,
        uriPostEmail,
        idContract,
        type,
        uriAccident,
        idSinistre,
        idTiers,
        uriTiers
    } = stateProps
    const {getContract, getAccident, postEmail, getTiers} = dispatchProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => {
            switch (type) {
                case 'pmq':
                case 'pmq-accident':
                case 'libre':
                    if (idSinistre) {
                        return (
                            getContract({query: _.replace(uriContract, '{id}', idContract), id: idContract}),
                                getAccident({query: _.replace(_.replace(uriAccident, '{id}', idContract), '{ids}', idSinistre)}),
                                getTiers({query: _.replace(uriTiers, '{id}', idTiers), id: idTiers})
                        )
                    } else if (idContract) {
                        return (
                            getContract({query: _.replace(uriContract, '{id}', idContract), id: idContract}),
                                getTiers({query: _.replace(uriTiers, '{id}', idTiers), id: idTiers})
                        )
                    } else {
                        return getTiers({query: _.replace(uriTiers, '{id}', idTiers), id: idTiers})
                    }
                default:
                    return getTiers({query: _.replace(uriTiers, '{id}', idTiers), id: idTiers})
            }
        },
        onSubmit: (form) => postEmail({form, query: _.replace(uriPostEmail, '{id}', idEmail), tiers_id: idTiers})
    }
}

const EmailContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader({ErrorIndicator: ErrorHttpComponent}),
    reduxForm({
        form: 'send-email',
        validate
    })
)(Email)

export default EmailContainer
