import React from 'react'
import moment from "moment";
import Badge from "../Badge/Badge"
import {Link} from 'react-router-dom'
import { withRouter } from "react-router"
import {getPrimeByFractionnement} from "../../../redux/selectors/quotes/quotes.selectors";
import {etatDevis, statutDevis} from "../../../utils/valueMatching";
import _ from "lodash";

const QuoteCard = (props) => {
    const { quote, roles } = props

    const CardContent = () => (
        <div className={"row"}>
            <div className={`task-info col-5`}>
                <Badge status={quote.feuTricolore} noText/>
                <h2>Devis n°{quote.id}{quote.realisateur_name ? ` par ${quote.realisateur_name}` :''}</h2>
                <p>Crée le <strong>{moment(quote.created_at).format('L')}</strong>
                    {quote.apporteur2 && <><span> via </span><strong>{quote.apporteur2.name}</strong></>}
                    {(quote.apporteur1 && !quote.apporteur2) && <><span> via </span><strong>{quote.apporteur1.name}</strong></>}
                </p>
                <p>Statut : <strong className={`text-${statutDevis(quote.statut)?.color}`}>{statutDevis(quote.statut)?.label}</strong> / Etat : {etatDevis(quote.etat)} / Acte de gestion : <strong>{quote.acteGestion === 'AV' ? 'Avenant' : 'Affaire nouvelle'}</strong></p>
            </div>
            <div className={`task-tiers col-3`}>
                <h3 className={'border-bottom'}>{quote.conducteur.prenom} {quote.conducteur.nom}</h3>
                <p className={'text-uppercase'}>{quote.vehicule.marque} {quote.vehicule.modele} <small>{quote.vehicule.immatriculation ? ' - Immatriculé '+ quote.vehicule.immatriculation : ''}</small></p>
            </div>
            <div className={`task-contrat col-4`}>
                <div>
                    <div className={'display-inline'}>
                        <p className={'text-uppercase'}>Formule {quote.formuleChoisieLibelle}</p>
                        <h3 className={'border-bottom'}>
                            {getPrimeByFractionnement({prime: quote.primeFC, code: quote.fractionnement, per: true})}
                        </h3>
                        <p>Options : {quote.listeOptions || '-'}</p>
                    </div>
                </div>
            </div>
        </div>
    );

    return (
        <>
            {(quote.acteGestion === 'AV' && _.includes(roles, 'gestionnaire televente')) ? (
                <div className={'link-devis'}>
                    <div className={`card-task card-task-reassign text-color-secondary`}>
                        <CardContent />
                    </div>
                </div>
            ) : (
                <Link to={{ pathname: `/devis/${quote.id}` }} className={'link-devis'}>
                    <div className={`card-task text-color-secondary`}>
                        <CardContent />
                    </div>
                </Link>
            )}
        </>
    );
}

QuoteCard.defaultProps = {
    showColTiers: true,
    showColContrat: true,
}

export default withRouter(QuoteCard)
