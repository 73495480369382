import {createSelector} from 'reselect'

const dashboardSelectors = state => state.dashboard

export const getDashboardData = createSelector(
    dashboardSelectors,
    dashboard => dashboard.data
)

export const getTotal = createSelector(
    getDashboardData,
    dashboard => dashboard.total
)

export const getPoles = createSelector(
    getDashboardData,
    dashboard => dashboard.poles
)

export const getGestionnaireDetail = createSelector(
    getDashboardData,
    dashboard => dashboard ? dashboard.gestionnaire_detail : {}
)
