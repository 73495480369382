import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import {reduxForm} from 'redux-form'
import {PAYMENT, postPayment} from '../../../../../redux/actions/app/payment/payment.actions'
import Payment from './Payment'
import {payDepositLink} from '../../../../../redux/selectors/links/links.selectors'
import {getLoadingEntity} from '../../../../../redux/selectors/ui/ui.selectors'
import validate from './validate'
import moment from 'moment'

const mapStateToProps = (state, ownProps) => {
    return {
        loading: getLoadingEntity(state, PAYMENT),
        uri: payDepositLink(state),
        contract: ownProps.location.state.contract,
        dateLimite: moment().isAfter(moment().format('YYYY-MM') + '-24', 'day')
    }
}

const mapDispatchToProps = (dispatch) => bindActionCreators({
    submitPostPayment: ({id_tiers, amount, url_return, by_mail, query}) => postPayment({
        id_tiers,
        amount,
        url_return,
        by_mail,
        query
    })
}, dispatch)


const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {submitPostPayment} = dispatchProps
    const {uri, contract} = stateProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        onSubmit: (form) => submitPostPayment({
            id_tiers: contract.souscripteur.id,
            amount: form.amount.replace(/,/g, '.'),
            url_return: !form.by_mail ? window.location.origin + '/contrat/' + contract.id : null,
            by_mail: form.by_mail || false,
            query: uri
        })
    }
}
const PaymentContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    reduxForm({
        form: 'paymentForm',
        validate
    })
)(Payment)

export default PaymentContainer
