import React, {Fragment, Component} from 'react';
import {Field} from 'redux-form'
import {Link} from "react-router-dom";
import Badge from "../../../../../Commun/Badge/Badge";
import DeclarationContainer from "./Steps/Declaration/DeclarationContainer";
import ReparationContainer from "./Steps/Reparation/ReparationContainer";
import AdversairesContainer from "./Steps/Adversaires/AdversairesContainer";
import Blesses from "./Steps/Blesses/Blesses";
import TemoinsContainer from "./Steps/Temoins/TemoinsContainer";
import showStep from "./Steps/showStep";
import Spinner from "../../../../../Commun/Spinner/Spinner";
import {validateForm} from "./validateForm";
import DeclarationBisContainer from "./Steps/DeclarationBis/DeclarationBisContainer";
import DeclarationTer from "./Steps/DeclarationTer/DeclarationTer";
import DocumentsContainer from "./Steps/Documents/DocumentsContainer";

class InitForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            firstCheck: false,
            secondCheck: false,
            emailIsSet: false
        }
    }

    componentDidMount() {
        this.props.logAction()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.submitFailed && document.getElementsByClassName('is-invalid')[0]) {
            document.getElementsByClassName('is-invalid')[0].scrollIntoView({behavior: 'smooth', block: "center"})
        }
    }

    setEmailToClient = cause => {
        if (!this.state.emailIsSet) {
            this.props.postRejectAccident(cause)
            this.setState({
                emailIsSet: !this.state.emailIsSet
            })
        }
    }

    changeValidFirstCheck = valid => {
        this.setState({
            firstCheck: valid
        })
    }

    changeValidSecondCheck = valid => {
        this.setState({
            secondCheck: valid
        })
    }

    render() {
        const {
            listDocuments,
            contract,
            handleSubmit,
            categorie,
            vehicule,
            identify,
            temoin,
            changeValue,
            loading,
            garanties,
            blesse,
            type_sinistre
        } = this.props

        return (
            <div className={'contract-container container'}>
                <div className={'row return-content'}>
                    <div className={'col'}>
                        <Link to={{pathname: `/contrat/${contract.id}`}} className={'btn btn-return'}>Retour</Link>
                    </div>
                </div>
                <div className={'row'}>
                    <div className={'col-12'}>
                        <h1 className={'title-primary'}>Vous déclarez un sinistre sur le
                            tiers&nbsp;:&nbsp;{contract.souscripteur.nom}</h1>
                    </div>
                    <div className={'col-12'}>
                        <Badge status={contract.situation.statut}/>
                        <span
                            className={'bold text-uppercase subtitle'}>Contrat n°{contract.numeroPolice} - {contract.title}</span>
                    </div>
                </div>
                <form onSubmit={handleSubmit} onKeyPress={event => {
                    if (event.which === 13 /* Enter */) {
                        event.preventDefault();
                    }
                }}>
                    <div className="row header-row">
                        <div className="col">
                            <DeclarationContainer changeValue={changeValue} setEmailToClient={this.setEmailToClient}
                                                  changeValidFirstCheck={this.changeValidFirstCheck}
                                                  changeValidSecondCheck={this.changeValidSecondCheck}/>
                        </div>
                    </div>

                    <div id="declaration-ter">
                        {
                            this.state.firstCheck && <div className="row margin-top card-sinistre">
                                <div className="col">
                                    <DeclarationTer/>
                                </div>
                            </div>
                        }
                    </div>

                    {
                        this.state.firstCheck && showStep({
                            step: 'declarationBis',
                            categorie,
                            typeSinistre: type_sinistre
                        }) &&
                        <div className="row margin-top card-sinistre">
                            <div className="col">
                                <DeclarationBisContainer setEmailToClient={this.setEmailToClient}
                                                         changeValidSecondCheck={this.changeValidSecondCheck}/>
                            </div>
                        </div>
                    }
                    <div id="declaration-final">
                        {this.state.secondCheck && <Fragment>

                            {
                                showStep({step: 'reparation', categorie, vehicule}) &&
                                <div className="row margin-top card-sinistre">
                                    <div className="col">
                                        <ReparationContainer changeValue={changeValue}/>
                                    </div>
                                </div>
                            }

                            {
                                showStep({step: 'adversaires', categorie, identify}) &&
                                <div className="row margin-top card-sinistre">
                                    <div className="col">
                                        <AdversairesContainer changeValue={changeValue}/>
                                    </div>
                                </div>
                            }

                            {
                                showStep({step: 'blesses', blesse}) &&
                                <div className="row margin-top card-sinistre">
                                    <div className="col">
                                        <Blesses/>
                                    </div>
                                </div>
                            }

                            {
                                showStep({step: 'temoin', categorie, temoin}) &&
                                <div className="row margin-top card-sinistre">
                                    <div className="col">
                                        <TemoinsContainer changeValue={changeValue}/>
                                    </div>
                                </div>
                            }

                            <div className="row margin-top card-sinistre">
                                <div className="col">
                                    <DocumentsContainer listDocuments={listDocuments}/>
                                </div>
                            </div>

                            <Field
                                component={'input'}
                                type={'text'}
                                hidden
                                name={'risque'}
                            />

                            <button
                                className={'btn btn-primary btn-lg btn-block margin-top position-relative'}
                                disabled={loading || validateForm({categorie, garanties})}
                            >
                                {loading && <Spinner absolute/>}
                                Enregistrer la déclaration
                            </button>
                        </Fragment>}
                    </div>
                </form>
            </div>
        );
    }
};

export default InitForm;
