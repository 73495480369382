import React from 'react';
import moment from "moment";

const Feedback = (props) => {
    const {feedback, tiers} = props

    return (
        <div className={'bloc-info bloc-satisfaction'}>
            <h2>Satisfaction client</h2>
            {
                feedback && feedback?.feedback_date &&
                <div className={' h-auto my-3'}>
                    <div className={'content row'}>
                        <div className={'col-5 left-content'}><p>Feedback à chaud :</p></div>
                        <div className={'col-7 right-content'}><p>{feedback.note}/10</p></div>

                        <div className={'col-5 left-content'}><p>Moment de vie :</p></div>
                        <div className={'col-7 right-content'}><p>{feedback.service}</p></div>

                        <div className={'col-5 left-content'}><p>Contact du :</p></div>
                        <div className={'col-7 right-content'}><p>{moment(feedback.feedback_date).format('L')}</p></div>
                    </div>
                </div>
            }

            <div className={'bloc-info h-auto'}>
                <div className={'content row'}>
                    <div className={'col-5 left-content'}>
                        <p>Dernier contact client :</p>
                    </div>
                    <div className={'col-7 right-content'}>
                        <p>{tiers.dernier_contact && tiers.dernier_contact.type ? `${tiers.dernier_contact.type} du ${moment(tiers.dernier_contact.date).format('L')}` : '-'}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Feedback;