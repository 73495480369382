import {SET_TASKPRIOS} from '../../actions/app/taskprios/tasks.actions'

export const taskPriosReducer = (tasks = {}, action = {}) => {
    const {payload} = action

    if (action.type === SET_TASKPRIOS) {
        return payload
    } else {
        return tasks
    }
}
