import {connect} from 'react-redux'
import {compose} from "redux";
import Feedback from "./Feedback";
import {FEEDBACK, getFeedback} from "../../../../../../redux/actions/app/feedback/feedback.actions";
import _ from "lodash";

import {getLoadingEntity} from "../../../../../../redux/selectors/ui/ui.selectors";
import {feedbackGetLink} from "../../../../../../redux/selectors/links/links.selectors";
import {getFeedbackData} from "../../../../../../redux/selectors/feedback/feedback.selectors";
import loader from "../../../../../Commun/Spinner/Loader/Loader";

const mapStateToProps = (state, ownProps) => {
    return {
        uri: feedbackGetLink(state),
        idTiers: ownProps.tiers.belair_id,
        loaded: getLoadingEntity(state, FEEDBACK) === false,
        feedback: getFeedbackData(state)
    }
}

const mapDispatchToProps = {
    getFeedback,
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {uri, idTiers} = stateProps
    const {getFeedback} = dispatchProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => getFeedback({query: _.replace(uri, '{id}', idTiers)})
    }
}

const FeedbackContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader()
)(Feedback)

export default FeedbackContainer
