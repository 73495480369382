import React, {useEffect, useState} from 'react';
import {Field} from "redux-form";
import DatePickerFieldRedux from "../../../../Commun/Input/DatePicker/DatePickerFieldRedux";
import moment from "moment";
import Spinner from "../../../../Commun/Spinner/Spinner";
import {getCourtiers} from "../../../../../utils/function";
import _ from "lodash";
import AutoComplete from "../../../../Commun/Input/Autocomplete/Autocomplete";
import RadioFieldRedux from "../../../../Commun/Input/Radio/RadioFieldRedux";
import CheckboxFieldRedux from "../../../../Commun/Input/Checkbox/CheckboxFieldRedux";
import Badge from "../../../../Commun/Badge/Badge";


const TransfertWallet = (props) => {
    const {handleSubmit, loading, changeValue, tiers, contracts, loadingContracts, type_transfert, selected_contracts, nouvel_apporteur } = props
    const [courtiers, setCourtiers] = useState(null)
    let messageValidation = `Etes-vous sûr de vouloir transférer les contrats de ${tiers.titre} ${tiers.name} - ${tiers.belair_id} vers le N°${nouvel_apporteur} ?`

    useEffect(() => {
        fetchCourtiers();
    }, [])

    async function fetchCourtiers() {
        let arrayCourtiers = await getCourtiers()
        let listCourtiers = _.orderBy(arrayCourtiers, ['name'], ['asc'])

        listCourtiers = listCourtiers.map(c => ({
            value: c.code_belair,
            label: c.code_belair + ' - ' + c.raison_sociale
        }))

        setCourtiers(listCourtiers)
        changeValue('nouvel_apporteur', '')
    }

    const updateContractsSelected = (e, contractId) => {
        let selected = selected_contracts || []
        console.log(selected)
        if (e) {
            changeValue('selected_contracts', selected.concat(contractId));
        } else {
            changeValue('selected_contracts', selected.filter((id) => id !== contractId));
        }
    }

    const renderContracts = () => {
        return type_transfert === 'partiel' ? (
            <div className={'row'}>
                <div className="col-12">
                    <label>Choissisez les contrats concernés par le transfert partiel :</label>
                    {loadingContracts ?
                        <div className="text-center">
                            <Spinner />
                        </div>
                     : <><div className="row">{renderContractItems()}</div><hr/></>}
                </div>
            </div>
        ) : null;
    };

    const renderContractItems = () => {
        const visibleContracts = _.orderBy(contracts.items, [contract => contract.souscripteur.nom], ['asc']);

        return visibleContracts.map((contract) => (
            <div key={contract.id} className="col-md-4">
                <div className="form-check form-check-inline">
                    <Field
                        name={`contracts[${contract.id}]`}
                        component={CheckboxFieldRedux}
                        type={'checkbox'}
                        id={`contrat-${contract.id}`}
                        onChange={(e) => updateContractsSelected(e.target.checked, contract.id)}
                    />
                    <label className="form-check-label" htmlFor={`contrat-${contract.id}`}>
                        <strong className={'font-weight-bold'} style={{marginRight: 10}}>{contract.numeroPolice}</strong><Badge status={contract.situation.statut} classBadge={'align-text-top no-margin'}/><br/>{contract.souscripteur.nom}
                    </label>
                </div>
            </div>
        ));
    };

    return (
        <div className="contract-container container">
            <div className="row">
                <div className="col-12">
                    <h1 className="title-primary">
                        {tiers.belair_id} - {tiers.titre} {tiers.name}
                    </h1>
                </div>
            </div>
            <div className="row header-row">
                <div className="col-12">
                    <form className="form-content" onSubmit={handleSubmit}>
                        {loading ? <div className="text-center"><p className={"font-weight-bolder"}>Transfert en cours</p><Spinner /></div> : <>
                            <div className="row">
                                <div className="col-6">
                                    <Field
                                        name="nouvel_apporteur"
                                        component={AutoComplete}
                                        label="Choisissez le nouvel apporteur"
                                        data={courtiers}
                                        subLabel="Si vous ne trouvez pas l'apporteur, vérifiez dans Belair qu'il est correctement typé"
                                        required
                                    />
                                </div>
                                {type_transfert === 'complet' &&
                                    <div className="col-6">
                                        <Field
                                            name={"date_transfert"}
                                            component={DatePickerFieldRedux}
                                            label={'Date du transfert'}
                                            value={moment()}
                                        />
                                    </div>
                                }
                            </div>
                            <div className={'row'}>
                                <div className="col-6">
                                    <Field
                                        name="type_transfert"
                                        component={RadioFieldRedux}
                                        label="Choisissez le type de transfert"
                                        data={[
                                            { value: 'partiel', label: 'Transfert partiel' },
                                            { value: 'complet', label: 'Transfert complet' },
                                        ]}
                                        required
                                    />
                                </div>
                            </div>
                            <hr />

                            {renderContracts()}

                            <div>
                                <button className="btn btn-primary d-block m-auto" type="submit" onClick={(e) => {
                                    e.preventDefault();
                                    if (window.confirm(messageValidation)) {
                                        handleSubmit();
                                    }
                                }}>
                                    Lancer le transfert
                                </button>
                            </div>
                        </> }
                    </form>
                </div>
            </div>
        </div>
    );
};



export default TransfertWallet;
