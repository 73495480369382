import React, {useEffect} from 'react';
import RadioFieldRedux from "../../../../../../../Commun/Input/Radio/RadioFieldRedux";
import {Field} from "redux-form";
import TextFieldRedux from "../../../../../../../Commun/Input/Text/TextFieldRedux";
import CallToAction from "../../../../../../../Commun/Button/CallToAction/CallToAction";
import MyButton from '../../../../../../../Commun/Button/Button'
import PostalCodeCity from "../../../../../../../Commun/PostalCodeCity/PostalCodeCity";

const FieldArrayTemoins = (props) => {
    const {fields, changeValue} = props

    useEffect(() => {
        if (props.fields.length === 0) {
            props.fields.push({})
        }
    }, [])

    return (
        <div>
            {
                fields.map((temoin, index) => (
                    <div key={index} className={'cadre'}>
                        <span className={'title-cadre'}>Témoin {index + 1}</span>
                        {' '}
                        <CallToAction
                            action={'delete'}
                            toolText={'Supprimer'}
                            onClick={(e) => {
                                e.preventDefault()
                                fields.remove(index)
                            }}
                        />

                        <Field
                            name={`${temoin}.titre`}
                            component={RadioFieldRedux}
                            label={'Civilité'}
                            data={[
                                {value: "M", label: "Monsieur"},
                                {value: "Mme", label: "Madame"}
                            ]}
                        />

                        <Field
                            name={`${temoin}.nom`}
                            component={TextFieldRedux}
                            normalize={v => v.toUpperCase()}
                            label="Nom"
                        />

                        <Field
                            name={`${temoin}.prenom`}
                            component={TextFieldRedux}
                            normalize={v => v.toUpperCase()}
                            label="Prénom"
                        />

                        <Field
                            name={`${temoin}.portable`}
                            component={TextFieldRedux}
                            label="Téléphone"
                            type={'tel'}
                        />

                        <Field
                            name={`${temoin}.adresse1`}
                            component={TextFieldRedux}
                            label="Adresse"
                        />

                        <PostalCodeCity
                            nameCodePostal={`${temoin}.cp`}
                            nameVille={`${temoin}.ville`}
                            label={'Ville de résidence'}
                            changeValue={changeValue}
                        />

                    </div>
                ))
            }
            <MyButton
                onClick={(e) => {
                    e.preventDefault();
                    fields.push({})
                }}
                className={'btn btn-secondary'}
            >
                Ajouter un témoin
            </MyButton>
        </div>
    );
}

export default FieldArrayTemoins;
