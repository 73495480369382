
import {API_ERROR, API_SUCCESS, apiRequest} from '../../../actions/core/api/api.actions'
import {setError, setLoader} from '../../../actions/app/ui/ui.actions'
import {setNotification} from "../../../actions/core/notifications/notifications.actions";
import {POST_CHANGE_PASSWORD, CHANGE_PASSWORD, setChangePassword} from "../../../actions/app/password/password.actions";
import {reset} from "redux-form";

export const changePasswordMiddleware = () => next => action => {
    next(action)

    const {payload} = action
    let message = null

    switch (action.type) {
        case POST_CHANGE_PASSWORD:
            next([
                apiRequest({
                    body: (action.payload.body),
                    method: 'POST',
                    url: action.payload.data,
                    entity: CHANGE_PASSWORD
                }),
                setLoader({state: true, entity: CHANGE_PASSWORD})
            ])
            break

        case `${CHANGE_PASSWORD} ${API_SUCCESS}`:
            message = `<p>Le mot de passe a bien été modifié</p>`
            next([
                setChangePassword({state: true}),
                setNotification({
                    entity: CHANGE_PASSWORD,
                    body: message,
                    type: 'success',
                    title: 'Mot de passe mis à jour!'
                }),
                setLoader({state: false, entity: CHANGE_PASSWORD}),
                setError({state: false, entity: CHANGE_PASSWORD}),
                reset('changePasswordForm')
            ])
            break

        case `${CHANGE_PASSWORD} ${API_ERROR}`:
            message = `
                <p>Votre demande n'a pas pu être envoyée. ${payload.data.response.data.reason}</p>
            `
            next([
                setNotification({
                    entity: CHANGE_PASSWORD,
                    body: message,
                    type: 'error',
                    title: 'Erreur lors du changement de mot de passe !',
                    timeout: '0'
                }),
                setError({state: true, entity: CHANGE_PASSWORD}),
                setLoader({state: false, entity: CHANGE_PASSWORD}),
            ])
            break

        default:
            break
    }
    return null
}
