import {SET_CONTRACT} from '../../actions/app/contracts/contracts.actions'

export const contractReducer = (contract = {}, action = {}) => {
    const {payload} = action

    if (action.type === SET_CONTRACT) {
        return payload
    } else {
        return contract
    }
}
