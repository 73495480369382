import {API_ERROR, API_SUCCESS, apiRequest} from '../../../actions/core/api/api.actions'
import {setError, setLoader} from '../../../actions/app/ui/ui.actions'
import {
    FLOTTE, FLOTTE_ACCIDENTS,
    FLOTTE_DETAIL,
    GET_FLOTTE, GET_FLOTTE_ACCIDENTS,
    GET_FLOTTE_DETAIL,
    setFlotte,
    setFlotteDetail
} from "../../../actions/app/flotte/flotte.actions";
import _ from 'lodash'
import {logAction} from "../../../actions/core/logs/logs.actions";
import {setAccidents} from "../../../actions/app/accidents/accidents.actions";

export const flotteMiddleware = () => next => action => {
    next(action)

    let url = '';

    switch (action.type) {

        case GET_FLOTTE:
            url = _.includes(action.payload.data, '?') ? action.payload.data : `${action.payload.data}?`

            if (action.payload.filters) {
                for (let [key, value] of Object.entries(action.payload.filters)) {
                    url = `${url}&${key}=${value}`
                }
            }

            next([
                apiRequest({body: null, method: 'GET', url, entity: FLOTTE}),
                setLoader({state: true, entity: FLOTTE})
            ])
            break

        case `${FLOTTE} ${API_SUCCESS}`:
            let {data, meta, links} = action.payload.data
            next([
                setFlotte({data, meta, links}),
                setError({state: false, entity: FLOTTE}),
                setLoader({state: false, entity: FLOTTE})
            ])
            break

        case `${FLOTTE} ${API_ERROR}`:
            next([
                setError({state: true, entity: FLOTTE}),
                setLoader({state: false, entity: FLOTTE})
            ])
            break

        case GET_FLOTTE_DETAIL:
            next([
                apiRequest({body: null, method: 'GET', url: action.payload.data, entity: FLOTTE_DETAIL}),
                setLoader({state: true, entity: FLOTTE_DETAIL}),
                logAction({type: 'SHOW_FLOTTE_DETAIL', entity: FLOTTE_DETAIL, vehicule: action.payload.otherData.id})
            ])
            break

        case `${FLOTTE_DETAIL} ${API_SUCCESS}`:
            next([
                setFlotteDetail({data: action.payload.data}),
                setLoader({state: false, entity: FLOTTE_DETAIL}),
                setError({state: false, entity: FLOTTE_DETAIL})
            ])
            break

        case `${FLOTTE_DETAIL} ${API_ERROR}`:
            next([
                setError({state: true, entity: FLOTTE_DETAIL}),
                setLoader({state: false, entity: FLOTTE_DETAIL})
            ])
            break

        case GET_FLOTTE_ACCIDENTS:
            url = _.includes(action.payload.data, '?') ? action.payload.data : `${action.payload.data}?`

            if (action.payload.filters) {
                for (let [key, value] of Object.entries(action.payload.filters)) {
                    url = `${url}&${key}=${value}`
                }
            }

            next([
                apiRequest({body: null, method: 'GET', url: url, entity: FLOTTE_ACCIDENTS}),
                setLoader({state: true, entity: FLOTTE_ACCIDENTS})
            ])
            break

        case `${FLOTTE_ACCIDENTS} ${API_SUCCESS}`:
            next([
                setAccidents({...action.payload}),
                setError({state: false, entity: FLOTTE_ACCIDENTS}),
                setLoader({state: false, entity: FLOTTE_ACCIDENTS})
            ])
            break

        case `${FLOTTE_ACCIDENTS} ${API_ERROR}`:
            next([
                setError({state: true, entity: FLOTTE_ACCIDENTS}),
                setLoader({state: false, entity: FLOTTE_ACCIDENTS})
            ])
            break

        default:
            break
    }
    return null
}
