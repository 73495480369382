import React, {Component, Fragment} from 'react'
import Badge from "../../../Commun/Badge/Badge";
import Contrat from "./Contrat/Contrat"
import Vehicule from "./Vehicule/Vehicule"
import Hamon from "./Hamon/Hamon"
import Garanties from "./Garanties/Garanties"
import {Link} from "react-router-dom";
import DocumentsContainer from "./Documents/DocumentsContainer";
import AccidentsContainer from "./Accidents/AccidentsContainer"
import QuittancesContainer from "./Quittances/QuittancesContainer";
import TasksContainer from "../Tiers/Client/Tasks/TasksContainer";
import moment from "moment";
import ButtonsContainer from "./Buttons/ButtonsContainer";
import ValidateContratContainer from "./ValideContrat/ValideContratContainer";
import Promo from "./Promo/Promo";
import NotesContainer from "./Notes/NotesContainer";
import PiecesContainer from "./Pieces/PiecesContainer";
import {redirectInNewWindow} from "../../../../utils/function";
import _ from "lodash";
import NavigationContratsContainer from "./NavigationContrats/NavigationContratsContainer";
import {openInWindow} from "../../../../constants/constants";
import ReclamationsContainer from "../Reclamations/ReclamationsContainer";
import AntecedentsContainer from "./Antecedents/AntecedentsContainer";
import FlotteContentContainer from "./Flotte/FlotteContentContainer";
import SignaturesContainer from "./Signatures/SignaturesContainer";
import FeedbackNoteContainer from "./FeedbackNote/FeedbackNoteContainer";
import VersionningContainer from "./Versionning/VersionningContainer";
import SouscripteurContainer from "./Souscripteur/SouscripteurContainer";
import CallToAction from "../../../Commun/Button/CallToAction/CallToAction";
import DerogationsContainer from '../Derogations/DerogationsContainer';
import GiftCardsContainer from "./GiftCards/GiftCardsContainer";

class Contract extends Component {
    componentDidMount() {
        window.scrollTo(0, 0)
        if (this.props.contract.id !== Number(this.props.idContract)) {
            this.props.getContract({
                query: (_.replace(this.props.uri, '{id}', this.props.idContract)),
                id: this.props.idContract
            })
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.contract.id !== Number(this.props.idContract)) {
            window.scrollTo(0, 0)
            this.props.getContract({
                query: (_.replace(this.props.uri, '{id}', this.props.idContract)),
                id: this.props.idContract
            })
        }
    }


    render() {
        const {contract, roles} = this.props
        const { motifCode, dateFin, fractionnement } = contract.situation
        const todayFormatted = moment().format("YYYY-MM-DD")
        const dateDiff = moment(todayFormatted).diff(moment(dateFin).format("YYYY-MM-DD"), 'days')

        const isCode5orIValid = (motifCode === '5' || motifCode === 'I') && dateDiff < 90;
        const isCodeZValid = motifCode === 'Z' && dateDiff < 60 && fractionnement !== "Paiement annuel" && fractionnement !== "Prélévement annuel";
        const isMotifCodeValid = isCode5orIValid || isCodeZValid;
        const isNotExcludedRole = !_.includes(roles, 'gestionnaire externe') && !_.includes(roles, 'gestionnaire televente');

        return (
            <div className={'cd-main-content'}>
                <div className={'contract-container container'}>
                    <div className={'row return-content'}>
                        <div className={'col-2'}>
                            <button onClick={() => this.props.history.go(-1)} className={'btn btn-return'}>Retour
                            </button>
                        </div>
                    </div>
                    <NavigationContratsContainer tiers={contract.souscripteur.id} current={contract.id}/>
                    {(contract.situation.statutCode === '2') &&
                        <ValidateContratContainer roles={roles} contract={contract} tiers={contract.souscripteur.id}
                                                  delaisResil={contract.situation.delaisAvantResil}/>
                    }
                    {!_.includes(roles, 'gestionnaire televente') &&
                        <SignaturesContainer contract={contract.id} tiers={contract.souscripteur}/>
                    }
                    <div className={'row'}>
                        <div className={'col-12'}>
                            <Link to={`/tiers/${contract.souscripteur.id}`}
                                  className={'link-title-primary d-inline-block'}>
                                <h1 className={'title-primary'}>
                                    {contract.souscripteur.titre} {contract.souscripteur.nom} {contract.souscripteur.id}
                                    <span className={'link-icon-primary ml-2'}/>
                                </h1>
                            </Link>
                            <Badge status={contract.situation.statut} classBadge={'align-text-top'}/>

                            {!_.includes(roles, 'gestionnaire televente') &&
                                <span className={'btn btn-cta btn-action d-inline-block float-right'}
                                      onClick={(e) => redirectInNewWindow({
                                          e,
                                          url: `/les-activites/contrat/${this.props.contract.id}`,
                                          name: openInWindow.activitiesContract
                                      })}>
                                Les activités du contrat
                            </span>}
                        </div>

                        {!_.includes(roles, 'gestionnaire televente') && <div className={'col-12 col-md-8 mt-3'}>
                            <ButtonsContainer contract={contract}/>
                        </div>}
                        <div className={'col-12 col-md-4 mt-3 text-right'}>
                            <FeedbackNoteContainer tiers_id={contract.souscripteur.id}/>
                        </div>

                        <div className={'col-12 mt-3'}>
                            <div className={'d-inline-block'}>
                                <span
                                    className={'bold subtitle d-block'}>Contrat n°{contract.numeroPolice} - {contract.title}</span>
                                <span
                                    className={'bold subtitle font-italic'}>Dernière modification sur le contrat le {moment(contract.situation.dateSituation).format('L')}</span>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col">
                            {((contract.situation.statutCode === '1' || contract.situation.statutCode === '5' || contract.situation.statutCode === '2' || (contract.situation.statutCode === 'I' && contract.vehicule.codeProduit !== '7311_NVEI')) && !_.includes(roles, 'gestionnaire externe') && !_.includes(roles, 'gestionnaire televente'))
                                && <Link to={`/contrat/${contract.id}/resilier`}
                                         className={'btn btn-cta btn-danger mt-3 ml-0'}>
                                    Résilier / suspendre le contrat
                                </Link>
                            }
                            { (isMotifCodeValid && isNotExcludedRole) &&
                                <Link to={`/contrat/${contract.id}/remise-en-vigueur`}
                                         className={'btn btn-cta btn-success mt-3 ml-0'}>
                                    Remise en vigueur du contrat
                                </Link>
                            }
                        </div>
                    </div>
                    <div className="row header-row">
                        {((contract.souscripteur.pays_naissance === null && contract.souscripteur.titre !== 'SOC' ) || (contract.souscripteur.titre === 'SOC' && contract.souscripteur.statut_juridique === null)) && <div className={'col'}>
                            <div className={'alert alert-warning'}>
                                <p>Attention, les données de naissance du Tiers ne sont pas complétées à 100%. Merci de
                                    faire le nécessaire.
                                    <CallToAction
                                        url={{
                                            pathname: `/tiers/${contract.souscripteur.id}/modifier-informations-naissance`,
                                            state: {tiers: contract.souscripteur.id}
                                        }}
                                        action={'edit-note'}
                                        toolText={'Modifier les informations de naissance'}
                                        style={{float: 'none', marginLeft: 30}}
                                    />
                                </p>
                            </div>
                        </div>}
                    </div>
                    <NotesContainer contract={contract} className={'loader'}/>
                    <TasksContainer className={'loader'} tiers={contract.souscripteur.id}/>

                    {(_.includes(roles, 'gestionnaire an') || _.includes(roles, 'gestionnaire vdc') || _.includes(roles, 'managers')) &&
                        <VersionningContainer className={'loader'} contract={contract.id}/>
                    }

                    {(contract.promo.campagne || contract.hamon.raisonSociale) &&
                        <div className={'row margin-top'}>
                            {contract.hamon.raisonSociale &&
                                <div className={'col'}><Hamon hamon={contract.hamon} contract={contract}/></div>}
                            {contract.promo.campagne && <div className={'col'}><Promo promo={contract.promo}/></div>}
                        </div>
                    }
                    {/* {contract.fidelisations &&
                    <div className={'row margin-top'}>
                        <div className={'col'}><FidelisationContainer fidelisations={contract.fidelisations}  contract={contract.id}/></div>
                    </div>
                    }*/}
                    <div className={'row margin-top'}>
                        <div className={'col'}>
                            <SouscripteurContainer souscripteur={contract.souscripteur} conducteur={contract.conducteur} tiersPayeur={contract.tiers_payeur}
                                                   coeff={contract.coeff_com} contract={contract.id} dernierGeste={contract.dernierGeste} roles={roles}/>
                        </div>
                        <div className={'col'}>
                            <Contrat contrat={contract}/>
                        </div>
                    </div>
                    {
                        !contract.flotte &&
                        <Fragment>

                            <div className={'row margin-top'}>
                                <div className={'col'}>
                                    <Vehicule vehicule={contract.vehicule}/>
                                </div>
                                <div className={'col'}>
                                    <AntecedentsContainer contract={contract.id}
                                                          codeProduit={contract.vehicule.codeProduit}
                                                          antecedents={contract.antecedents}
                                                          permis={contract.conducteur.permis}
                                                          conducteur={contract.conducteur} tiers={contract.souscripteur}
                                    />
                                </div>
                            </div>
                            <div className={'row margin-top'}>
                                <div className={'col'}>
                                    <Garanties garanties={contract.garanties}
                                               accessoires={contract.vehicule.valeurAccessoires}/>
                                </div>
                            </div>
                            <div className={'row margin-top'}>
                                <div className={'col'}>
                                    <DocumentsContainer className={'loader'} contractId={contract.id}
                                                        tiersId={contract.souscripteur.id}
                                                        risqueNum={contract.adhesionPrincipale} roles={roles}/>
                                </div>
                            </div>
                            <div className={'row margin-top'}>
                                <div className={'col'}>
                                    <DerogationsContainer className={'loader'} />
                                </div>
                            </div>
                            <div className="row margin-top">
                                <div className="col">
                                    <ReclamationsContainer className={'loader'} id={contract.souscripteur.id}/>
                                </div>
                            </div>
                            <div className={'row margin-top'}>
                                <div className={'col'}>
                                    <AccidentsContainer className={'loader'} contract={contract}/>
                                </div>
                            </div>
                            <div className={'row margin-top'}>
                                <div className={'col'}>
                                    <GiftCardsContainer className={'loader'} contract={contract}/>
                                </div>
                            </div>
                        </Fragment>
                    }
                    {contract.flotte &&
                        <FlotteContentContainer className={'loader'} contractId={contract.id} contract={contract}/>}

                    <PiecesContainer className={'loader'}/>
                    <QuittancesContainer className={'loader'} contractId={contract.id} tiers={contract.souscripteur}
                                         situation={contract.situation} dernierCourrierCV={contract.dernierCourrierCV}/>
                </div>
            </div>
        )
    }
}

export default Contract
