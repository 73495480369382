import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import DashboardContent from './DashboardContent'
import ErrorHttpComponent from '../../../Commun/Error/ErrorHttpComponent'
import {getDashboard} from '../../../../redux/actions/app/dashboard/dashboard.actions'
import {dashboardGetLink} from '../../../../redux/selectors/links/links.selectors'
import loader from "../../../Commun/Spinner/Loader/Loader";

const mapStateToProps = (state) => {
    return {
        uri: dashboardGetLink(state),
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    getDashboard: ({query, sort, service, service_user}) => getDashboard({query, sort, service, service_user}),
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {getDashboard} = dispatchProps
    const {uri} = stateProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => getDashboard({
            query: uri,
            sort: localStorage.getItem('sort_dashboard') || '',
            service: localStorage.getItem('service') || '',
            service_user: localStorage.getItem('service_user') || ''
        })
    }
}

const DashboardContentContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader({ErrorIndicator: ErrorHttpComponent})
)(DashboardContent)

export default DashboardContentContainer
