import React, {Component, Fragment} from 'react';
import {Field} from "redux-form";
import Spinner from "../../../../Commun/Spinner/Spinner";
import SelectFieldRedux from "../../../../Commun/Input/Select/SelectFieldRedux";
import FileFieldRedux from "../../../../Commun/Input/File/FileFieldRedux";
import _ from "lodash";
import TextFieldRedux from "../../../../Commun/Input/Text/TextFieldRedux";
import CheckboxFieldRedux from "../../../../Commun/Input/Checkbox/CheckboxFieldRedux";
import EmailContenu from "./EmailContenu/EmailContenu";
import {emailsAutoSinistre, emailsAutoContrat} from "./emailsAutomatiques";


class Email extends Component {
    constructor(props) {
        super(props)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.state = {
            autres: this.props.autres,
            ccLibreProfile: this.props.ccLibreProfile,
            valid: true,
            emailsAuto: this.props.accident ? emailsAutoSinistre : (!_.isEmpty(this.props.contract) ? emailsAutoContrat : [])
        };
    }

    handleSubmit(form) {
        if (this.state.autres !== 'Autres') {
            let value = this.state.autres.split('|')
            form.profile = value[0].toLowerCase() === 'réparateur' ? 'garage' : value[0].toLowerCase()
            form.email = value[1]
            form.name = value[2]
        } else {
            form.name = form.email
        }

        //On enlève les <mark> du texte pour les champs non required
        form.text = _.replace(form.text, /<mark>?(.*)<\/mark>/gi, '');

        const emailSelected = _.find(this.state.emailsAuto, {'value': this.props.template});
        form.template_name = emailSelected ? emailSelected.label : 'Email libre';
        form.template_title = emailSelected ? emailSelected.title : '';

        this.props.onSubmit(form);
    }

    componentDidUpdate() {
        /* Contrôle taille PJ */
        if (this.props.files) {
            let size = 0;
            const limit = 10000000
            this.props.files.map(f => {
                if (f) {
                    size = size + f.size
                }
            })

            if (size > limit && this.state.valid) {
                this.setState({
                    valid: false
                })
            }

            if (size < limit && !this.state.valid) {
                this.setState({
                    valid: true
                })
            }
        }

    }

    componentWillUnmount() {
        this.props.clearEmailsEnvoyes();
    }

    render() {
        const {
            handleSubmit,
            loading,
            contract,
            type,
            changeValue,
            is_send,
            accident,
            copieCourtier,
            destinataire_id,
            copieClient,
            copieLibre,
            recipients,
            tiers,
            copieLibreEmail,
            emailsEnvoyes,
            profileDestinataire
        } = this.props
        const {autres, ccLibreProfile, emailsAuto} = this.state
        const correspondanceTypeCie = {
            'allianzccau@allianz.fr': 'Dossier matériel',
            'corporel@allianz.fr': 'Dossier corporel',
            'allianzdpr1@allianz.fr': 'Dossier hors convention',
            'allianzsia@allianz.fr': 'Dossier survenu ou impliquant un tiers étranger',
            'dibcvei@allianz.fr': 'Gestion des rapports en perte totale et procédure VEI',
        };

        let destinataires = []

        if (accident) {
            accident.intervenants.forEach((intervenant) => {
                if (type !== 'pmq-accident' && _.includes(['Expert', 'Réparateur'], intervenant.type) && intervenant.email !== '') {
                    destinataires.push({
                        'libelle': `${intervenant.type} : ${intervenant.raisonSociale} ${intervenant.email}`,
                        'id': `${intervenant.type}|${intervenant.email}|${intervenant.raisonSociale}`
                    })
                }
            })

            if (type === 'pmq-accident' && accident.expert) {
                destinataires.push({
                    'libelle': `Expert : ${accident.expert.raisonSociale} ${accident.expert.email}`,
                    'id': `Expert|${accident.expert.email}|${accident.expert.raisonSociale}`
                })
            }

            if (accident.mailCompagnie !== '') {
                destinataires.push({
                    'libelle': `Compagnie : ALLIANZ IARD ${correspondanceTypeCie[accident.mailCompagnie] ? `(${correspondanceTypeCie[accident.mailCompagnie]}) ` : ''}${accident.mailCompagnie}`,
                    'id': `Compagnie|${accident.mailCompagnie}|ALLIANZ IARD`
                })
            }

            if (accident.compagnieAdverse != null && accident.mailCompagnieAdverse != null && accident.mailCompagnieAdverse != '') {
                destinataires.push({
                    'libelle': `Compagnie Adverse : ${accident.compagnieAdverse} ${accident.mailCompagnieAdverse}`,
                    'id': `Compagnie|${accident.mailCompagnieAdverse}|${accident.compagnieAdverse}`
                })
            }
        }
        if (tiers && tiers.address_mail !== '') {
            destinataires.push({
                'libelle': ` Client : ${tiers.titre} ${tiers.name} ${tiers.address_mail}`,
                'id': `Client|${tiers.address_mail}|${tiers.name}`,
            })
        }
        if (!_.isEmpty(contract)) {
            if (contract.apporteurs.apporteur1.email !== '') {
                destinataires.push({
                    'libelle': `Courtier : ${contract.apporteurs.apporteur1.raisonSociale || contract.apporteurs.apporteur1.nom} ${contract.apporteurs.apporteur1.email}`,
                    'id': `Courtier|${contract.apporteurs.apporteur1.email}|${contract.apporteurs.apporteur1.raisonSociale || contract.apporteurs.apporteur1.nom}`,
                })
            }
            if (type !== 'pmq-accident' && contract.apporteurs.apporteur2.email && contract.apporteurs.apporteur2.email !== '') {
                destinataires.push({
                    'libelle': `Concessionnaire : ${contract.apporteurs.apporteur2.raisonSociale || contract.apporteurs.apporteur2.nom} ${contract.apporteurs.apporteur2.email}`,
                    'id': `Concessionnaire|${contract.apporteurs.apporteur2.email}|${contract.apporteurs.apporteur2.raisonSociale || contract.apporteurs.apporteur2.nom}`,
                })
            }
        }

        const addCcEmail = (profile) => {
            if (!_.find(recipients, ['profile', profile])) {
                recipients.push(
                    {
                        email: profile === 'courtier' ? contract.apporteurs.apporteur1.email : contract.souscripteur.email,
                        name: profile === 'courtier' ? contract.apporteurs.apporteur1.raisonSociale || contract.apporteurs.apporteur1.nom : contract.souscripteur.nom,
                        profile,
                        emailLibre: false
                    }
                )
            }

            if (profile === 'courtier' && copieCourtier) {
                _.remove(recipients, (v) => {
                    return v.profile === profile && !v.emailLibre
                })
            }
            if (profile === 'client' && copieClient) {
                _.remove(recipients, (v) => {
                    return v.profile === profile && !v.emailLibre
                })
            }

            changeValue('recipients', recipients)
        }

        const handleChangeEmailLibre = (field, value) => {
            _.remove(recipients, (v) => {
                return v.emailLibre
            })

            //maj des infos liées au profil
            if (field === "profile" && value) {
                this.setState({ccLibreProfile: value})
                majBonjour(value);
            }

            //Soit on vient de cocher la checkbox soit on l'avait déjà cochée
            if ((field === "copieLibre" && value) || (field !== "copieLibre" && copieLibre)) {
                let email = (field === "email" && value) ? value : copieLibreEmail;
                let profile = (field === "profile" && value) ? value : ccLibreProfile;
                let name;

                if (profile === 'courtier' && !_.isEmpty(contract) && contract.apporteurs && (contract.apporteurs.apporteur1.raisonSociale || contract.apporteurs.apporteur1.nom)) {
                    name = contract.apporteurs.apporteur1.raisonSociale || contract.apporteurs.apporteur1.nom;
                } else if (profile === 'concessionnaire' && !_.isEmpty(contract) && contract.apporteurs && (contract.apporteurs.apporteur2.raisonSociale || contract.apporteurs.apporteur2.nom)) {
                    name = contract.apporteurs.apporteur2.raisonSociale || contract.apporteurs.apporteur2.nom;
                } else if (profile === 'client') {
                    name = tiers.name;
                }

                recipients.push(
                    {
                        email: email,
                        name: name ? name : email,
                        profile: profile,
                        emailLibre: true
                    }
                )
            }
        }

        const handleChange = (e) => {
            this.setState({autres: e.target.value})

            if (e.target.value !== 'Autres') {
                let value = e.target.value.split('|')
                handleChangeEmailLibre('profile', (value[0].toLowerCase() === 'réparateur' ? 'garage' : value[0].toLowerCase()));
            } else {
                majBonjour(e.target.value);
            }
            changeValue('template', '')
            changeValue('template_title', '')
        }

        const majBonjour = (value) => {
            // affichage du Bonjour !
            changeValue('text', getBonjour(value))
        }

        const getBonjour = (value) => {
            if (_.includes(value, 'Client') || (!Array.isArray(value) && value === 'client')) {
                return 'Bonjour,';
            } else {
                return 'Cher Partenaire,';
            }
        }

        return (
            <div className={'contract-container container'}>
                {!is_send || type === 'libre' ?
                    <Fragment>
                        {emailsEnvoyes && !_.isEmpty(emailsEnvoyes) &&
                            <div className={'alert alert-info m-0 mb-4'}>
                                <p className={'font-weight-bold'}>Emails envoyés :</p>
                                <ul className={'mb-0'}>
                                    {emailsEnvoyes.map((email, i) => {
                                        return <li key={i}>{email.label}</li>
                                    })}
                                </ul>
                            </div>
                        }
                        <div className={'row'}>
                            <div className={'col-12'}>
                                <h1 className={'title-primary'}>{tiers.belair_id} - {tiers.titre} {tiers.name}</h1>
                            </div>
                        </div>
                        <div className={'row header-row'}>
                            <div className={'col-12'}>
                                <form className={'form-content'} onSubmit={handleSubmit(this.handleSubmit)}>
                                    <p className="font-weight-bold mb-4">Destinataire de l'email</p>
                                    <Field
                                        name={'destinataire_id'}
                                        component={SelectFieldRedux}
                                        label={'Liste des destinataires'}
                                        required
                                        onChange={(e) => handleChange(e)}
                                    >
                                        <option value={""}>Sélectionner un destinataire</option>
                                        {destinataires.map((dest, index) => (
                                            <option key={index} value={dest.id}>{dest.libelle}</option>
                                        ))}
                                        <option value='Autres'>Autres</option>
                                    </Field>

                                    {!_.isEmpty(contract) && contract.apporteurs.apporteur1.email && destinataire_id &&
                                        <Field
                                            component={CheckboxFieldRedux}
                                            name={'copieCourtier'}
                                            label={`Mettre en copie le courtier : ${contract.apporteurs.apporteur1.raisonSociale || contract.apporteurs.apporteur1.nom} (${contract.apporteurs.apporteur1.email})`}
                                            onChange={() => addCcEmail('courtier')}
                                        />}
                                    {!_.isEmpty(contract) && contract.souscripteur.email && destinataire_id && <Field
                                        component={CheckboxFieldRedux}
                                        name={'copieClient'}
                                        label={`Mettre en copie le client : ${contract.souscripteur.nom} (${contract.souscripteur.email})`}
                                        onChange={() => addCcEmail('client')}
                                    />}

                                    <Field
                                        component={CheckboxFieldRedux}
                                        name={'copieLibre'}
                                        label={`Mettre en copie un email libre`}
                                        onChange={() => handleChangeEmailLibre('copieLibre', !copieLibre)}
                                    />

                                    {copieLibre && <Fragment>
                                        <Field
                                            name={'copieLibreEmail'}
                                            component={TextFieldRedux}
                                            label={'Email du destinataire de la copie libre'}
                                            onChange={(e) => handleChangeEmailLibre('email', e.target.value)}
                                        />
                                    </Fragment>}

                                    {autres === 'Autres' && <Fragment>
                                        <Field
                                            name={'profile'}
                                            component={SelectFieldRedux}
                                            label={'Type'}
                                            onChange={(e) => handleChangeEmailLibre('profile', e.target.value)}
                                        >
                                            <option value={""}>Sélectionner le type de destinataire</option>
                                            <option value={'courtier'}>Courtier</option>
                                            <option value={'concessionnaire'}>Concessionnaire</option>
                                            <option value={'client'}>Client</option>
                                        </Field>
                                        <Field
                                            name={'email'}
                                            component={TextFieldRedux}
                                            label={'Email du destinataire'}
                                        />
                                    </Fragment>}

                                    <hr className={'my-5'}/>

                                    <EmailContenu {...this.props} emailsAuto={_.orderBy(emailsAuto, ['label'], ['asc'])}
                                                  getBonjour={getBonjour} majBonjour={majBonjour}
                                                  typeDestinataire={_.upperFirst(destinataire_id && destinataire_id !== 'Autres' ? destinataire_id.substr(0, destinataire_id.indexOf('|')) : (destinataire_id && destinataire_id === 'Autres' ? profileDestinataire : ''))}/>

                                    <hr className={'my-5'}/>

                                    <p className="font-weight-bold mb-4">Pièces jointes à ajouter</p>

                                    <div>
                                        <Field
                                            name={'files[0]'}
                                            component={FileFieldRedux}
                                            label={'Pièce jointe n°1'}
                                            onChangeValue={changeValue}
                                        />
                                        <Field
                                            name={'files[1]'}
                                            component={FileFieldRedux}
                                            label={'Pièce jointe n°2'}
                                            onChangeValue={changeValue}
                                        />
                                        <Field
                                            name={'files[2]'}
                                            component={FileFieldRedux}
                                            label={'Pièce jointe n°3'}
                                            onChangeValue={changeValue}
                                        />
                                        <Field
                                            name={'files[3]'}
                                            component={FileFieldRedux}
                                            label={'Pièce jointe n°4'}
                                            onChangeValue={changeValue}
                                        />
                                        <Field
                                            name={'files[4]'}
                                            component={FileFieldRedux}
                                            label={'Pièce jointe n°5'}
                                            onChangeValue={changeValue}
                                        />
                                        {
                                            !this.state.valid &&
                                            <p className={'text-danger font-weight-bold'}>Attention, la taille total des
                                                pièces jointes dépasse 14mo, votre email ne peut pas être envoyé.</p>
                                        }

                                        <p className={'subtitle'}>Pour des raisons de délivrabilité de l'email, vous ne
                                            pouvez pas ajouter plus de 5 pièces-jointes et le poids total ne doit pas
                                            dépasser 14mo.</p>
                                        <p className="subtitle mb-5">Pensez également à bien vérifier le nommage et le
                                            contenu de vos fichiers avant de les envoyer !</p>
                                    </div>

                                    <button
                                        className={'btn btn-primary position-relative'}
                                        disabled={!this.state.valid || loading}
                                    >
                                        {loading && <Spinner absolute/>}
                                        Envoyer l'email
                                    </button>
                                </form>
                            </div>
                        </div>
                    </Fragment>
                    : <div className={'text-center'}>
                        <h2 className={'title-primary title-success'}>Votre email a bien été envoyé&nbsp;!</h2>
                    </div>
                }
            </div>
        );
    }
};

export default Email;
