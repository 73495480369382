import {
    SET_IS_LOGIN,
    SET_LOADER,
    SET_MAINTENANCE,
    SET_ERROR,
    SET_ID_ENTITY_SELECTED,
    SET_ACCIDENT_SUCCESS,
    SET_VALIDATE_DOCUMENT_KOFAX_SUCCESS,
    SET_VALIDATE_DOCUMENTS_PENDING,
    SET_IS_LOGOUT,
    SET_DOCUMENTS_NEEDEED_FOR_ACCIDENT,
    SET_CONFIRME_VIREMENT,
    SET_CHANGE_ADDRESS,
    SET_NB_TASKS, SET_FILTER,
    SET_VALIDATE_POST_ACCIDENT, SET_ADD_EXPERT_REPORT, SET_HIVERNAGE_SUCCESS, SET_HIVERNAGE_REDUCTION
} from '../../actions/app/ui/ui.actions'
import {SET_CREATE_ACCOUNT} from "../../actions/app/account/account.actions";
import {SET_PAYMENT} from "../../actions/app/payment/payment.actions";
import {SET_STATISTIQUES} from "../../actions/app/statistiques/statistiques.actions";
import {SET_VALIDATE_VIREMENTS_PROD} from "../../actions/app/virements/virements.actions";
import {CLEAR_EMAILS_ENVOYES, SET_EMAILS_ENVOYES} from "../../actions/app/email/email.actions";

const initState = {
    login: false,
    logout: false,
    maintenance: false,
    selected: {},
    validateDocument: [],
    validateDocumentsPending: [],
    emailsEnvoyes: []
}

export const uiReducer = (ui = initState, action = {}) => {
    const {payload, meta} = action

    switch (true) {
        case action.type.includes(SET_LOADER):
            return {...ui, loading: {...ui.loading, [meta]: payload}}
        case action.type.includes(SET_ERROR):
            return {...ui, error: {...ui.error, [meta]: payload}}
        case action.type.includes(SET_IS_LOGIN):
            return {...ui, login: payload}
        case action.type.includes(SET_IS_LOGOUT):
            return {...ui, logout: payload}
        case action.type.includes(SET_MAINTENANCE):
            return {...ui, maintenance: payload}
        case action.type.includes(SET_ID_ENTITY_SELECTED):
            return {...ui, selected: {...ui.selected, [meta]: payload}}
        case action.type.includes(SET_CREATE_ACCOUNT):
            return {...ui, account: payload.data}
        case action.type.includes(SET_PAYMENT):
            return {...ui, postPayment: {...ui.postPayment, 'state': payload}}
        case action.type.includes(SET_DOCUMENTS_NEEDEED_FOR_ACCIDENT):
            return {...ui, documentsForAccident: payload.data}
        case action.type.includes(SET_ACCIDENT_SUCCESS):
            return {...ui, accidentPost: payload}
        case action.type.includes(SET_VALIDATE_DOCUMENT_KOFAX_SUCCESS):
            return {...ui, validateDocument: {...ui.validateDocument, [payload.formName]: payload.state}}
        case action.type.includes(SET_VALIDATE_DOCUMENTS_PENDING):
            return {...ui, validateDocumentsPending: {...ui.validateDocumentsPending, [payload.index]: payload.form}}
        case action.type.includes(SET_CONFIRME_VIREMENT):
            return {...ui, confirmeVirement: payload}
        case action.type.includes(SET_VALIDATE_POST_ACCIDENT):
            return {...ui, validatePostAccident: payload}
        case action.type.includes(SET_STATISTIQUES):
            return {...ui, statistiques: payload}
        case action.type.includes(SET_CHANGE_ADDRESS):
            return {...ui, changeAddress: payload}
        case action.type.includes(SET_NB_TASKS):
            return {...ui, nbTasks: payload.data}
        case action.type.includes(SET_VALIDATE_VIREMENTS_PROD):
            return {...ui, validateVirementProd: payload}
        case action.type.includes(SET_EMAILS_ENVOYES):
            return {...ui, emailsEnvoyes: [...ui.emailsEnvoyes, {label: payload.data}]}
        case action.type.includes(CLEAR_EMAILS_ENVOYES):
            return {...ui, emailsEnvoyes: []}
        case action.type.includes(SET_FILTER):
            return {...ui, filters: {...ui.filters, [meta]: payload}}
        case action.type.includes(SET_ADD_EXPERT_REPORT):
            return {...ui, expertReportPost: payload}
        case action.type.includes(SET_HIVERNAGE_SUCCESS):
            return {...ui, hivernagePost: payload}
        case action.type.includes(SET_HIVERNAGE_REDUCTION):
            return {...ui, hivernageReduction: payload}

        default:
            return ui
    }
}
