import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import ChangeCompagnieAccident from "./ChangeCompagnieAccident";
import {change, reduxForm} from "redux-form";
import {getLoadingEntity} from "../../../../../redux/selectors/ui/ui.selectors";
import {updateAccidentCompanyGetLink} from "../../../../../redux/selectors/links/links.selectors";
import _ from "lodash";
import {
    POST_COMPAGNIE_ADVERSE_ACCIDENT,
    postUpdateAccidentCompagnieAdverse
} from "../../../../../redux/actions/app/accidents/accidents.actions";

const mapStateToProps = (state, ownProps) => {
    return {
        uri: updateAccidentCompanyGetLink(state),
        accidentId: ownProps.location.state.accident,
        contractId: ownProps.location.state.contract,
        loading: getLoadingEntity(state, POST_COMPAGNIE_ADVERSE_ACCIDENT),
        initialValues: {
            "company": '',
        }
    }
}


const mapDispatchToProps = (dispatch, ownProps) => bindActionCreators({
    postUpdateAccidentCompagnieAdverse,
    changeValue: (field, value) => change('change-compagnie', field, value)
}, dispatch)


const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {uri, accidentId, contractId} = stateProps
    const {postUpdateAccidentCompagnieAdverse} = dispatchProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        onSubmit: form => postUpdateAccidentCompagnieAdverse({
            query: _.replace(_.replace(uri, '{id}', contractId), '{ids}', accidentId),
            form
        })
    }
}


const ChangeCompagnieAccidentContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    reduxForm({
        form: 'change-compagnie'
    })
)(ChangeCompagnieAccident)


export default ChangeCompagnieAccidentContainer
