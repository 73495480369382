import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import Comptabilite from './Comptabilite'
import {CartesVertesComptaGetLink} from "../../../../redux/selectors/links/links.selectors";
import {postExtractionCartesVertes} from "../../../../redux/actions/app/compta/compta.actions";
import loader from "../../../Commun/Spinner/Loader/Loader";

const mapStateToProps = (state) => {
    return {
        uri: CartesVertesComptaGetLink(state),
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    postExtractionCartesVertes,
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {postExtractionCartesVertes} = dispatchProps
    const {uri} = stateProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        submitExtractionCVComptabilite: () => postExtractionCartesVertes({query: uri}),
    }
}

const ComptabiliteContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader()
)(Comptabilite)

export default ComptabiliteContainer
