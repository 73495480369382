import React from 'react'
import CallToAction from "../../../../Commun/Button/CallToAction/CallToAction";

const Hamon = ({hamon, contract}) => {

    return (
        <div className={'bloc-info bloc-contrat'}>
            <h2>Souscription Loi Hamon</h2>
            <CallToAction
                url={{
                    pathname: `/contrat/${contract.id}/modifier-informations-hamon`,
                    state: {
                        title: `${contract.souscripteur.titre} ${contract.souscripteur.nom} ${contract.souscripteur.id}`,
                        subtitle: `Contrat n°${contract.numeroPolice} - ${contract.title}`,
                        id_assureur: hamon.id,
                        assureur: hamon.raisonSociale,
                        contrat: hamon.oldPolice,
                        id_contrat: contract.id
                    }
                }}
                action={'edit-note'}
                toolText={'Editer les informations'}
            />
            <div className={'content row'}>
                <div className={'col-5 left-content'}>
                    <p>Raison sociale :</p>
                    <p>Adresse :</p>
                    <p>Code Postal - Ville :</p>
                    <p>Numéro de contrat :</p>
                </div>
                <div className={'col-7 right-content'}>
                    <p>{hamon.raisonSociale}</p>
                    <p>{hamon.adresse.rue1} {hamon.adresse.rue2}</p>
                    <p>{hamon.adresse.codePostal} {hamon.adresse.ville}</p>
                    <p>{hamon.oldPolice}</p>
                </div>
            </div>

        </div>
    )
}

export default Hamon
