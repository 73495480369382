import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import {change, reduxForm} from "redux-form";
import validate from "./validate";
import {getLoadingEntity} from "../../../../../redux/selectors/ui/ui.selectors";
import {
    updateTiersGetLink
} from "../../../../../redux/selectors/links/links.selectors";
import {postUpdateTiers, POST_UPDATE_TIERS} from "../../../../../redux/actions/app/tiers/tiers.actions";

import AddTiers from "./AddTiers";
import _ from "lodash";
import loader from "../../../../Commun/Spinner/Loader/Loader";

const mapStateToProps = (state, ownProps) => {
    return {
        id: ownProps.match.params.id,
        uri: updateTiersGetLink(state),
        loading: getLoadingEntity(state, POST_UPDATE_TIERS),
        accidentId: ownProps.location.state.accident,
        contractId: ownProps.location.state.contract,
        edit: true,
        initialValues: {
            'nom': ownProps.location.state.adverse.nom || null,
            'prenom': ownProps.location.state.adverse.prenom || null,
            'adresse': ownProps.location.state.adverse.adresse || null,
            'cp': ownProps.location.state.adverse.codePostal || null,
            'ville': ownProps.location.state.adverse.ville || null,
            'immatriculation': ownProps.location.state.adverse.immat || null,
            'police': ownProps.location.state.adverse.police || null,
        }
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    changeValue: (field, value) => change(`edit_tiers`, field, value),
    postUpdateTiers,
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {uri, id, accidentId, contractId} = stateProps
    const {postUpdateTiers} = dispatchProps


    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        onSubmit: (form) => {
            form.accident = accidentId
            form.contract = contractId
            postUpdateTiers({query: _.replace(uri, '{id}', id), form})
        }
    }
}

const EditTiersContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader(),
    reduxForm({
        form: 'edit_tiers',
        validate
    }),
)(AddTiers)

export default EditTiersContainer
