import _ from "lodash";

const validate = values => {
    const errors = {}

    const requiredFields = [
        'catsinorigin',
        'numCie'
    ]

    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Champ obligatoire'
        }
    })

    if (values.numCie !== undefined && !_.startsWith(values.numCie.toLowerCase(), 'c')) {
        errors.numCie = "La référence Compagnie dans ce formulaire doit commencer par un \"C\", si ce n'est pas le cas il faut utiliser le formulaire de déclaration classique"
    }

    return errors
}

export default validate
