import React from 'react'
import moment from "moment";

const Vehicule = ({vehicule}) => {
    const isVAE = vehicule.codeProduit === '7311_VAE'
    const isNVEI = vehicule.codeProduit === '7311_NVEI'

    return (
        <div className={'bloc-info bloc-vehicule'}>
            <h2>Véhicule</h2>
            <div className={'content row'}>
                <div className={'col-5 left-content'}>
                    <p>Type de véhicule :</p>
                    {!isVAE && !isNVEI && <p>Date de MEC :</p>}
                    {!isVAE && !isNVEI && <p>Immatriculation :</p>}
                    {!isVAE && !isNVEI && <p>Usage :</p>}
                </div>
                <div className={'col-7 right-content'}>
                    <p>{vehicule.marque} {vehicule.modele}</p>
                    {(!isVAE && !isNVEI && vehicule.dateMEC)&& <p>{moment(vehicule.dateMEC).format('L')}</p>}
                    {(!isVAE && !isNVEI) && <p>{vehicule.immatriculation}</p>}
                    {(!isVAE && !isNVEI) && <p>{vehicule.usage}</p>}
                </div>
            </div>
        </div>
    )
}

export default Vehicule
