import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import DashboardCollapseRow from "./DashboardCollapseRow";
import {dashboardTaskDetailGetLink} from "../../../../../../redux/selectors/links/links.selectors";
import {getManagerTaskDetail} from "../../../../../../redux/actions/app/dashboard/dashboard.actions";
import _ from "lodash";
import loader from "../../../../../Commun/Spinner/Loader/Loader";

const mapStateToProps = (state, ownProps) => {
    return {
        uri: dashboardTaskDetailGetLink(state),
        user: ownProps.user,
        detail: ownProps.user.detail ? ownProps.user.detail : []
    }
}


const mapDispatchToProps = dispatch => bindActionCreators({
    getManagerTaskDetail,
}, dispatch)


const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {getManagerTaskDetail} = dispatchProps
    const {user, uri} = stateProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => getManagerTaskDetail({query: _.replace(uri, '{id}', user.pro_id), pro_id: user.pro_id})
    }
}


const DashboardCollapseRowContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader()
)(DashboardCollapseRow)


export default DashboardCollapseRowContainer
