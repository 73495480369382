
import {API_ERROR, API_SUCCESS, apiRequest} from '../../../actions/core/api/api.actions'
import {setError, setLoader, setValidateDocumentKofax} from '../../../actions/app/ui/ui.actions'
import {
    POST_VALIDATE_DOCUMENT,
    POST_VALIDATE_DOCUMENTS_TASK,
    VALIDATE_DOCUMENT,
} from "../../../actions/app/validate_documents/validate_documents.actions";
import {setNotification} from "../../../actions/core/notifications/notifications.actions";
import {reset} from "redux-form";
import {CLOSE_TASK} from '../../../actions/app/tasks/tasks.actions'

export const validateDocumentMiddleware = () => next => action => {
    next(action)

    const {payload} = action
    let message = null
    let nextAction = []

    switch (action.type) {
        case POST_VALIDATE_DOCUMENT:
            next([
                apiRequest({
                    body: (action.payload.body),
                    method: 'POST',
                    url: action.payload.data,
                    entity: VALIDATE_DOCUMENT,
                    otherData: action.payload.otherData
                }),
                setLoader({state: true, entity: VALIDATE_DOCUMENT}),
            ])
            break

        case POST_VALIDATE_DOCUMENTS_TASK:
            const formPieces = action.payload.body.pieces

            let params = {
                tiers: action.payload.body.tiers,
                risque: action.payload.body.risque,
            }

            if (action.payload.body.sinistre) {
                params = {...params, sinistre: action.payload.body.sinistre};
            }

            let index = 0
            Object.keys(formPieces).forEach(form => {
                for (let [key, value] of Object.entries(formPieces[form])) {
                    if (value.codeatt !== 'Autres') {
                        params[`pieces[${index}][codeatt]`] = value.codeatt
                        params[`pieces[${index}][statut]`] = value.statut
                        if (value.commentaire) {
                            params[`pieces[${index}][commentaire]`] = value.commentaire
                        }
                    }
                    index++
                }
            })

            if (params['pieces[0][codeatt]'] === undefined && index === 1) {
                next([
                    //on valide la tache sans document
                    apiRequest({
                        body: null,
                        method: 'POST',
                        url: action.payload.otherData.queryCloseTask,
                        entity: CLOSE_TASK
                    }),
                    setLoader({state: true, entity: POST_VALIDATE_DOCUMENTS_TASK}),
                ])
            } else {
                next([
                    //on valide les documents
                    apiRequest({
                        body: (params),
                        method: 'POST',
                        url: action.payload.data,
                        entity: POST_VALIDATE_DOCUMENTS_TASK,
                        otherData: action.payload.otherData
                    }),
                    setLoader({state: true, entity: POST_VALIDATE_DOCUMENTS_TASK}),
                ])
            }
            break

        case `${VALIDATE_DOCUMENT} ${API_SUCCESS}`:
            message = `<p>Le document a bien été ajouté à la liste des documents attendus du ${action.payload.meta.otherData.sinistre ? 'sinistre' : 'contrat'}.</p>`

            next([
                setError({state: false, entity: VALIDATE_DOCUMENT}),
                setLoader({state: false, entity: VALIDATE_DOCUMENT}),
                setNotification({
                    entity: VALIDATE_DOCUMENT,
                    body: message,
                    type: 'success',
                    title: 'Le document attendu a été ajouté !'
                }),
                reset(action.payload.meta.otherData.formName)
            ])

            break

        case `${POST_VALIDATE_DOCUMENTS_TASK} ${API_SUCCESS}`:
            message = `<p>La pièce a bien été validée.</p>`

            next([
                apiRequest({
                    body: null,
                    method: 'POST',
                    url: action.payload.meta.otherData.queryCloseTask,
                    entity: CLOSE_TASK
                }),
                setError({state: false, entity: POST_VALIDATE_DOCUMENTS_TASK}),
                setLoader({state: false, entity: POST_VALIDATE_DOCUMENTS_TASK}),
                setNotification({
                    entity: POST_VALIDATE_DOCUMENTS_TASK,
                    body: message,
                    type: 'success',
                    title: 'Pièce validée avec succès'
                }),
                reset(action.payload.meta.otherData.formName)
            ])

            break

        case `${VALIDATE_DOCUMENT} ${API_ERROR}`:
            message = `<p>Votre demande n'a pas pu être envoyée. ${payload.data.response.data.reason}</p>`

            nextAction = [
                setNotification({
                    entity: VALIDATE_DOCUMENT,
                    body: message,
                    type: 'error',
                    title: 'Une erreur est survenue !',
                    timeout: '0'
                }),
                setError({state: true, entity: VALIDATE_DOCUMENT}),
                setLoader({state: false, entity: VALIDATE_DOCUMENT})
            ]

            if (action.payload.meta.otherData.kofax) {
                nextAction.push(setValidateDocumentKofax({
                    state: false,
                    formName: action.payload.meta.otherData.formName
                }))
            }

            next(nextAction)
            break

        default:
            break
    }
    return null
}
