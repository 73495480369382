import React, {useEffect} from 'react';
import {Field} from "redux-form";
import TextAreaFieldRedux from "../../../../../../../Commun/Input/Text/TextAreaFieldRedux";
import TextFieldRedux from "../../../../../../../Commun/Input/Text/TextFieldRedux";

const Declaration = () => {

    useEffect(() => {
        document.getElementById('declaration-ter').scrollIntoView({behavior: 'smooth'})
    }, [])

    return (
        <div className={'form-content'}>
            <h1 className={'title-primary'}>Quelles sont les circonstances ?</h1>

            <Field
                name='commentaire'
                component={TextAreaFieldRedux}
                label={'Circonstances'}
                maxLength={'120'}
                subLabel={'120 caractères maximum'}
            />

            <Field
                name='conduct'
                component={TextFieldRedux}
                label='Nom du conducteur'
            />

        </div>
    );
};

export default Declaration;
