import React, {Component} from 'react';
import TasksContainer from './TasksList/TasksContainer'
import {courtiersPremium, typeAgenda} from "../../../../utils/optionsSelect";
import moment from "moment";
import {getBoolean} from "../../../../utils/function";
import _ from "lodash";
import TraitementCheckdocContainer from "../TraitementCheckdoc/TraitementCheckdocContainer";
import {LocalizationProvider} from "@mui/x-date-pickers";
import {AdapterMoment} from "@mui/x-date-pickers/AdapterMoment";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import TextField from "@mui/material/TextField";

class TasksContent extends Component {
    constructor(props) {
        super(props)
        localStorage.getItem('sort') === null && localStorage.setItem('sort', 'date')
        localStorage.getItem('agendas') === null && localStorage.setItem('agendas', false)
        localStorage.getItem('multiple') === null && localStorage.setItem('multiple', false)
        this.state = {
            query: this.props.uri,
            focusedInput: false,
            focusedInputTaskDate: false,
            focusedInputPopUpRange: false,
            showMultiple: getBoolean(localStorage.getItem('multiple')),
            showAgendas: getBoolean(localStorage.getItem('agendas')),
            startDate: localStorage.getItem('date_start_create_contract') ? moment(localStorage.getItem('date_start_create_contract'), 'DD/MM/YYYY') : null,
            endDate: localStorage.getItem('date_end_create_contract') ? moment(localStorage.getItem('date_end_create_contract'), 'DD/MM/YYYY') : null,
            startDatePopUp: localStorage.getItem('date_start_create_popup_range') ? moment(localStorage.getItem('date_start_create_popup_range'), 'DD/MM/YYYY') : null,
            endDatePopUp: localStorage.getItem('date_end_create_popup_range') ? moment(localStorage.getItem('date_end_create_popup_range'), 'DD/MM/YYYY') : null,
            taskDate: localStorage.getItem('date_create_task') ? moment(localStorage.getItem('date_create_task'), 'DD/MM/YYYY') : null,
        }
    }

    componentDidMount() {
        this.props.setLoadingContractTrue()
    }

    handleChangeAgenda = e => {
        this.setState({
            showAgendas: !this.state.showAgendas,
            startDatePopUp: null,
            endDatePopUp: ''
        })
        localStorage.setItem('agendas', !this.state.showAgendas)
        localStorage.setItem('date_end_create_popup_range', '')
        localStorage.setItem('date_start_create_popup_range', '')
        localStorage.setItem('type_agenda', '')
        this.props.getTasks({
            query: this.state.query,
            sort: localStorage.getItem('sort'),
            agendas: localStorage.getItem('agendas') || ''
        })
    }

    handleChangeMulti = (e) => {
        this.setState({showMultiple: !this.state.showMultiple})
        localStorage.setItem('multiple', !this.state.showMultiple)
        this.props.getTasks({
            query: this.state.query,
            sort: localStorage.getItem('sort'),
            multiple: localStorage.getItem('multiple') || ''
        })
    }

    handleChangeSort = (e) => {
        localStorage.setItem('sort', e.target.value)
        this.props.getTasks({
            query: this.state.query,
            sort: localStorage.getItem('sort')
        })
    }

    handleChangeFilter = (e) => {
        let filter = e.target.name.substring(7)
        localStorage.setItem(filter, e.target.value)

        this.props.getTasks({
            query: this.state.query,
            sort: localStorage.getItem('sort'),
            [filter]: localStorage.getItem(filter)
        })
    }

    handleChangeDates = (start, end, type) => {

        if (!end) {
            localStorage.setItem(`date_end_${type}`, moment().format('L'))
        } else {
            localStorage.setItem(`date_end_${type}`, moment(end).format('L'))
        }
        localStorage.setItem(`date_start_${type}`, moment(start).format('L'))

        if (type === 'create_contract') {
            this.setState({
                startDate: start,
                endDate: end
            })
            this.props.getTasks({
                query: this.state.query,
                sort: localStorage.getItem('sort'),
                date_start: localStorage.getItem(`date_start_${type}`) || '',
                date_end: localStorage.getItem(`date_end_${type}`) || ''
            })
        } else if (type === 'create_popup_range') {
            localStorage.setItem('date_create_task', '')
            this.setState({
                startDatePopUp: start,
                endDatePopUp: end,
                taskDate: null
            })
            this.props.getTasks({
                query: this.state.query,
                sort: localStorage.getItem('sort'),
                date_start_create_popup_range: localStorage.getItem(`date_start_${type}`) || '',
                date_end_create_popup_range: localStorage.getItem(`date_end_${type}`) || ''
            })
        }

    }

    handleChangeDate = (date, type) => {
        localStorage.setItem(`date_${type}`, moment(date).format('L'))

        if (type === 'create_task') {

            //On évite les conflits entre dates
            localStorage.setItem('date_end_create_popup_range', '')
            localStorage.setItem('date_start_create_popup_range', '')

            this.setState({
                taskDate: date,
                startDatePopUp: null,
                endDatePopUp: null
            })
            this.props.getTasks({
                query: this.state.query,
                sort: localStorage.getItem('sort'),
                date_create_popup_range: localStorage.getItem(`date_${type}`) || ''
            })
        }
    }

    resetFilters = () => {
        localStorage.setItem('date_end_create_contract', '')
        localStorage.setItem('date_start_create_contract', '')
        localStorage.setItem('date_end_create_popup_range', '')
        localStorage.setItem('date_start_create_popup_range', '')
        localStorage.setItem('date_create_task', '')
        localStorage.setItem('contrat_situation', '')
        localStorage.setItem('paniere', '')
        localStorage.setItem('type_agenda', '')
        localStorage.setItem('portefeuille', '')
        localStorage.setItem('produit', '')
        localStorage.setItem('courtier', '')
        localStorage.setItem('resil_date', '')

        this.setState({
            startDate: null,
            endDate: null,
            startDatePopUp: null,
            endDatePopUp: null,
            taskDate: null

        })
        this.props.getTasks({
            query: this.state.query,
            sort: localStorage.getItem('sort'),
            date_start: '',
            date_end: ''
        })
    }


    render() {
        const {meta, panieres, roles} = this.props
        const {showAgendas} = this.state

        return (
            <div className={'tasks-container container'}>
                <div className={'row'}>
                    <div className={'col-12'}>
                        {_.includes(roles, 'manager qualite') && <TraitementCheckdocContainer/>}
                        <h1 className={'title-primary title-task'}>Mes tâches assignées</h1>
                        {meta.total > 0 && <small className={'bold text-uppercase'}>{meta.total} tâches</small>}
                        <div className={'styled-checkbox float-right'}>
                            <input
                                type="checkbox"
                                name="filter_agenda"
                                id="agenda"
                                onChange={this.handleChangeAgenda}
                                value={this.state.showAgendas}
                                defaultChecked={this.state.showAgendas}
                            />
                            <label htmlFor="agenda">Afficher les agendas ?</label>
                            <input
                                type="checkbox"
                                name="filter_multiple"
                                id="multiple"
                                onChange={this.handleChangeMulti}
                                value={this.state.showMultiple}
                                defaultChecked={this.state.showMultiple}
                            />
                            <label htmlFor="multiple">Afficher les contrats avec plusieurs tâches ?</label>
                        </div>
                    </div>
                    <div className={'col-12 filters'}>
                        <div className={'content'}>
                            <div className={'mb-3'}>
                                <small className={'label'}>Filtre par AFN</small>
                                <small className={'label-right'} onClick={this.resetFilters}>Réinitialiser les
                                    filtres</small>
                            </div>
                            <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="fr">
                                <DatePicker
                                    className={'mr-1 small date-picker'}
                                    label='Date de début'
                                    value={this.state.startDate}
                                    onChange={(newValue) => {
                                        this.handleChangeDates(newValue, this.state.endDate, "create_contract")
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                />
                            </LocalizationProvider>

                            <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="fr">
                                <DatePicker
                                    className={'mr-1 small date-picker'}
                                    label='Date de fin'
                                    value={this.state.endDate}
                                    onChange={(newValue) => {
                                        this.handleChangeDates(this.state.startDate, newValue, "create_contract")
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                    minDate={this.state.startDate}
                                />
                            </LocalizationProvider>

                            <LocalizationProvider dateAdapter={AdapterMoment} adapterLocale="fr">
                                <DatePicker
                                    className={'mr-1 small date-picker'}
                                    label='Date de la tâche'
                                    value={this.state.taskDate}
                                    onChange={(newValue) => {
                                        this.handleChangeDate(newValue, "create_task")
                                    }}
                                    renderInput={(params) => <TextField {...params} />}
                                    minDate={this.state.startDate}
                                />
                            </LocalizationProvider>
                            <select
                                name="filter_courtier"
                                onChange={this.handleChangeFilter}
                                className={'small-select select-sort-courtier custom-select'}
                                value={localStorage.getItem('courtier')}
                            >
                                <option value="">Courtier premium</option>
                                {courtiersPremium.map((courtier, index) => (
                                    <option key={index} value={courtier.code}>{courtier.libelle}</option>
                                ))}
                            </select>
                            <select
                                name="filter_contrat_situation"
                                onChange={this.handleChangeFilter}
                                className={'small-select select-filter-tasks custom-select'}
                                value={localStorage.getItem('contrat_situation')}
                            >
                                <option value="">Situation du contrat</option>
                                <option value="1">En cours</option>
                                <option value="2">En établissement</option>
                                <option value="6">Résilié</option>
                                <option value="5">Suspendue</option>
                                <option value="I">Résilié/Impayé</option>
                                <option value="A">Avenant en attente</option>
                            </select>

                            {panieres.length > 0 && <select
                                name="filter_paniere"
                                onChange={this.handleChangeFilter}
                                className={'small-select select-filter-tasks custom-select'}
                                value={localStorage.getItem('paniere')}
                            >
                                <option value={''}>Panière</option>
                                {panieres.map((paniere, index) =>
                                    <option key={index} value={paniere}>{paniere}</option>
                                )}
                            </select>}

                            <select
                                name="filter_portefeuille"
                                onChange={this.handleChangeFilter}
                                className={'small-select select-filter-tasks custom-select'}
                                value={localStorage.getItem('portefeuille')}
                            >
                                <option value={''}>Portefeuille</option>
                                <option value={'AGG'}>AGGRAVES</option>
                                <option value={'AMA'}>AMA</option>
                                <option value={'BQC'}>BQ_CASINO</option>
                                <option value={'COM'}>COMPARATEURS</option>
                                <option value={'CON'}>CONCESSIONNAIRE</option>
                                <option value={'COP'}>CONCESS_PREMIUM</option>
                                <option value={'COU'}>COURTIER</option>
                                <option value={'DIR'}>DIRECT</option>
                                <option value={'DUC'}>DUCATI</option>
                                <option value={'GPV'}>GPVL</option>
                                <option value={'MAP'}>MARCHE_PRO</option>
                                <option value={'MCO'}>AIMCO</option>
                                <option value={'MVA'}>MVAGUSTA</option>
                                <option value={'PRE'}>PREMIUM</option>
                            </select>
                            <select
                                name="filter_produit"
                                onChange={this.handleChangeFilter}
                                className={'small-select select-filter-tasks custom-select'}
                                value={localStorage.getItem('produit')}
                            >
                                <option value={''}>Type de produit</option>
                                <option value={'7311_SC'}>Cyclo</option>
                                <option value={'7311_MOT1'}>Moto & Scooter</option>
                                <option value={'7311_PVE'}>Moto Verte</option>
                                <option value={'7311_PVK'}>Quad</option>
                                <option value={'7311_NVEI'}>Trottinette</option>
                                <option value={'7311_VAE'}>Vélo</option>
                            </select>

                            <select
                                name="filter_resil_date"
                                onChange={this.handleChangeFilter}
                                className={'small-select select-filter-tasks custom-select'}
                                value={localStorage.getItem('resil_date')}
                            >
                                <option value={''}>Date de résiliation</option>
                                <option value={'inferieure'}>Inférieure à 14 mois</option>
                                <option value={'superieure'}>Supérieure à 14 mois</option>
                            </select>

                            <select
                                name="sort"
                                onChange={this.handleChangeSort}
                                className={'small-select select-sort-tasks custom-select'}
                                value={localStorage.getItem('sort')}
                            >
                                <option value="date">Du + ancien au + récent</option>
                                <option value="-date">Du + récent au + ancien</option>
                                <option value="paniere">Panière A-Z</option>
                                <option value="-paniere">Panière Z-A</option>
                            </select>
                        </div>
                        {showAgendas && <div className={'content'}>
                            <select
                                name="filter_type_agenda"
                                onChange={this.handleChangeFilter}
                                className={'small-select select-filter-tasks custom-select'}
                                value={localStorage.getItem('type_agenda')}
                            >
                                <option value={''}>Type d'agenda</option>
                                {typeAgenda.map((paniere, index) =>
                                    <option key={index} value={paniere.codeBelair}>{paniere.libelle}</option>
                                )}
                            </select>
                        </div>}
                    </div>
                </div>
                <div className={'row'}>
                    <div className={'col'}>
                        <TasksContainer className='loader'/>
                    </div>
                </div>
            </div>
        );
    }
}

export default TasksContent;
