import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'

import ChangeAddress from "./ChangeAddress";
import {change, formValueSelector, reduxForm} from "redux-form";
import ErrorHttpComponent from "../../../../Commun/Error/ErrorHttpComponent";
import {CONTRACT, getContract, getContracts} from "../../../../../redux/actions/app/contracts/contracts.actions";
import _ from "lodash";
import {getContractData} from "../../../../../redux/selectors/contract/contract.selectors";
import {getChangeAddressUi, getErrorEntity, getLoadingEntity} from "../../../../../redux/selectors/ui/ui.selectors";
import {
    amendmentChangeAddressGetLink,
    contractDefaultGetLink,
    contractsTiersDefaultGetLink
} from "../../../../../redux/selectors/links/links.selectors";
import {getContractsItemsData} from "../../../../../redux/selectors/contracts/contracts.selectors";
import {
    postChangeAddress
} from "../../../../../redux/actions/app/change_address/change_address.actions";
import validate from './validate'
import loader from "../../../../Commun/Spinner/Loader/Loader";

const mapStateToProps = (state, ownProps) => {
    const formName = `change_address-${ownProps.match.params.id}`
    const selector = formValueSelector(formName)

    return {
        loading: getLoadingEntity(state, CONTRACT) || getLoadingEntity(state, formName),
        contract: getContractData(state),
        contracts: getContractsItemsData(state),
        uriPostChangeAddress: amendmentChangeAddressGetLink(state),
        uriContract: contractDefaultGetLink(state),
        uriContracts: contractsTiersDefaultGetLink(state),
        idContract: ownProps.match.params.id,
        changeAddress: getChangeAddressUi(state),
        loaded: getLoadingEntity(state, CONTRACT) === false,
        newRue: `${selector(state, 'adresseNew')} ${selector(state, 'adresse2New') !== undefined ? selector(state, 'adresse2New') : ''} ${selector(state, 'adresse3New') !== undefined ? selector(state, 'adresse3New') : ''}, ${selector(state, 'codePostalNew')} ${selector(state, 'villeNew')}`,
        newAddr: `${selector(state, 'codePostalB')}, ${selector(state, 'villeB')}`,
        error: getErrorEntity(state, CONTRACT),
        sansStationnement: selector(state, 'sansStationnement'),
        dateEffet: selector(state, 'dateEffet'),
        addressNotFound: selector(state, 'addressNotFound'),
        form: formName,
        adresseNew: selector(state, 'adresseNew')
    }
}

const mapDispatchToProps = (dispatch, ownProps) => bindActionCreators({
    getContract: (query) => getContract({query}),
    getContracts: ({query, id}) => getContracts({query: _.replace(query, '{id}', id)}),
    changeValue: (field, value) => change(`change_address-${ownProps.match.params.id}`, field, value),
    postChangeAddress: ({query, form, formName}) => postChangeAddress({query, form, formName})
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {uriPostChangeAddress, uriContract, idContract, form} = stateProps
    const {getContract, postChangeAddress} = dispatchProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => getContract(_.replace(uriContract, '{id}', idContract)),
        onSubmit: (formulaire) => postChangeAddress({query: uriPostChangeAddress, form: formulaire, formName: form})
    }
}

const ChangeAddressContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    reduxForm({
        validate
    }),
    loader({ErrorIndicator: ErrorHttpComponent})
)(ChangeAddress)

export default ChangeAddressContainer
