import {
    VIREMENTS,
    GET_VIREMENTS,
    VIREMENT,
    GET_VIREMENT,
    setVirement,
    setVirements,
    POST_VIREMENT,
    VIREMENTS_PROD,
    setVirementsProd,
    GET_VIREMENTS_PROD,
    POST_VALIDATE_VIREMENTS_PROD,
    VALIDATE_VIREMENTS_PROD, setValidateListVirements
} from '../../../actions/app/virements/virements.actions'
import {API_ERROR, API_SUCCESS, apiRequest} from '../../../actions/core/api/api.actions'
import {setError, setLoader, setConfirmeVirement} from '../../../actions/app/ui/ui.actions'

import {setNotification} from "../../../actions/core/notifications/notifications.actions";
import _ from 'lodash'

export const virementsMiddleware = () => next => action => {
    next(action)
    let message = null

    switch (action.type) {
        case GET_VIREMENTS:
            const {filters_vir_sinistre, pageVirement, sortVirement} = action.payload

            let filter_vir_sinistre = '';
            filter_vir_sinistre += `&sort=${sortVirement}`
            filter_vir_sinistre += `&montant=${filters_vir_sinistre["montantVirement"]}`
            filter_vir_sinistre += `&date=${filters_vir_sinistre["dateVirement"]}`
            filter_vir_sinistre += `&numCbt=${filters_vir_sinistre["numSinistre"]}`
            filter_vir_sinistre += `&beneficiaires_prelevements=${filters_vir_sinistre["beneficiairesPrelevementsVirement"]}`

            let url_vir_sinistre = `${action.payload.data}?page=${pageVirement}${filter_vir_sinistre}`

            next([
                apiRequest({body: null, method: 'GET', url: url_vir_sinistre, entity: VIREMENTS}),
                setLoader({state: true, entity: VIREMENTS})
            ])
            break

        case `${VIREMENTS} ${API_SUCCESS}`:

            next([
                setVirements({data: action.payload.data.data, meta: action.payload.data.meta}),
                setError({state: false, entity: VIREMENTS}),
                setLoader({state: false, entity: VIREMENTS})
            ])
            break

        case `${VIREMENTS} ${API_ERROR}`:
            next([
                setError({state: true, entity: VIREMENTS}),
                setLoader({state: false, entity: VIREMENTS})
            ])
            break

        case GET_VIREMENTS_PROD:
            const {filters, page, sort} = action.payload

            let filter = '';
            filter += `&sort=${sort}`
            filter += `&contrat_situation=${filters["contrat_situation"]}`
            filter += `&montant=${filters["montant"]}`
            filter += `&motif=${filters["motif"]}`
            filter += `&date=${filters["date"]}`
            filter += `&fractionnement=${filters["fractionnement"]}`
            filter += `&contrat=${filters["contrat"]}`
            filter += `&multiple=${filters["multiple"]}`
            filter += `&duplicate_amount=${filters["duplicate"]}`


            let url = `${action.payload.data}?page=${page}${filter}`

            next([
                apiRequest({body: null, method: 'GET', url, entity: VIREMENTS_PROD}),
                setLoader({state: true, entity: VIREMENTS_PROD}),
                setValidateListVirements({state: false}),
            ])
            break

        case `${VIREMENTS_PROD} ${API_SUCCESS}`:
            next([
                setVirementsProd({data: action.payload.data.data, meta: action.payload.data.meta}),
                setError({state: false, entity: VIREMENTS_PROD}),
                setLoader({state: false, entity: VIREMENTS_PROD})
            ])
            break

        case `${VIREMENTS_PROD} ${API_ERROR}`:
            next([
                setError({state: true, entity: VIREMENTS_PROD}),
                setLoader({state: false, entity: VIREMENTS_PROD})
            ])
            break

        case GET_VIREMENT:
            next([
                apiRequest({body: null, method: 'GET', url: action.payload.data, entity: VIREMENT}),
                setLoader({state: true, entity: VIREMENT})
            ])
            break

        case `${VIREMENT} ${API_SUCCESS}`:
            const {data} = action.payload

            next([
                setVirement({data}),
                setError({state: false, entity: VIREMENT}),
                setLoader({state: false, entity: VIREMENT})
            ])
            break

        case `${VIREMENT} ${API_ERROR}`:
            next([
                setError({state: true, entity: VIREMENT}),
                setLoader({state: false, entity: VIREMENT})
            ])
            break

        case POST_VIREMENT:
            let body = _.cloneDeep(action.payload.body)
            delete body.factures
            let finalArray = []

            if (body.indemnisation) {
                let array = body.indemnisation
                _.map(array, poste => {
                    if (poste && poste.check === 'T' && (poste.montant || poste.valeur)) {
                        delete poste.check
                        finalArray.push(poste)
                    }
                })
                delete body.indemnisation
            }

            if (body.penalite) {
                let array = body.penalite
                _.map(array, poste => {
                    if (poste && poste.check === 'T' && (poste.montant || poste.valeur)) {
                        delete poste.check
                        finalArray.push(poste)
                    }
                })
                delete body.penalite
            }

            if (body.deduction === undefined || body.deduction === 'Sélectionner la déduction') {
                body.deduction = 'AUCUNE'
            }

            _.map(finalArray, (item, index) => {
                for (let [key, value] of Object.entries(item)) {
                    body[`indemnisation[${index}][${key}]`] = value
                }
            })

            if (body.facturesCochees) {
                _.map(body.facturesCochees, (item, index) => {
                    for (let [key, value] of Object.entries(item)) {
                        body[`factures_indemnisees[${index}][${key}]`] = value
                    }
                })
                delete body.facturesCochees
            }

            if (body.rapportsExpertCoches) {
                _.map(body.rapportsExpertCoches, (item, index) => {
                    for (let [key, value] of Object.entries(item)) {
                        body[`rapports_indemnises[${index}][${key}]`] = value
                    }
                })
                delete body.rapportsExpertCoches
            }

            next([
                apiRequest({
                    body: (body),
                    method: 'POST',
                    url: action.payload.data,
                    entity: POST_VIREMENT
                }),
                setLoader({state: true, entity: POST_VIREMENT})
            ])
            break

        case `${POST_VIREMENT} ${API_SUCCESS}`:
            window.scroll(0, 0)
            next([
                setError({state: false, entity: POST_VIREMENT}),
                setLoader({state: false, entity: POST_VIREMENT}),
                setConfirmeVirement({state: true}),
                setNotification({
                    entity: POST_VIREMENT,
                    body: '',
                    type: 'success',
                    title: `Le virement a bien été enregistré.`
                }),
            ])
            break

        case `${POST_VIREMENT} ${API_ERROR}`:
            window.scroll(0, 0)
            next([
                setError({state: true, entity: POST_VIREMENT}),
                setLoader({state: false, entity: POST_VIREMENT}),
                setConfirmeVirement({state: false}),
                setNotification({
                    entity: POST_VIREMENT,
                    body: action.payload.data.response.data.reason,
                    type: 'error',
                    title: `Le virement n'a pas été enregistré.`,
                    timeout: '0'
                }),
            ])
            break


        case POST_VALIDATE_VIREMENTS_PROD:
            let dataForm = new FormData()
            action.payload.body.virements.forEach((v, index) => {
                dataForm.set(`virements[${index}]`, v)
            })

            next([
                apiRequest({body: dataForm, method: 'POST', url: action.payload.data, entity: VALIDATE_VIREMENTS_PROD}),
                setLoader({state: true, entity: VALIDATE_VIREMENTS_PROD})
            ])
            break

        case `${VALIDATE_VIREMENTS_PROD} ${API_SUCCESS}`:
            next([
                setValidateListVirements({state: true}),
                setNotification({
                    entity: VALIDATE_VIREMENTS_PROD,
                    body: '',
                    type: 'success',
                    title: 'Les virements ont bien été validés'
                }),
                setLoader({state: false, entity: VALIDATE_VIREMENTS_PROD}),
                setError({state: false, entity: VALIDATE_VIREMENTS_PROD})
            ])
            break

        case `${VALIDATE_VIREMENTS_PROD} ${API_ERROR}`:
            message = `
                <p>Votre demande n'a pas pu être envoyée. ${action.payload.data.response.data.reason}</p>
            `
            next([
                setNotification({
                    entity: VALIDATE_VIREMENTS_PROD,
                    body: message,
                    type: 'error',
                    title: 'Erreur !',
                    timeout: '0'
                }),
                setError({state: true, entity: VALIDATE_VIREMENTS_PROD}),
                setLoader({state: false, entity: VALIDATE_VIREMENTS_PROD}),
            ])
            break

        default:
            break;
    }
    return null
}


/*virements[0]: 10174
virements[1]: 10145
virements[2]: 10157
virements[3]: 10144
virements[4]: 10175
virements[5]: 10183
virements[6]: 10184
virements[7]: 10185*/
