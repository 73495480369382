import {GET_SETTINGS, setSettings, SETTINGS, POST_UPDATE_SETTINGS} from '../../../actions/app/settings/settings.actions'
import {API_ERROR, API_SUCCESS, apiRequest} from '../../../actions/core/api/api.actions'
import {setError, setLoader} from '../../../actions/app/ui/ui.actions'
import {setNotification} from "../../../actions/core/notifications/notifications.actions";

export const settingsMiddleware = () => next => action => {
    next(action)

    switch (action.type) {

        case GET_SETTINGS:
            next([
                apiRequest({body: null, method: 'GET', url: action.payload.data, entity: SETTINGS}),
                setLoader({state: true, entity: SETTINGS})
            ])
            break

        case `${SETTINGS} ${API_SUCCESS}`:
            next([
                setSettings({data: action.payload.data}),
                setError({state: false, entity: SETTINGS}),
                setLoader({state: false, entity: SETTINGS})
            ])
            break

        case `${SETTINGS} ${API_ERROR}`:
            next([
                setError({state: true, entity: SETTINGS}),
                setLoader({state: false, entity: SETTINGS})
            ])
            break
        
        case POST_UPDATE_SETTINGS:
            next([
                apiRequest({
                    body: null,
                    method: 'POST',
                    url: action.payload.data,
                    entity: POST_UPDATE_SETTINGS
                }),
                setLoader({state: true, entity: POST_UPDATE_SETTINGS})
            ])
            break

        case `${POST_UPDATE_SETTINGS} ${API_SUCCESS}`:
            next([
                setNotification({
                    entity: POST_UPDATE_SETTINGS,
                    body: 'Paramètres mis à jour',
                    type: 'success',
                    title: 'Modification effectuée'
                }),
                setError({state: false, entity: POST_UPDATE_SETTINGS}),
                setLoader({state: false, entity: POST_UPDATE_SETTINGS})
            ])

            break

        case `${POST_UPDATE_SETTINGS} ${API_ERROR}`:
            next([
                setNotification({
                    entity: POST_UPDATE_SETTINGS,
                    body: `<p>Une erreur est survenue. ${action.payload.data.response.data.reason}</p>`,
                    type: 'error',
                    title: 'Erreur !',
                    timeout: '0'
                }),
                setError({state: true, entity: POST_UPDATE_SETTINGS}),
                setLoader({state: false, entity: POST_UPDATE_SETTINGS})
            ])
            break

        default:
            break
    }
    return null
}
