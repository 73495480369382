import React from 'react'
import SelectFieldRedux from "../../../../Commun/Input/Select/SelectFieldRedux";
import {emailCompagnieSinistre} from "../../../../../utils/optionsSelect";
import {Field} from "redux-form";
import _ from "lodash";
import CallToAction from "../../../../Commun/Button/CallToAction/CallToAction";


const TypeSinistre = (props) => {
    const {permissions, changeValue, accident, onSubmit, contract} = props

    const onSubmitForm = value => {
        changeValue("mailCompagnie", value)
        onSubmit({mailCompagnie: value})
    }

    return (
        <div className={'bloc-info bloc-decla'}>
            <h2>Type de sinistre</h2>
            <div className={'content row'}>
                <div className={'col-5 left-content'}>
                    <p>Catégorie :</p>
                </div>
                <div className={'col-7 right-content'}>
                    <p>{accident.categorie}</p>
                </div>
                <div className={'col-5 left-content'}>
                    <p>Type de sinistre :</p>
                </div>
                <div className={'col-7 right-content'}>
                    <p>{accident.type}</p>
                </div>
                <div className={'col-5 left-content'}>
                    <p>Responsabilité :</p>
                </div>
                <div className={'col-7 right-content'}>
                    <p>
                        {accident.tauxResp}%
                        {_.includes(permissions, 'update accident responsabilite') &&
                            <CallToAction
                                url={{
                                    pathname: `/contrat/${contract}/sinistre/${accident.id}/modification-responsabilite`,
                                    state: {
                                        contract: contract,
                                        accident: accident
                                    }
                                }}
                                action={'edit-repairer'}
                                toolText={`Modifier le taux de responsabilité`}
                            />
                        }
                    </p>
                </div>
                <div className={'col-5 left-content'}>
                    <p>IDA :</p>
                </div>
                <div className={'col-7 right-content'}>
                    <p>{accident.ida}</p>
                </div>
                <div className={'col-5 left-content'}>
                    <p>Email pour la relance compagnie :</p>
                </div>
                <div className={'col-7 right-content'}>
                    <p className={'accident-mailCompagnie'}>
                        {_.includes(permissions, 'update accident email company') ?
                            <Field
                                name='mailCompagnie'
                                component={SelectFieldRedux}
                                onChange={e => onSubmitForm(e.target.value)}
                            >
                                <option value={null}>Sélectionner un email</option>
                                {emailCompagnieSinistre.map((email, index) => (
                                    <option key={index} value={email}>{email}</option>
                                ))}
                            </Field>
                            :
                            accident.mailCompagnie
                        }
                    </p>
                </div>
            </div>
        </div>
    )
}

export default TypeSinistre
