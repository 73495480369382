import React, {Component, Fragment, useEffect, useState} from 'react';
import Badge from "../../../../Commun/Badge/Badge";
import PostalCodeCity from "../../../../Commun/PostalCodeCity/PostalCodeCity";
import TextFieldRedux from "../../../../Commun/Input/Text/TextFieldRedux";
import {Field} from "redux-form";
import CheckboxFieldRedux from "../../../../Commun/Input/Checkbox/CheckboxFieldRedux";
import {normalizeBooleanForNoobBelair, normalizeDate} from "../../../../../utils/normalizeInputs";
import Spinner from "../../../../Commun/Spinner/Spinner";
import moment from "moment";
import _ from "lodash";
import {getAddress} from "../../../../../utils/function";

const ChangeAddress = (props) => {
    const {getContracts, uriContracts, contract, changeValue, contracts, loading, changeAddress, newRue, newAddr, sansStationnement, dateEffet, addressNotFound, adresseNew, onSubmit, handleSubmit, history} = props
    const [state, setState] = useState({
        stationnement: true,
        listAddress: [],
        addressSelected: null,
        defaultValuePostalCodeCity: null,
        adresseNewIsTooLong: false,
        hasSearchAddress: false,
    });

    useEffect(() => {
        getContracts({ query: uriContracts, id: contract.souscripteur.id });
        changeValue('codePostalA', contract.vehicule.lieuStationnementCodePostal);
        changeValue('villeA', contract.vehicule.lieuStationnementVille);
        changeValue('codeProduit', contract.vehicule.codeProduit);
        changeValue('contrat', contract.id);
        changeValue('dateEffet', moment().format('DD/MM/YYYY'));
        changeValue('sansStationnement', _.includes(['7311_VAE', '7311_NVEI'], contract.vehicule.codeProduit));
        changeValue('assuranceCorse', contract.vehicule.codeProduit !== "7311_VAE");
        changeValue('new_adress_is_new_lieuStationnement', "T");
        changeValue('addressNotFound', true)
    }, []);

    const fetchAddress = async (value) => {
        let address = await getAddress(value);
        setState({
            ...state,
            listAddress: address,
            addressSelected: false,
            hasSearchAddress: true,
        });
    };

    const selectedAddress = (address) => {
        changeValue('adresseNew', address.additionalInfo_1);
        changeValue('adresse3New', address.buildingName || '');
        changeValue('codePostalNew', address.postalCode);
        changeValue('villeNew', address.localitySynonym || address.locality);
        setState({
            ...state,
            defaultValuePostalCodeCity: `${address.postalCode} ${address.localitySynonym || address.locality}`,
            addressSelected: address,
        });
    };

    if (!state.adresseNewIsTooLong && adresseNew?.length > 31) {
        changeValue('addressNotFound', true);
        setState({
            ...state,
            adresseNewIsTooLong: true,
        });
    } else if (state.adresseNewIsTooLong && adresseNew?.length <= 31) {
        setState({
            ...state,
            adresseNewIsTooLong: false,
        });
    }

    const myOnSubmit = (form) => {
        onSubmit(form);
        if (state.hasSearchAddress) {
            getAddress(_.get(form, 'adresseNew'), "ValidateForm");
        }
    };

    let canValidate = moment(dateEffet, 'DD/MM/YYYY').startOf('day') >= moment(contract.situation.date).startOf('day');

    return (
        <div className={'contract-container container'}>
            <div className={'row return-content'}>
                <div className={'col'}>
                    <button onClick={() => history.go(-1)} className={'btn btn-return'}>Retour</button>
                </div>
            </div>
            <div className={'row'}>
                <div className={'col-12'}>
                    <h1 className={'title-primary'}>{contract.souscripteur.titre} {contract.souscripteur.nom} {contract.souscripteur.id}</h1>
                    <Badge status={contract.situation.statut} />
                </div>
                <div className={'col-12'}>
                    <span className={'bold text-uppercase subtitle'}>Contrat n°{contract.numeroPolice} - {contract.title}</span>
                </div>
            </div>
            <div className="row header-row">
                <div className={'col-12'}>
                    {
                        changeAddress && changeAddress.id === contract.id
                            ? <div className={`form-content`}>
                                <p>Nouvelle adresse : {newRue}</p>
                                {!sansStationnement && <p>Nouvelle adresse de stationnement : {newAddr}</p>}
                                {changeAddress.data.url && <Fragment>
                                    <p>L'adresse que vous venez de valider correspond à une nouvelle zone de tarification.</p>
                                    <p>Le formulaire d'avenant s'est ouvert dans un nouvel onglet. Merci de le compléter avec la nouvelle adresse pour que le client reçoive ses nouvelles Dispositions Particulières.</p>

                                    <a href={changeAddress.data.url} target={'_blank'} rel="noopener noreferrer">Cliquez ici si la fenêtre ne s'ouvre pas</a>
                                </Fragment>}
                            </div>
                            : <form className={`form-content`} onSubmit={handleSubmit(form => myOnSubmit(form))}>
                                <Field
                                    name='dateEffet'
                                    component={TextFieldRedux}
                                    label={'Date d\'effet'}
                                    normalize={normalizeDate}
                                    placeholder={'dd/mm/aaaa'}
                                />
                                {!canValidate &&
                                    <div className="alert alert-danger mt-3 mb-5" role="alert">
                                        <p>Attention, la date d'effet est inférieure à la date d'effet de la dernière pièce du contrat !</p>
                                    </div>
                                }

                                <Field
                                    name='adresseNew'
                                    component={TextFieldRedux}
                                    label={'Nouvelle adresse : (Ex: ENTREE F BATIMENT 5 37 BIS RUE DE LA GARE 75011 PARIS)'}
                                    normalize={changeAddress => changeAddress.toUpperCase()}
                                    onChange={(e) => fetchAddress(e.target.value)}
                                />

{/*                                <Field
                                    component={CheckboxFieldRedux}
                                    name={'addressNotFound'}
                                    label={'Je ne trouve pas mon adresse'}
                                />*/}

                                {
                                    state.adresseNewIsTooLong &&
                                    <div className="alert alert-warning mt-3" role="alert">
                                        <p>Attention, l'adresse dépasse le nombre de caractère autorisé. (31) <br /> Merci de rentrer manuellement l'adresse et d'utiliser le complément d'adresse.</p>
                                    </div>
                                }

                                {
                                    !state.adresseNewIsTooLong && !state.addressSelected && !addressNotFound && _.map(state.listAddress, (address, i) => (
                                        <div key={i} onClick={() => selectedAddress(address)} className={"list-group"}>
                                            <p className={'pointer m-0 list-group-item list-group-item-action'}>{address.inputOutput}</p>
                                        </div>
                                    ))
                                }

                                {state.addressSelected && !addressNotFound &&
                                    <>
                                        <Field
                                            name='adresse2New'
                                            component={TextFieldRedux}
                                            label={"Complément d'adresse : (Ex: Appartement 25)"}
                                            normalize={changeAddress => changeAddress.toUpperCase()}
                                        />
                                        {
                                            state.addressSelected.buildingName && <Field
                                                name='adresse3New'
                                                component={TextFieldRedux}
                                                label={"Batiment, escalier, étages : (Ex: ENTREE F BATIMENT 5)"}
                                                normalize={changeAddress => changeAddress.toUpperCase()}
                                                disabled
                                            />
                                        }

                                        <PostalCodeCity
                                            nameCodePostal='codePostalNew'
                                            nameVille='villeNew'
                                            label={'Code postal, ville : (Ex: 75011 PARIS)'}
                                            changeValue={changeValue}
                                            id={'address'}
                                            myDefaultValue={state.defaultValuePostalCodeCity}
                                            disabled
                                        />
                                    </>
                                }

                                {addressNotFound &&
                                    <>
                                        <Field
                                            name='adresse2New'
                                            component={TextFieldRedux}
                                            label={"Complément d'adresse : (Ex: Appartement 25)"}
                                            normalize={changeAddress => changeAddress.toUpperCase()}
                                        />

                                        <Field
                                            name='adresse3New'
                                            component={TextFieldRedux}
                                            label={"Batiment, escalier, étages : (Ex: ENTREE F BATIMENT 5)"}
                                            normalize={changeAddress => changeAddress.toUpperCase()}
                                        />

                                        <PostalCodeCity
                                            nameCodePostal='codePostalNew'
                                            nameVille='villeNew'
                                            label={'Code postal, ville : (Ex: 75011 PARIS)'}
                                            changeValue={changeValue}
                                            id={'address'}
                                            myDefaultValue={state.defaultValuePostalCodeCity}
                                        />
                                    </>
                                }

                                {!sansStationnement &&
                                    <>
                                        <Field
                                            component={CheckboxFieldRedux}
                                            name={'new_adress_is_new_lieuStationnement'}
                                            label={'Cette adresse est également l\'adresse de stationnement.'}
                                            onChange={() => setState({ ...state, stationnement: !state.stationnement })}
                                            normalize={normalizeBooleanForNoobBelair}
                                        />

                                        {
                                            !state.stationnement && <PostalCodeCity
                                                nameCodePostal='cp_new_lieuStationnement'
                                                nameVille='ville_new_lieuStationnement'
                                                label={'Code postal, ville de stationnement : (si différent de l\'adresse postale)'}
                                                changeValue={changeValue}
                                                id={'lieuStationnement'}
                                            />
                                        }
                                    </>
                                }

                                {
                                    loading
                                        ? <Spinner />
                                        : canValidate &&
                                        <button
                                            className={`btn btn-primary btn-block ${state.adresseNewIsTooLong && 'disabled'}`}
                                            type={'submit'}
                                            onClick={(e) => state.adresseNewIsTooLong ? e.preventDefault() : null}>
                                            Je valide la nouvelle adresse
                                        </button>
                                }

                                {
                                    contracts.total > 1 && <div className="alert alert-warning mt-3 mb-0" role="alert">
                                        <p>Attention, cette modification d'adresse ne concerne que le contrat N°{contract.numeroPolice}.</p>
                                        <p>{contract.title}.</p>
                                        <p className={'mt-1'}>Pour modifier l'adresse des autres contrats, merci de faire la modification d'adresse pour chaque contrat.</p>
                                    </div>
                                }
                            </form>
                    }
                </div>
            </div>
        </div>
    );
};

export default ChangeAddress;
