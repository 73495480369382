import React from 'react'
import QuittanceCard from '../../../../Commun/Card/QuittanceCard'
import moment from "moment";

const Quittances = (props) => {
    const {
        quittances,
        publish_url,
        contractId,
        tiers,
        postPayReceipt,
        postLogActionEditDocument,
        roles,
        situation,
        dernierCourrierCV
    } = props

    return (
        <div className={'row margin-top'}>
            <div className={'col'}>
                <div className={'bloc-info bloc-quittances'}>
                    <h2>Liste des quittances</h2>
                    <div className={'content row'}>
                        {
                            dernierCourrierCV
                            &&
                            <p className={'col-12 text-right text-danger font-weight-bold'}>La dernière carte verte par
                                courrier a été envoyée le {moment(dernierCourrierCV).format('L')}</p>
                        }
                        {quittances.length >= 1 ?
                            <table className="table">
                                <thead>
                                <tr>
                                    <th scope="col">Période</th>
                                    <th scope="col">N° Pièce</th>
                                    <th scope="col">Type</th>
                                    <th scope="col">Libellé</th>
                                    <th scope="col">Montant</th>
                                    <th scope="col">Frais</th>
                                    <th scope="col">Solde restant</th>
                                    <th>Document</th>
                                    <th scope="col">Payer</th>
                                    <th>Détails</th>
                                </tr>
                                </thead>
                                <tbody>
                                {quittances.map((quittance, index) =>
                                    <QuittanceCard
                                        quittance={quittance}
                                        postPayReceipt={postPayReceipt}
                                        postLogActionEditDocument={postLogActionEditDocument}
                                        publishUrl={publish_url}
                                        contractId={contractId}
                                        tiersId={tiers.id}
                                        roles={roles}
                                        situation={situation}
                                        key={index}
                                    />
                                )}
                                </tbody>
                            </table>
                            :
                            <p className={'no-result'}>Il n'y a pas de quittances sur le contrat</p>
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Quittances
