
import {API_ERROR, API_SUCCESS, apiRequest} from '../../../actions/core/api/api.actions'
import {setError, setLoader} from '../../../actions/app/ui/ui.actions'
import {setNotification} from "../../../actions/core/notifications/notifications.actions";
import {POST_PAY_RECEIPT, PAY_RECEIPT, setPayReceipt} from '../../../actions/app/quittances/quittance.actions'
import {GET_QUITTANCE, QUITTANCE, setQuittance} from "../../../actions/app/quittances/quittance.actions";

export const quittanceMiddleware = () => next => action => {
    next(action)

    const {payload} = action
    let message = null

    switch (action.type) {
        case POST_PAY_RECEIPT:
            next([
                apiRequest({
                    body: (action.payload.body),
                    method: 'POST',
                    url: action.payload.data,
                    entity: PAY_RECEIPT
                }),
                setLoader({state: true, entity: PAY_RECEIPT})
            ])
            break

        case `${PAY_RECEIPT} ${API_SUCCESS}`:
            message = `<p>Le mail avec le lien de paiement a bien été envoyé au client</p>`
            next([
                setPayReceipt({state: true}),
                setNotification({entity: PAY_RECEIPT, body: message, type: 'success', title: 'Mail envoyé !'}),
                setLoader({state: false, entity: PAY_RECEIPT}),
                setError({state: false, entity: PAY_RECEIPT}),
            ])
            break

        case `${PAY_RECEIPT} ${API_ERROR}`:
            message = `
                <p>Votre demande n'a pas pu être envoyée. ${payload.data.response.data.reason}</p>
            `
            next([
                setNotification({
                    entity: PAY_RECEIPT,
                    body: message,
                    type: 'error',
                    title: 'Erreur lors de l\'envoi du mail !',
                    timeout: '0'
                }),
                setError({state: true, entity: PAY_RECEIPT}),
                setLoader({state: false, entity: PAY_RECEIPT}),
            ])
            break

        case GET_QUITTANCE:
            next([
                apiRequest({body: null, method: 'GET', url: action.payload.data, entity: QUITTANCE}),
                setLoader({state: true, entity: QUITTANCE})
            ])
            break

        case `${QUITTANCE} ${API_SUCCESS}`:
            const {details, tableau} = action.payload.data

            next([
                setQuittance({details, tableau}),
                setError({state: false, entity: QUITTANCE}),
                setLoader({state: false, entity: QUITTANCE})
            ])
            break

        case `${QUITTANCE} ${API_ERROR}`:
            next([
                setError({state: true, entity: QUITTANCE}),
                setLoader({state: false, entity: QUITTANCE})
            ])
            break

        default:
            break
    }
    return null
}
