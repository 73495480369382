import {setTaskPrios, TASKPRIOS, GET_TASKPRIOS} from '../../../actions/app/taskprios/tasks.actions'
import {API_ERROR, API_SUCCESS, apiRequest} from '../../../actions/core/api/api.actions'
import {setError, setLoader} from '../../../actions/app/ui/ui.actions'
import _ from 'lodash'

export const taskPriosMiddleware = () => next => action => {
    next(action)

    switch (action.type) {

        case GET_TASKPRIOS:
            const {filters} = action.payload

            let filter = '';
            if (_.includes(window.location.pathname, 'mes-taches-prio')) {
                filter += `portefeuille=${filters["portefeuille"]}`
                filter += `&date_start=${filters["date_start"]}`
                filter += `&date_end=${filters["date_end"]}`
                filter += `&score=${filters["score"]}`
            }

            let url = `${action.payload.data}?${filter}`

            next([
                apiRequest({body: null, method: 'GET', url, entity: TASKPRIOS}),
                setLoader({state: true, entity: TASKPRIOS})
            ])
            break

        case `${TASKPRIOS} ${API_ERROR}`:
            next([
                setError({state: true, entity: TASKPRIOS}),
                setLoader({state: false, entity: TASKPRIOS})
            ])
            break

        case `${TASKPRIOS} ${API_SUCCESS}`:
            const {data, total} = action.payload.data

            next([
                setTaskPrios({data, total}),
                setError({state: false, entity: TASKPRIOS}),
                setLoader({state: false, entity: TASKPRIOS})
            ])
            break
        default:
            break
    }
    return null
}
