import React, {Component, Fragment} from 'react';
import {Field} from 'redux-form'
import _ from 'lodash'
import {getCompagnys} from "../../../../../../../../utils/function";
import TextFieldRedux from "../../../../../../../Commun/Input/Text/TextFieldRedux";
import RadioFieldRedux from "../../../../../../../Commun/Input/Radio/RadioFieldRedux";
import PostalCodeCity from "../../../../../../../Commun/PostalCodeCity/PostalCodeCity";
import FilteredAutocomplete from "../../../../../../../Commun/Forms/FilteredAutocomplete/FilteredAutocomplete";
import {codeCompagnys} from "../../../../../../../../utils/optionsSelect";

class Adversaires extends Component {
    constructor(props) {
        super(props)
        this.state = {
            compagnys: []
        }
    }

    componentDidMount() {
        this.fetchCompagnys()
    }

    async fetchCompagnys() {
        let compagnys = await getCompagnys()

        const listcompanys = compagnys.map(c => (
            {
                value: c.id,
                codegta: c.codegta,
                label: c.id + ' - ' + c.name + ' - ' + c.adresse + ' - ' + c.codePostal + ' ' + c.ville
            }
        ))

        this.setState({
            compagnys: listcompanys
        })
    }

    render() {
        const {titre, adresseT, changeValue} = this.props
        const {compagnys} = this.state

        return (
            <div className="col" style={{marginBottom: 30}}>
                <div className={`form-content h-100`}>
                    <h1 className={'title-primary'}>Informations de l'adversaire</h1>

                    <Field
                        name={`adversaires.0.immat`}
                        component={TextFieldRedux}
                        label='Immatriculation'
                    />

                    <Field
                        name={`adversaires.0.police`}
                        component={TextFieldRedux}
                        label="Numéro de contrat d'assurance"
                    />

                    <FilteredAutocomplete
                        name={`adversaires.0.cie`}
                        label="Compagnie d'assurance"
                        labelOtherValue="Autre compagnie"
                        dataFiltered={_.filter(compagnys, c => _.includes(codeCompagnys, c.value))}
                        data={compagnys}
                        onChange={(value) => { changeValue('codegta', _.find(compagnys, ['value', value])?.codegta)}}
                        changeValue={changeValue}
                        required
                        />

                    <Field
                        name={`adversaires.0.titre`}
                        component={RadioFieldRedux}
                        label={'Civilité'}
                        data={[
                            {value: "M", label: "Monsieur"},
                            {value: "Mme", label: "Madame"},
                            {value: "Societe", label: "Société"}
                        ]}
                        required
                    />

                    <Field
                        name={`adversaires.0.nom`}
                        component={TextFieldRedux}
                        normalize={v => v.toUpperCase()}
                        label="Nom"
                    />

                    {(titre === 'M' || titre === 'Mme') &&
                        <Field
                            name={`adversaires.0.prenom`}
                            component={TextFieldRedux}
                            normalize={v => v.toUpperCase()}
                            label="Prénom"
                        />
                    }

                    <Field
                        name={`adverse_adresse`}
                        component={RadioFieldRedux}
                        label={'Avez-vous son adresse ?'}
                        data={[
                            {value: "T", label: "Oui"},
                            {value: "F", label: "Non"}
                        ]}
                    />

                    {adresseT === 'T' && <Fragment>
                        <Field
                            name={`adversaires.0.adresse1`}
                            component={TextFieldRedux}
                            label="Adresse"
                        />

                        <PostalCodeCity
                            nameCodePostal={`adversaires.0.cp`}
                            nameVille={`adversaires.0.ville`}
                            label={'Ville de résidence'}
                            changeValue={changeValue}
                        />

                    </Fragment>
                    }

                    <div className="alert alert-warning" role="alert" style={{margin: '40px 0 20px 0'}}>
                        Attention, pour finaliser l'ouverture du sinistre, vous devez renseigner la compagnie, le nom et
                        l'immatriculation du Tiers adverse.<br/><br/>Si vous n'avez pas l'ensemble de ces informations,
                        le sinistre s'importera en situation "En attente"
                    </div>
                </div>
            </div>
        );
    }
};

export default Adversaires;
