import {connect} from 'react-redux';
import {compose} from 'redux';
import _ from 'lodash';
import AvenantSimple from "./AvenantSimple";
import {CONTRACT, getContract} from "../../../../../redux/actions/app/contracts/contracts.actions";
import {contractDefaultGetLink} from "../../../../../redux/selectors/links/links.selectors";
import {getContractData} from "../../../../../redux/selectors/contract/contract.selectors";
import {getErrorEntity, getLoadingEntity} from "../../../../../redux/selectors/ui/ui.selectors";
import loader from "../../../../Commun/Spinner/Loader/Loader";

const mapStateToProps = state => {

    return {
        contract: getContractData(state),
        query: contractDefaultGetLink(state),
        loaded: getLoadingEntity(state, CONTRACT) === false,
        error: getErrorEntity(state, CONTRACT)
    }
}

const mapDispatchToProps = {
    getContract
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {id} = ownProps.match.params;
    const {query} = stateProps;
    const {getContract} = dispatchProps;

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => getContract({query: _.replace(query, '{id}', id)})
    }
}

const AvenantSimpleContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader()
)(AvenantSimple);

export default AvenantSimpleContainer
