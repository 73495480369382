import React from 'react';
import Spinner from "../../../../../Commun/Spinner/Spinner";
import {Field} from "redux-form";
import SelectFieldRedux from "../../../../../Commun/Input/Select/SelectFieldRedux";
import DatePickerFieldRedux from "../../../../../Commun/Input/DatePicker/DatePickerFieldRedux";
import moment from "moment";
import PostalCodeCity from "../../../../../Commun/PostalCodeCity/PostalCodeCity";
import TextAreaFieldRedux from "../../../../../Commun/Input/Text/TextAreaFieldRedux";
import CheckboxFieldRedux from "../../../../../Commun/Input/Checkbox/CheckboxFieldRedux";
import {categorieSin, idas, paysSinistre} from "../../../../../../utils/optionsSelect";
import RadioFieldRedux from "../../../../../Commun/Input/Radio/RadioFieldRedux";


const FormUpdateAccident = (props) => {
    const {handleSubmit, loading, changeValue, defaultValueCodepostalCity, pays_lieu_sinistre} = props

    return (
        <div>
            <form className={`form-content`} onSubmit={handleSubmit} onKeyPress={event => {
                if (event.which === 13 /* Enter */) {
                    event.preventDefault();
                }
            }}>
                <div className="row">
                    <div className="col">
                        <Field
                            name='catsin'
                            component={SelectFieldRedux}
                            label={'Catégorie du sinistre'}
                        >
                            {categorieSin.map((cat, index) => (
                                <option key={index} value={cat.code}>{cat.libelle}</option>
                            ))}
                        </Field>
                    </div>
                    <div className="col">
                        <Field
                            name={"dateSurv"}
                            component={DatePickerFieldRedux}
                            label={'Date de survenance du sinistre'}
                            maxDate={moment()}
                        />
                    </div>
                </div>

                <Field
                    name='pays_lieu_sinistre'
                    component={RadioFieldRedux}
                    label={'Le sinistre a-t-il eu lieu dans un pays étranger ?'}
                    data={[{value: "T", label: "Oui"}, {value: "F", label: "Non"}]}
                    onChange={() => changeValue('lieu', "")}
                />

                {
                    pays_lieu_sinistre === "T" &&
                    <Field
                        name='paysdefaultValueCountry'
                        component={SelectFieldRedux}
                        label={'Pays où le sinistre au eu lieu'}
                        input={{
                            onChange: (e) => {
                                changeValue('lieu', e.target.value)
                                changeValue('code_postal', "99000")
                            }
                        }}
                    >
                        <option value={""}>Choisissez un pays</option>
                        {
                            paysSinistre.map((pays, index) =>
                                <option key={index} value={pays.value}>{pays.label}</option>
                            )
                        }
                    </Field>
                }

                {
                    pays_lieu_sinistre === "F" && <PostalCodeCity
                        nameCodePostal='code_postal'
                        nameVille='lieu'
                        label={'Lieu du sinistre'}
                        changeValue={changeValue}
                        id={'address'}
                        defaultValue={defaultValueCodepostalCity}
                    />
                }
                <Field
                    name='circonstances'
                    component={TextAreaFieldRedux}
                    label={'Circonstances'}
                    maxLength={'120'}
                    subLabel={'120 caractères maximum'}
                />
                <Field
                    name='decDommages'
                    component={TextAreaFieldRedux}
                    label={'Dommages déclarés'}
                    maxLength={'120'}
                    subLabel={'120 caractères maximum'}
                />
                <div className="row">
                    <div className="col-12 col-md-6">
                        <Field
                            component={SelectFieldRedux}
                            name='tauxResp'
                            label={'Responsabilité'}
                        >
                            <option>Sélectionnez un taux de responsabilité</option>
                            <option value="0">0%</option>
                            <option value="25">25%</option>
                            <option value="50">50%</option>
                            <option value="75">75%</option>
                            <option value="100">100%</option>
                        </Field>
                    </div>
                    <div className="col-12 col-md-6">
                        <Field
                            name='ida'
                            component={SelectFieldRedux}
                            label={'IDA'}
                        >
                            <option>Sélectionnez une IDA</option>
                            {idas.map((ida, index) => (
                                <option key={index} value={ida.code}>{ida.libelle}</option>
                            ))}
                        </Field>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12 col-md-6">
                        <Field
                            component={CheckboxFieldRedux}
                            name={'corporel'}
                            label={'Corporel'}
                            type={'checkbox'}
                        />
                    </div>
                </div>
                {
                    loading ? <Spinner style={{display: 'block', margin: 'auto'}}/> :
                        <button className={'btn btn-primary d-block m-auto'} type={'submit'}>
                            Mettre à jour le sinistre
                        </button>
                }
            </form>
        </div>
    );
};

export default FormUpdateAccident;
