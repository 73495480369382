import React, {Component} from 'react'
import AddDocumentContainer from "../../Forms/AddDocument/AddDocumentContainer";
import './documents.scss';
import DocumentItemContainer from "./DocumentItem/DocumentItemContainer";
import {redirectInNewWindow} from "../../../../../utils/function";
import {listMailJet, openInWindow} from "../../../../../constants/constants";
import _ from "lodash";
import DocumentItemLight from "./DocumentItem/DocumentItemLight";

class Documents extends Component {
    constructor(props) {
        super(props)
        this.state = {
            addDoc: false
        }
    }

    handleAddDoc() {
        this.setState({
            addDoc: !this.state.addDoc
        })
    }

    render() {
        const {documents, contractId, tiersId, risqueNum, type, logAction, idSinistre, roles} = this.props
        const {addDoc} = this.state

        return (
            <div className={'bloc-info bloc-documents'}>
                <h2>Base documentaire</h2>
                {!_.includes(roles, 'gestionnaire televente') && <div className={'call-to-action-bloc-info'}>
                    {
                        type !== 'sinistre'
                            ? <span className={'btn btn-cta btn-email float-right'}
                                    onClick={(e) => redirectInNewWindow({
                                        e,
                                        url: `/envoyer-email/pmq/${listMailJet.pmq}/${tiersId}/${contractId}${idSinistre ? `/${idSinistre}` : ''}`,
                                        name: openInWindow.email
                                    })}>Envoyer par email</span>
                            : <span className={'btn btn-cta btn-email float-right'}
                                    onClick={(e) => redirectInNewWindow({
                                        e,
                                        url: `/envoyer-email/pmq-accident/${listMailJet.pmqAccident}/${tiersId}/${contractId}/${idSinistre}`,
                                        name: openInWindow.email
                                    })}>Envoyer par email</span>
                    }
                </div>}

                <div className={'content row'}>
                    <table className="table">
                        <thead>
                        <tr>
                            <th scope="col">Document</th>
                            <th scope="col">Commentaire</th>
                            {
                                type === 'sinistre' &&
                                <th scope="col">Date de création</th>
                            }
                            <th scope="col" className={'text-center'}>Recu</th>
                            <th scope="col" className={'text-center'}>Traité</th>
                            <th scope="col">Détails</th>
                            <th scope="col">&nbsp;</th>
                        </tr>
                        </thead>
                        <tbody>
                        {documents.map((document, index) =>
                            !_.includes(roles, 'gestionnaire televente') ?
                                <DocumentItemContainer roles={roles} logAction={logAction} document={document}
                                                       key={index}
                                                       form={`document-${document.code}-${document.adhesion}`}
                                                       type={type}/>
                                : <DocumentItemLight logAction={logAction} document={document} key={index}
                                                     form={`document-${document.code}-${document.adhesion}`}
                                                     type={type}/>
                        )}
                        </tbody>
                    </table>
                    {!_.includes(roles, 'gestionnaire televente') && <div className={'col-12'}>
                        {addDoc && <AddDocumentContainer risqueNum={risqueNum} contractId={contractId} tiersId={tiersId}
                                                         type={type} sinistre={idSinistre}/>}
                        {!addDoc && <button
                            className={'no-background'}
                            onClick={() => this.handleAddDoc()}
                        >
                            + Ajouter un document attendu
                        </button>}
                    </div>}
                </div>
            </div>
        )
    }
}

export default Documents
