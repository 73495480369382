import React from 'react'
import {withRouter} from "react-router"
import _ from "lodash";

const TaskPrioCard = (props) => {
    const {taskprio, history, url, getTaskPrio} = props

    const redirect = (taskprio) => {
        let url_fu = _.replace(_.replace(url, '{contrat}', taskprio.contrat), '{tiers}', taskprio.numtiers)
        getTaskPrio({query: url_fu})
        history.push(`/contrat/${taskprio.contrat}`)
    }

    return (
        <div className={`col-4 card-taskprio`}>
            <div className={`content`}>
                <div onClick={() => redirect(taskprio)}>
                    <h3 className={'border-bottom'}>{taskprio.rsociale}&nbsp;{taskprio.numtiers}</h3>
                    <div>
                        <p className={'text-uppercase'}>{taskprio.police || taskprio.portefeuille}</p>
                    </div>
                </div>
            </div>
        </div>
    )
}


export default withRouter(TaskPrioCard)
