import React, {Component} from 'react';
import FlotteListContainer from "./FlotteList/FlotteListContainer";
import Loupe from '../../../../../assets/img/icons/loupe_dark.png'
import {formatImmatriculation} from "../../../../../utils/function";
import DocumentsContainer from "../Documents/DocumentsContainer";
import ReclamationsContainer from "../../Reclamations/ReclamationsContainer";
import FlotteAccidentsContainer from "./FlotteAccidents/FlotteAccidentsContainer";

class FlotteContent extends Component {
    constructor(props) {
        super(props)
        this.textInput = React.createRef();
        this.state = {
            query: this.props.uri,
            sort: '',
            active: '',
            immat: ''
        }
    }

    handleChangeSort = (value, field) => {
        let newValue = formatImmatriculation(value.replaceAll(' ', ''))

        this.setState({[field]: newValue})
        this.props.getFlotte({
            ...this.state,
            [field]: newValue,
            contrat: this.props.contractId
        })
    }

    handleChangePage = (query) => {
        this.props.getFlotte({
            query: query,
            sort: this.state.sort,
            contrat: this.props.contractId,
            immat: this.state.immat,
            active: this.state.active,
        })
    }

    render() {
        const {meta, links, contractId, contract, roles} = this.props

        return (
            <div className={'tasks-container'}>
                <div className={'row margin-top'}>
                    <div className={'col'}>
                        <div className={'bloc-info bloc-vehicules'}>
                            <div className={'row'}>
                                <h2>Liste des véhicules</h2>
                                <div className={'col-12 filters'}>
                                    <div className={'content d-flex'}>
                                        <select
                                            name="sort"
                                            onChange={(e) => this.handleChangeSort(e.target.value, 'sort')}
                                            className={'small-select select-sort-tasks custom-select'}
                                            value={this.state.sort}
                                        >
                                            <option value={''}>Adhesion</option>
                                            <option value={'date'}>Mise en circulation croissant</option>
                                            <option value={'-date'}>Mise en circulation décroissant</option>
                                        </select>
                                        <div className="input-group w-auto">
                                            <input type="text"
                                                   className="form-control small-input align-top mr-0"
                                                   placeholder="Immatriculation"
                                                   style={{height: 32}}
                                                   ref={this.textInput}
                                                   onKeyDown={(e) => e.key === 'Enter' && this.handleChangeSort(this.textInput.current.value.toUpperCase(), 'immat')}
                                                   onChange={(e) => e.target.value.length === 0 && this.handleChangeSort('', 'immat')}
                                            />
                                            <div style={{height: 32}} className="input-group-append pointer"
                                                 onClick={() => this.handleChangeSort(this.textInput.current.value.toUpperCase(), 'immat')}>
                                                <span className="input-group-text"><img alt='icon' src={Loupe} className={'pt-1 align-top'} style={{width: 20}}/></span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={'row'}>
                                <div className={'col'}>
                                    <FlotteListContainer className='loader' contractId={contractId}/>
                                </div>
                            </div>
                            <div className={'row'}>
                                <nav>
                                    <p>{meta.to}/{meta.total} véhicules</p>
                                    <ul className="pagination">
                                        {meta.current_page > 2 && <li className="page-item"
                                                                      onClick={() => this.handleChangePage(links.first)}>
                                            <span className="page-link">{`<<`}</span></li>}
                                        {meta.current_page > 1 &&
                                            <li className="page-item" onClick={() => this.handleChangePage(links.prev)}>
                                                <span className="page-link"> {`<`} </span></li>}
                                        <li className="page-item"><span
                                            className={`page-link current`}>{meta.current_page}</span></li>
                                        {meta.last_page > meta.current_page &&
                                            <li className="page-item" onClick={() => this.handleChangePage(links.next)}>
                                                <span className="page-link">{`>`}</span></li>}
                                        {meta.last_page >= meta.current_page + 2 &&
                                            <li className="page-item" onClick={() => this.handleChangePage(links.last)}>
                                                <span className="page-link">{`>>`}</span></li>}
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={'row margin-top'}>
                    <div className={'col'}>
                        <DocumentsContainer className={'loader'} contractId={contract.id}
                                            tiersId={contract.souscripteur.id} risqueNum={contract.adhesionPrincipale}
                                            roles={roles}/>
                    </div>
                </div>
                <div className="row margin-top">
                    <div className="col">
                        <ReclamationsContainer className={'loader'} id={contract.souscripteur.id}/>
                    </div>
                </div>
                <div className={'row margin-top'}>
                    <div className={'col'}>
                        <FlotteAccidentsContainer className={'loader'} contract={contract}/>
                    </div>
                </div>
            </div>
        );
    }
}

export default FlotteContent;
