import _ from 'lodash';
;
import { DELETE_DEROGATIONS, DEROGATIONS, GET_DEROGATIONS, POST_DEROGATIONS, setDerogations } from '../../../actions/app/derogations/derogations.actions';
import { setError, setLoader } from '../../../actions/app/ui/ui.actions';
import { API_ERROR, API_SUCCESS, apiRequest } from '../../../actions/core/api/api.actions';
import { setNotification } from '../../../actions/core/notifications/notifications.actions';
export const derogationsMiddleware = () => (next) => (action) => {
    next(action);
    let clone = null;

    switch (action.type) {
        case GET_DEROGATIONS:
            next([apiRequest({ body: null, method: 'GET', url: action.payload.data, entity: DEROGATIONS }), setLoader({ state: true, entity: DEROGATIONS })]);
            break;

        case `${DEROGATIONS} ${API_SUCCESS}`:
            next([setDerogations({ derogations: action.payload.data }), setError({ state: false, entity: DEROGATIONS }), setLoader({ state: false, entity: DEROGATIONS })]);
            break;

        case `${DEROGATIONS} ${API_ERROR}`:
            next([setError({ state: true, entity: DEROGATIONS }), setLoader({ state: false, entity: DEROGATIONS })]);
            break;

        case POST_DEROGATIONS:
            clone = _.cloneDeep(action.payload.body);
            clone.date = clone.date.format('YYYY-MM-DD HH:mm:ss');

            next([
                apiRequest({ body: (clone), method: 'POST', url: action.payload.data, entity: POST_DEROGATIONS, otherData: action.payload.otherData }),
                setLoader({ state: true, entity: POST_DEROGATIONS }),
            ]);
            break;

        case `${POST_DEROGATIONS} ${API_SUCCESS}`:
            next([
                setNotification({ title: 'Dérogation/Fidélisation ajoutée avec succès', type: 'success', entity: POST_DEROGATIONS }),
                setError({ state: false, entity: POST_DEROGATIONS }),
                setLoader({ state: false, entity: POST_DEROGATIONS }),
            ]);
            break;

        case `${POST_DEROGATIONS} ${API_ERROR}`:
            next([
                setNotification({
                    entity: POST_DEROGATIONS,
                    body: action.payload.data.response.data.reason,
                    type: 'error',
                    title: 'Une erreur est survenue.',
                    timeout: '0'
                }),
                setError({ state: true, entity: POST_DEROGATIONS }),
                setLoader({ state: false, entity: POST_DEROGATIONS })
            ]);
            break;

        case DELETE_DEROGATIONS:
            next([
                apiRequest({ body: (action.payload.body), method: 'POST', url: action.payload.data, entity: DELETE_DEROGATIONS }),
                setLoader({ state: true, entity: DELETE_DEROGATIONS }),
            ]);
            break;

        case `${DELETE_DEROGATIONS} ${API_SUCCESS}`:
            next([
                setNotification({ title: 'Dérogation/Fidélisation supprimée avec succès', type: 'success', entity: DELETE_DEROGATIONS }),
                setError({ state: false, entity: DELETE_DEROGATIONS }),
                setLoader({ state: false, entity: DELETE_DEROGATIONS }),
            ]);
            break;

        case `${DELETE_DEROGATIONS} ${API_ERROR}`:
            next([setError({ state: true, entity: DELETE_DEROGATIONS }), setLoader({ state: false, entity: DELETE_DEROGATIONS })]);
            break;

        default:
            break;
    }
    return null;
};
