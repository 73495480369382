
import {API_ERROR, API_SUCCESS, apiRequest} from '../../../actions/core/api/api.actions';
import {setError, setLoader} from '../../../actions/app/ui/ui.actions';
import {setNotification} from "../../../actions/core/notifications/notifications.actions";
import {POST_CHEQUES_CADEAUX} from "../../../actions/app/cheques_cadeaux/cheques_cadeaux.actions";
import {setTiers} from "../../../actions/app/tiers/tiers.actions";

export const chequesCadeauxMiddleware = () => next => action => {
    next(action)

    const {payload} = action
    let message;

    switch (action.type) {

        case POST_CHEQUES_CADEAUX:
            next([
                apiRequest({
                    body: (payload.body),
                    method: 'POST',
                    url: payload.data,
                    entity: POST_CHEQUES_CADEAUX
                }),
                setLoader({state: true, entity: POST_CHEQUES_CADEAUX})
            ])
            break

        case `${POST_CHEQUES_CADEAUX} ${API_SUCCESS}`:
            message = `<p>La modification a bien été enregistrée.</p>`

            next([
                setTiers({data: payload.data}),
                setError({state: false, entity: POST_CHEQUES_CADEAUX}),
                setLoader({state: false, entity: POST_CHEQUES_CADEAUX}),
                setNotification({
                    entity: POST_CHEQUES_CADEAUX,
                    body: message,
                    type: 'success',
                    title: "Modification enregistrée !"
                })
            ])

            break

        case `${POST_CHEQUES_CADEAUX} ${API_ERROR}`:
            message = `<p>${payload.data.response.data.reason}</p>`

            next([
                setError({state: true, entity: POST_CHEQUES_CADEAUX}),
                setLoader({state: false, entity: POST_CHEQUES_CADEAUX}),
                setNotification({
                    entity: POST_CHEQUES_CADEAUX,
                    body: message,
                    type: 'error',
                    title: 'Une erreur est survenue !',
                    timeout: '0'
                })

            ])
            break

        default:
            break
    }
    return null
}
