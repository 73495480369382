const validate = values => {
    const errors = {}

    const requiredFields = [
        /* Déclaration */
        'catsinorigin',
        'indentify',
        'datesurvorigin',
        'lieu',
        'conduct',
        'corpoconduct',
        'temoin',
        'vehicule',
        'vehiculeDommage',
        'commentaire',
        'constat_accident',
        'intervention_police',
        'reception_constat',
        'dommagesDeclares',
        'commentaire2',
        'accord_ida'
    ]

    requiredFields.forEach(field => {
        if (!values[field]) {
            errors[field] = 'Champ obligatoire'
        }
    })

    return errors
}

export default validate
