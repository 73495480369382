import {connect} from 'react-redux'
import DeclarationAccidentNavigation from "./DeclarationAccidentNavigation";
import {bindActionCreators, compose} from "redux";
import {CONTRACT, getContract} from "../../../../../redux/actions/app/contracts/contracts.actions";
import _ from "lodash";
import ErrorHttpComponent from "../../../../Commun/Error/ErrorHttpComponent";
import {getContractData} from "../../../../../redux/selectors/contract/contract.selectors";
import {contractDefaultGetLink} from "../../../../../redux/selectors/links/links.selectors";
import {getErrorEntity, getLoadingEntity} from "../../../../../redux/selectors/ui/ui.selectors";
import {getUserIdBelair, getUserPermissions, getUserRoles} from "../../../../../redux/selectors/user/user.selectors";
import loader from "../../../../Commun/Spinner/Loader/Loader";

const mapStateToProps = (state, ownProps) => {
    const contract = getContractData(state);

    return {
        roles: getUserRoles(state),
        linksDisabled: contract && (!contract.accident_claim_link || contract.situation.statutCode === 'A' || !_.includes(getUserPermissions(state), 'create accident')),
        contract: contract,
        idContract: ownProps.match.params.id,
        idVehicule: ownProps.match.params.idVehicule || '',
        uri: contractDefaultGetLink(state),
        loaded: getLoadingEntity(state, CONTRACT) === false,
        error: getErrorEntity(state, CONTRACT),
        belairId: getUserIdBelair(state)
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    getContract: ({query, id}) => getContract({query, id}),
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {getContract} = dispatchProps
    const {uri, idContract} = stateProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => getContract({query: _.replace(uri, '{id}', idContract), id: idContract}),
    }
}

const DeclarationAccidentNavigationContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader({ErrorIndicator: ErrorHttpComponent})
)(DeclarationAccidentNavigation)

export default DeclarationAccidentNavigationContainer
