import React from 'react'
import moment from "moment";
import {withRouter} from "react-router"
import {openInWindow} from "../../../constants/constants";

const VirementCard = (props) => {
    const {virement, history, idBelair} = props
    const {
        montant,
        tiers,
        contrat_id,
        sinistre,
        typeAccident,
        created_at,
        dateOuverture,
        numCbt,
        contrat,
        ordre,
        realisateur
    } = virement
    const isSameReal = parseInt(realisateur) === idBelair

    const redirect = () => {
        if (sinistre && !isSameReal) {
            window.open(
                `${window.location.origin}/virements/${tiers.id}/${sinistre}/${ordre}`,
                openInWindow.task, 'toolbar=0,location=0,menubar=0,width=1470,height=670,resizable,scrollbars=yes,status=1,top=100'
            )
            history.push({
                pathname: `/contrat/${contrat_id}/sinistre/${sinistre}`
            })
        } else if(!isSameReal) {
            window.open(
                `${window.location.origin}/virements/${contrat_id}`,
                openInWindow.task, 'toolbar=0,location=0,menubar=0,width=1470,height=670,resizable,scrollbars=yes,status=1,top=100'
            )
        }
    }

    return (
        <div className={`card-virement ${isSameReal && 'card-task-grey'}`} onClick={() => redirect()}>
            <div className={"row"}>
                <div className={`task-info col-5`}>
                    <h2>Réglement du {moment(created_at).format('L')}</h2>
                    <p>D'un montant de <strong>{montant}€</strong></p>
                </div>
                <div className={`task-tiers col-3`}>
                    <p className={'text-uppercase'}>{tiers.titre} {tiers.nom}</p>
                    <h3 className={'border-bottom'}>{tiers.id}</h3> {tiers.type && '-'} <h3>{tiers.type}</h3>
                </div>
                <div className={`task-contrat col-4`}>
                    <div>
                        <div className={'display-inline'}>
                            <p className={'text-uppercase'}>{typeAccident} ouvert
                                le {moment(dateOuverture).format('L')}</p>
                            <h3 className={'border-bottom'}>{numCbt}</h3>
                            <p>{contrat.libelle}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withRouter(VirementCard)
