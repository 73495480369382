import React, {Component, Fragment} from 'react'
import moment from "moment";
import MyButton from "../../../../Commun/Button/Button";
import {Link} from "react-router-dom";
import CallToAction from "../../../../Commun/Button/CallToAction/CallToAction";
import _ from "lodash";
import {redirectInNewWindow} from "../../../../../utils/function";
import {listMailJet, openInWindow} from "../../../../../constants/constants";

export default class Contrat extends Component {
    constructor(props) {
        super(props);
        this.state = {
            apporteur1: false,
            apporteur2: false,
        }
        this.toggleDetailApp1 = this.toggleDetailApp1.bind(this);
        this.toggleDetailApp2 = this.toggleDetailApp2.bind(this);
    }

    toggleDetailApp1() {
        this.setState({apporteur1: !this.state.apporteur1})
    }

    toggleDetailApp2() {
        this.setState({apporteur2: !this.state.apporteur2})
    }

    render() {
        const {contrat, show = this.state.apporteur1, show2 = this.state.apporteur2} = this.props

        return (
            <div className={'bloc-info bloc-contrat'}>
                <h2>Situation du contrat</h2>
                <div className={'content'}>
                    <div className={'row'}>
                        <div className={'col-5 left-content'}>
                            {contrat.hivernages[0] && contrat.hivernages[0].statutCode === '1' &&
                                <p>Ma période hivernale&nbsp;:</p>
                            }
                            {contrat.situation.dateFin &&
                                <Fragment>
                                    <p>Résilié le&nbsp;:</p>
                                    <p>Motif de résiliation&nbsp;:</p>
                                    {(((contrat.situation.motifCode === '5' && (moment(moment().format("YYYY-MM-DD")).diff(moment(contrat.situation.dateFin).format("YYYY-MM-DD"), 'days') < 90))))
                                        && <p>Lien vers iQera :</p>
                                    }
                                </Fragment>
                            }
                            <p>Echéance principale&nbsp;:</p>
                            <p>Date d'affaire nouvelle&nbsp;:</p>
                            <p>Fractionnement&nbsp;:</p>
                            {contrat.situation.jour_prel ? <p>Date de prélèvement&nbsp;:</p> : ''}
                            <p>Portefeuille&nbsp;:</p>
                            {contrat.apporteurs &&
                                <Fragment>
                                    <p>Apporteur 1&nbsp;:</p>
                                    <div className={`${!show ? 'hidden' : ''}`}>
                                        <p>Adresse&nbsp;:</p>
                                        {contrat.apporteurs.apporteur1.rue2 && <p>Complément d'adresse&nbsp;:</p>}
                                        <p>Code Postal - Ville&nbsp;:</p>
                                        <p>Téléphone&nbsp;:</p>
                                        <p>Email&nbsp;:</p>
                                    </div>
                                    <p>Taux de commission&nbsp;:</p>
                                    {contrat.apporteurs.apporteur2.nom && <p>Apporteur 2&nbsp;:</p>}
                                    {(contrat.apporteurs.apporteur2.rue1 || contrat.apporteurs.apporteur2.phone) &&
                                        <div className={`${!show2 ? 'hidden' : ''}`}>
                                            <p>Adresse&nbsp;:</p>
                                            {contrat.apporteurs.apporteur2.rue2 && <p>Complément d'adresse&nbsp;:</p>}
                                            <p>Code Postal - Ville&nbsp;:</p>
                                            <p>Téléphone&nbsp;:</p>
                                            <p>Email&nbsp;:</p>
                                        </div>}
                                    {contrat.apporteurs.apporteur2.tauxCommission && <p>Taux de commission&nbsp;:</p>}
                                    {contrat.date_paiement_cheque_cadeau && <p>Date de paiement du chèque cadeau&nbsp;:</p>}
                                </Fragment>
                            }
                            {contrat.apporteurs.apporteur1.id !== '43397' && <p>Adhérent service PMQ&nbsp;:</p>}
                            {contrat.realisateur && <p>Réalisateur&nbsp;:</p>}
                            <p>IBAN&nbsp;:</p>
                            <p>RUM&nbsp;:</p>
                            {contrat.banque.titulaire && <p>Titulaire du compte&nbsp;:</p>}
                            <p>Acompte contrat&nbsp;:</p>
                            { contrat.apporteurs.apporteur1.id === '43397' && <p>Assuré éligible fidélisation :</p>}
                            { contrat.avantage && <p>Avantage :</p>}
                        </div>
                        <div className={'col-7 right-content'}>
                            {contrat.hivernages[0] && contrat.hivernages[0].statutCode === '1' &&
                                <p>Activée jusqu’au {moment(contrat.hivernages[0].dateFin, 'YYYY-MM-DD').format('DD/MM/YYYY')}.</p>
                            }
                            {contrat.situation.dateFin &&
                                <Fragment>
                                    <p>{moment(contrat.situation.dateFin).format('L')}</p>
                                    <p>{contrat.situation.motif}</p>
                                    <p>{(((contrat.situation.motifCode === '5' && (moment(moment().format("YYYY-MM-DD")).diff(moment(contrat.situation.dateFin).format("YYYY-MM-DD"), 'days') < 90))))
                                        && <a href={`https://extranet-defficlic.iqera.app/LoginOrpheeAction.do`}
                                              target={'_blank'} rel="noopener noreferrer">Accès iQera</a>
                                    }</p>
                                </Fragment>
                            }
                            <p>{contrat.situation.echeance || '-'}</p>
                            <p>{moment(contrat.situation.dateAffaireNouvelle).format('L')} à {contrat.situation.heureAffaireNouvelle}</p>
                            <p className={`${contrat.situation.fractionnement && contrat.situation.fractionnement !== "Prélévement mensuel" ? 'error' : ''}`}>
                                {contrat.situation.fractionnement || '-'}
                                <CallToAction
                                    url={{
                                        pathname: `/contrat/${contrat.id}/changement-fractionnement`,
                                        state: {
                                            fractionnement: contrat.situation.fractionnement,
                                            prelevement: contrat.prelevement
                                        }
                                    }}
                                    action={'edit-note'}
                                    toolText={'Changer le fractionnement'}
                                    disabled={contrat.can_change_fractionnement === false}
                                    disabledReason={(contrat.situation.statutCode !== '1' && contrat.situation.statutCode !== '2' ? 'La modification du fractionnement n\'est possible que sur les contrats en cours ou en établissement' : contrat.can_change_fractionnement === false && 'Vous ne pouvez changer le fractionnement que 2 mois avant la date d\'échéance maximum.')}
                                />
                            </p>
                            {contrat.situation.jour_prel ? <p>Tous les {contrat.situation.jour_prel} du mois</p> : ''}
                            <p>{contrat.portefeuille || '-'}</p>
                            {contrat.apporteurs &&
                                <Fragment>
                                    <p className={'inline'}>
                                        {<Link to={`/tiers/${contrat.apporteurs.apporteur1.id}`}>
                                            {contrat.apporteurs.apporteur1.raisonSociale}
                                            {contrat.apporteurs.apporteur1.premium && ' - Premium'}
                                        </Link>}
                                        <MyButton
                                            className={'btn btn-rounded'}
                                            onClick={this.toggleDetailApp1}
                                        >
                                            {!show ? '+' : '-'}
                                        </MyButton>
                                    </p>
                                    <div className={`${!show ? 'hidden' : ''}`}>
                                        <p>{contrat.apporteurs.apporteur1.rue1}</p>
                                        {contrat.apporteurs.apporteur1.rue2 && <p>{contrat.apporteurs.apporteur1.rue2}</p>}
                                        <p>{contrat.apporteurs.apporteur1.codePostal} {contrat.apporteurs.apporteur1.ville}</p>
                                        <p>{contrat.apporteurs.apporteur1.phone || '-'}</p>
                                        <p>{contrat.apporteurs.apporteur1.email || '-'}</p>
                                    </div>
                                    <p>{contrat.apporteurs.apporteur1.tauxCommission || '-'}
                                        {contrat.apporteurs.apporteur1.fraisDossier > 0 && <Fragment>
                                            <span> / Frais de dossier :</span> {contrat.apporteurs.apporteur1.fraisDossier}€
                                        </Fragment>}</p>
                                    {contrat.apporteurs.apporteur2.nom &&
                                        <p className={'inline'}>
                                            <Link to={`/tiers/${contrat.apporteurs.apporteur2.id}`}>
                                                {contrat.apporteurs.apporteur2.nom}
                                                {contrat.apporteurs.apporteur2.premium && ' - Premium'}
                                            </Link>
                                            <MyButton
                                                className={'btn btn-rounded'}
                                                onClick={this.toggleDetailApp2}
                                            >
                                                {!show2 ? '+' : '-'}
                                            </MyButton>
                                        </p>
                                    }
                                    {contrat.apporteurs.apporteur2.nom &&
                                        <div className={`${!show2 ? 'hidden' : ''}`}>
                                            <p>{contrat.apporteurs.apporteur2.rue1}</p>
                                            {contrat.apporteurs.apporteur2.rue2 &&
                                                <p>{contrat.apporteurs.apporteur2.rue2}</p>}
                                            <p>{contrat.apporteurs.apporteur2.codePostal} {contrat.apporteurs.apporteur2.ville}</p>
                                            <p>{contrat.apporteurs.apporteur2.phone || '-'}</p>
                                            <p>{contrat.apporteurs.apporteur2.email || '-'}</p>
                                        </div>
                                    }
                                    {contrat.apporteurs.apporteur2.tauxCommission &&
                                        <p>{contrat.apporteurs.apporteur2.tauxCommission || '-'}</p>}
                                </Fragment>
                            }
                            {contrat.date_paiement_cheque_cadeau &&
                                <p className={'pb-3'}>{moment(contrat.date_paiement_cheque_cadeau).format('L')}</p>}
                            {contrat.apporteurs.apporteur1.id !== '43397' && <p>{contrat.apporteurs.apporteur1.adherent_pmq ? 'OUI' : contrat.apporteurs.apporteur1.adherent_pmq === null ? 'N\'a pas répondu' : 'NON'}</p>}
                            {contrat.realisateur && <p>{contrat.realisateur}</p>}
                            <p>{contrat.banque.iban || '-'}</p>
                            <p>{contrat.banque.rum || '-'}</p>
                            {contrat.banque.titulaire && <p>{contrat.banque.titulaire}</p>}
                            <p>{contrat.acompte}€</p>
                            {contrat.apporteurs.apporteur1.id === '43397' && (contrat.avenant_link_fid ? <p>OUI</p> : <p className="error">NON</p>)}
                            { contrat.avantage && <p>{contrat.avantage}</p>}
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
