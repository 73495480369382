import React, {useEffect, useState} from 'react';
import {Field} from "redux-form";
import RadioSimpleFieldRedux from "../../../../../../Commun/Input/RadioSimple/RadioSimpleFieldRedux";
import CheckboxFieldRedux from "../../../../../../Commun/Input/Checkbox/CheckboxFieldRedux";
import _ from "lodash";
import DetailsReglementCalculFranchiseContainer
    from "./DetailsReglementCalculFranchise/DetailsReglementCalculFranchiseContainer";
import Badge from "./Badge/Badge";
import BadgeRgt from "../../../../../../Commun/Badge/BadgeRgt";
import TextFieldRedux from "../../../../../../Commun/Input/Text/TextFieldRedux";
import moment from "moment";

const DetailsReglement = (props) => {
    const {
        changeValue,
        client,
        reparateurs,
        rapports_bca,
        accessoires,
        facturesCochees,
        rapportsExpertCoches,
        montant_penalite_franchise,
        franchise_reglee,
        setBeneficiaire,
        setStep,
        lese,
        hasDocFactureReparation,
        hasFactureReparation,
        hasDocFactureAntivolRecuTraite,
        oppositions,
        documents,
        beneficiaire,
        accident,
        penalites,
        hasFactureAntivol,
        penalite_ok,
        remboursement_franchise,
        montant_remboursement_franchise,
        titulaire_banque,
        blocageVerificationClientOk,
        hasFactureVehicule,
        montantFactureVehicule,
        contract,
        hasValMaj
    } = props
    const dateMEC = moment(contract.vehicule.dateMEC)
    const dateSurv = moment(accident.dateSurv)
    const isDateMECWithin12Months = dateSurv.diff(dateMEC, 'months') <= 12
    const isDateMECWithin12To24Months = dateSurv.diff(dateMEC, 'months') > 12 && dateSurv.diff(dateMEC, 'months') <= 24
    const isDateMECOver24Months = dateSurv.diff(dateMEC, 'months') > 24

    useEffect(() => {
        if (accident.categorieCode === '10' && hasFactureVehicule && isDateMECWithin12To24Months && hasValMaj) {
            changeValue('montant_type_reglement', montantFactureVehicule);
            changeValue('type_reglement', 'VALACHAT1224M');
        }
        if (accident.categorieCode === '10' && isDateMECWithin12Months && hasFactureVehicule) {
            changeValue('montant_type_reglement', montantFactureVehicule);
            changeValue('type_reglement', 'VALACHAT');
        }
        if (accident.categorieCode === '10' && isDateMECOver24Months && hasValMaj) {
            changeValue('indemnisation[8][check]', 'T');
            changeValue('type_reglement', 'VALEXPERT');
        }
    }, []);

    const [totalFacturesRapportsExperts, setTotalFacturesRapportsExperts] = useState(0)
    const [showCalcul, setShowCalcul] = useState(false)
    const [beneficiaireType, setBeneficiaireType] = useState(null)
    const [blocageVerificationClient, setBlocageVerificationClient] = useState(false);
    const [msgErreur, setMsgErreur] = useState();

    const parseFloatMontant = (montant) => parseFloat(_.replace(montant, ',', '.'));

    const getFilteredValues = (values, callback) => values ? values.filter(callback) : [];
    const rapports_expert = getFilteredValues(rapports_bca, r => parseFloatMontant(r.montantExpertise) >= 0);
    const factures = getFilteredValues(accessoires, f => parseFloatMontant(f.valeur_achat) >= 0 || parseFloatMontant(f.montant) >= 0);
    const getBeneficiaires = (entities, label) => entities ? entities.map(entity => ({value: entity.id, label: `${label} : ${entity.raisonSociale}`})) : [];
    const beneficiaires = [...getBeneficiaires([client], "CLIENT"), ...getBeneficiaires(reparateurs, "REPARATEUR"), ...getBeneficiaires(lese, "LESE")];

    const handleChange = (array, idlabel, idKey, idItem, checked, montant, label = '') => {
        return checked
            ? [...array, {id: idKey, [idlabel]: idItem, value: parseFloatMontant(montant), label: label}]
            : _.reject(array, ['id', idKey]);
    }

    const handleChangeBeneficiaire = (value, benefType) => {
        setBeneficiaire(value);
        setBeneficiaireType(benefType);
        changeValue('blocage_verification_client_ok', false);
        setBlocageVerificationClient(benefType === 'client' && titulaire_banque && client.raisonSociale.toLowerCase() !== titulaire_banque.toLowerCase())
    }

    const handleChangeRapportsExpert = (id, id_rapport, checked, montant) => {
        const newVal = handleChange(rapportsExpertCoches, 'id_rapport', id, id_rapport, checked, montant);
        changeValue('rapportsExpertCoches', newVal);

        if((_.sumBy(newVal, 'value') + _.sumBy(facturesCochees, 'value')) <= 0){
            setShowCalcul(false)
        }
        setTotalFacturesRapportsExperts(_.sumBy(newVal, 'value') + _.sumBy(facturesCochees, 'value'));
    }

    const handleChangeFactures = (id, id_facture, checked, montant, label) => {
        const newVal = handleChange(facturesCochees, 'id_facture', id, id_facture, checked, montant, label);
        changeValue('facturesCochees', newVal);

        if((_.sumBy(newVal, 'value') + _.sumBy(rapportsExpertCoches, 'value')) <= 0){
            setShowCalcul(false)
        }
        setTotalFacturesRapportsExperts(_.sumBy(newVal, 'value') + _.sumBy(rapportsExpertCoches, 'value'));
    }

    const messageValidate = () => {
        if(penalite_ok){
            return null
        }
        if(hasDocFactureReparation && !hasFactureReparation) {
            return "Il n'y a pas de facture de réparations enregistrée dans le bloc facture.";
        }
        if((hasDocFactureAntivolRecuTraite || hasFactureAntivol) && !_.isEmpty(penalites) && accident.categorieCode === '10' && !penalite_ok) {
            return (<>
                <p>Anomalie détectée : une facture antivol est présente sur le dossier et des pénalités sont enregistrées. Merci de repasser sur le dossier</p>
                <div className={'mb-4 mt-2'}>
                    <Field
                        name={`penalite_ok`}
                        component={CheckboxFieldRedux}
                        label={
                            <div dangerouslySetInnerHTML={{ __html: "<p>La pénalité enregistrée n'est pas liée à l'antivol SRA</p>" }} />
                        }
                    />
                </div>
                </>
            )
        }
        if(!hasDocFactureAntivolRecuTraite && !hasFactureAntivol && _.isEmpty(penalites) && accident.categorieCode === '10' ) {
            return "Anomalie détectée : aucune pénalité enregistrée alors que pas de facture antivol attendue. Merci de repasser sur le dossier";
        }
        if(!hasDocFactureReparation && hasFactureReparation){
            return "Il n'y a pas de facture de réparations dans la base documentaire.";
        }

        if(accident.remboursement_garage === "T" && beneficiaireType === 'client' && _.find(facturesCochees, ['label', 'reparation'])){
            return "Une prise en charge est délivrée au réparateur, vous ne pouvez pas effectuer le remboursement des réparations au client";
        }

        if(franchise_reglee <= 0 && totalFacturesRapportsExperts <= 0){
            return "Vous devez sélectionner un rapport d'expert ou une facture";
        }
        if(!remboursement_franchise && totalFacturesRapportsExperts <= 0){
            return "Vous devez sélectionner un rapport d'expert, une facture ou un remboursement de franchise";
        }

        return null;
    }

    const isProduit7311 = ['7311_SC', '7311_PVK', '7311_PVE', '7311_MQ50', '7311_VAE'].includes(accident.codeProduit);

    const documentRequis = {
        "10": {
            "client": isProduit7311 ? ["6", "10", "H", "15", "16", "17", "18", "19", "20", "22", "2", "14", "13"] : ["6"],
            "reparateur": !isProduit7311 ? ["6"] : [""]
        },
        "104": {
            "reparateur": ["7", "5", "25", "C", "F", "6", "8", "24", "R"],
            "client": ["C", "F", "6", "8", "24", "R"]
        },
        "24": {
            "reparateur": ["7", "5", "25", "C", "F", "6", "8", "24", "R"],
            "client": ["C", "F", "6", "8", "24", "R"]
        },
        "103": {
            "reparateur": ["7", "5", "25", "C", "F", "6", "24"],
            "client": ["C", "F", "6", "24"]
        },
        "9": {
            "reparateur": ["5", "7", "6", "H", "Q", "10", "6", "13"],
            "client": ["H", "Q", "10", "6", "13"]
        },
        "105": {
            "reparateur": ["5", "7", "P", "10", "6", "13"],
            "client": ["P", "10", "6", "13"]
        },
        "18": {
            "reparateur": ["5", "7", "F", "6"],
            "client": ["F", "6"]
        },
        "19": {
            "reparateur": ["5", "7", "F", "U", "6"],
            "client": ["F", "U", "6"]
        }
    };

    const areAllDocumentsValid = (documents, categorie, beneficiaire_type) => {
        const codeDocumentsRequis = documentRequis[categorie] ? documentRequis[categorie][beneficiaire_type] : {}
        const receivedDocuments = _.filter(documents, document =>
            _.includes(codeDocumentsRequis, document.code) && (!document.traite || document.dateReception === null || (document.dateReception !== null && document.traite === false))
        );

        return _.every(receivedDocuments, document => document.traite === true);
    }

    const getInvalidDocumentLabels = (documents, categorie, beneficiaire_type) => {
        const codeDocumentsRequis = documentRequis[categorie][beneficiaire_type];
        const documentsRequis = _.filter(documents, document =>
            _.includes(codeDocumentsRequis, document.code)
        );
        const documentsTraites = _.filter(documentsRequis, document =>
            (!document.traite || document.dateReception === null || (document.dateReception !== null && document.traite === false))
        );

        return _.map(documentsTraites, 'libelle');
    }

    const isValidWithDocuments = (documents, categorie, beneficiaire_type) => {
        return areAllDocumentsValid(documents, categorie, beneficiaire_type) || false;
    }

    useEffect(() => {
        const newMessage = messageValidate();
        setMsgErreur(newMessage);
    }, [penalite_ok, beneficiaireType, facturesCochees, rapportsExpertCoches, remboursement_franchise])

    /* Permet de vider les champs liés au calcul des franchises */
    const cleanFieldsFranchise = () => {
        changeValue(`[penalite][3][montant]`, undefined)
        changeValue(`montant_franchise`, undefined)
        changeValue(`pourcentage_franchise`, undefined)
        changeValue(`valeur_min_franchise`, undefined)
        changeValue(`valeur_max_franchise`, undefined)
        changeValue(`[penalite][5][valeur]`, undefined)
    }

    useEffect(() => {
        cleanFieldsFranchise();
    }, [showCalcul])

    return (
        <div id={'detailsReglement'}>
            <p className={'titre-section'}>Choisissez le bénéficiaire du règlement sinistre :</p>
            <Field
                name={'beneficiaire'}
                component={RadioSimpleFieldRedux}
                label={false}
                data={beneficiaires}
                onChange={e => handleChangeBeneficiaire(e.target.value, (e.target.labels[0].textContent).split(':')[0].toLowerCase().trim())}
                className={'mb-4'}
            />

            {
                (beneficiaireType === 'client' && titulaire_banque && client.raisonSociale.toLowerCase() !== titulaire_banque.toLowerCase()) &&
                <>
                    <div className="alert alert-danger mb-3" role="alert">Le titulaire du compte est différent du souscripteur, il faut procéder à un règlement par chèque</div>

                    <Field
                        name={`blocage_verification_client_ok`}
                        component={CheckboxFieldRedux}
                        label={`J'ai vérifié que le titulaire de la carte grise était identique au titulaire du compte`}
                        onChange={e => {
                            setBlocageVerificationClient(!blocageVerificationClient)
                        }}
                    />
                </>
            }

            <hr className={'mb-4'}/>

            <p className={'titre-section'}>Choisissez les éléments à régler :</p>
            <div className={'alert alert-warning'} style={{fontSize:13, marginBottom: 15}}>
                Merci de vérifier :
                <ul>
                    <li>la réception d'un éventuel rapport d'expert dans les chronos</li>
                    <li>la réception d'éventuelles factures dans les chronos</li>
                    <li>qu'un règlement par chèque n'a pas déjà été effectué sur ces éléments</li>
                </ul>
            </div>

            <label className={'titre-section-2'}>Vous pouvez choisir un ou plusieurs rapport de l'expert :</label>
            <div className={'mb-2 mt-2'}>
                {
                    !_.isEmpty(rapports_expert) ? rapports_expert.map((r, i) => {
                            let montant = parseFloatMontant(r.montantExpertise)

                            if ((r.commentaire && r.commentaire === 'Cède son véhicule') || r.idEsa === 'le lesé ne conserve pas son véhicule') {
                                montant = parseFloatMontant(r.valeurAvtSinistre);
                            }

                            montant = _.round(montant, 2);

                            return <div className={'row'} key={i}>
                                <div className={'col'}>
                                    <Field
                                        name={`rapport_expert[${i}]`}
                                        component={CheckboxFieldRedux}
                                        label={`Montant de l'expertise : ${montant}€`}
                                        onChange={e => handleChangeRapportsExpert(i, r.id, e.target.checked, montant)}
                                        key={i}
                                    />
                                </div>
                                <div className={'col'}>
                                    <BadgeRgt code={r.codeStatutReglement} status={r.statutReglement}/>
                                </div>
                            </div>
                        })
                        : <p>Aucun rapport d'expert enregistré sur ce sinistre</p>
                }
            </div>
            <p className="title-total text-right mb-4" >
                Total rapport d'expert : {_.sumBy(rapportsExpertCoches, 'value')}€
            </p>

            <div className={'separator separator-2'}></div>

            <label className={'titre-section-2 mt-4'}>Vous pouvez choisir une ou plusieurs factures :</label>
            <div className={'mb-4 mt-2'}>
                {
                    !_.isEmpty(factures) ? factures.map((f, i) => {
                            const val = parseFloatMontant(f.montant) > 0 ? f.montant : f.valeur_achat;

                            return <div className={'row'} key={i}>
                                <div className={'col'}>
                                    {
                                        !f.codeStatutReglement || f.codeStatutReglement === '0' ?
                                            <Field
                                                name={`factures[${i}]`}
                                                component={CheckboxFieldRedux}
                                                label={`Facture ${f.libelle} : ${val}€`}
                                                onChange={e => handleChangeFactures(i, f.id, e.target.checked, val, f.libelle)}
                                            />
                                            : <p className={'factureDisabled'}>Facture {f.libelle} : {val}€</p>
                                    }
                                </div>
                                <div className={'col'}>
                                    <Badge code={f.codeStatutReglement} status={f.statutReglement}/>
                                </div>
                            </div>
                        })
                        : <p>Aucune facture enregistrée sur ce sinistre</p>
                }
            </div>

            {
                (!remboursement_franchise && totalFacturesRapportsExperts > 0) &&
                <>
                    <button className={'btn btn-secondary btn-small mb-4'} type={'button'}
                            onClick={() => setShowCalcul(!showCalcul)} style={{margin: '0 auto', display: 'block'}}>
                        Calculer la franchise
                    </button>

                    {
                        showCalcul && <DetailsReglementCalculFranchiseContainer
                            montant_penalite_franchise={montant_penalite_franchise}
                            totalFacturesRapportsExperts={totalFacturesRapportsExperts}
                            changeValue={changeValue}
                            cleanFieldsFranchise={cleanFieldsFranchise}
                        />
                    }
                </>
            }

            <p className="title-total text-right" >
                Total des factures : {_.round(_.sumBy(facturesCochees, 'value'), 2)}€
            </p>

            {
                franchise_reglee > 0 &&
                <>
                    <div className={'alert alert-info w-100 m-0 mb-4'}>
                        <p>Montant de franchise déjà déduite : {_.round(franchise_reglee, 2)}€</p>
                    </div>

                    <div className={'separator separator-2'}></div>

                    <label className={'titre-section-2 mt-4'}>Vous pouvez choisir de rembourser la franchise :</label>
                    <div className={'mb-4 mt-2'}>
                        <Field
                            name={`remboursement_franchise`}
                            component={CheckboxFieldRedux}
                            label={`Rembourser la franchise`}
                            onChange={e => {
                                setShowCalcul(false)
                                changeValue('montant_remboursement_franchise', (!remboursement_franchise ? franchise_reglee : null));
                                changeValue('indemnisation[9][montant]', (!remboursement_franchise ? franchise_reglee : null));
                            }}
                        />

                        {
                            remboursement_franchise &&
                            <Field
                                name='montant_remboursement_franchise'
                                component={TextFieldRedux}
                                type={'number'}
                                label={false}
                                onChange={e => {
                                    changeValue('indemnisation[9][montant]', e.target.value);
                                }}
                            />
                        }
                    </div>

                </>
            }

            <div className={'separator separator-2'}></div>

            {oppositions &&
                <>
                    <label className={'titre-section-2 mt-5'}>Vous pouvez choisir une opposition :</label>

                    <div className={'mb-4 mt-2'}>
                        <Field
                            name={`opposition_montant`}
                            component={CheckboxFieldRedux}
                            label={`Opposition d'un montant de ${Number(oppositions).toFixed(2)}€`}
                        />
                    </div>
                </>
            }

            { msgErreur && <div className="alert alert-danger" role="alert">{msgErreur}</div> }

            {(isValidWithDocuments(documents, accident.categorieCode, beneficiaireType)) ?
                beneficiaire && <button className={'btn btn-primary float-right mt-2'} type={'button'} disabled={(msgErreur || (blocageVerificationClient && !blocageVerificationClientOk) ||  (remboursement_franchise && !montant_remboursement_franchise))} onClick={() => {
                    setStep(1)
                    window.scrollTo(0, 0);
                }}>
                    Poursuivre le règlement
                </button>
                :
                <div className={'alert alert-danger'}>
                    <p>Vous ne pouvez pas poursuivre le réglement, les documents ne sont pas traités : {getInvalidDocumentLabels(documents, accident.categorieCode, beneficiaireType).join(', ')}</p>
                </div>
            }
        </div>
    );
};

export default DetailsReglement;
