import React, {Fragment, useState} from 'react';
import {Field} from "redux-form";
import SelectFieldRedux from "../../../../../Commun/Input/Select/SelectFieldRedux";
import _ from "lodash";
import TextAreaFieldRedux from "../../../../../Commun/Input/Text/TextAreaFieldRedux";
import './emailContenu.scss'

const EmailContenu = (props) => {
    const {
        type,
        template,
        champsPersonnalises,
        changeValue,
        getBonjour,
        majBonjour,
        typeDestinataire,
        text,
        emailsAuto
    } = props
    const [selectedTemplateText, setSelectedTemplateText] = useState(false)

    const handleChangeTemplate = (e) => {
        if (e.target.value) {
            const selectedEmailValues = _.find(emailsAuto, {'value': e.target.value});
            changeValue('champsPersonnalises', selectedEmailValues.champs ?? []);
            setSelectedTemplateText(selectedEmailValues.text ?? '');

            //On remplace tous les champs à compléter
            // const textSelected = _.replace(selectedEmailValues.text, new RegExp(_.join(_.map(selectedEmailValues.champs, 'slug'), '|'), "gi"), '')
            changeValue('text', `${getBonjour(typeDestinataire)}<br/><br/>${selectedEmailValues.text}`);
        } else {
            setSelectedTemplateText('');
            majBonjour(typeDestinataire)
            changeValue('template_title', '')
            changeValue('champsPersonnalises', []);
        }
    }

    const handleChangeChampsPerso = () => {
        //On met à jour l'affichage du texte avec tous les champs personnalisés stockés et le texte sélectionné
        let textSelected = selectedTemplateText;

        _.map(champsPersonnalises, function (champ) {
            textSelected = textSelected.replaceAll(champ.slug, _.find(champsPersonnalises, ['slug', champ.slug]).value ?? champ.slug);
        });

        textSelected = _.replace(textSelected, /\n|\r/g, '<br/>');

        changeValue('text', `${getBonjour(typeDestinataire)}<br/><br/>${textSelected}`);
    }

    return (
        <div>
            <p className="font-weight-bold mb-4">Contenu de l'email</p>

            {
                type === 'libre' && typeDestinataire &&
                <Field
                    name={'template'}
                    component={SelectFieldRedux}
                    label={'Modèle d\'email'}
                    onChange={handleChangeTemplate}
                >
                    <option value={""}>Email libre</option>
                    {
                        _.filter(emailsAuto, {'type': typeDestinataire}).map((email, index) => (
                            <option key={index} value={email.value}>{email.label}</option>
                        ))
                    }
                </Field>
            }

            {
                template ?
                    <div className={'form-group'}>
                        <div id={'preview-text'} className={'form-control'} dangerouslySetInnerHTML={{__html: text}}/>
                    </div>
                    : <Field
                        name={'text'}
                        component={TextAreaFieldRedux}
                        label={'Zone de personnalisation'}
                        rows={10}
                        disabled={template}
                    />
            }

            {
                template && !_.isEmpty(champsPersonnalises) && champsPersonnalises.map((champ, i) => {
                    return <Fragment key={i}>
                        {
                            champ.type && champ.type === "select" ?
                                <Field
                                    name={`champsPersonnalises[${i}][value]`}
                                    component={SelectFieldRedux}
                                    label={champ.label}
                                    required
                                    onClick={handleChangeChampsPerso}
                                >
                                    <option value={""}>Choisissez une valeur</option>
                                    {champ.values.map((value, index) => (
                                        <option key={index} value={value}>{value}</option>
                                    ))}
                                </Field>
                                :
                                <Field
                                    key={i}
                                    name={`champsPersonnalises[${i}][value]`}
                                    component={TextAreaFieldRedux}
                                    label={champ.label}
                                    onKeyUp={handleChangeChampsPerso}
                                    rows={1}
                                    required={!champ.notRequired ?? true}
                                />
                        }
                    </Fragment>
                })
            }

            {
                template && _.isEmpty(champsPersonnalises) &&
                <div className={'alert alert-warning m-0'}><p>Pour ce template vous n'avez pas de champs à
                    compléter.</p></div>
            }
        </div>
    );
};

export default EmailContenu;
