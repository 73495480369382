import React, {Fragment} from 'react'
import {Link} from "react-router-dom";
import {redirectInNewWindow} from "../../../../../utils/function";
import {openInWindow} from "../../../../../constants/constants";
import _ from "lodash";
import Badge from "../../Forms/VirementsSinistre/Steps/DetailsReglement/Badge/Badge";
import BadgeRgt from "../../../../Commun/Badge/BadgeRgt";
import moment from "moment/moment";
import CallToAction from "../../../../Commun/Button/CallToAction/CallToAction";

const RapportExpert = (props) => {
    const {rapports_bca, idContract, idAccident, permissions, submitDelete} = props

    return (
        <div className={'bloc-info bloc-rapport'}>
            <h2>Rapport de l'expert</h2>
            <div className={'content row'}>
                {rapports_bca && rapports_bca.length > 0 ?
                    rapports_bca.map((rapport_bca, index) =>
                        <Fragment key={index}>
                            <div className={'col-5 left-content'}>
                                <p>Issue :</p>
                            </div>
                            <div className={'col-7 right-content'}>
                                {!rapport_bca.issueDef &&  <CallToAction
                                    onClick={() => {
                                        if (window.confirm(`Etes-vous sûr de vouloir supprimer le rapport ?`)) submitDelete(rapport_bca.id)
                                    }}
                                    action={'delete-penalties'}
                                    toolText={'Supprimer le rapport'}
                                />}
                                <p>{(!rapport_bca.montantExpertise || rapport_bca.montantExpertise === '0,00') && rapport_bca.issueDef.toLowerCase() === "autres rapports" ? 'Rapport de carence' : rapport_bca.issueDef}</p>
                            </div>
                            {rapport_bca.commentaire && <Fragment>
                                <div className={'col-5 left-content'}>
                                    <p>Commentaire :</p>
                                </div>
                                <div className={'col-7 right-content'}>
                                    <p>{rapport_bca.idEsa}</p>
                                </div>
                            </Fragment>}
                            <div className={'col-5 left-content'}>
                                <p>{rapport_bca.commentaire_gest || 'Montant de l\'expertise'} :</p>
                            </div>
                            <div className={'col-7 right-content'}>
                                <p>{rapport_bca.issueDef.toLowerCase() === "rapport en perte total" ? (rapport_bca.valeurAvtSinistre + '€' || '-') : (rapport_bca.montantExpertise + '€' || '-')}</p>
                            </div>
                            <div className={'col-5 left-content'}>
                                <p>Valeur de sauvetage :</p>
                            </div>
                            <div className={'col-7 right-content'}>
                                <p>{rapport_bca.valeurSauvetage + '€' || '-'}</p>
                            </div>
                            {rapport_bca.statutReglement && <>
                                <div className={'col-5 left-content'}>
                                    <p>Statut du règlement :</p>
                                </div>
                                <div className={'col-7 right-content'}>
                                    {rapport_bca.codeStatutReglement === '1' && <BadgeRgt code={rapport_bca.codeStatutReglement} status={`${rapport_bca.statutReglement} le ${moment(rapport_bca.dateReglement).format('L')}`}/>}
                                    {rapport_bca.codeStatutReglement !== '1' && <BadgeRgt code={rapport_bca.codeStatutReglement} status={rapport_bca.statutReglement}/>}
                                </div>
                            </> }
                            {rapport_bca.statutFacture === '1' && <div className={'col-12'}>
                                <div className={'col full-content'}>
                                    <p>Le montant de l'expertise est égale à la facture.</p>
                                </div>
                            </div>}
                            {rapport_bca.statutFacture === '2' &&
                                <Fragment>
                                    <div className={'col-5 left-content'}>
                                        <p>Montant de la facture :</p>
                                    </div>
                                    <div className={'col-7 right-content'}>
                                        <p>{rapport_bca.montantFacture + '€' || '-'}</p>
                                    </div>
                                    <div className={'col-5 left-content'}>
                                        <p>Ecart entre le montant expert et la facture :</p>
                                    </div>
                                    <div className={'col-7 right-content'}>
                                        <p>{(rapport_bca.differenceMontant) + '€' || '-'}</p>
                                    </div>
                                </Fragment>
                            }
                            {rapport_bca.statutFacture === '3' && <div className={'col-12'}>
                                <div className={'col full-content'}>
                                    <p>La facture n'a pas été contrôlée par l'expert.</p>
                                </div>
                            </div>}
                            {rapport_bca.procAppli === 'VEI' && <Fragment>
                                <div className={'col-5 left-content'}>
                                    <p>Propriétaire du véhicule :</p>
                                </div>
                                <div className={'col-7 right-content'}>
                                    <p>{rapport_bca.prorietaire || '-'}</p>
                                </div>
                            </Fragment>}
                            <span className={'separator'}/>
                        </Fragment>
                    )
                    : <div className={'col-12'}>
                        <p>Aucun rapport d'expert pour ce sinistre</p>
                    </div>
                }
                {
                    _.includes(permissions, "update accident informations") &&
                    <div className={'col-12 full-content'}>
                        <Link
                            style={{
                                fontSize: 14,
                                textAlign: 'center',
                                fontWeight: 'bold',
                                display: 'block',
                                margin: '15px 0'
                            }}
                            onClick={(e) => redirectInNewWindow({
                                e,
                                url: `/contrat/${idContract}/sinistre/${idAccident}/ajout-rapport-expert`,
                                name: openInWindow.addRapportExpert
                            })}
                        >
                            Ajouter un rapport d'expert
                        </Link>
                    </div>
                }

            </div>
        </div>
    )
}

export default RapportExpert
