import React, {Fragment, useEffect} from 'react'
import moment from "moment";
import APIFrame from "../../../../../Commun/APILink/APIFrame";
import APIImage from "../../../../../Commun/APILink/APIImage";
import {Field} from "redux-form";
import SelectFieldRedux from "@/components/Commun/Input/Select/SelectFieldRedux";
import RadioFieldRedux from "@/components/Commun/Input/Radio/RadioFieldRedux";
import DatePickerFieldRedux from "@/components/Commun/Input/DatePicker/DatePickerFieldRedux";
import CheckboxFieldRedux from "@/components/Commun/Input/Checkbox/CheckboxFieldRedux";
import Spinner from "@/components/Commun/Spinner/Spinner";
import MyButton from "@/components/Commun/Button/Button";
import checkDefaultGarantie from "@/utils/checkDefaultGarantie";
import _ from "lodash";

const Rgt = (props) => {
    const {
        task: {date, type, paniere, tiers_id, sinistre},
        files,
        datesurv_ok,
        categorie_ok,
        categorie_correctif,
        loadingRgt,
        loadingTerminate,
        handleSubmit,
        change,
        type_doc,
        sign_pec
    } = props
    const garantiesSin = ['RC', 'DR', 'DA', 'PCC1', 'PCC2', 'PCC3', 'IC', 'ICP', 'ACCEQ', 'EQ', 'VALMAJ', 'RFDTA', 'SCRC', 'SCDR', 'SCDA', 'SCIC', 'RC2', 'RC35', 'PCC0', 'PVRC', 'PVDR', 'PVDC', 'PVDA', 'PVIC']
    const resp = sinistre.tauxResp
    const adversaires = sinistre.adverse
    const codegta = sinistre.codeGta
    const blesse = sinistre.type !== 'Matériel'

    const garantiesContrat = _.filter(sinistre.garanties, (o) => {
        return _.includes(garantiesSin, o.code)
    })

    const categorieSin = [
        {code: '103', libelle: 'Accident avec Tiers'},
        {code: '104', libelle: 'Accident sans Tiers'},
        {code: '105', libelle: 'Tentative de vol'},
        {code: '24', libelle: 'Vandalisme'},
    ]

    useEffect(() => {
        if (datesurv_ok === 'F') {
            change('dateSurv', moment())
        }
    }, [datesurv_ok])

    useEffect(() => {
        if(type_doc === 'rapport' && categorie_correctif === '103') {
            const adversairesArray = _.values(adversaires);
            checkDefaultGarantie({codegta, responsabilite: resp, garantiesContrat, changeValue: change, blesse, adversaires: adversairesArray})

        }
    }, [type_doc, categorie_correctif])

    return (
        <Fragment>
            <div className={'row'}>
                <div className={'col text-center'}>
                    <h2 className={'title-primary text-uppercase'}>{paniere} - Tiers n°{tiers_id}</h2>
                    <p className={'subtitle'}>Tâche du {moment(date).format('L')}. Recu par {type}</p>
                </div>
            </div>
            <div className={'header-row row'}>
                {files !== undefined &&
                    files.map((file, index) => (
                        file.extension.toLowerCase() === 'pdf' ?
                            <div className={'col-8'} style={{marginBottom: 30}} key={index}>
                                <APIFrame src={`${file.url}`} title={`${file.code}`} frameBorder="0"
                                          style={{height: '100%', width: '100%', marginBottom: 30}}/>
                            </div>
                            :
                            <div className={'col-7 task-img-container'} key={index}>
                                <APIImage src={`${file.url}`} ref={el => this.refImg = el} alt={'Document'}/>
                            </div>
                    ))
                }
                <div className={`col-md-4 ${files !== undefined ? '' : 'offset-md-4'}`}>
                    <form className={'form-content'} onSubmit={handleSubmit}>
                        <Field
                            name='type_doc'
                            component={RadioFieldRedux}
                            label={'Le document est :'}
                            data={[
                                {value: "rapport", label: "un rapport"},
                                {value: "pec", label: "une prise en charge"},
                                {value: "facture", label: "une facture"}
                            ]}
                            required
                        />
                        {type_doc === 'facture' &&
                            <div className={'alert alert-info m-0 mb-2'} style={{fontSize: 12}}>
                                S'il s'agit d'une facture, merci de transférer la tâche en popup FACTURE
                            </div>
                        }
                        {type_doc === 'rapport' &&
                            <>
                                <div className={'alert alert-info m-0 mb-2'} style={{fontSize: 12}}>
                                    <strong>Date de survenue renseignée sur le
                                        sinistre :</strong> {moment(sinistre.dateSurv).format('L')}
                                </div>
                                <Field
                                    name='dateSurvOk'
                                    component={RadioFieldRedux}
                                    label={'La date du sinistre est-elle correcte ?'}
                                    data={[{value: "T", label: "Oui"}, {value: "F", label: "Non"}]}
                                    required
                                />
                                {datesurv_ok === 'F' && <>
                                    <Field
                                        name={`dateSurv`}
                                        component={DatePickerFieldRedux}
                                        label={'Date de survenance'}
                                        maxDate={moment()}
                                    />
                                    <small>A la validation de la tâche, un email sera automatiquement envoyé à Allianz pour leur indiquer la nouvelle date de survenance.</small>
                                </>

                                }
                                <hr/>
                                <div className={'alert alert-info m-0 mb-2'} style={{fontSize: 12}}>
                                    <strong>Catégorie renseignée sur le sinistre :</strong> {sinistre.categorie}<br/>
                                    <strong>Circonstances renseignées sur le sinistre :</strong> {sinistre.circonstances}
                                </div>
                                <Field
                                    name='catsinOk'
                                    component={RadioFieldRedux}
                                    label={'Le sinistre constaté par l\'expert correspond-il bien à la déclaration du client ?'}
                                    data={[{value: "T", label: "Oui"}, {value: "F", label: "Non"}]}
                                    required
                                />
                                {categorie_ok === 'F' &&
                                    <>
                                        <Field
                                            name={`catsin`}
                                            component={SelectFieldRedux}
                                            label={'Catégorie du sinistre'}
                                        >
                                            {categorieSin
                                                .filter(cat => cat.code !== sinistre.categorieCode)
                                                .map((cat, index) => (
                                                    <option key={index} value={cat.code}>{cat.libelle}</option>
                                                ))
                                            }
                                        </Field>

                                        {((!sinistre.garanties || !sinistre.garanties.some(garantie => garantie.code === 'DTA' || garantie.code === 'DA' || garantie.code === 'SCDA' || garantie.code === 'PVDA')) && (categorie_correctif === '104' || categorie_correctif === '24')) &&
                                            <div className={'alert alert-warning m-0 mb-2'} style={{fontSize: 12}}>
                                                <strong>Attention !</strong> Le client n'a pas de garantie DTA, il faut donc regarder si l'intervention du fond de garantie est possible.
                                                <br/><br/>
                                                <Field
                                                    name={`validation_intervention`}
                                                    component={CheckboxFieldRedux}
                                                    label={'J\'ai pris connaisance du message, je valide et fais le nécessaire'}
                                                />
                                            </div>
                                        }
                                    </>
                                }
                            </>
                        }
                        {type_doc === 'pec' &&
                            <>
                                <Field
                                    name='delaiPec'
                                    component={RadioFieldRedux}
                                    label={'La prise en charge est-elle dans les délais ?'}
                                    data={[
                                        {value: "0", label: "Avant la date limite"},
                                        {value: "1", label: "1 mois"},
                                        {value: "2", label: "entre 3 et 6 mois"},
                                        {value: "3", label: "> 6 mois"},
                                    ]}
                                    required
                                />
                                <Field
                                    name='signPec'
                                    component={RadioFieldRedux}
                                    label={'Est-elle signée ?'}
                                    data={[
                                        {value: "T", label: "Oui"},
                                        {value: "F", label: "Non"},
                                    ]}
                                    required
                                />
                                {sign_pec === 'F' && <Field
                                    name='abandonPec'
                                    component={RadioFieldRedux}
                                    label={'Abandonne-t-il la prise en charge ?'}
                                    data={[
                                        {value: "T", label: "Oui"},
                                        {value: "F", label: "Non"},
                                    ]}
                                    required
                                />}
                                <div className={'alert alert-info m-0 mb-2'} style={{fontSize: 12}}>
                                    Vérifier au dossier si le garage refuse la <strong>Prise en charge</strong> avant de valider la tâche
                                </div>
                            </>
                        }
                        {loadingTerminate ? <Spinner/>
                            : <MyButton
                                variant='raised'
                                color='secondary'
                                size='large'
                                className={'btn btn-primary mt-5'}
                                disabled={loadingRgt}
                            >
                                {loadingRgt && <Spinner absolute/>}
                                Valider
                            </MyButton>
                        }
                    </form>
                </div>
            </div>
        </Fragment>
    )
}

export default Rgt
