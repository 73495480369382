import React from 'react';
import {Field} from "redux-form";
import TextFieldRedux from "../../../../Commun/Input/Text/TextFieldRedux";
import Spinner from "../../../../Commun/Spinner/Spinner";
import PostalCodeCity from "../../../../Commun/PostalCodeCity/PostalCodeCity";


const AddTiers = (props) => {
    const {handleSubmit, loading, edit, changeValue, location: {state: {adverse}}} = props

    return (
        <div className={'contract-container container'}>
            <div className={'row return-content'}>
                <div className={'col'}>
                    <button onClick={() => props.history.go(-1)} className={'btn btn-return'}>Retour</button>
                </div>
            </div>
            <div className={'row header-row'}>
                <div className={'col-12'}>
                    <form onSubmit={handleSubmit} className={'form-content'}>
                        <div className="row">
                            <div className="col">
                                <Field
                                    name='nom'
                                    component={TextFieldRedux}
                                    label={'Nom*'}
                                    normalize={v => v.toUpperCase()}
                                    required
                                />
                            </div>
                            <div className="col">
                                <Field
                                    name='prenom'
                                    component={TextFieldRedux}
                                    label={'Prénom*'}
                                    normalize={v => v.toUpperCase()}
                                    required
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <Field
                                    name='adresse'
                                    component={TextFieldRedux}
                                    label={'Adresse :*'}
                                    required
                                />
                            </div>
                            <div className="col">
                                <PostalCodeCity
                                    nameCodePostal='cp'
                                    nameVille='ville'
                                    label={'Code postal, ville :'}
                                    id={'cp-ville'}
                                    changeValue={changeValue}
                                    defaultValue={adverse && `${adverse.ville} ${adverse.codePostal}`}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <Field
                                    name='immatriculation'
                                    component={TextFieldRedux}
                                    label={'Immatriculation :'}
                                    normalize={v => v.toUpperCase()}
                                />
                            </div>
                            <div className="col">
                                <Field
                                    name='police'
                                    component={TextFieldRedux}
                                    label={'N° de police :'}
                                />
                            </div>
                        </div>
                        {
                            loading ? <Spinner style={{display: 'block', margin: 'auto'}}/> :
                                <button className={'btn btn-primary d-block'} data-test-id="avenant_simple_btn_submit"
                                        type={'submit'}>
                                    {edit ? 'Editer le tiers' : 'Ajouter le tiers'}
                                </button>
                        }

                    </form>
                </div>
            </div>
        </div>
    );
};


AddTiers.defaultProps = {};


AddTiers.propTypes = {};


export default AddTiers;
