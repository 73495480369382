import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import DashboardDetailActivites from "./DashboardDetailActivites";
import {
    DASHBOARD_MORE_TASK_DETAIL,
    getManagerMoreTaskDetail
} from "../../../../../redux/actions/app/dashboard/dashboard.actions";
import _ from "lodash";
import {dashboardMoreTaskDetailGetLink} from "../../../../../redux/selectors/links/links.selectors";
import {getLoadingEntity} from "../../../../../redux/selectors/ui/ui.selectors";
import {getGestionnaireDetail} from "../../../../../redux/selectors/dashboard/dashboard.selectors";
import loader from "../../../../Commun/Spinner/Loader/Loader";

const mapStateToProps = state => {
    return {
        uri: dashboardMoreTaskDetailGetLink(state),
        loaded: getLoadingEntity(state, DASHBOARD_MORE_TASK_DETAIL) === false,
        gestionnaireDetails: getGestionnaireDetail(state)
    }
}


const mapDispatchToProps = dispatch => bindActionCreators({
    getManagerMoreTaskDetail,
}, dispatch)


const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {uri} = stateProps
    const {getManagerMoreTaskDetail} = dispatchProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => getManagerMoreTaskDetail({query: _.replace(uri, '{id}', ownProps.match.params.tiers_id)})
    }
}


const DashboardDetailActivitesContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader()
)(DashboardDetailActivites)


export default DashboardDetailActivitesContainer
