import {API_ERROR, API_SUCCESS, apiRequest} from '../../../actions/core/api/api.actions'
import {setError, setLoader} from '../../../actions/app/ui/ui.actions'
import {setNotification} from "../../../actions/core/notifications/notifications.actions";
import {POST_EDIT_HAMON, EDIT_HAMON, setEditHamon} from "../../../actions/app/hamon/hamon.actions";

export const hamonMiddleware = () => next => action => {
    next(action)

    const {payload} = action
    let message = null

    switch (action.type) {
        case POST_EDIT_HAMON:
            next([
                apiRequest({body: action.payload.body, method: 'POST', url: action.payload.data, entity: EDIT_HAMON}),
                setLoader({state: true, entity: EDIT_HAMON})
            ])
            break

        case `${EDIT_HAMON} ${API_SUCCESS}`:
            message = `<p>Les informations de la Loi Hamon ont bien été mises à jour</p>`
            next([
                setEditHamon({state: true}),
                setNotification({entity: EDIT_HAMON, body: message, type: 'success', title: 'Informations modifiées!'}),
                setLoader({state: false, entity: EDIT_HAMON}),
                setError({state: false, entity: EDIT_HAMON}),
            ])
            break

        case `${EDIT_HAMON} ${API_ERROR}`:
            message = `
                <p>Votre demande n'a pas pu être envoyée. ${payload.data.response.data.reason}</p>
            `
            next([
                setNotification({
                    entity: EDIT_HAMON,
                    body: message,
                    type: 'error',
                    title: 'Erreur lors de l\'edition !',
                    timeout: '0'
                }),
                setError({state: true, entity: EDIT_HAMON}),
                setLoader({state: false, entity: EDIT_HAMON}),
            ])
            break

        default:
            break
    }
    return null
}
