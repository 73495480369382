import React from 'react'
import PieceCard from '../../../../Commun/Card/PieceCard'

const Pieces = (props) => {
    const {pieces} = props

    return (
        <div className={'row margin-top'}>
            <div className={'col'}>
                <div className={'bloc-info bloc-pieces'}>
                    <h2>Liste des pièces sur le contrat</h2>
                    <div className={'content row'}>
                        <table className="table">
                            <thead>
                            <tr>
                                <th scope="col">N° Pièce</th>
                                <th scope="col">Motif</th>
                                <th scope="col">Date d'effet</th>
                                <th scope="col">Situation</th>
                                <th scope="col">ID Devis</th>
                            </tr>
                            </thead>
                            <tbody>
                            {pieces.map((piece, index) =>
                                <PieceCard piece={piece} key={index}/>
                            )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Pieces
