import React from 'react'
import moment from "moment";

const TacheSimple = (props) => {
    const {task: {title, description, date, type, paniere, tiers_id}, message} = props

    return (
        <div className={'container'}>
            <div className={'row'}>
                <div className={'col text-center'}>
                    <h2 className={'title-primary text-uppercase'}>{paniere} - Tiers n°{tiers_id}</h2>
                    <p className={'subtitle'}>Tâche du {moment(date).format('L')}. Recu par {type}</p>
                </div>
            </div>
            <div className={'header-row row'}>
                <div className={'col-12'}>
                    <p className={'form-content text-center'}>
                        Type : {title}<br/>
                        Description : {message ? message : description ?
                        <span dangerouslySetInnerHTML={{__html: description}}/> : '-'}
                    </p>
                </div>
            </div>
        </div>
    )
}

export default TacheSimple
