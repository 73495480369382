import React, {Fragment, useState} from 'react'
import moment from "moment";
import {Link} from "react-router-dom";
import CallToAction from "../../../../Commun/Button/CallToAction/CallToAction";
import _ from "lodash";
import {redirectInNewWindow} from "../../../../../utils/function";
import {openInWindow} from "../../../../../constants/constants";
import BadgeRgt from "../../../../Commun/Badge/BadgeRgt";
import Badge from "../../Forms/VirementsSinistre/Steps/DetailsReglement/Badge/Badge";

const Accessoires = (props) => {
    const {accessoires, contract, accident, postSubmitDeleteAccessoire} = props
    const arrayAccessoires = ['casque', 'gants', 'blouson', 'bottes', 'top_case', 'tablier', 'combinaison', 'echappement', 'top_bloc']
    const [disable, setDisable] = useState(false)

    const handleClick = (id) => {
        postSubmitDeleteAccessoire(id)
        setDisable(true)
    }

    const getNameAccessoires = (libelle) => {
        switch (libelle) {
            case 'reparation':
                return 'Réparation'
            case 'antivol':
                return 'Antivol SRA'
            case 'airbag':
                return 'Gilet Airbag'
            case 'casque':
                return 'Casque'
            case 'gants':
                return 'Gants'
            case 'blouson':
                return 'Blouson'
            case 'bottes':
                return 'Bottes'
            case 'top_case':
                return 'Top Case'
            case 'tablier':
                return 'Tablier'
            case 'combinaison':
                return 'Combinaison'
            case 'echappement':
                return 'Pot d\'échappement'
            case 'top_bloc':
                return 'Top Bloc'
            default:
                return libelle
        }
    }

    return (
        <div className={'bloc-info bloc-facture'}>
            <h2>Facture</h2>
            <div className={'content row'}>
                {accessoires && accessoires.length > 0 ?
                    accessoires.map((accessoire, index) =>
                        <Fragment key={index}>
                            <div className={'col-5 left-content'}>
                                <p>Type :</p>
                            </div>
                            <div className={'col-7 right-content'}>
                                <p>{getNameAccessoires(accessoire.libelle)}
                                    <CallToAction
                                        onClick={(e) => redirectInNewWindow({
                                            e,
                                            url: `/contrat/${contract.id}/sinistre/${accident.id}/edit-accessoires/${accessoire.id}`,
                                            name: openInWindow.addAccessoires
                                        })}
                                        action={'edit-repairer'}
                                        toolText={`Modifier l'accessoire`}
                                    />
                                    <CallToAction
                                        action={'delete-note'}
                                        toolText={'Supprimer l\'accessoire'}
                                        onClick={() => {
                                            handleClick(accessoire.id)
                                        }}
                                        url={'#'}
                                        disabled={disable}
                                    />
                                </p>
                            </div>
                            {(_.includes(arrayAccessoires, accessoire.libelle) || _.startsWith(accessoire.libelle, 'Autres : ')) &&
                                <>
                                    <div className={'col-5 left-content'}>
                                        <p>Date d’achat :</p>
                                    </div>
                                    <div className={'col-7 right-content'}>
                                        <p>{moment(accessoire.date_achat).format('L')}</p>
                                    </div>
                                </>
                            }
                            {(_.includes(arrayAccessoires, accessoire.libelle) || _.startsWith(accessoire.libelle, 'Autres : ')) &&
                                <>
                                    <div className={'col-5 left-content'}>
                                        <p>Valeur initiale :</p>
                                    </div>
                                    <div className={'col-7 right-content'}>
                                        <p>{accessoire.valeur_achat + '€' || '-'}</p>
                                    </div>
                                </>
                            }
                            {accessoire.montant && <>
                                <div className={'col-5 left-content'}>
                                    {(_.includes(arrayAccessoires, accessoire.libelle) || _.startsWith(accessoire.libelle, 'Autres : ')) ?
                                        <p>Valeur après vétusté :</p> :
                                        <p>Valeur remboursé :</p>
                                    }
                                </div>
                                <div className={'col-7 right-content'}>
                                    <p>{accessoire.montant + '€' || '-'}</p>
                                </div>
                            </>}
                            {accessoire.statutReglement && <>
                                <div className={'col-5 left-content'}>
                                    <p>Statut du règlement :</p>
                                </div>
                                <div className={'col-7 right-content'}>
                                    {accessoire.codeStatutReglement === '1' && <Badge code={accessoire.codeStatutReglement} status={`${accessoire.statutReglement} le ${moment(accessoire.dateReglement).format('L')}`}/>}
                                    {accessoire.codeStatutReglement !== '1' && <Badge code={accessoire.codeStatutReglement} status={accessoire.statutReglement}/>}
                                </div>
                            </> }
                            <span className={'separator'} style={{margin: '5px 0'}}/>
                        </Fragment>
                    )
                    : <div className={'col-12'}>
                        <p>Aucune facture pour ce sinistre</p>
                    </div>
                }
                <div className={'col-12 full-content'}>
                    <Link
                        style={{
                            fontSize: 14,
                            textAlign: 'center',
                            fontWeight: 'bold',
                            display: 'block',
                            margin: '15px 0'
                        }}
                        onClick={(e) => redirectInNewWindow({
                            e,
                            url: `/contrat/${contract.id}/sinistre/${accident.id}/ajout-accessoires`,
                            name: openInWindow.addAccessoires
                        })}
                    >
                        Ajouter une facture
                    </Link>
                </div>
            </div>
        </div>
    )
}

export default Accessoires
