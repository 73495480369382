export const RECLAMATION = '[Reclamation]';

export const GET_RECLAMATION = `${RECLAMATION} Get`;
export const SET_RECLAMATION = `${RECLAMATION} Set`;
export const POST_RECLAMATION = `${RECLAMATION} Post`;
export const POST_TRANSFERT_RECLAMATION = `${RECLAMATION} Transfert Post`;
export const POST_ACCUSE_RECLAMATION = `${RECLAMATION} Accuse Reception Post`;
export const POST_CLOSE_RECLAMATION = `${RECLAMATION} Close Post`;
export const POST_EDIT_DOCUMENT_RECLAMATION = `${RECLAMATION} Edit document`;

export const getReclamation = ({query}) => ({
    type: GET_RECLAMATION,
    payload: {
        data: query
    }
})

export const setReclamation = ({data}) => ({
    type: SET_RECLAMATION,
    payload: {data},
    meta: {
        entity: RECLAMATION
    }
})

export const postReclamation = ({query, form}) => ({
    type: POST_RECLAMATION,
    payload: {
        data: query,
        body: form
    }
})

export const postTransfertReclamation = ({query, form}) => ({
    type: POST_TRANSFERT_RECLAMATION,
    payload: {
        data: query,
        body: form
    }
})

export const postAccuseReceptionReclamation = ({query}) => ({
    type: POST_ACCUSE_RECLAMATION,
    payload: {
        data: query
    }
})

export const postCloseReclamation = ({query, form, otherData}) => ({
    type: POST_CLOSE_RECLAMATION,
    payload: {
        data: query,
        body: form,
        otherData
    }
})

export const postEditDocumentReclamation = ({query, form}) => ({
    type: POST_EDIT_DOCUMENT_RECLAMATION,
    payload: {
        data: query,
        body: form
    }
})
