import React, {useEffect, useState} from 'react';
import {Field} from "redux-form";
import SelectFieldRedux from "../../../../../../Commun/Input/Select/SelectFieldRedux";
import _ from 'lodash'
import moment from 'moment'
import TextFieldRedux from "../../../../../../Commun/Input/Text/TextFieldRedux";
import FieldsVirementsSinistre from "./FieldsVirementsSinistre/FieldsVirementsSinistre";
import CheckboxFieldRedux from "../../../../../../Commun/Input/Checkbox/CheckboxFieldRedux";
import {normalizeBooleanForNoobBelair} from "../../../../../../../utils/normalizeInputs";

const VirementsSinistre = (props) => {
    const {
        rapports_bca,
        contract,
        montant_type_reg,
        sousTotalOne,
        montant_total,
        changeValue,
        beneficiaire,
        type_reglement,
        deduction,
        postesIndem,
        penalites,
        accident,
        totalAccessoires,
        montant_plafond,
        montant_complement,
        reparateurs,
        client,
        facturesCochees,
        rapportsExpertCoches,
        IDA,
        RC,
        hasGarantieEquipement,
        montant_penalite_franchise,
        setBeneficiaire,
        choiceBeneficiaire,
        lese,
        indem_values,
        reglements_en_banque,
        handleSubmit,
        reg_effectue_type,
        postes_indem_penalites_regles,
        penalite_values,
        opposition_montant,
        hasGarantieRfvi,
        penalite_franchise,
        franchise_reglee,
        reglement_cheque,
        // hasGarantieACC10,
        hasGarantieACC20,
        hasGarantieAccessoireEquipement,
        categorie_code,
        hivernage,
        formValues
    } = props

    const [msgErreur, setMsgErreur] = useState(null)
    const [showDoubleValidation, setShowDoubleValidation] = useState(false)
    let nbGarantie = 0

    let sousTotalTwo = 0 + montant_complement
    _.map(postesIndem, poste => {
        sousTotalTwo = _.round(sousTotalTwo + Number(poste.value || 0), 2)
    })

    let sousTotalThree = 0
    _.map(_.reject(penalites, {'text': 'rachat_de_franchise'}), penalite => {
        sousTotalThree = _.round(sousTotalThree + Number(penalite.value || 0), 2)
    })

    const setValeursPreRemplissage = () => {
        //Labels attribués et spécifiques permet de récupérer par déduction les autres
        const fields_attribues =['reparation', 'vehicule', 'remorquage / gardiennage', 'antivol', 'gravage', 'airbag', 'gants', 'casque', 'Facture Gants', 'blouson', 'combinaison', 'bottes', 'Facture Pantalon', 'echappement', 'top_bloc', 'tablier', 'top_case'];
        const montant_autre = _.sumBy(facturesCochees, function (o) { return !_.includes(fields_attribues, o.label) ? o.value : 0;});
        const condition_effets_personnels = !_.startsWith(IDA, 'HI') && RC === '0';
        let fields_comment = [];

        let montant_remorquage_gardiennage =0;
        let montant_accessoires_equipements =0;
        let montant_accessoires_10 =0;
        let montant_accessoires_20 =0;
        let montant_equipements =0;
        let montant_casque_gants =0;
        let montant_effets_personnels =0;
        let montant_moyens_protection =0;
        let montant_majoration_valeur_expertise =0;
        let montant_remboursement_franchise =0;

        const montant_max_equipements = 750;
        const montant_max_casque_et_gants = 250;
        const montant_type_reglement = _.sumBy(rapportsExpertCoches, 'value') + _.sumBy(facturesCochees, function (o) {return _.includes(['reparation', 'vehicule'], o.label) ? o.value : 0;});

        const moyens_protection = _.sumBy(facturesCochees, function (o) {return _.includes(['antivol', 'gravage'], o.label) ? o.value : 0;});
        const blouson = _.sumBy(facturesCochees, function (o) {return  o.label === 'blouson' ? o.value : 0;});
        const combinaison = _.sumBy(facturesCochees, function (o) {return  o.label === 'combinaison' ? o.value : 0;});
        const bottes = _.sumBy(facturesCochees, function (o) {return  o.label === 'bottes' ? o.value : 0;});
        const pantalon = _.sumBy(facturesCochees, function (o) {return  o.label === 'Facture Pantalon' ? o.value : 0;});
        const gants = _.sumBy(facturesCochees, function (o) { return _.includes(['gants', 'Facture Gants'], o.label) ? o.value : 0; });
        const echappement = _.sumBy(facturesCochees, function (o) {return  o.label === 'echappement' ? o.value : 0;});
        const top_bloc = _.sumBy(facturesCochees, function (o) {return  o.label === 'top_bloc' ? o.value : 0;});
        const tablier = _.sumBy(facturesCochees, function (o) {return  o.label === 'tablier' ? o.value : 0;});
        const top_case = _.sumBy(facturesCochees, function (o) {return  o.label === 'top_case' ? o.value : 0;});
        const airbag = _.sumBy(facturesCochees, function (o) {return  o.label === 'airbag' ? o.value : 0;});
        const casque = _.sumBy(facturesCochees, function (o) {return  o.label === 'casque' ? o.value : 0;});

        montant_remorquage_gardiennage += _.sumBy(facturesCochees, function (o) {
            if(o.label === 'remorquage / gardiennage'){
                const maxValue = _.find(postesIndem, r => r?.libelle === 'REMORQUAGE')?.maxValue;

                if(maxValue && o.value > maxValue){
                    return maxValue;
                }
                return o.value
            }
            return 0;
        });

        //Gestion Antivol / Gravage
        montant_moyens_protection += moyens_protection;
        // Gestion Airbag
        montant_effets_personnels += airbag;
        //gestion gants et casque
        if(condition_effets_personnels){
            montant_effets_personnels += gants + casque;
        } else {
            montant_casque_gants += gants + casque;

            //Si montant supérieur au max et pas la garantie alors on bloque au max // sinon ça va dans le complément access et equipement
            if((montant_casque_gants > montant_max_casque_et_gants) && !hasGarantieAccessoireEquipement) {
                montant_casque_gants = montant_max_casque_et_gants;
            } else if(montant_casque_gants > montant_max_casque_et_gants){
                fields_comment = [...fields_comment, 'casque', 'gants', 'Facture Gants' ];
            }
        }

        // Gestion pantalon / blouson / bottes / combinaison // Si pas de garantie on ne pré-rempli pas
        if(condition_effets_personnels){
            montant_effets_personnels += pantalon + blouson + bottes + combinaison;
        } else if(hasGarantieEquipement){
            montant_equipements += pantalon + blouson + bottes + combinaison;
        } else if(hasGarantieAccessoireEquipement){
            montant_accessoires_equipements += pantalon + blouson + bottes + combinaison;
            fields_comment = [...fields_comment, 'pantalon', 'blouson', 'bottes', 'combinaison' ];
        }

        //Gestion Top case / Echappement / Top bloc / Si pas de garantie on ne pré-rempli pas
        if(hasGarantieACC20){
            montant_accessoires_20 += top_bloc + echappement + top_case;
        } else if(hasGarantieAccessoireEquipement){
            montant_accessoires_equipements += top_bloc + echappement + top_case;
            fields_comment = [...fields_comment, 'top_bloc', 'echappement', 'top_case'];
        }

        // Gestion tablier / Si pas de garantie on ne pré-rempli pas
        if(condition_effets_personnels){
            montant_effets_personnels += tablier;
        } else if(hasGarantieACC20){
            montant_accessoires_20 += tablier;
        } else if(hasGarantieAccessoireEquipement){
            montant_accessoires_equipements += tablier;
            fields_comment = [...fields_comment, 'tablier'];
        }

        // Gestion de autres montants - écrit à la main
        if(hasGarantieACC20){
            montant_accessoires_20 += montant_autre;
        } else if(hasGarantieAccessoireEquipement){
            montant_accessoires_equipements += montant_autre;
        }


        if (montant_remorquage_gardiennage > 0) { changeValue(`[indemnisation][0][montant]`, _.round(montant_remorquage_gardiennage, 2) ); }
        if (montant_accessoires_equipements > 0) { changeValue(`[indemnisation][1][montant]`, _.round(montant_accessoires_equipements, 2) ); }
        if (montant_accessoires_10 > 0){ changeValue(`[indemnisation][2][montant]`, _.round(montant_accessoires_10, 2) ); }
        if (montant_accessoires_20 > 0){ changeValue(`[indemnisation][3][montant]`, _.round(montant_accessoires_20, 2) ); }
        if (montant_equipements > 0){
            if(montant_equipements > montant_max_equipements){
                changeValue(`[indemnisation][4][montant]`, _.round(montant_max_equipements, 2));
            } else {
                changeValue(`[indemnisation][4][montant]`, _.round(montant_equipements, 2));
            }
        }
        if (montant_casque_gants > 0){
            if(montant_casque_gants > montant_max_casque_et_gants){
                changeValue(`[indemnisation][5][montant]`, _.round(montant_max_casque_et_gants, 2) );
                changeValue(`[indemnisation][5][complement]`, _.round(montant_casque_gants-montant_max_casque_et_gants, 2));
            } else {
                changeValue(`[indemnisation][5][montant]`, _.round(montant_casque_gants, 2) );
            }
        }
        if (montant_effets_personnels > 0){ changeValue(`[indemnisation][6][montant]`, _.round(montant_effets_personnels, 2) );}
        if (montant_moyens_protection > 0){ changeValue(`[indemnisation][7][montant]`, _.round(montant_moyens_protection, 2) );}
        if (montant_majoration_valeur_expertise > 0){ changeValue(`[indemnisation][8][montant]`, _.round(montant_majoration_valeur_expertise, 2) );}
        if (montant_remboursement_franchise > 0){ changeValue(`[indemnisation][9][montant]`, _.round(montant_remboursement_franchise, 2) );}

        //Gestion Réparation
        changeValue('montant_type_reglement', _.round(montant_type_reglement, 2) );

        // Gestion du commentaire Accessoires et équipements = Tout ce qui s'est déversé dans le champ accessoires et équip 
        let accessEquipementsComment = '';
        _.map(facturesCochees, function (o) {
            if(_.includes(fields_comment, o.label) || (!hasGarantieACC20 && hasGarantieAccessoireEquipement && !_.includes(fields_attribues, o.label))){
                accessEquipementsComment = `${accessEquipementsComment ? `${accessEquipementsComment}, ` : ''}${o.label}`;
            }
        });
        if(accessEquipementsComment){
            changeValue(`[indemnisation][1][commentaire]`, accessEquipementsComment);
        }

        // Gestion du montant total
        changeValue('montant_total', _.round( (montant_remorquage_gardiennage + montant_accessoires_equipements + montant_accessoires_10 + montant_accessoires_20 + montant_equipements + montant_casque_gants + montant_effets_personnels + montant_moyens_protection + montant_majoration_valeur_expertise +  montant_remboursement_franchise + montant_type_reglement) - (montant_penalite_franchise ?? 0), 2));

        if(opposition_montant) {
            changeValue('deduction', 'OPPOSITION')
            changeValue('montant_deduction', Number(accident.oppositions).toFixed(2))
        }

        if(hivernage && (categorie_code === '103' || categorie_code === '104')){
            changeValue(`[penalite][7][libelle]`, 'SURFRAN_HIVER')
            changeValue(`[penalite][7]][type]`, 'penalite')
            changeValue(`[penalite][7]][check]`, 'T')
            changeValue(`[penalite][7]][montant]`, 450)
        }
    }

    const messageValidate = () => {
        const isGarage = _.find(reparateurs, r => r?.id === beneficiaire);
        const indem_values_checked = _.filter(indem_values, i => i?.check && i.check === 'T');

        if(isGarage && type_reglement === 'VALEXPERT'){
            return 'Le véhicule est épave, pas de remboursement au garage.';
        }
        if(isGarage && !_.isEmpty(indem_values_checked)){
            if(_.find(indem_values_checked, i => i?.libelle === 'EQUPMTS' || i?.libelle === 'ACCESSOIRES' || i?.libelle === 'CASQUEGANTS')){
                return 'Les équipements ne sont pas à rembourser au garage.';
            }

            if(!(_.startsWith(IDA, 'HI') && RC === '0') && _.find(indem_values_checked, i => i?.libelle === 'ACC10' || i?.libelle === 'ACC20')){
                return 'Les accessoires ne sont pas à rembourser au garage.';
            }
        }
        if(type_reglement === 'DMGVEHICULE' && _.find(indem_values_checked, i => i?.libelle === 'EFFETPERSO')){
            return 'Vous ne pouvez pas poursuivre le règlement car le type de règlement est “Dommages aux véhicules” et que des effets personnels sont à régler';
        }

        if(!_.find(facturesCochees, ['label', 'remorquage / gardiennage']) && _.find(indem_values_checked, ['libelle', 'REMORQUAGE'])){
            return 'Le remorquage/gardiennage ne peut pas être remboursé car il n\'y a pas de facture enregistrée'
        }

        if(accident.categorieCode === "10" &&
            _.includes(['7311_MOT1', '20_MOTGAN', '7311_SC'], accident.codeProduit) &&
            moment(contract.situation.date) >= moment('2020-05-18') &&
        _.isEmpty(rapports_bca)){
            return 'Vous ne pouvez pas poursuivre le règlement car il n\'y a pas de rapport d\'expert'
        }

        return null;
    }

    //Fonction permettant de gérer l'affichage du bouton de double validation
    const showConfirmButton = () => {
        const form_values_indem_penalites_checked = _.filter(_.concat(indem_values, penalite_values), i => i?.check && i.check === 'T');

        //Si on a déjà un virement de type J identique à celui que l'on cherche à effectuer mais pour un autre bénéficiaire
        if(_.find(reglements_en_banque, function (reg) {return reg.debit === Number(montant_total) && reg.belair_id !== beneficiaire})){
            return true;
        }
        //On vérifie si ce type de règlement a déjà été effectué
        if(reg_effectue_type === type_reglement){
            return true;
        }
        //Si un des postes indemnisation ou pénalité est coché alors qu'il a déjà été réglé
        if(_.find(postes_indem_penalites_regles, function (label) {return _.find(form_values_indem_penalites_checked, {'libelle': label});})){
            return true;
        }
        return false;
    }

    useEffect(() => {
        if(defaultError() === null) {
            const newMessage = messageValidate();
            setMsgErreur(newMessage);
        }

        const show = showConfirmButton();
        if(show !== showDoubleValidation){
            setShowDoubleValidation(show)
            changeValue('verification_manager', show ? 'T' : 'F');
        }
    }, [beneficiaire, type_reglement, montant_total, indem_values, penalite_values])

    useEffect(() => {
        setValeursPreRemplissage();
    }, [facturesCochees, rapportsExpertCoches])

    useEffect(() => {
        const newMessage = defaultError()
        setMsgErreur(newMessage)
    }, [penalite_franchise])

    const defaultError = () => {
        if(hasGarantieRfvi && penalite_franchise && accident.categorieCode === '10') {
            return 'Il ne peut pas y avoir de franchise'
        } else if(!hasGarantieRfvi && !penalite_franchise && accident.categorieCode === '10' && franchise_reglee === null) {
            return 'Une franchise doit être appliquée'
        } else {
            return null
        }
    }

    return (
        <>
            <p className={'titre-section'}>Choix du bénéficiaire et de la garantie sinistrée :</p>
            <div className="row">
                <div className="col">
                    <Field
                        name={'beneficiaire'}
                        component={SelectFieldRedux}
                        label={'Choix du bénéficiaire'}
                        onChange={(e) => setBeneficiaire(e.target.value)}
                    >
                        <option value={null}>Sélectionner le bénéficiaire</option>
                        {reparateurs &&
                            reparateurs.map((r, index) => {
                                return <option key={index} value={r.id}>Réparateur agréé : {r.raisonSociale}</option>
                            })
                        }
                        {client && <option value={`${client.id}`}>Client : {client.raisonSociale}</option>}
                        {lese &&
                            lese.map((l, index) => {
                                return <option key={index} value={l.id}>Lésé : {l.raisonSociale}</option>
                            })
                        }
                    </Field>

                    {
                        beneficiaire === 'autre' && <Field
                            name={'autre_beneficiaire'}
                            component={TextFieldRedux}
                            label={'Autre bénéficiaire'}
                        />
                    }

                    {
                        choiceBeneficiaire && beneficiaire !== 'autre' &&
                        <>
                            {
                                choiceBeneficiaire.iban && choiceBeneficiaire.nb_ibans === 1 &&
                                <div>
                                    <Field
                                        name={`reglement_cheque`}
                                        component={CheckboxFieldRedux}
                                        label={`Je souhaite effectuer le règlement par chèque`}
                                        normalize={normalizeBooleanForNoobBelair}
                                    />

                                    {
                                        reglement_cheque !== 'T' &&
                                        <>
                                            <Field
                                                name={'iban'}
                                                component={TextFieldRedux}
                                                label={'IBAN'}
                                            />

                                            <Field
                                                name={'titulaire'}
                                                component={TextFieldRedux}
                                                label={'Titulaire du compte'}
                                            />
                                        </>
                                    }
                                </div>
                            }
                            {
                                choiceBeneficiaire.nb_ibans > 1 &&
                                <p className={'title-primary text-center'}>Plusieurs IBAN sont présents sur le
                                    contrat.<br/>La validation du virement fera un popup REG_CHQ</p>
                            }
                            {
                                choiceBeneficiaire.nb_ibans === 0 &&
                                <p className={'title-primary text-center'}>Pas d'IBAN de renseigné, contacter le
                                    bénéficiaire au : {choiceBeneficiaire.phone}</p>
                            }
                        </>
                    }
                </div>
                <div className="col">
                    <Field
                        name={'garantie'}
                        component={SelectFieldRedux}
                        label={'Choix de la garantie sinistrée'}
                    >
                        <option value={null}>Sélectionner la garantie sinistrée</option>
                        {
                            accident.garanties.map((g, index) => {
                                if (g.sinistree === 'OUI') {
                                    nbGarantie++
                                    return <option value={g.code} key={index}>{g.libelle}</option>
                                }
                                return ''
                            })
                        }
                    </Field>

                    <Field
                        name={'montant_total'}
                        component={TextFieldRedux}
                        label={'Montant du règlement total'}
                    />
                </div>
            </div>
            {nbGarantie > 1 &&
                <div className={'alert alert-warning alert-light alert-smaller'}>Si vous avez deux garanties sinistrées,
                    merci de sélectionner la garantie principale (ex. : choisir "Dommages Tous accidents" plutôt que le
                    "Rachat de franchise DTA")</div>}

            <p className={'titre-section'}>Type de règlement et déductions :</p>
            <div className="row">
                <div className="col">
                    <Field
                        name={'type_reglement'}
                        component={SelectFieldRedux}
                        label={'Type de règlement'}
                    >
                        <option value={null}>Sélectionner le type de règlement</option>
                        <option value="DMGVEHICULE">Dommages au véhicule</option>
                        <option value="VALEXPERT">Valeur à dire d'expert</option>
                        <option value="VALACHAT">Valeur d'achat</option>
                        <option value="VALACHAT1224M">Valeur d'achat 12 - 24 mois</option>
                        <option value="REMBCOMPL">Remboursement Complémentaire</option>
                        <option value="AUTRE">Autres</option>
                    </Field>

                    {
                        type_reglement === 'AUTRE' && <Field
                            name={'autre_type_reglement'}
                            component={TextFieldRedux}
                            label={'Autre type de règlement'}
                        />
                    }

                    {
                        type_reglement !== 'REMBCOMPL' && <Field
                            name={'montant_type_reglement'}
                            component={TextFieldRedux}
                            label={'Montant du type de règlement'}
                        />
                    }
                </div>
                <div className="col">
                    <Field
                        name={'deduction'}
                        component={SelectFieldRedux}
                        label={'Déduction'}
                    >
                        <option value={null}>Sélectionner la déduction</option>
                        <option value="VETUSTE">Vétusté</option>
                        <option value="VALSAUVETAGE">Valeur de sauvetage</option>
                        <option value="OPPOSITION">Opposition</option>
                        <option value="INDEMDEJAREGLE">Indemnité déjà réglée</option>
                        <option value="INDEMDJREGLGAR">Indemnité déjà réglée au garage</option>
                        <option value="AUCUNE">Aucune</option>
                    </Field>

                    {
                        deduction !== 'AUCUNE' && <Field
                            name={'montant_deduction'}
                            component={TextFieldRedux}
                            label={'Montant de la déduction'}
                        />
                    }

                    {
                        (deduction && deduction === 'INDEMDEJAREGLE') && <Field
                            name={'deduction_commentaire'}
                            component={TextFieldRedux}
                            label={'Commentaire'}
                        />
                    }
                </div>
            </div>

            <div className="row">
                <div className="col">
                    <Field
                        name={'categorie_reglement'}
                        component={SelectFieldRedux}
                        label={'Catégorie du règlement'}
                    >
                        <option value={null}>Sélectionner la catégorie de règlement</option>
                        <option value="1">Valeur principale</option>
                        <option value="2">Valeur secondaire</option>
                    </Field>
                    <Field
                        name={'valeur_cycle'}
                        component={TextFieldRedux}
                        label={'Montant de la valeur du cycle'}
                    />
                </div>
                <div className="col">
                    <Field
                        name={'plafond_acc_eqp'}
                        component={TextFieldRedux}
                        label={'Montant de plafond accessoires et équipements'}
                    />
                </div>
            </div>

            <p className={'title-primary text-center'}>Sous total 1 : {sousTotalOne} €<br/>
                <span
                    className={'bold subtitle font-italic'}>{`Montant du type de règlement - montant des déductions`}</span>
            </p>

            {(totalAccessoires > montant_plafond) && <div className="alert alert-danger" role="alert">
                Attention !<br/>
                Le montant des accessoires et équipements ne doivent pas dépasser le plafond accessoires et équipements
            </div>}
            <p className={'titre-section'}>Autres postes d'indemnisation :</p>
            <div className="row">
                {
                    postesIndem.map((poste, index) =>
                        <div className="col-6" key={index}>
                            <FieldsVirementsSinistre poste={poste} changeValue={changeValue} type={'indemnisation'}
                                                     index={index} montantTypeReg={montant_type_reg} formValues={formValues}/>
                        </div>
                    )
                }
            </div>

            <p className={'title-primary text-center'}>Sous total 2 : {sousTotalTwo} €<br/>
                <span className={'bold subtitle font-italic'}>Total des postes d'indemnisation</span>
            </p>

            <p className={'titre-section'}>Pénalités :</p>
            <div className="row">
                {
                    penalites.map((poste, index) =>
                        <div className="col-6" key={index}>
                            <FieldsVirementsSinistre poste={poste} changeValue={changeValue} type={'penalite'}
                                                         index={index}/>
                        </div>
                    )
                }
            </div>
            <p className={'title-primary text-center'}>Sous total 3 : {sousTotalThree} €<br/>
                <span className={'bold subtitle font-italic'}>Total des pénalités</span>
            </p>

            <p className={'title-primary text-center'}>Total
                : {_.round(sousTotalOne + sousTotalTwo - sousTotalThree, 2)} € <br/>
                <span
                    className={'bold subtitle font-italic'}>{`Sous total 1 : ${sousTotalOne}€ + Sous total 2 : ${sousTotalTwo}€ - Sous total 3 : ${sousTotalThree}€`}</span>
            </p>

            {
                (_.round(sousTotalOne + sousTotalTwo - sousTotalThree, 2)) !== Number(montant_total || 0) &&
                <div className="alert alert-warning" role="alert">
                    Attention !<br/>
                    Le <b>Total global : {_.round(sousTotalOne + sousTotalTwo - sousTotalThree, 2)}€</b> doit être
                    identique au <b>Montant du règlement total : {montant_total || 0}€</b>
                </div>
            }

            { msgErreur && <div className="alert alert-danger" role="alert">{msgErreur}</div> }

            <button className={'btn btn-primary float-right'}
                    onClick={(e) => {
                        if(showDoubleValidation){
                            e.preventDefault();
                            if (window.confirm('Un poste d’indemnisation similaire a déjà été réglé sur ce sinistre. Êtes-vous sûr(e) de vouloir continuer ?')) {
                                handleSubmit();
                            }
                        }
                    }}
                    disabled={msgErreur || (_.round(sousTotalOne + sousTotalTwo - sousTotalThree, 2)) !== Number(montant_total)}
            >
                Valider
            </button>
        </>
    );
};

export default VirementsSinistre;
