import React, {useEffect, useState} from 'react';
import './nouveau_devis.scss'
import {getPros} from "../../../../../utils/function";
import _ from "lodash";

const NouveauDevis = (props) => {
    const {SubscribeLinks} = props
    const [velo, setVelo] = useState(null)
    const [trottinette, setTrottinette] = useState(null)
    const [autres, setAutres] = useState(null)
    const [courtiers, setCourtiers] = useState(null)

    const handleChange = (value) => {
        setVelo(SubscribeLinks[value].velo ?? null)
        setTrottinette(SubscribeLinks[value].trottinette ?? null)
        setAutres(SubscribeLinks[value].autres)
    }

    useEffect(() => {
        fetchCourtiers();
    }, [])

    async function fetchCourtiers() {
        let arrayCourtiers = await getPros()
        let listCourtiers = _.orderBy(arrayCourtiers, ['name'], ['asc'])

        listCourtiers = listCourtiers.map(c => ({
            value: c.belair_id,
            label: c.code_belair + ' - ' + c.raison_sociale
        }))

        setCourtiers(listCourtiers)
    }

    return (
        <div className={'navigation-devis container'}>
            <div className={'row header-row'}>
                <div className={'col-12'}>
                    <h1 className={'title-primary title-task'}>Nouveau devis</h1>
                    <select
                        name="apporteur_devis"
                        onChange={(e) => handleChange(e.target.value)}
                        className={'small-select-contrat select-filter-tasks custom-select mb-5'}
                    >
                        <option value={''}>Choix de l'apporteur</option>
                        <option value="april_moto">APRIL Moto</option>
                        <option value="meilleur_taux">Meilleur Taux</option>
                        <option value="les_furets">Les Furets</option>
                        <option value="lfa">Les Furets Assurance</option>
                        <option value="le_lynx">Le Lynx</option>
                        <option value="lsa">LSA Courtage by AM Gestion</option>
                        <option value="rva">RVA Assurances by AM Gestion</option>
                        <option value="assurpeople">Assurpeople by AM Gestion</option>
                    </select>
                </div>
            </div>
            <div className="row header-row">
                {autres !== null &&
                    <div className={'col-12 col-md-6 col-xl-4 mb-4'}>
                        <a href={autres} target={'_blank'} rel="noopener noreferrer"
                           className={`bloc-icon d-block text-center py-5 px-2`}>
                            <div className={'icone icone-moto'}/>
                            <div className={'d-block mt-4'}>
                                <span className={'font-weight-bold'}>2-roues</span>
                            </div>
                        </a>
                    </div>
                }
                {velo !== null &&
                    <div className={'col-12 col-md-6 col-xl-4 mb-4'}>
                        <a href={velo} target={'_blank'} rel="noopener noreferrer"
                           className={`bloc-icon d-block text-center py-5 px-2`}>
                            <div className={'icone icone-velo'}/>
                            <div className={'d-block mt-4'}>
                                <span className={'font-weight-bold'}>Vélo</span>
                            </div>
                        </a>
                    </div>
                }
                {trottinette !== null &&
                    <div className={'col-12 col-md-6 col-xl-4 mb-4'}>
                        <a href={trottinette} target={'_blank'} rel="noopener noreferrer"
                           className={`bloc-icon d-block text-center py-5 px-2`}>
                            <div className={'icone icone-trottinette'}/>
                            <div className={'d-block mt-4'}>
                                <span className={'font-weight-bold'}>Trottinette</span>
                            </div>
                        </a>
                    </div>
                }
            </div>
        </div>
    );
};

export default NouveauDevis;
