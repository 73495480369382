import React from 'react';
import PropTypes from 'prop-types'


const DetailsReglementRow = (props) => {
    const {title, value} = props

    return (
        <div className={'row mb-2'}>
            <div className={'col-6'} dangerouslySetInnerHTML={{__html: title ? `${title}&#8239;: ` : ''}}/>
            <div className={'col-6'}><b>{value}</b></div>
        </div>
    );
};


DetailsReglementRow.defaultProps = {
    title: ''
};


DetailsReglementRow.propTypes = {
    title: PropTypes.string,
    value: PropTypes.string.isRequired,
};


export default DetailsReglementRow;
