import React, {Fragment} from 'react'
import moment from "moment";
import {etatDevis} from "../../../../../../utils/valueMatching";
import {Link} from "react-router-dom";
import MyButton from "../../../../../Commun/Button/Button";
import {redirectInNewWindow} from "../../../../../../utils/function";
import {openInWindow} from "../../../../../../constants/constants";

const Info = ({quote}) => {
    return (
        <div className={'bloc-info bloc-contrat'}>
            <h2>Devis</h2>
            <div className={'content row'}>
                <div className={'col-5 left-content'}>
                    <p>Etat du devis :</p>
                    <p>Acte de gestion :</p>
                    <p>Création du devis :</p>
                    <p>Fractionnement :</p>
                    <p>Prime fractionnée TTC :</p>
                    <p>Prime annuelle :</p>
                    <p>Formule :</p>
                    <p>Options :</p>
                    <p>Réalisateur :</p>
                    <p>Apporteur 1 :</p>
                    {quote.apporteur2.name && <p>Apporteur 2 :</p>}
                </div>
                <div className={'col-7 right-content'}>
                    <p>{etatDevis(quote.etat)}</p>
                    <p>{quote.acteGestion === 'AN' ? 'Affaire nouvelle' : 'Avenant'}</p>
                    <p>{moment(quote.createdDate).format('L')}</p>
                    <p>{quote.fractionnement}</p>
                    <p>{quote.primeFractionneTTC}€</p>
                    <p>{quote.primeFA}€</p>
                    <p>{quote.libelleFormule}</p>
                    <p>{quote.listeOptions || '-'}</p>
                    <p>{quote.realisateur_name || '-'}</p>
                    {
                        quote.apporteur1.id === '43397'
                        ? <p>{quote.apporteur1.name}</p>
                        : <p><a href={'#'}  onClick={(e) => redirectInNewWindow({
                                e,
                                url: `/tiers/${quote.apporteur1.id}`,
                                name: openInWindow.tiers
                            })}>{quote.apporteur1.name}</a></p>
                    }
                    {quote.apporteur2.name &&
                        <p><a href={'#'} onClick={(e) => redirectInNewWindow({
                                e,
                                url: `/tiers/${quote.apporteur2.id}`,
                                name: openInWindow.tiers
                            })}>{quote.apporteur2.name}
                        </a></p>
                    }
                </div>
            </div>
        </div>
    )
}

export default Info
