import React from 'react'
import CallToAction from "../../../../../Commun/Button/CallToAction/CallToAction";
import {redirectInNewWindow} from "../../../../../../utils/function";
import {openInWindow} from "../../../../../../constants/constants";

const ButtonsGarage = (props) => {
    const {tiers, postLogRib} = props

    const handleClickFormRib = () => {
        postLogRib()
    }

    return (
        <div className={'buttons'}>
            <CallToAction
                url={{pathname: `/tiers/${tiers.belair_id}/modifier-informations`, state: {tiers: tiers}}}
                action={'coordonnees'}
                toolText={'Modifier les coordonnées'}
            />

            <CallToAction
                action={'add-chrono'}
                toolText={'Ajouter un chrono / agenda'}
                onClick={(e) => redirectInNewWindow({
                    e,
                    url: `/tiers/${tiers.belair_id}/ajout-suivi`,
                    name: openInWindow.addActivities
                })}
            />
            <CallToAction
                url={`/tiers/${tiers.belair_id}/modifie-rib`}
                action={'validate_rib'}
                toolText={'Modifier le RIB'}
                onClick={() => {
                    handleClickFormRib()
                }}
            />
        </div>
    )
}

export default ButtonsGarage
