import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import {change, reduxForm} from "redux-form";
import AddTiers from "./AddTiers";
import {createTiersGetLink} from "../../../../../redux/selectors/links/links.selectors";
import {getLoadingEntity} from "../../../../../redux/selectors/ui/ui.selectors";
import {POST_CREATE_TIERS, postCreateTiers} from "../../../../../redux/actions/app/tiers/tiers.actions";
import validate from "./validate";

const mapStateToProps = (state, ownProps) => {
    return {
        uri: createTiersGetLink(state),
        accidentId: ownProps.location.state.accident,
        contractId: ownProps.location.state.contract,
        loading: getLoadingEntity(state, POST_CREATE_TIERS),
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    changeValue: (field, value) => change(`add_tiers`, field, value),
    submitPostCreateTiers: ({form, query}) => postCreateTiers({form, query}),
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {uri, accidentId, contractId} = stateProps
    const {submitPostCreateTiers} = dispatchProps


    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        onSubmit: (form) => {
            form.type = '1'
            form.accident = accidentId
            form.contract = contractId
            submitPostCreateTiers({query: uri, form})
        }
    }
}

const AddTiersContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    reduxForm({
        form: 'add_tiers',
        validate
    }),
)(AddTiers)


export default AddTiersContainer
