import {API_ERROR, API_SUCCESS, apiRequest} from '../../../actions/core/api/api.actions'
import {setError, setLoader} from '../../../actions/app/ui/ui.actions'
import {setNotification} from "../../../actions/core/notifications/notifications.actions";
import {
    POST_UPDATE_PREMIUM,
    UPDATE_PREMIUM,
    setUpdatePremium
} from "../../../actions/app/status_premium/status_premium.actions";


export const updatePremiumMiddleware = () => next => action => {
    next(action)

    const {payload} = action
    let message = null

    switch (action.type) {
        case POST_UPDATE_PREMIUM:

            next([
                apiRequest({
                    body: (payload.body),
                    method: 'POST',
                    url: action.payload.data,
                    entity: UPDATE_PREMIUM
                }),
                setLoader({state: true, entity: UPDATE_PREMIUM})
            ])
            break

        case `${UPDATE_PREMIUM} ${API_SUCCESS}`:
            message = `<p>Le statut Premium du courtier a bien été mis à jour</p>`
            next([
                setUpdatePremium({state: true}),
                setNotification({
                    entity: UPDATE_PREMIUM,
                    body: message,
                    type: 'success',
                    title: 'Statut du courtier modifié!'
                }),
                setLoader({state: false, entity: UPDATE_PREMIUM}),
                setError({state: false, entity: UPDATE_PREMIUM}),
            ])
            break

        case `${UPDATE_PREMIUM} ${API_ERROR}`:
            message = `
                <p>Votre demande n'a pas pu être envoyée. ${payload.data.response.data.reason}</p>
            `
            next([
                setNotification({
                    entity: UPDATE_PREMIUM,
                    body: message,
                    type: 'error',
                    title: 'Erreur lors de l\'edition !',
                    timeout: '0'
                }),
                setError({state: true, entity: UPDATE_PREMIUM}),
                setLoader({state: false, entity: UPDATE_PREMIUM}),
            ])
            break

        default:
            break
    }
    return null
}
