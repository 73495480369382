import CallToAction from "../../../../Commun/Button/CallToAction/CallToAction";
import {redirectInNewWindow} from "../../../../../utils/function";
import {listMailJet, openInWindow} from "../../../../../constants/constants";
import React, {Component} from "react";
import _ from "lodash";

class Buttons extends Component {
    handleClickEditDocument = () => {
        this.props.postLogActionEditDocument()
    }

    handleClickFormRib = () => {
        this.props.postLogActionFormRib()
    }

    redirect = (e) => {
        e.preventDefault()
        redirectInNewWindow({
            e,
            url: `/contrat/${this.props.idContract}/sinistre/${this.props.accident.id}/virements`,
            name: openInWindow.virements
        })
    }

    render() {
        const {idContract, accident, souscripteur, noteUrl, publishUrl, reclamations, permissions} = this.props

        return (
            <div className={'buttons'}>
                <CallToAction
                    action={'edit-document'}
                    toolText={'Editer un document'}
                    url={`${publishUrl}&contrat=${idContract}&tiers=${souscripteur.id}&sinistre=${accident.id}`}
                    publish
                    link_externe
                    onClick={() => {
                        this.handleClickEditDocument()
                    }}
                />
                {_.includes(permissions, 'update accident informations') &&
                    <CallToAction
                        action={'edit-accident'}
                        toolText={'Modification sinistre'}
                        url={`/contrat/${idContract}/sinistre/${accident.id}/edition-sinistre`}
                    />
                }
                <CallToAction
                    action={'add-chrono'}
                    toolText={'Ajouter un chrono / agenda'}
                    onClick={(e) => redirectInNewWindow({
                        e,
                        url: `/contrat/${idContract}/sinistre/${accident.id}/ajout-suivi`,
                        name: openInWindow.addActivities
                    })}
                />
                <CallToAction
                    url={{
                        pathname: `/contrat/${idContract}/sinistre/${accident.id}/note`,
                        state: {
                            url: noteUrl,
                            title: `${souscripteur.titre} ${souscripteur.nom} ${souscripteur.id} - Sinistre n°${accident.numCbt} - ${accident.marqueVehicule} ${accident.modeleVehicule}`
                        }
                    }}
                    action={'add-note'}
                    toolText={'Ajouter une note'}
                    disabled={accident.total_notes >= 2}
                    disabledReason={accident.total_notes >= 2 && 'Le sinistre ne peut pas avoir plus de 2 notes d\'informations'}
                />
                <CallToAction
                    action={'paiement'}
                    toolText={'Effectuer un règlement'}
                    onClick={(e) => this.redirect(e)}
                />
                <CallToAction
                    action={'email'}
                    toolText={'Envoyer un email'}
                    onClick={(e) => redirectInNewWindow({
                        e,
                        url: `/envoyer-email/libre/${listMailJet.libre}/${souscripteur.id}/${idContract}/${accident.id}`,
                        name: `${openInWindow.emailAccident}${accident.id}`
                    })}
                />
                <CallToAction
                    action={'reclamation'}
                    toolText={'Ouvrir une réclamation'}
                    onClick={(e) => redirectInNewWindow({
                        e,
                        url: `/reclamation/${souscripteur.id}/${idContract}/${accident.id}`,
                        name: openInWindow.formulaire
                    })}
                    data-class={reclamations.length > 0 ? 'hasReclamation' : null}
                />

                {
                    accident.intervenants && !_.isEmpty(_.filter(accident.intervenants, function (i) {
                        return !i.iban && (i.type === 'Client' || i.type === 'Réparateur');
                    })) ?
                        <CallToAction
                            action={'validate_rib'}
                            toolText={'Ajout d\'un RIB'}
                            onClick={(e) => {
                                this.handleClickEditDocument();
                                redirectInNewWindow({
                                    e,
                                    url: `/contrat/${this.props.idContract}/sinistre/${accident.id}/modifie-rib`,
                                    name: openInWindow.ribAccident
                                });
                            }}
                        /> : ''
                }
                <CallToAction
                    action={'derogation'}
                    toolText={'Créer une dérogation / fidélisation'}
                    onClick={(e) => redirectInNewWindow({
                        e,
                        url: `/derogation/${souscripteur.id}/${this.props.idContract}/${accident.id}`,
                        name: `${openInWindow.derogation}${this.props.idContract}-${accident.id}`
                    })}
                />
            </div>
        )
    }
}

export default Buttons
