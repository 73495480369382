import React, {Fragment} from 'react'
import moment from "moment";
import APIFrame from "../../../../../Commun/APILink/APIFrame";
import APIImage from "../../../../../Commun/APILink/APIImage";

const ResB = (props) => {
    const {task: {title, description, date, type, tiers_id}, files} = props

    return (
        <Fragment>
            <div className={'row'}>
                <div className={'col text-center'}>
                    <h2 className={'title-primary text-uppercase'}>Tâche RES_B - Tiers n°{tiers_id}</h2>
                    <p className={'subtitle'}>Tâche du {moment(date).format('L')}. Recu par {type}</p>
                </div>
            </div>
            <div className={'header-row row'}>
                {files !== undefined &&
                    files.map((file, index) => (
                        file.extension.toLowerCase() === 'pdf' ?
                            <div className={'col-8'} style={{marginBottom: 30}} key={index}>
                                <APIFrame src={`${file.url}`} title={`${file.code}`} frameBorder="0"
                                        style={{height: '100%', width: '100%', marginBottom: 30}}/>
                            </div>
                            :
                            <div className={'col-7 task-img-container'} key={index}>
                                <APIImage src={`${file.url}`} ref={el => this.refImg = el} alt={'Document'}/>
                            </div>
                    ))
                }
                <div className={`col-md-4 ${files !== undefined ? '' : 'offset-md-4'}`}>
                    <p className={'form-content text-center'}>
                        La demande de résiliation n'a pas pu être traitée sur l'espace conseiller. Merci de repasser sur
                        le dossier pour valider les informations clients.
                    </p>

                    <p className={'form-content text-center'}>
                        Type : {title}<br/>
                        Description : {description ? <span dangerouslySetInnerHTML={{__html: description}}/> : '-'}
                    </p>
                </div>
            </div>
        </Fragment>
    )
}

export default ResB
