import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'

import TaskPriosContent from './TaskPriosContent'
import ErrorHttpComponent from '../../../Commun/Error/ErrorHttpComponent'
import {getTaskPrios, TASKPRIOS} from '../../../../redux/actions/app/taskprios/tasks.actions'
import {taskPrioDetailGetLink, taskPriosLink} from '../../../../redux/selectors/links/links.selectors'
import {getLoadingEntity} from "../../../../redux/selectors/ui/ui.selectors";
import {getTaskPriosTotal} from "../../../../redux/selectors/taskprios/tasks.selectors";
import loader from "../../../Commun/Spinner/Loader/Loader";

const mapStateToProps = state => {
    return {
        uri: taskPriosLink(state),
        uri_detail: taskPrioDetailGetLink(state),
        total: getTaskPriosTotal(state) || {},
        loaded: getLoadingEntity(state, TASKPRIOS) === false
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    getTaskPrios: ({query, portefeuille, date_start, date_end, score}) => getTaskPrios({
        query,
        portefeuille,
        date_start,
        date_end,
        score
    })
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {getTaskPrios} = dispatchProps
    const {uri} = stateProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        load: () => getTaskPrios(
            {
                query: uri,
                portefeuille: localStorage.getItem('portefeuille') || '',
                date_start: localStorage.getItem('date_start_afn') || '',
                date_end: localStorage.getItem('date_end_afn') || '',
                score: localStorage.getItem('score') || '',
            }),
    }
}

const TaskPriosContentContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader({ErrorIndicator: ErrorHttpComponent})
)(TaskPriosContent)

export default TaskPriosContentContainer
