import React, {Fragment, Component} from 'react';
import {Field} from 'redux-form'
import {Link} from "react-router-dom";
import Badge from "../../../../../Commun/Badge/Badge";
import DeclarationContainer from "./Steps/Declaration/DeclarationContainer";
import AdversairesContainer from "./Steps/Adversaires/AdversairesContainer";
import Blesses from "./Steps/Blesses/Blesses";
import TemoinsContainer from "./Steps/Temoins/TemoinsContainer";
import showStep from "./Steps/showStep";
import Spinner from "../../../../../Commun/Spinner/Spinner";
import DeclarationBisContainer from "./Steps/DeclarationBis/DeclarationBisContainer";
import ConstatContainer from "./Steps/Constat/ConstatContainer";
import PoliceContainer from "./Steps/Police/PoliceContainer";
import ClientContainer from "./Steps/Client/ClientContainer";
import CirconstancesContainer from "./Steps/Circonstances/CirconstancesContainer";
import DegatsContainer from "./Steps/Degats/DegatsContainer";
import ChocsContainer from "./Steps/Chocs/ChocsContainer";
import _ from "lodash";
import ResponsabiliteContainer from "./Steps/Responsabilite/ResponsabiliteContainer";
import DommageVehiculeContainer from "./Steps/DommageVehicule/DommageVehiculeContainer";
import GarantiesContainer from "./Steps/Garanties/GarantiesContainer";
import DocumentsContainer from "./Steps/Documents/DocumentsContainer";
import DeclarationTerContainer from "./Steps/DeclarationTer/DeclarationTerContainer";
import pdfIda from './../../../../../../assets/documents/cas_ida.pdf'

class InitForm extends Component {
    constructor(props) {
        super(props)
        this.state = {
            firstCheck: false,
            secondCheck: false,
            hideFirstForm: false,
            hideSecondForm: true,
            emailIsSet: false
        }
    }

    componentDidMount() {
        this.props.logAction()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.submitFailed && document.getElementsByClassName('is-invalid')[0]) {
            document.getElementsByClassName('is-invalid')[0].scrollIntoView({behavior: 'smooth', block: "center"})
        }
    }

    setEmailToClient = cause => {
        if (!this.state.emailIsSet) {
            this.props.postRejectAccident(cause)
            this.setState({
                emailIsSet: !this.state.emailIsSet
            })
        }
    }

    changeValidFirstCheck = valid => {
        this.setState({
            firstCheck: valid
        })
    }

    changeValidSecondCheck = valid => {
        this.setState({
            secondCheck: valid
        })
    }


    render() {

        const {
            contract,
            handleSubmit,
            categorie,
            identify,
            temoin,
            changeValue,
            blesse,
            type_sinistre,
            formValues,
            postCasIda,
            loadedIda,
            vehiculeDommage,
            loading,
            constatTiersCheck,
            constatAssureCheck,
            constatCheck,
            listDocuments,
            getDocumentsAccident,
            conducteur,
            adversaireNom,
            adversairePrenom,
            roles,
            belairId
        } = this.props

        /* Liste belair ID des users qui ont accès au nouveau formulaire */
        const belairIdAuth = [547754, 500054, 500002, 500236, 549069, 548961, 500038, 500040, 500067, 514346, 500161, 500083, 500004, 500249, 550841, 500035]

        const changeForm = () => {
            this.setState({
                hideFirstForm: false,
                hideSecondForm: true
            })
        }

        const calculIda = (values) => {
            let regex = /\d+/g;
            let arrayA = []
            let arrayB = []
            let obj = []
            let formCirc = _.get(values, ['circonstances']) || {}

            _.map(formCirc, function (o, n) {
                if (_.startsWith(n, 'coche_a') && o === true) {
                    arrayA.push(_.split(n, 'coche_a')[1]);
                }
            });
            arrayA.sort()
            _.map(arrayA, function (a, i) {
                if (i === 0) {
                    let found = a.match(regex)
                    obj.vehicule_a = String(a).replace(/\D/g, '')
                    let letter = a.substring(a.indexOf(found[0]) + 1)
                    if (letter === 'b') {
                        obj.type = 'arret'
                    }
                } else if (i === 1) {
                    let found = a.match(regex)
                    obj.vehicule_a_bis = String(a).replace(/\D/g, '')
                    let letter = a.substring(a.indexOf(found[0]) + 1)
                    if (letter === 'b') {
                        obj.type = 'arret'
                    }
                }
            })
            _.map(formCirc, function (o, n) {
                if (_.startsWith(n, 'coche_b') && o === true) {
                    arrayB.push(_.split(n, 'coche_b')[1]);

                }
            });
            arrayB.sort()
            _.map(arrayB, function (a, i) {
                if (i === 0) {
                    let found = a.match(regex)
                    obj.vehicule_b = String(a).replace(/\D/g, '')
                    let letter = a.substring(a.indexOf(found[0]) + 1)
                    if (letter === 'b') {
                        obj.type = 'arret'
                    }
                } else if (i === 1) {
                    let found = a.match(regex)
                    obj.vehicule_b_bis = String(a).replace(/\D/g, '')
                    let letter = a.substring(a.indexOf(found[0]) + 1)
                    if (letter === 'b') {
                        obj.type = 'arret'
                    }
                }
            })

            postCasIda(obj)
            this.setState({
                hideFirstForm: true,
                hideSecondForm: false
            })
        }

        return (
            <div className={'contract-container container constat-container'}>
                <div className={'row'}>
                    <div className={'col-12'}>
                        <h1 className={'title-primary'}>Vous déclarez un sinistre sur le
                            tiers&nbsp;:&nbsp;{contract.souscripteur.nom}</h1>
                    </div>
                    <div className={'col-12'}>
                        <Badge status={contract.situation.statut}/>
                        <span
                            className={'bold text-uppercase subtitle'}>Contrat n°{contract.numeroPolice} - {contract.title}</span>
                    </div>
                </div>
                <form onSubmit={handleSubmit} onKeyPress={event => {
                    if (event.which === 13 /* Enter */) {
                        event.preventDefault();
                    }
                }}>
                    {
                        !this.state.hideFirstForm && <Fragment>
                            <div className="row header-row">
                                <div className="col">
                                    <DeclarationContainer changeValue={changeValue} setEmailToClient={this.setEmailToClient}
                                                          changeValidFirstCheck={this.changeValidFirstCheck}
                                                          changeValidSecondCheck={this.changeValidSecondCheck}
                                                          getDocumentsAccident={getDocumentsAccident}/>
                                </div>
                            </div>

                            <div id="declaration-ter">
                                {
                                    this.state.firstCheck && <Fragment>
                                        {showStep({step: 'constat', categorie, typeSinistre: type_sinistre}) &&
                                            <div className="row margin-top card-sinistre">
                                                <div className="col">
                                                    <ConstatContainer/>
                                                </div>
                                            </div>
                                        }
                                        {(constatAssureCheck === 'F' || constatTiersCheck === 'F') &&
                                            <div className="row margin-top card-sinistre">
                                                <div className="col">
                                                    <PoliceContainer getDocumentsAccident={getDocumentsAccident}/>
                                                </div>
                                            </div>
                                        }
                                    </Fragment>
                                }
                            </div>

                            {
                                this.state.firstCheck && showStep({
                                    step: 'declarationBis',
                                    categorie,
                                    typeSinistre: type_sinistre
                                }) &&
                                <div className="row margin-top card-sinistre">
                                    <div className="col">
                                        <DeclarationBisContainer setEmailToClient={this.setEmailToClient}
                                                                 changeValidSecondCheck={this.changeValidSecondCheck}/>
                                    </div>
                                </div>
                            }
                            <div id="declaration-pers">
                                {this.state.secondCheck && <div className="row margin-top card-sinistre">
                                    {showStep({step: 'blesses', blesse}) &&
                                        <Blesses/>
                                    }
                                    {showStep({step: 'temoin', categorie, temoin}) &&
                                        <TemoinsContainer changeValue={changeValue}/>
                                    }
                                </div>}
                            </div>
                            {this.state.secondCheck && <div id="declaration-circ">
                                <h2 className={'text-center header-row'}>Véhicules impliqués</h2>

                                <div className="row header-row card-sinistre">
                                    <ClientContainer/>

                                    {
                                        showStep({step: 'adversaires', categorie, identify}) &&
                                        <AdversairesContainer changeValue={changeValue}/>
                                    }
                                </div>
                                {constatCheck === 'T' && <Fragment>
                                    <div className="col-md-12 col-sm-12" style={{marginBottom: 55}}>
                                        <h2 className={'text-center header-row'}>Circonstances</h2>
                                        <p className={'text-center'}>Mettre une croix dans chacune des cases <br/>utiles
                                            pour préciser le croquis</p>
                                        <span
                                            className={'float-left font-weight-bold h5'}>Notre assuré<br/>{conducteur}</span>
                                        <span
                                            className={'float-right font-weight-bold h5'}>Adversaire<br/>{adversaireNom} {adversairePrenom}</span>
                                    </div>
                                    <div className="row margin-top card-sinistre">
                                        <div className="col">
                                            <CirconstancesContainer form={formValues} changeValue={changeValue}/>
                                        </div>
                                    </div>
                                </Fragment>}
                                <div className="row margin-top card-sinistre">
                                    <div className="col">
                                        <DeclarationTerContainer/>
                                    </div>
                                </div>
                                <div className="row margin-top card-sinistre">
                                    <div className="col">
                                        <ChocsContainer/>
                                    </div>
                                </div>
                                <div className="row margin-top card-sinistre">
                                    <div className="col">
                                        <DegatsContainer getDocumentsAccident={getDocumentsAccident}/>
                                    </div>
                                </div>
                                <span className={'btn btn-primary btn-lg btn-block margin-top position-relative'}
                                      onClick={(e) => {
                                          e.preventDefault()
                                          calculIda(formValues)
                                      }}
                                >
                                {_.includes(belairIdAuth, belairId) && _.includes(roles, 'gestionnaire indem') ? `Calculer l'IDA et la responsabilité` : `Poursuivre`}
                            </span>
                            </div>}
                        </Fragment>
                    }
                    {
                        !this.state.hideSecondForm && loadedIda && <Fragment>
                            {_.includes(belairIdAuth, belairId) && _.includes(roles, 'gestionnaire indem') && <>
                                <div className="col-md-12 col-sm-12" style={{marginBottom: 55}}>
                                    <div className={'row return-content header-row'}>
                                        <div className={'col'}>
                                        <span onClick={(e) => {
                                            e.preventDefault()
                                            changeForm()
                                        }} className={'btn btn-return'}>Retour au formulaire précédent</span>
                                        </div>
                                    </div>
                                    <h2 className={'text-center'}>Responsabilité et Cas IDA</h2>
                                    <p className={'text-center'}>Voici la responsabilité et le cas IDA <br/>calculés selon
                                        les données saisies lors de la déclaration de sinistre</p>
                                    <a className={'text-center d-block'} href={pdfIda} target="_blank" download>Téléchargez
                                        l'ensemble des cas IDA</a>
                                </div>
                                <div className="row margin-top card-sinistre">
                                    <div className="col">
                                        <ResponsabiliteContainer/>
                                    </div>
                                </div>
                            </>}
                            {vehiculeDommage === 'T' &&
                                <Fragment>
                                    <div className="row margin-top card-sinistre">
                                        <div className="col">
                                            <DommageVehiculeContainer changeValue={changeValue}/>
                                        </div>
                                    </div>
                                </Fragment>
                            }

                            {_.includes(belairIdAuth, belairId) && _.includes(roles, 'gestionnaire indem') &&
                                <div className="row margin-top card-sinistre">
                                    <div className="col">
                                        <GarantiesContainer/>
                                    </div>
                                </div>}

                            <div className="row margin-top card-sinistre">
                                <div className="col">
                                    <DocumentsContainer listDocuments={listDocuments} belairIdAuth={belairIdAuth}
                                                        belairId={belairId}/>
                                </div>
                            </div>

                            <button
                                className={'btn btn-primary btn-lg btn-block margin-top position-relative'}
                                disabled={loading}
                            >
                                {loading && <Spinner absolute/>}
                                Enregistrer la déclaration
                            </button>
                        </Fragment>
                    }

                    <Field
                        component={'input'}
                        type={'text'}
                        hidden
                        name={'risque'}
                    />
                </form>
            </div>
        );
    }
};

export default InitForm;
