import {connect} from 'react-redux'
import {compose} from "redux";
import AddTiersPayeur from "./AddTiersPayeur";
import {getLoadingEntity} from "../../../../../redux/selectors/ui/ui.selectors";
import {POST_CREATE_THIRD_PARTY_PAYER, postCreateTiersPayeur} from "../../../../../redux/actions/app/tiers_payeur/tiers_payeur.actions";
import {updateThirdPartyPayerGetLink} from "../../../../../redux/selectors/links/links.selectors";
import _ from "lodash";
import {change, formValueSelector, reduxForm} from "redux-form";
import validate from "./validate";
import moment from "moment/moment";

const mapStateToProps = (state, ownProps) => {
    const selector = formValueSelector('add_tiers_payeur')

    return {
        type: selector(state, 'type'),
        relationship: selector(state, 'relationship'),
        loading: getLoadingEntity(state, POST_CREATE_THIRD_PARTY_PAYER) === true,
        uri: updateThirdPartyPayerGetLink(state),
        idContract: ownProps.match.params.id,
        initialValues: {
            type: 'P',
            'birthdate': moment().subtract(14, 'year'),
        }
    }
}

const mapDispatchToProps = {
    postCreateTiersPayeur,
    changeValue: (field, value) => change('add_tiers_payeur', field, value)
}

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const { uri, idContract } = stateProps
    const { postCreateTiersPayeur } = dispatchProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        onSubmit: form => postCreateTiersPayeur({query: _.replace(uri, '{id}', idContract), form, otherData: {idContract}})
    }
}

const AddTiersPayeurContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    reduxForm({
        form: 'add_tiers_payeur',
        validate
    }),
)(AddTiersPayeur)

export default AddTiersPayeurContainer
