import React from 'react';
import Badge from "../../../../../Commun/Badge/Badge";
import {Field} from "redux-form";
import DatePickerFieldRedux from "../../../../../Commun/Input/DatePicker/DatePickerFieldRedux";
import TextFieldRedux from "../../../../../Commun/Input/Text/TextFieldRedux";
import Spinner from "../../../../../Commun/Spinner/Spinner";
import moment from "moment/moment";
import SelectFieldRedux from "../../../../../Commun/Input/Select/SelectFieldRedux";

const InitForm = (props) => {
    const {contract, handleSubmit, loading, history, validatePostAccident, dateSurvMax, dateSurv, dateDerPiece, isFleet, pieces} = props

    return (
        <div>
            {validatePostAccident ?
                <div className={'row margin-top text-center'}>
                    <div className={'col'}>
                        <h2 className={'title-primary title-success'}>Votre sinistre a bien été enregistré&nbsp;!</h2>
                    </div>
                </div>
                : <div className={'contract-container container'}>
                    <div className={'row return-content'}>
                        <div className={'col'}>
                            <button onClick={() => history.go(-1)} className={'btn btn-return'}>Retour</button>
                        </div>
                    </div>
                    <div className={'row'}>
                        <div className={'col-12'}>
                            <h1 className={'title-primary'}>Vous déclarez un sinistre non-déclaré sur le
                                tiers&nbsp;:&nbsp;{contract.souscripteur.nom}</h1>
                        </div>
                        <div className={'col-12'}>
                            <Badge status={contract.situation.statut}/>
                            <span
                                className={'bold text-uppercase subtitle'}>Contrat n°{contract.numeroPolice} - {contract.title}</span>
                        </div>
                    </div>

                    <div className="row header-row">
                        <div className={'col-12'}>
                            <form className={`form-content`} onSubmit={handleSubmit}>
                                <Field
                                    name={"dateSurv"}
                                    component={DatePickerFieldRedux}
                                    label={'Date de survenance'}
                                    minDate={moment(contract.situation.dateAffaireNouvelle)}
                                    maxDate={dateSurvMax}
                                    required
                                />

                                {
                                    !isFleet && dateSurv && dateSurv.isBefore(dateDerPiece) &&
                                    <>
                                        <div className={'alert alert-warning'}>
                                            <p>Attention, il y a une incohérence sur la date de survenance par rapport à la date de la
                                                dernière pièce.</p>
                                            <p>Merci de vérifier les données.</p>
                                        </div>

                                        <Field
                                            name='risque'
                                            component={SelectFieldRedux}
                                            label={'Changer de pièce sur le contrat'}
                                        >
                                            {
                                                pieces.map((p, index) =>
                                                    <option key={index}
                                                            value={p.adhesionPrincipale}>{p.motif} : {moment(p.date).format('L')}{p.dateFin ? ` - ${moment(p.dateFin).format('L')}` : ''}</option>
                                                )
                                            }
                                        </Field>
                                    </>
                                }

                                <Field
                                    name={'numCie'}
                                    component={TextFieldRedux}
                                    label={'Numéro de compagnie en référence commençant par "C"'}
                                    required
                                />

                                <p>Type de sinistre : <strong>Accident avec Tiers</strong></p>
                                <p>Responsabilité : <strong>100%</strong></p>
                                <p>Circonstances : <strong>NON DECLARE</strong></p>

                                {
                                    loading
                                        ? <Spinner/>
                                        : <button className={'btn btn-primary btn-block'} type={'submit'}>
                                            Enregistrer la déclaration
                                        </button>
                                }
                            </form>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
};

export default InitForm;
