import {connect} from 'react-redux'
import {bindActionCreators, compose} from 'redux'
import FormUpdateAccident from "./FormUpdateAccident";
import {getAccidentData} from "../../../../../../redux/selectors/accident/accident.selectors";
import {getLoadingEntity} from "../../../../../../redux/selectors/ui/ui.selectors";
import {change, formValueSelector, reduxForm} from "redux-form";
import {
    POST_UPDATE_ACCIDENT,
    postUpdateAccident
} from "../../../../../../redux/actions/app/accidents/accidents.actions";
import moment from "moment";
import {updateAccidentGetLink} from "../../../../../../redux/selectors/links/links.selectors";
import _ from "lodash";

const mapStateToProps = (state, ownProps) => {
    const selector = formValueSelector('update-accident')
    const accident = getAccidentData(state);

    return {
        idContract: ownProps.idContract,
        idAccident: ownProps.idAccident,
        query: updateAccidentGetLink(state),
        loading: getLoadingEntity(state, POST_UPDATE_ACCIDENT),
        defaultValueCodepostalCity: accident.codePostalAccident !== '99000' ? `${accident.villeAccident} ${accident.codePostalAccident}` : '',
        pays_lieu_sinistre: selector(state, 'pays_lieu_sinistre'),
        initialValues: {
            catsin: accident.categorieCode,
            dateSurv: moment(accident.dateSurv, "YYYY-MM-DD"),
            circonstances: accident.circonstances,
            decDommages: accident.decDommages,
            tauxResp: accident.tauxResp,
            corporel: accident.type === 'Corporel',
            code_postal: accident.codePostalAccident,
            lieu: accident.villeAccident,
            pays_lieu_sinistre: accident.codePostalAccident === '99000' ? 'T' : 'F',
            pays: accident.codePostalAccident === '99000' ? accident.villeAccident : null,
            ida: accident.ida
        }
    }
}


const mapDispatchToProps = dispatch => bindActionCreators({
    changeValue: (field, value) => change('update-accident', field, value),
    postUpdateAccident
}, dispatch)


const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {query, idContract, idAccident} = stateProps
    const {postUpdateAccident} = dispatchProps


    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        onSubmit: form => postUpdateAccident({
            form,
            query: _.replace(_.replace(query, '{id}', idContract), '{ids}', idAccident)
        })
    }
}


const FormUpdateAccidentContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    reduxForm({
        form: 'update-accident'
    })
)(FormUpdateAccident);


export default FormUpdateAccidentContainer
