import React, {Fragment} from 'react'
import TaskPrioCard from "../../../../Commun/Card/TaskPrioCard";

const TaskPrios = (props) => {
    const {tasks, url, getTaskPrio} = props

    return (
        <Fragment>
            {tasks && tasks.length ?
                tasks.map((taskprio, index) => (
                    <TaskPrioCard taskprio={taskprio} key={index} url={url} getTaskPrio={getTaskPrio}/>
                ))
                : <p className={'text-center'}>Aucune tâche priorisée.</p>
            }
        </Fragment>
    )
}

export default TaskPrios
