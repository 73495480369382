import React, {Component} from 'react'

class Versionning extends Component {
    handleClick = (id) => {
        this.props.submitPostValidateVersionning(id)
    }

    render() {
        const {versionning} = this.props

        return (
            versionning.length > 0 ?
                <div className={'row margin-top'}>
                    <div className={'col'}>
                        <div className={'bloc-info bloc-documents'}>
                            <h2>Mise à jour des données à effectuer</h2>
                            <div className={'call-to-action-bloc-info'}>
                                <span className={'btn btn-cta btn-version float-right'}
                                      onClick={() => this.handleClick(null)}>J'ai traité les modifications</span>
                            </div>

                            <div className={'content row'}>
                                <table className="table">
                                    <thead>
                                    <tr>
                                        <th scope="col">Champs</th>
                                        <th scope="col">Ancienne valeur</th>
                                        <th scope="col">Nouvelle valeur</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {versionning.map((v, index) =>
                                        <tr key={index}>
                                            <td>{v.field}</td>
                                            <td>{v.old_value}</td>
                                            <td>{v.new_value}</td>
                                            <td>
                                                <button onClick={() => this.handleClick(v.id)}
                                                        className={'btn btn-delete-red'}/>
                                            </td>
                                        </tr>
                                    )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                : <></>
        )
    }
}

export default Versionning
