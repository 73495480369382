

export const EDIT_HAMON = '[Edit Hamon]'
export const POST_EDIT_HAMON = `${EDIT_HAMON} Post`
export const SET_EDIT_HAMON = `${EDIT_HAMON} Set`


export const postEditHamon = ({insurer, police, query}) => ({
    type: POST_EDIT_HAMON,
    payload: {
        data: query,
        body: ({insurer, police}),
    }
})

export const setEditHamon = ({state}) => ({
    type: SET_EDIT_HAMON,
    payload: state
})
