import React from 'react';
import _ from 'lodash'
import moment from "moment";
import {Link} from "react-router-dom";
import CallToAction from "../../../../Commun/Button/CallToAction/CallToAction";
import {redirectInNewWindow} from "../../../../../utils/function";
import {listMailJet, openInWindow} from "../../../../../constants/constants";
import AccuseReceptionContainer from "./AccuseReception/AccuseReceptionContainer";

const ReclamationDetail = (props) => {
    const {reclamation, match: {params: {id, ids}}} = props
    let numero;

    switch (reclamation.type) {
        case 'sinistre':
            numero = reclamation.sinistre.numCbt
            break;
        case 'contrat':
            numero = reclamation.contrat.numeroPolice
            break;
        default:
            numero = reclamation.id_tiers
    }

    return (
        <div className={'cd-main-content'}>
            <div className={'contract-container container'}>
                <div className="row">
                    <div className="col-12 mb-3">
                        <div className={'d-inline-block'}>
                            <span className={'bold subtitle d-block'}>Réclamation n°{reclamation.numero_reclamation} sur le <Link
                                to={`/contrat/${reclamation.contrat.id}${reclamation.type === 'sinistre' ? `/sinistre/${reclamation.sinistre.id}` : ''}`}
                                className={'link-title-primary'}>{reclamation.type} n°{numero} <span
                                className={'link-icon-primary'}/></Link></span>
                        </div>
                        <div className="float-right">
                            <CallToAction
                                action={'edit-document'}
                                toolText={'Editer un document'}
                                onClick={(e) => redirectInNewWindow({
                                    e,
                                    url: `/ma-reclamation/${id}/${ids}/editer-document`
                                })}
                            />

                            <Link
                                className={`email call-to-action`}
                                to={{
                                    pathname: `/envoyer-email/libre/${listMailJet.libre}/${reclamation.id_tiers}/${reclamation.contrat.id}${reclamation.sinistre.id ? `/${reclamation.sinistre.id}` : ""}`,
                                    state: {
                                        reclamation: ids
                                    }
                                }}
                            >
                                <span className={'tooltips'}>
                                    Envoyer un email
                                </span>
                            </Link>
                            <CallToAction
                                action={'add-chrono'}
                                toolText={'Ajouter un chrono / agenda'}
                                onClick={(e) => redirectInNewWindow({
                                    e,
                                    url: `/ma-reclamation/${id}/${ids}/ajout-suivi`,
                                    name: openInWindow.addActivities
                                })}
                            />
                            <span className={'btn btn-cta btn-action float-right d-inline-block'}
                                  onClick={(e) => redirectInNewWindow({
                                      e,
                                      url: `/les-activites/reclamation/${reclamation.id}/${id}`,
                                      name: openInWindow.activitiesComplain
                                  })}>Les activités de la réclamation</span>
                        </div>
                    </div>
                </div>

                <div className={'row'}>
                    <div className={'col-12'}>
                        <h1 className={'title-primary'}>Détails de la {reclamation.motif}</h1>
                    </div>
                </div>

                {
                    reclamation.situationCode !== '03' && <div className="row">
                        <div className="col">
                            <Link
                                className={'btn btn-cta btn-danger mt-3 ml-0 mr-2'}
                                to={{
                                    pathname: `/envoyer-email/libre/${listMailJet.libre}/${reclamation.id_tiers}/${reclamation.contrat.id}${reclamation.sinistre.id ? `/${reclamation.sinistre.id}` : ""}`,
                                    state: {
                                        reclamation: ids,
                                        clotureReclamation: true
                                    }
                                }}
                            >
                                Envoyer un email et clôturer
                            </Link>
                            <Link
                                className={'btn btn-cta btn-danger mt-3 ml-0'}
                                onClick={(e) => redirectInNewWindow({
                                    e,
                                    url: `/ma-reclamation/${id}/${ids}/ajout-suivi-et-cloture`,
                                    name: openInWindow.addActivities
                                })}
                            >
                                Créer un chrono et clôturer
                            </Link>
                        </div>
                    </div>
                }

                <div className="row header-row">
                    <div className={'col-12'}>
                        <div className={'form-content'}>

                            <div className={'row'}>
                                <div className={'col'}>
                                    <p>Nature de la réclamation : <b>{reclamation.nature}</b></p>
                                </div>
                                <p className={'float-right mr-5'}>Situation : <span
                                    className={`badge badge-${reclamation.situation === 'En cours' ? 'danger' : reclamation.situation === 'Terminée' ? 'success' : 'warning'}`}>{reclamation.situation}</span>
                                </p>
                            </div>

                            <hr/>

                            <div className="row">
                                <div className={'col-6'}>
                                    <p>Date réception : <b>{moment(reclamation.date_reception).format('L')}</b></p>
                                </div>
                                <div className="col-6">
                                    <p>Suivi par : <b>{reclamation.tiers_suivi}</b></p>
                                </div>
                            </div>

                            <div className={'row'}>
                                <div className={'col'}>
                                    <p>Précisions : <b>{reclamation.description}</b></p>
                                </div>
                            </div>

                            <div className="row">
                                <div className={'col-6'}>
                                    <p>Date envoi avis de réception
                                        : <b>{reclamation.date_avis ? moment(reclamation.date_avis).format('L') : '-'}</b>
                                    </p>
                                </div>
                                <div className={'col-6'}>
                                    <p>Date réception de refus de réponse
                                        : <b>{reclamation.date_refus ? moment(reclamation.date_refus).format('L') : '-'}</b>
                                    </p>
                                </div>
                            </div>

                            <div className="row">
                                <div className={'col-6'}>
                                    <p>Date de la réponse
                                        : <b>{reclamation.date_reponse ? moment(reclamation.date_reponse).format('L') : '-'}</b>
                                    </p>
                                </div>
                                <div className={'col-6'}>
                                    <p>Affaire clôturée le
                                        : <b>{reclamation.date_cloture ? moment(reclamation.date_cloture).format('L') : '-'}</b>
                                    </p>
                                </div>
                            </div>

                            <div className="row">
                                <div className={'col-6'}>
                                    <p>Issue : <b>{reclamation.issue || '-'}</b></p>
                                </div>
                                <div className={'col-6'}>
                                    <p>Nature de la clôture : <b>{reclamation.nature_cloture || '-'}</b></p>
                                </div>
                            </div>

                            <hr/>

                            <div className="row">
                                <div className={'col-12'}>
                                    <p>Description des actions : <b>{reclamation.commentaire || '-'}</b></p>
                                </div>
                            </div>

                            <hr/>

                            <div className={'row'}>
                                <div className={'col-6'}>
                                    <p>Numéro de classement : <b>{reclamation.numero_reclamation}</b></p>
                                </div>
                                <div className={'col-6'}>
                                    <p>Date d'enregistrement
                                        : <b>{moment(reclamation.date_enregistrement).format('L')}</b></p>
                                </div>
                            </div>

                            <div className={'row'}>
                                <div className={'col-6'}>
                                    <p>Moment de vie : <b>{_.capitalize(reclamation.moment_vie)}</b></p>
                                </div>
                                <div className={'col-6'}>
                                    <p>Qualité de l'emetteur : <b>{_.capitalize(reclamation.qualite_emetteur)}</b></p>
                                </div>
                            </div>

                            <div className={'row'}>
                                <div className={'col-6'}>
                                    <p>Niveau de réclamation : <b>{_.capitalize(reclamation.niveau_recla)}</b></p>
                                </div>
                                <div className={'col-6'}>
                                    <p>Réclamation initiale : <b>{reclamation.recla_initiale ? 'Oui' : 'Non'}</b></p>
                                </div>
                            </div>

                            <div className={'row'}>
                                <div className={'col-6'}>
                                    <p>Assureur : {reclamation.assureur &&
                                        <b>{reclamation.assureur.nom}, {reclamation.assureur.adresse.rue1} {reclamation.assureur.adresse.codePostal} {reclamation.assureur.adresse.ville} </b>}</p>
                                </div>
                            </div>

                            <div className="text-center mt-4">
                                {
                                    reclamation.situationCode !== '02' &&
                                    <Link className='btn btn-primary btn-inline-block mr-2'
                                          to={`/ma-reclamation/transfert-niveau-2/${reclamation.id_tiers}/${reclamation.id}`}>
                                        Transférer au niveau 2
                                    </Link>
                                }
                                {
                                    reclamation.situationCode !== '03' && <AccuseReceptionContainer id={id} ids={ids}/>
                                }
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

ReclamationDetail.defaultProps = {};

ReclamationDetail.propTypes = {};

export default ReclamationDetail;
