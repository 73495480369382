import React from 'react';
import './checkbox.scss'

const CheckboxFieldRedux = (props) => {
    const {input, meta: {touched, error}, label, className, id} = props

    let newChecked = input.value
    if (input.value === "F") {
        newChecked = false
    } else if (input.value === "T") {
        newChecked = true
    }

    return (
        <div className={`form-group ${className}`}>
            <div className={'styled-checkbox'}>
                <input
                    type="checkbox"
                    id={id || input.name}
                    {...input}
                    checked={newChecked}
                />
                <label htmlFor={id || input.name}>{label}</label>
                {touched && error && <small className="invalid-feedback">{error}</small>}
            </div>
        </div>
    );
};

CheckboxFieldRedux.defaultProps = {
    meta: {
        touched: false,
        error: false
    },
    className: '',
    id: ''
}

export default CheckboxFieldRedux;
