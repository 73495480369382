import {bindActionCreators, compose} from 'redux'
import {connect} from 'react-redux'
import {ACCIDENT, getAccident, postDefineAsCovered} from '../../../../redux/actions/app/accidents/accidents.actions'
import {getErrorEntity, getLoadingEntity} from '../../../../redux/selectors/ui/ui.selectors'
import Accident from './Accident'
import {
    accidentGetLink,
    contractDefaultGetLink, AccidentDefineAsCoveredGetLink
} from '../../../../redux/selectors/links/links.selectors'
import ErrorHttpComponent from '../../../Commun/Error/ErrorHttpComponent'
import {getAccidentData} from '../../../../redux/selectors/accident/accident.selectors'
import _ from "lodash";
import {CONTRACT, getContract} from "../../../../redux/actions/app/contracts/contracts.actions";
import {getContractData} from "../../../../redux/selectors/contract/contract.selectors";
import {getUserPermissions, getUserRoles} from "../../../../redux/selectors/user/user.selectors";
import loader from "../../../Commun/Spinner/Loader/Loader";

const mapStateToProps = (state, ownProps) => {
    return {
        idContract: ownProps.match.params.id,
        idAccident: ownProps.match.params.ids,
        accident: getAccidentData(state),
        contract: getContractData(state),
        uri: accidentGetLink(state),
        loaded: getLoadingEntity(state, ACCIDENT) === false && getLoadingEntity(state, CONTRACT) === false,
        error: getErrorEntity(state, ACCIDENT) && getErrorEntity(state, CONTRACT) === false,
        uri_contract: contractDefaultGetLink(state),
        roles: getUserRoles(state),
        permissions: getUserPermissions(state),
        uri_define_covered: AccidentDefineAsCoveredGetLink(state)
    }
}

const mapDispatchToProps = dispatch => bindActionCreators({
    getAccident: ({query, id}) => getAccident({query, id}),
    getContract: (query) => getContract({query}),
    postDefineAsCovered,
}, dispatch)

const mergeProps = (stateProps, dispatchProps, ownProps) => {
    const {getAccident, getContract, postDefineAsCovered} = dispatchProps
    const {uri, idContract, idAccident, uri_contract, uri_define_covered} = stateProps

    return {
        ...ownProps, ...stateProps, ...dispatchProps,
        submitDefinedAsCovered: () => postDefineAsCovered({query: _.replace(_.replace(uri_define_covered, '{id}', idContract), '{ids}', idAccident)}),
        load: () => {
            getAccident({query: _.replace(_.replace(uri, '{id}', idContract), '{ids}', idAccident), id: idAccident})
            getContract(_.replace(uri_contract, '{id}', idContract))
        }
    }
}

const AccidentContainer = compose(
    connect(mapStateToProps, mapDispatchToProps, mergeProps),
    loader({ErrorIndicator: ErrorHttpComponent})
)(Accident)

export default AccidentContainer
