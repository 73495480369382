import {apiRequest} from '../../../actions/core/api/api.actions'
import {GET_TASKPRIO, TASKPRIO} from "../../../actions/app/taskprios/tasks.actions";

export const taskPrioDetailMiddleware = () => next => action => {
    next(action)

    switch (action.type) {
        case GET_TASKPRIO:
            next([
                apiRequest({body: null, method: 'GET', url: action.payload.data, entity: TASKPRIO}),
            ])
            break
        default:
            break;
    }
    return null
}
