import React from 'react';
import {Field} from "redux-form";
import SelectFieldRedux from "../../../../../Commun/Input/Select/SelectFieldRedux";
import TextAreaFieldRedux from "../../../../../Commun/Input/Text/TextAreaFieldRedux";
import Spinner from "../../../../../Commun/Spinner/Spinner";

const ReclamationClose = (props) => {
    const {handleSubmit, tiers, loading} = props

    return (
        <div className={'contract-container container'}>
            <h1 className={'title-primary'}>{tiers.titre} {tiers.name} {tiers.belair_id}</h1>

            <div className={'row header-row'}>
                <div className={'col-12'}>
                    <form className={`form-content`} onSubmit={handleSubmit}>
                        <h2 className="title-primary text-center d-block">Clotûre de la réclamation</h2>

                        <div className="row">
                            <div className="col-6">
                                <Field
                                    component={SelectFieldRedux}
                                    name='nature'
                                    label={'Nature de la clôture'}
                                >
                                    <option value={""}>Choisissez la nature de la clôture</option>
                                    <option value={"01"}>Terminée</option>
                                    <option value={"02"}>Abandonnée</option>
                                    <option value={"03"}>Contentieux</option>
                                    <option value={"04"}>Transfert Assistance</option>
                                    <option value={"05"}>Transfert Compagnie</option>
                                    <option value={"06"}>Transfert Expert</option>
                                    <option value={"99"}>Autres</option>
                                </Field>
                            </div>

                            <div className="col-6">
                                <Field
                                    component={SelectFieldRedux}
                                    name='issue'
                                    label={'Issue'}
                                >
                                    <option value={""}>Choisissez une issue</option>
                                    <option value={"1"}>Favorable</option>
                                    <option value={"2"}>Partiellement favorable</option>
                                    <option value={"3"}>Refusée</option>
                                    <option value={"4"}>Transfert</option>
                                    <option value={"5"}>Sans Suite</option>
                                </Field>
                            </div>

                            <div className="col-12">
                                <Field
                                    name={'description'}
                                    component={TextAreaFieldRedux}
                                    label={'Description des actions'}
                                    subLabel={'255 caractères maximum'}
                                    maxLength={'255'}
                                    rows="5"
                                />
                            </div>
                        </div>

                        {
                            loading ? <Spinner class={'float-right'}/> :
                                <button className={'btn btn-primary btn-inline-block mt-3 float-right'} type={'submit'}>
                                    Valider la clôture
                                </button>
                        }
                    </form>
                </div>
            </div>
        </div>
    );
};

export default ReclamationClose;
