import {API_ERROR, API_SUCCESS, apiRequest} from '../../../actions/core/api/api.actions'
import {setError, setLoader} from '../../../actions/app/ui/ui.actions'
import {
    GET_VERSIONNING,
    VERSIONNING,
    setVersionning,
    VALIDATE_VERSIONNING,
    POST_VALIDATE_VERSIONNING, setValidateVersionning
} from "../../../actions/app/versionning/versionning.actions";

import {setNotification} from "../../../actions/core/notifications/notifications.actions";

export const versionningMiddleware = () => next => action => {
    next(action)

    switch (action.type) {

        case GET_VERSIONNING:
            next([
                apiRequest({body: null, method: 'GET', url: action.payload.data, entity: VERSIONNING}),
                setLoader({state: true, entity: VERSIONNING})
            ])
            break

        case `${VERSIONNING} ${API_SUCCESS}`:
            const {data} = action.payload.data

            next([
                setVersionning({data}),
                setError({state: false, entity: VERSIONNING}),
                setLoader({state: false, entity: VERSIONNING})
            ])
            break

        case `${VERSIONNING} ${API_ERROR}`:
            next([
                setError({state: true, entity: VERSIONNING}),
                setLoader({state: false, entity: VERSIONNING})
            ])
            break

        case POST_VALIDATE_VERSIONNING:
            next([
                apiRequest({
                    body: (action.payload.body),
                    method: 'POST',
                    url: action.payload.data,
                    entity: VALIDATE_VERSIONNING
                }),
                setLoader({state: true, entity: VALIDATE_VERSIONNING})
            ])
            break

        case `${VALIDATE_VERSIONNING} ${API_SUCCESS}`:
            next([
                setValidateVersionning({state: true}),
                setNotification({
                    entity: VALIDATE_VERSIONNING,
                    body: `<p>La validation de la mise à jour des données a été enregistrée</p>`,
                    type: 'success',
                    title: 'Mise à jour enregistrée !'
                }),
                setLoader({state: false, entity: VALIDATE_VERSIONNING}),
                setError({state: false, entity: VALIDATE_VERSIONNING}),
                window.location.reload()
            ])
            break

        case `${VALIDATE_VERSIONNING} ${API_ERROR}`:
            next([
                setNotification({
                    entity: VALIDATE_VERSIONNING,
                    body: `<p>Votre demande n'a pas pu être envoyée.</p>`,
                    type: 'error',
                    title: 'Erreur lors de l\'enregistrement !',
                    timeout: '0'
                }),
                setError({state: true, entity: VALIDATE_VERSIONNING}),
                setLoader({state: false, entity: VALIDATE_VERSIONNING}),
            ])
            break

        default:
            break
    }
    return null
}
